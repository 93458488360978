import React from 'react'

import { FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'

import logo from '../../images/logos/rectangle-logo-with-r.png'
import DefaultLayout from '../../layouts/DefaultLayout'
import { above, colors } from '../../styles/utilities'
import VideoEmbed from '../../v3/components/VideoEmbed'
import heroDesktop from './hero-desktop.jpg'
import heroMobile from './hero-mobile.jpg'

type TAApplicantPageProps = {
  className?: string
}

const TAApplicantPage = ({ className }: TAApplicantPageProps) => {
  const year = new Date().getFullYear()
  return (
    <DefaultLayout>
      <div className={className}>
        <div className="header">
          <div className="container">
            <div className="header-flex">
              <img src={logo} alt="Ultimate Medical Academy" />
            </div>
          </div>
        </div>
        <div className="container">
          <h1>Thank you for your application!</h1>
          <p>Learn why others choose UMA.</p>
        </div>
        <div className="hero container">
          <VideoEmbed
            className="video-embed"
            embed={() => (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/l05P2TiyX6Q?rel=0&autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
            poster={() => (
              <picture>
                <source media="(min-width: 768px)" srcSet={`${heroDesktop}`} />
                <img src={heroMobile} alt="UMA building" />
              </picture>
            )}
          />
        </div>
        <div className="container">
          <h2>Empowering people to make a difference.</h2>
          <p>
            Ultimate Medical Academy is a nonprofit healthcare career school
            built on care. We believe that providing quality care to our team
            members allows them to offer that same level of care to our
            students.{' '}
          </p>
          <p>
            In turn, we prepare those students to support an industry that helps
            others. We call this the Ripple Effect of Care.
          </p>
          <h3>
            While playing an important role in this process, UMA team members
            may receive benefits such as:
          </h3>
          <ul className="green-ul">
            <li>
              Flexible work arrangements to accommodate different schedules.
            </li>
            <li>
              15 days of PTO with 8 holidays in the first year for full-time
              team members.
            </li>
            <li>
              Personal development options to help you excel in your career.
            </li>
            <li>Volunteer time off for full and part-time team members.</li>
            <li>Mental wellness benefits for full-time team members.</li>
            <li>A 401(k) plan, and more.</li>
          </ul>
          <p>
            UMA care starts with our team — people like you who are willing to
            support others in their pursuit of an education and a career.
          </p>
        </div>
        <div className="questions">
          <div className="container">
            <div className="questions-inner">
              <h2>Questions?</h2>
              <p>
                Contact our Talent Acquisitions team. We&apos;re here to help.
              </p>
              <p>
                <strong>Call 813-605-8400.</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <p>
              UMA is institutionally accredited by the Accrediting Bureau of
              Health Education Schools (ABHES.org), a national accrediting
              agency that is recognized by the U.S. Secretary of Education.
            </p>
            <p>
              © {year} UMA Education, Inc. d/b/a Ultimate Medical Academy |{' '}
              <a
                href="https://www.ultimatemedical.edu/about/citations/"
                className="td-n-hu"
              >
                Marketing Statistics
              </a>{' '}
              |{' '}
              <a
                href="https://www.ultimatemedical.edu/privacy-policy/"
                className="td-n-hu"
              >
                Privacy Policy
              </a>
            </p>
            <p>
              Ultimate Medical Academy does not recruit or enroll residents of
              the European Union.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

TAApplicantPage.defaultProps = {
  className: '',
}

export default styled(TAApplicantPage)`
  h1,
  h2,
  h3 {
    color: ${colors.umaDarkBlue};
  }
  .header {
    background-color: ${colors.umaUltimateLightGray};
    padding: 0.75rem 0;
  }
  .header-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .hero {
    img {
      max-width: 100%;
      width: 100%;
    }
    .video-embed {
      button {
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 100%;
        cursor: pointer;
      }
    }
    .video-container {
      position: relative;
      iframe {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
  .questions {
    background-color: ${colors.umaDarkBlue};
    text-align: center;
    margin: 4rem 0 0 0;

    ${above.md`
      background-color: white;
      margin: 4rem 0;
    `}

    h2 {
      color: white;
    }
    p {
      color: white;
    }

    .questions-inner {
      padding: 2rem 0;
      ${above.md`
        background-color: ${colors.umaDarkBlue};
      `}
    }
  }
  .footer {
    background-color: ${colors.umaUltimateLightGray};
    padding: 2rem 0;
  }
`
