import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useEffect } from "react";
import constants from "../../utilities/constants";
import QueryStringParser from "@ultimatemedical/uma-utils/dist/v2/queryString/parser";

const RememberTraceParams = ({acornParamConfig, sparkroomParamConfig, whichForm}) => {
  useEffect(() => {
    const now         = new Date(),
          expiry      = now.setMinutes(now.getMinutes() + 30),
          qsp         = new QueryStringParser(window.location.search),
          paramConfig = whichForm === "acorn" ? acornParamConfig : sparkroomParamConfig;
    qsp.saveToLocalStorage(
      constants.LOCALSTORAGE_KEY_TRACE, expiry, paramConfig
    );
  }, [acornParamConfig, sparkroomParamConfig, whichForm]);
  return null;
};

const mapStateToProps = state => {
  return {
    acornParamConfig: state.acornFormParams,
    sparkroomParamConfig: state._r_formParams
  }
};

RememberTraceParams.propTypes = {
  whichForm: PropTypes.oneOf(["acorn", "sparkroom"]).isRequired
};

RememberTraceParams.defaultProps = {
  whichForm: "sparkroom"
};

export default connect(mapStateToProps)(RememberTraceParams);