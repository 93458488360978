import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import DefaultLayout from '@Layouts/Default'
import renderModules from '@Util/renderModules'
import { hiddenFormParamsAction, UMAAction } from '@Redux/actions'
import collectFrontmatterFormParams from '@Util/collectFrontmatterFormParams'

const DefaultTemplate = ({ className, data }) => {

  const dispatch = useDispatch()
  
  const hiddenFormParams = collectFrontmatterFormParams(
    data.markdownRemark.frontmatter.form.hiddenParams
  )
  const modules = data?.markdownRemark?.frontmatter?.modules?.map(m => {
    m.frontmatter = data?.markdownRemark?.frontmatter
    m.html = data?.markdownRemark?.html
    return m;
  })
  const seo = data?.markdownRemark?.frontmatter?.seo
  const uma = data?.markdownRemark?.frontmatter?.uma
  
  useEffect(() => {
    dispatch(hiddenFormParamsAction.updateMultiple(hiddenFormParams))
    dispatch(UMAAction.updateCampus(uma.campus))
    dispatch(UMAAction.updatePhone(uma.phone))
  }, [data, dispatch, hiddenFormParams, uma.campus, uma.phone])

  return (
    <DefaultLayout seo={seo} className={className}>
      {modules.length && modules && renderModules(modules)}
    </DefaultLayout>
  )
}

export default styled(DefaultTemplate)``

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        uma {
          campus
          phone
        }
        modules {
          module
        }
        form {
          ...Form
        }
      }
      ...Seo
      ...ModuleHeroScalableConstrained
      ...ModuleContentAndForm
      ...ModuleProgramToggle
      ...ModuleQuote
    }
  }
`
