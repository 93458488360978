/* eslint-disable @typescript-eslint/no-shadow, no-param-reassign */

import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

/**
 * A validator is a function that takes a value of type string and returns an
 * error message of type string.
 */
export type ValidatorFn = (v: string) => string

export function required(msg?: string): (v: string) => string {
  if (!msg) msg = 'Required'
  return (v: string) => {
    if (v === '') return msg
    return ''
  }
}

export function min(len: number, msg?: string): (v: string) => string {
  if (!msg) msg = 'Please enter at least two characters'
  return (v: string) => {
    if (v.length < len) return msg
    return ''
  }
}

export function len(
  min: number,
  max: number,
  msg?: string,
): (v: string) => string {
  if (!msg && min === max) msg = `Field length should be ${min} characters`
  else if (!msg) {
    msg = `Field length should be between ${min} and ${max} characters`
  }
  return (v: string) => {
    if (v.length < min || v.length > max) return msg
    return ''
  }
}

export function email(msg?: string): (v: string) => string {
  if (!msg) msg = 'Please enter a valid email address'
  return (v: string) => {
    if (!isEmail(v)) return msg
    return ''
  }
}

export function phone(msg?: string): (v: string) => string {
  if (!msg) msg = 'Please enter a valid phone number'
  return (v: string) => {
    if (!isMobilePhone(v)) return msg
    return ''
  }
}
