import React, { useEffect, useRef, useState } from 'react'

import Form from '@ultimatemedical/acorn/dist/FormSelectCampus'
import FormParamBuilder from '@ultimatemedical/uma-utils/dist/v2/formParam/builder'
import QueryStringParser from '@ultimatemedical/uma-utils/dist/v2/queryString/parser'
import PropTypes from 'prop-types'
import parse from 'react-html-parser'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { AcornFormParams } from '../../redux/actions'
import { colors } from '../../styles/utilities'
import addQueryStringParams from '../../utilities/addQueryStringParams'
import constants from '../../utilities/constants'
import { getGAClientID } from '../../utilities/cookie'
import { reqInfoDisc } from '../../utilities/disclaimers'

const AcornFormWrapper = ({
  aboveForm,
  beforeSubmitCallback,
  clearwaterOptionLabel,
  className,
  dispatch,
  excludePrograms,
  includeProgramSelect,
  overrideFormLabels,
  redirect,
  removeFormParamsAndSpecifyDefaultValues,
  _r_formParams,
}) => {
  // form ref
  // used to query for inputs to change their order on page load
  const formRef = useRef()
  useEffect(() => {
    const phoneInput = formRef.current.querySelector('input[name="Phone"]')
      .parentNode
    const emailInput = formRef.current.querySelector('input[name="Email"]')
      .parentNode
    emailInput.parentNode.insertBefore(emailInput, phoneInput)
  }, [formRef])

  // default local state
  const [formParams, setFormParams] = useState([])
  const [redirectState, setRedirect] = useState(redirect)

  // build form params (hidden inputs)
  // we're initializing with values from redux,
  // then adding local storage overrides,
  // then query string overrides
  useEffect(() => {
    const qsp = new QueryStringParser(window.location.search)
    const fpb = new FormParamBuilder(_r_formParams)
      .withOverrides(
        JSON.parse(
          localStorage.getItem(constants.LOCALSTORAGE_KEY_TRACE) || '{}',
        ),
        { pairs: qsp.all() },
      )
      .build()
    setFormParams(fpb.getKeyValuePairs())

    const clientID = getGAClientID()
    if (clientID) {
      setFormParams((prevState) => {
        const newState = [...prevState]
        newState.push({
          key: 'clientId',
          value: clientID,
        })
        return newState
      })
    }
  }, [_r_formParams])

  return (
    <div className={`${className} form-wrap`} ref={formRef}>
      {aboveForm && aboveForm()}
      <Form
        excludePrograms={excludePrograms}
        useShortProgramNames
        requiredFieldMarkers
        clearwaterOptionLabel={clearwaterOptionLabel}
        beforeSubmitCallback={beforeSubmitCallback}
        redirect={redirectState}
        formParams={formParams}
        // programSelectDefault="UNSPEC"
        selectPlaceholder="Select One"
        includeProgramSelect={includeProgramSelect}
        leadIdCallback={(token) => {
          setRedirect(addQueryStringParams(redirect, { lid: token }))
          dispatch(
            AcornFormParams.update([
              {
                key: 'leadid_token',
                value: token,
              },
            ]),
          )
        }}
        overrideFormLabels={
          overrideFormLabels || {
            Email: 'Email Address',
          }
        }
        removeFormParamsAndSpecifyDefaultValues={
          removeFormParamsAndSpecifyDefaultValues || {}
        }
        // submitButtonText={parse('Request Info <span class="spinner">&gt;</span>')} />
        submitButtonText={parse('Request Info')}
      />
      <p className="disclaimer mb-0">
        <small>{reqInfoDisc}</small>
      </p>
    </div>
  )
}

AcornFormParams.propTypes = {
  aboveForm: PropTypes.func,
  beforeSubmitCallback: PropTypes.func,
  className: PropTypes.string,
  excludePrograms: PropTypes.arrayOf(PropTypes.string),
  overrideFormLabels: PropTypes.object,
  redirect: PropTypes.string,
  removeFormParamsAndSpecifyDefaultValues: PropTypes.object,
}

AcornFormParams.defaultProps = {
  excludePrograms: [],
}

const StyledAcornFormWrapper = styled(AcornFormWrapper)`
  padding: 1rem;
  font-size: 0.9rem;

  input,
  select {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.45rem;
    width: 100%;
  }

  .input-wrap {
    background-color: #fff;
    border-radius: 1px;
    margin: 1rem 0;
    position: relative;
  }

  button {
    background-color: ${colors.umaGreen};
    border: none;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    padding: 1rem;
    text-transform: uppercase;
    width: 100%;

    &[disabled] {
      background-color: ${colors.umaDarkGray};
      cursor: not-allowed;
    }
  }

  .label-and-error {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0.5rem 0.25rem 0.75rem;
    width: 100%;
    font-size: 0.75rem;
    font-weight: bold;

    label {
      white-space: nowrap;
    }

    .error {
      color: ${colors.umaRed};
      font-size: 0.7rem;
      text-align: right;
      font-weight: normal;
    }
  }

  .disclaimer {
    color: #fff;
    font-size: 0.9rem;
    line-height: 1;
  }

  .success.top-msg,
  .error.top-msg {
    background-color: ${colors.umaGreen};
    border-radius: 2px;
    color: #fff;
    padding: 0.5rem;
    line-height: 1;

    p {
      margin: 0;
    }
  }

  .error.top-msg {
    background-color: ${colors.umaRed};

    a {
      color: #fff;
    }
  }

  .required {
    color: ${colors.umaRed};
  }
`

const mapStateToProps = (state) => ({
  _r_formParams: state.acornFormParams,
})

export default connect(mapStateToProps)(StyledAcornFormWrapper)
