// Node Module Imports
import htmr from 'htmr'
import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Component Imports
import CNContentTop from './chat-now/CNContentTop'
import CNHeader from './chat-now/CNHeader'
import PS92020Hero from '../components/heroes/PS92020Hero'
import DRTVHero from '../components/heroes/PS92020Hero'
import HeroScalableLogo from '../components/HeadersAndHeroes/HeroScalableLogo'
import ImageToutsCareMantra from '../components/cross-template-statics/ImageToutsCareMantra'
import Layout from '../components/Layout'
import LogoSquare from '../components/LogoSquare'
import NumberTouts from '../components/cross-template-statics/NumberTouts'
import Quote from '../components/reusables/Quote'
import SEO from '../components/SEO'
import VideoLazyload from '../components/reusables/VideoLazyload'
import YouTubeEmbed from '../components/reusables/YouTubeEmbed'

// Style Imports
import { above, below, colors } from '../styles/utilities'

// Hooks Imports
import useGa from '../hooks/useGa'

// Redux Imports
import { store } from '../redux'
import { AcornFormParams } from '../redux/actions'

// Utility Imports
import findInObject from '../utilities/findInObject'
import sp from '../utilities/shortcodeParser'

const TemplateCallNowNewer = ({ className, data }) => {
  const onlineOnly = findInObject('onlineOnly', data.markdown.frontmatter)

  const settings = {
    allowNull: true,
  }

  const leadsource = findInObject(
      'tracking.leadsource',
      data.markdown.frontmatter,
      settings,
    ),
    leadvendor = findInObject(
      'tracking.leadvendor',
      data.markdown.frontmatter,
      settings,
    ),
    vendor = findInObject(
      'tracking.vendor',
      data.markdown.frontmatter,
      settings,
    ),
    phoneNumber = findInObject('phone_number', data.markdown.frontmatter, {
      strictMode: true,
    })

  if (leadsource) {
    store.dispatch(AcornFormParams.updateParamValue('LeadSource', leadsource))
  }
  if (leadvendor) {
    store.dispatch(AcornFormParams.updateParamValue('LeadVendor', leadvendor))
  }
  if (vendor) {
    store.dispatch(AcornFormParams.updateParamValue('Vendor', vendor))
  }

  const excludePrograms = data?.markdown?.frontmatter?.v1?.form?.excludePrograms?.map(
    (p) => p.code,
  )

  const formEvent = useGa()

  const sortPrograms = (programEdges) => {
    return programEdges.sort((a, b) => {
      let aNorm = a.node.meta.program_full_name.toLowerCase(),
        bNorm = b.node.meta.program_full_name.toLowerCase()
      if (aNorm < bNorm) {
        return -1
      } else if (aNorm > bNorm) {
        return 1
      }
      return 0
    })
  }

  const sortedOnlinePrograms = sortPrograms(data.onlineDegreePrograms.edges),
    sortedClearwaterPrograms = sortPrograms(
      data.clearwaterDiplomaPrograms.edges,
    )

  const programLinkPathMapperOnline = [
    'health-and-human-services-2',
    'health-information-technology-2',
    'medical-administrative-assistant-2',
    'medical-office-billing-specialist-2',
    'pharmacy-technician',
    'healthcare-accounting',
    'healthcare-management-2',
    'medical-billing-coding-2',
  ]

  const programLinkPathMapperClearwater = [
    'dental-assistant-2',
    'medical-assistant-2',
    'nursing-assistant-2',
    'patient-care-technician-2',
    'phlebotomy-2',
    'dental-assistant-2',
    'medical-assistant-2',
  ]

  const fm = data.markdown.frontmatter

  return (
    <Layout
      seo={'seo'}
      showDisclaimer={true}
      sticky={{
        display_sticky_footer: true,
        chat_button_id_select: {
          label: 'Custom',
          value: 'LP_DIV_1432733350793',
        },
        chatBtnId: 'LP_DIV_1432733350793',
      }}
      phone={fm.phone_number}
    >
      <SEO
        title="Your passion. Our mission. Because we care."
        description="Life can be more rewarding when we care for others. With care, small ripples can become waves that have the potential to strengthen communities and inspire other acts of care."
      />
      <div className={className} id={data.markdown.frontmatter.template.id}>
        <CNHeader
          chatButtonColor="#0095CB"
          chatIDForHeader="LP_DIV_lead_nurturing"
          phone={fm.phone_number}
        />

        <HeroScalableLogo heroType="scalable" template="chat-now" />

        <PS92020Hero
          bgImgSm={fm.hero.img_sm.path.publicURL}
          bgImgLg={fm.hero.img_lg.path.publicURL}
          phoneNumber={fm.phone_number}
          className="d-lg-none"
        >
          <div className="container hero-content">
            <div className="row pt-5 ">
              <div className="col-lg-7">
                <p className="mb-0 mt-0">{htmr(sp(fm.hero.text))}</p>
              </div>
            </div>
          </div>
        </PS92020Hero>

        <DRTVHero
          bgPosition="50% center"
          bgImgSm={fm.hero.img_sm.path.publicURL}
          bgImgLg={fm.hero.img_lg.path.publicURL}
          hideLogoAndPhone={true}
          phoneNumber={fm.phone_number}
          className="d-none d-lg-block"
        >
          <div className="container hero-content">
            <div className="row pt-5 ">
              <div className="col-lg-7">
                <p className="mb-0 mt-0">{htmr(sp(fm.hero.text))}</p>
              </div>
            </div>
          </div>
        </DRTVHero>

        <CNContentTop
          id="clearwater-care-mobile-phone-btn"
          template="call-now"
          phone={fm.phone_number}
          doNotMoveChatBox={false}
          chatBox={{
            chat_box_color: {
              label: 'Green',
              value: '#78BE20',
            },
            chat_button_color: {
              label: 'Light Blue',
              value: '#0095CB',
            },
            disclaimer: fm.call_box.under_button,
            heading: fm.call_box.header,
          }}
          chatButtonColor="#78BE20"
          chatIDForChatBox="LP_DIV_lead_nurturing_3"
          chatIDForMobileContent="LP_DIV_lead_nurturing_2"
          pageClass=""
          general={{
            logo_type: 'horizontal',
            chat_button_text: 'Chat Now',
            chat_id_header: 'LP_DIV_lead_nurturing',
            chat_id_mobile: 'LP_DIV_lead_nurturing_2',
            chat_id_sidebar: 'LP_DIV_lead_nurturing_3',
            header_chat_color_override: null,
            mobile_chat_color_override: { value: '#78BE20' },
          }}
          markdownContent={() => (
            <>
              {htmr(sp(fm.content_section.above_video))}
              <VideoLazyload
                posterAlt="Care Pays Back video placeholder. Click or press enter to play."
                posterSrc={fm.content_section.video_poster.path.publicURL}
                includePlayIcon={false}
              >
                <YouTubeEmbed
                  className="video-iframe"
                  width={999}
                  videoId={fm.content_section.video_id}
                  title="Care Pays Back"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </VideoLazyload>

              {htmr(sp(fm.content_section.below_video))}
            </>
          )}
        />
      </div>
    </Layout>
  )
}

export default styled(TemplateCallNowNewer)`
  &#clearwater-care {
    .ga-call {
      ${below.md`
        transform: translate(25vw, 40%);
      `}
      &.fb-track-click {
        ${below.md`
        transform: translate(0px, 0px);
      `}
      }
    }
    .section--hero {
      min-height: 100vw;
      ${above.sm`
        min-height: 29.6vw;
      `}
    }
  }
  p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1,
  h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .header-wrapper {
    background-color: ${colors.umaLightBlue};
    padding: 0.75rem 0;
  }

  [class^='form-wrap'] {
    h2.form-font {
      font-size: 2rem;
    }
    p.form-font {
      font-size: 1rem;
      ${above.lg`
        font-size: 1.25rem;
      `}
    }
  }

  .form-wrapper {
    background-color: ${colors.umaDarkBlue};
    > .container {
      padding: 0 7px;
    }
    .form-container {
      margin: 0 auto;
      padding: 0;
      max-width: 500px;
      h2,
      p {
        text-align: center;
      }
    }

    form {
      flex-wrap: wrap;
      justify-content: space-between;

      ${above.lg`
        display: flex;
      `}
    }

    form .input-wrap,
    form .button-wrap {
      flex: 0 0 48.5%;
      margin: 10px 6.75px;
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      select {
        font-size: 1rem;
      }
    }
    form .button-wrap {
      display: flex;
      margin: 1.5rem 6.75px;
    }
  }

  .hero-content {
    .row {
      padding-top: 8rem;

      ${above.lg`
        padding-top: 2rem;
        padding-bottom: 2rem;      
      `}
    }

    p {
      color: #fff;
      font-size: 3rem;
      line-height: 1;
      text-transform: uppercase;

      ${above.lg`
        font-size: 4rem;
      `}
    }

    .arrow {
      background-color: ${colors.umaOrange};
      box-shadow: 8px 8px 6px hsla(0, 0%, 0%, 0.4);
      border-radius: 1px;
      display: inline-block;
      margin-top: 1.5rem;
      position: relative;
      max-width: 88%;

      svg {
        border-radius: 1px;
        fill: ${colors.umaOrange};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        width: auto;
      }
    }

    .arrow p {
      font-size: 1.5rem;

      ${above.md`
        font-size: 2rem;
      `}
    }
  }

  .page-content {
    h1 {
      margin-top: 0;
    }

    li {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    strong {
      color: ${colors.umaTeal};
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: bold;
    }
  }

  .program-stack {
    color: white;

    .heading {
      color: white;
    }

    .Link {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .online,
    .clearwater {
      position: relative;

      .bg-img-wrap,
      .text {
        ${above.md`
          margin-top: -3.45rem;
        `}
      }

      .bg-img-wrap {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        overflow: hidden;
      }

      .text {
        background-color: ${colors.umaDarkBlue};
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        overflow: hidden;
      }
    }

    .online::before,
    .clearwater::before {
      content: '';
      display: block;
      background-color: ${colors.umaGreen};
      position: absolute;
      top: 3.45rem;
      right: 0;
      bottom: 3.45rem;
      left: 0;

      ${above.md`
        top: 0; bottom: -3.45rem;
        padding-bottom: 3.45rem;
      `}
    }

    .online {
      ${above.sm`
        margin-bottom: 3.5rem;
        margin-top: 4rem;
      `}
      ${above.md`
      margin-top: 8rem;
      `}
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }

    .clearwater {
      ${above.md`
        margin-bottom: 4rem;
      `}
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .phone {
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    font-weight: normal;
    text-decoration: none;

    svg {
      transform: scaleX(-1);
      font-size: 1.5rem;

      ${above.sm`
        margin-right: 0.5rem;
        font-size: 1rem;
      `}
    }
  }

  .form-wrap--lg {
    position: relative;
    ${above.lg`
      box-shadow: -8px -8px 6px hsla(0, 0%, 0%, 0.4);
      margin-top: -10rem;
    `}

    &::before {
      background-color: ${colors.umaLightBlue};
      border-radius: 2px;
      content: '';
      display: block;
      opacity: 0.9;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    .form--lg {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .mobile-menu-wrap {
    background: ${colors.umaLightBlue};
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: -100%;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    &.active {
      display: block;
      left: 0;
      opacity: 0.9;
    }
  }

  .mobile-menu {
    background-color: ${colors.umaDarkGray};
    color: #fff;
    font-size: 0.9rem;
    max-width: 350px;
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: all 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;

    width: 80%;
    z-index: 9;

    &.active {
      left: 0;

      a {
        padding: 0.2rem;
      }
      .close-wrap {
        text-align: right;
      }
    }

    a {
      display: block;
      color: #fff;
      line-height: 1.1rem;
      padding: 10rem 0;
      text-decoration: none;
      transition: color 0.2s ease-in-out, padding 0.4s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        color: ${colors.umaGreenHover};
      }
    }

    ul {
      list-style: none;
      margin: 0.5rem 0 2rem;
      padding: 0 0 0 1rem;
    }

    li {
      line-height: 1.2rem;
      margin: 0.75rem 0 0.75rem 0.5rem;
      text-indent: -1.1rem;

      svg {
        margin-bottom: -2px;
        margin-right: 0.2rem;
      }
    }

    .menu-heading {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }

  [class^='VideoLazyload'] {
    max-width: 700px;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    .video-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  a#clearwater-care-mobile-phone-btn.btn-text.ga-call.d-md-none.chat {
    transform: translate(50%, -3rem);
  }
`

export const query = graphql`
  query($id: String!) {
    markdown: mdx(id: { eq: $id }) {
      frontmatter {
        template {
          id
        }
        onlineOnly
        phone_number
        v1 {
          form {
            excludePrograms {
              code
            }
          }
        }
        tracking {
          leadsource
          leadvendor
          vendor
        }
        content_section {
          above_video
          video_id
          video_poster {
            path {
              publicURL
            }
          }
          below_video
        }
        hero {
          img_sm {
            path {
              publicURL
            }
          }
          img_lg {
            path {
              publicURL
            }
          }
          text
        }
        call_box {
          header
          under_button
        }
        clearwaterPrograms {
          img {
            path {
              publicURL
            }
          }
        }
        onlinePrograms {
          img {
            path {
              publicURL
            }
          }
        }
      }
      body
    }

    clearwaterDiplomaPrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "diploma" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    clearwaterDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_code }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    onlineDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "45" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_full_name
          }
        }
      }
    }
  }
`
