import React from 'react'

import imageSource from '../../assets/icons/checklist.png'
import Tout from './Tout'

const FinancialAid = () => (
  <Tout
    image={{ alt: 'checklist', source: imageSource }}
    text="Financial aid"
  />
)

export default FinancialAid
