// Node Module Imports
import React from 'react'
import styled from 'styled-components'

// Component Imports
import DefaultLayout from '../layouts/DefaultLayout'

// Style Imports
import { above, colors } from '../styles/utilities'

// Asset Imports
import LogoBigger from '../components/LogoBigger'
import healthcareCareerGuide from '../images/relevancy/common/healthcare-career-guide.png'
import chevPatternPurpleSevenPercent from '../images/relevancy/common/pattern-chev-purple-7-percent.png'
import downloadTheGuide from '../images/relevancy/mobile/download-the-guide.png'

// Hook imports
import useGa from '../hooks/useGa'

const DownloadGuide = ({ className }) => {
  const year = new Date().getFullYear()
  const submitEvent = useGa()

  return (
    <DefaultLayout>
      <div className={className}>
        <div className="header">
          <div className="left-side">
            <LogoBigger />
            <p className="explore">
              Explore our comprehensive
              <br />
              healthcare career guide.
            </p>
            <img
              className="hcg"
              src={healthcareCareerGuide}
              alt="Ultimate Medical Academy's Healthcare Career Guide"
            />
            <a
              href="https://www.ultimatemedical.edu/pdfs/guides/Healthcare_Career_Guide.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="ga-guide"
              onClick={() => {
                submitEvent({
                  category: 'download guide',
                  action: 'click',
                  label: 'download guide - Learn',
                })
              }}
            >
              <img
                src={downloadTheGuide}
                alt="Download the Healthcare Career Guide. Click here."
              />
            </a>
          </div>
          <div className="right-side">
            <img
              src={healthcareCareerGuide}
              alt="Ultimate Medical Academy's Healthcare Career Guide"
            />
          </div>
        </div>
        <div className="about-uma-outer">
          <div className="about-uma-inner">
            <p>
              <strong>About UMA</strong>
            </p>
            <p>
              Ultimate Medical Academy educates students for careers in
              healthcare that can make a difference in their lives, and the
              lives of others. We are a dynamic educational institution
              committed to equipping and empowering students to excel in
              healthcare careers.
            </p>
            <p>
              UMA is proud to have supported more than 55,000 students through
              graduation.
            </p>
            <small>
              © {year} UMA Education, Inc. d/b/a Ultimate Medical Academy |{' '}
              <a
                href="https://www.ultimatemedical.edu/about/citations/"
                className="td-n-hu"
              >
                Marketing Statistics
              </a>{' '}
              |{' '}
              <a
                href="https://www.ultimatemedical.edu/privacy-policy/"
                className="td-n-hu"
              >
                Privacy Policy
              </a>
            </small>
            <br />
            <br />
            <small>
              Ultimate Medical Academy does not recruit or enroll residents of
              the European Union.
            </small>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default styled(DownloadGuide)`
  .header {
    background: url('${chevPatternPurpleSevenPercent}');
    text-align: center;
    padding: 3rem 0;
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      margin: auto;
    }
    [class^='LogoRelevancy'] {
      margin-bottom: 2rem;
    }
    .left-side {
      .explore {
        display: none;
        ${above.md`
          display: block;
        `}
        color: ${colors.umaDarkBlue};
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.8rem;
        text-align: left;
        margin: 1.6rem 0 1rem 2.8rem;
      }
      .hcg {
        display: block;
        margin-top: 2rem;
        transform: translateX(-0.7rem);
        ${above.md`
          display: none;
        `}
      }
    }
    .right-side {
      display: none;
      ${above.md`
        display: block;
      `}
    }
  }
  .about-uma-outer {
    .about-uma-inner {
      margin: 0 calc(100% / 12);
      padding: 2rem 0;
      border-top: 1px solid ${colors.umaDarkGray};
      ${above.xl`
          max-width: 1200px;
          margin: auto;
      `}
    }
    p {
      color: ${colors.umaDarkGray};
      font-size: 1.5rem;
      line-height: 1.7rem;
      margin: 1rem 0;
    }
  }
`
