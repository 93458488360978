import React from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { colors } from '../../../styles/utilities'

export enum ChevronDirection {
  Up,
  Down,
  Left,
  Right,
}

type ChevronProps = {
  className?: string
  color?: string
  direction?: ChevronDirection
  width?: string
}

const Chevron = ({ className, color, direction, width }: ChevronProps) => (
  <svg
    className={cx(
      className,
      { up: direction === ChevronDirection.Up },
      { down: direction === ChevronDirection.Down },
      { left: direction === ChevronDirection.Left },
      { right: direction === ChevronDirection.Right },
    )}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 82.98 71.97"
    fill={color}
    width={width}
    height={width}
  >
    <g>
      <g>
        <polygon points="0 0.02 41.45 40.98 82.98 0 82.98 30.97 41.54 71.97 0 30.97 0 0.02" />
      </g>
    </g>
  </svg>
)

Chevron.defaultProps = {
  className: '',
  color: colors.dev.green.b(),
  direction: ChevronDirection.Down,
  width: '50px',
}

export default styled(Chevron)`
  display: inline-block;
  margin-top: -3px;

  &.up {
    transform: rotate(-180deg);
  }

  &.right {
    transform: rotate(-90deg);
  }

  &.left {
    transform: rotate(90deg);
  }
`
