import React from 'react'
import ModuleHeroScalableConstrained from '@Modules/HeroScalableConstrained'
import ModuleContentAndForm from '@Modules/ContentAndForm'
import ModuleProgramToggle from '@Modules/ProgramToggle'
import ModuleNumberTouts from '@Modules/NumberTouts'
import ModuleFooter from '@Modules/Footer'
import ModuleQuote from '@Modules/Quote'

// todo refactor to be stateless
export default class Module extends React.Component {
  renderModule(type, attributes, className) {
    const moduleCallbacks = {
      'heroScalableConstrained': (attributes) => (
        <ModuleHeroScalableConstrained {...attributes} className={className} />
      ),
      'contentAndForm': (attributes) => (
        <ModuleContentAndForm {...attributes} className={className} />
      ),
      'programToggle': (attributes) => (
        <ModuleProgramToggle {...attributes} className={className} />
      ),
      'numberTouts': (attributes) => (
        <ModuleNumberTouts {...attributes} className={className} />
      ),
      'footer': (attributes) => (
        <ModuleFooter {...attributes} className={className} />
      ),
      'quote': (attributes) => (
        <ModuleQuote {...attributes} className={className} />
      ),
    }

    // prevent app from crashing when module is not defined
    if (moduleCallbacks[type] !== undefined) {
      return moduleCallbacks[type](attributes)
    }

    return null
  }

  render() {
    const { attributes, className, frontmatter, type } = this.props
    return this.renderModule(type, {...frontmatter, ...attributes}, className)
  }
}