import React from 'react'

import cx from 'classnames'
import { FaPhone } from 'react-icons/fa'

import { Phone } from '../../lib/util/format'

type PhoneCTAProps = {
  className?: string
  displayIcon?: boolean
  phone: Phone
}

const PhoneCTA = ({ className, displayIcon, phone }: PhoneCTAProps) => {
  return (
    <a className={cx(className)} href={phone.href}>
      {displayIcon && <FaPhone className="mr-2" />}
      <span>{phone.label}</span>
    </a>
  )
}

PhoneCTA.defaultProps = {
  className: undefined,
  displayIcon: true,
}

export default PhoneCTA
