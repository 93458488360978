/* eslint-disable import/prefer-default-export */

/*-------------------------------------------------------------------
  Why? Because it's more clear. Seeing `UMACampusCode.Online` makes
  more sense than seeing `"45"` in the codebase. Plus, the enum acts
  as a single source of truth for Campus codes.
---------------------------------------------------------------------*/

/**
 * UMA's campus codes.
 */
export enum UMACampusCode {
  Clearwater = '47',
  Empty = '',
  Online = '45',
  Unknown = 'UNSPEC',
}
