import React from 'react'

import { lighten } from 'polished'
import PropTypes from 'prop-types'
import parse from 'react-html-parser'
import styled from 'styled-components'

import useGa from '../../hooks/useGa'
import { above, hexToHSL } from '../../styles/utilities'

// hooks

const CNChatBox = ({
  chatBox,
  chatIDForChatBox,
  chatTextColor,
  className,
  componentTextColor,
  doNotMoveChatBox,
  phone,
  template,
}) => {
  const submitEvent = useGa()

  return (
    <div className={className}>
      <div className="svg-wrapper">
        <svg
          version="1.1"
          id="Layer_36_copy_3"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 360 360"
          className="svg"
        >
          <g>
            <path d="M156.9,248.8h-13.7l-28,22.5v-22.5H86.4c-14.9,0-27-12.1-27-27V94.2c0-14.9,12.1-27,27-27H214c14.9,0,27,12.1,27,27v25.1h20.6V94.2c0-26.3-21.3-47.7-47.6-47.7H86.4c-26.3,0-47.6,21.3-47.6,47.7v127.5c0,26.3,21.3,47.7,47.6,47.7h8.2v8.1v36.8l28.7-23l27.2-21.8h10.3c-2.5-5.6-3.9-11.7-3.9-18.2V248.8z" />
            <path d="M288.9,131.9H202c-17.9,0-32.5,14.5-32.5,32.5v86.9c0,17.9,14.5,32.5,32.5,32.5h43.2l18.5,14.9l19.5,15.7v-25v-5.5h5.6c17.9,0,32.5-14.5,32.5-32.5v-86.9C321.4,146.4,306.8,131.9,288.9,131.9z" />
          </g>
        </svg>
      </div>
      <p className="cta-text position-relative">{parse(chatBox.heading)}</p>
      {template === 'call-now' ? (
        <>
          {/* eslint-disable-next-line */}
          <a
            className="btn-text ga-call fb-track-click d-lg-none"
            href={`tel:${phone}`}
            ga-label="sidebar"
            onClick={() => {
              submitEvent({
                category: 'call',
                action: 'click',
                label: 'click to call - sidebar - learn',
              })
            }}
          >
            Call Us
          </a>
          {/* eslint-disable-next-line */}
          <a
            className="btn-text ga-call fb-track-click d-none d-lg-flex"
            href={`tel:${phone}`}
            ga-label="sidebar"
            onClick={() => {
              submitEvent({
                action: 'click',
                category: 'call',
                label: 'click to call - desktop header - learn',
              })
            }}
          >
            {phone}
          </a>
        </>
      ) : (
        // The div below is empty because LivePerson automatically adds in the chat text
        // eslint-disable-next-line
        <button
          // id={chatIDForChatBox}
          className="btn-text ga-chat chat-launch"
          onClick={() => {
            if (window.Genesys) window.Genesys('command', 'Messenger.open')
            submitEvent({
              action: 'click',
              category: 'chat',
              label: 'click to chat - sidebar - learn',
            })
          }}
        >
          Chat Now
        </button>
      )}
      <p className="below-btn">{parse(chatBox.disclaimer)}</p>
    </div>
  )
}

export default styled(CNChatBox)`
  * {
    color: ${({ componentTextColor }) => componentTextColor || '#fff'};
  }
  background-color: hsla(
    ${(props) => hexToHSL(props.chatBox.chat_box_color.value)},
    0.8
  );
  ${above.md`
    transform: ${(props) =>
      props.doNotMoveChatBox ? '' : 'translateY(-75px)'};
  `};
  .svg-wrapper {
    position: absolute;
    width: 100%;
    text-align: right;
    svg {
      position: relative;
      width: 30%;
      opacity: 0.2;
      fill: #fff;
      transform: translateY(10%);
      ${above.md`
        width: 60%;
      `};
    }
  }
  .cta-text {
    margin: 28px 15px 50px 20px;
    padding-top: 100px;
    font-size: 3rem;
    line-height: normal;
    ${above.md`
      font-size: 1.8rem;
    `};
  }
  .btn-text {
    align-items: center;
    background: ${(props) => props.chatBox.chat_button_color.value};
    color: ${(props) => props.chatTextColor || '#fff'};
    cursor: pointer;
    border: 0;
    display: flex;
    font-size: 2.2rem;
    font-weight: 400;
    justify-content: center;
    margin: auto;
    min-height: 73px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    width: 96%;

    &:hover,
    &:focus {
      filter: brightness(1.1);
    }

    .LPMcontainer {
      margin: 0 !important;
      div {
        cursor: pointer;
        padding: 15px 0;
      }
    }
    ${above.md`
      width: 90%;
    `};
  }
  .below-btn {
    margin: 10px auto 0 20px;
    padding-bottom: 20px;
  }
`

CNChatBox.propTypes = {
  chatBox: PropTypes.object.isRequired,
  chatIDForChatBox: PropTypes.string.isRequired,
  chatTextColor: PropTypes.string,
  className: PropTypes.string.isRequired,
  componentTextColor: PropTypes.string,
}
