import { ReduxAction } from '../../types'

const UPDATE_CAMPUS = "UPDATE_CAMPUS"
const UPDATE_PHONE = "UPDATE_PHONE"

interface UMAActionNames {
  UPDATE_CAMPUS: string,
  UPDATE_PHONE: string
}

class UMAAction {

  names: UMAActionNames

  constructor() {
    this.names = {
      UPDATE_CAMPUS: UPDATE_CAMPUS,
      UPDATE_PHONE : UPDATE_PHONE
    }
  }

  updateCampus(value: string): ReduxAction {
    return {
      type: this.names.UPDATE_CAMPUS,
      payload: value
    }
  }

  updatePhone(value: string): ReduxAction {
    return {
      type: this.names.UPDATE_PHONE,
      payload: value
    }
  }

}

export default new UMAAction