import React, { useContext, useEffect } from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { FootnoteContext } from '../../context/FootnoteContext'
import { makeid } from '../../util/strings'

type FootnotesProps = {
  className?: string
}

const Footnotes = ({ className }: FootnotesProps) => {
  const { clearFootnotes, footnotes } = useContext(FootnoteContext)
  useEffect(() => {
    return () => clearFootnotes()
  }, [clearFootnotes])
  if (footnotes.length < 1) return null
  return (
    <div className={cx(className, 'container')}>
      <ol>
        {footnotes.map((f) => (
          <li key={makeid()}>{f.content}</li>
        ))}
      </ol>
    </div>
  )
}

Footnotes.defaultProps = {
  className: '',
}

export default styled(Footnotes)`
  font-size: 0.8rem;
  li {
    line-height: 1.3;
  }
`
