// ok because we're using implicit label controls
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'

import cx from 'classnames'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import styled from 'styled-components'

import { FieldState } from '../../hooks/useForm'

const SelectField = ({
  className,
  field,
}: {
  className?: string
  field: FieldState
}) => {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    field.update({ value: e.target.value })
    field.validate()
  }

  const onBlur = () => {
    field.update({ touched: true })
  }

  return (
    <label className={cx(className, 'input-wrapper position-relative')}>
      <span className="d-flex justify-content-between label-and-error">
        <span className="d-block label-text">
          {field.label}
          {field.markRequired && <span className="req"> *</span>}
        </span>
        <span className="d-block error">
          {field.touched && field.errors?.[0]}
        </span>
      </span>
      <select
        className={cx({ 'color-gray-d': !field.value })}
        onBlur={onBlur}
        onChange={onChange}
        // not sure why adding this breaks this field, but it does
        // value={field.value}
        name={field.id}
      >
        <option value="">{field.placeholder || 'Select One'}</option>
        {field.options.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      <div className="carets position-absolute">
        <FaCaretUp className="caret" />
        <FaCaretDown className="caret" />
      </div>
    </label>
  )
}

SelectField.defaultProps = {
  className: '',
}

export default styled(SelectField)`
  select {
    color: #000;
  }
  .carets {
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .caret {
    display: block;
    &:last-of-type {
      margin-top: -0.5em;
    }
  }
  .color-gray-d {
    color: #8f8f8f;
  }
`
