import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import watchInputs from '../utilities/watchInputs'
import { above, colors } from '../styles/utilities'
import { ClassList, applyPhoneMask } from '../utilities'
import { buildSparkroomRequestString } from '../utilities'
import {
  LeadIdService,
  OnProgramSelectService,
  ProgramSelectSetupService,
  FormRedirectService,
  FormRefererService,
  StateSelectSetupService,
} from '../services'
import { stateOptionsHTML } from '../utilities/states'

class SparkroomForm extends Component {
  /**
   * constructor
   *
   * @param {void}
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.ref = createRef()
    this.componentDidMount = this.componentDidMount.bind(this)
    this.postFormLoadProcessing = this.postFormLoadProcessing.bind(this)
    this.state = {
      formHtml: null,
    }
  }

  async componentDidMount() {
    let response, body, request

    try {
      request = buildSparkroomRequestString(
        this.props._r_formParams,
        window.location.search,
      )
      response = await fetch(request)

      if (!response.ok) {
        throw new Error('Network response was not ok.')
      }

      body = await response.text()
      // console.log('body', body)
      const formDoc = new DOMParser().parseFromString(body, 'text/html')

      // if we're adding a state dropdown then we need to add it to the HTML body
      // (Sparkroom's HTML doesn't have it)
      if (this.props?.includeStateSelect) {
        const phoneInput = formDoc.getElementById('PhoneDiv')
        const stateDiv = document.createElement('div')
        stateDiv.id = 'StateDiv'
        stateDiv.className = 'form-group'
        const stateLabel = document.createElement('label')
        stateLabel.className = 'control-label'
        stateLabel.innerHTML = 'State'
        stateDiv.innerHTML = stateOptionsHTML
        stateDiv.prepend(stateLabel)
        phoneInput.after(stateDiv)
      }

      if (this.props?.buttonText) {
        formDoc.body.innerHTML = formDoc.body.innerHTML.replace(
          '>Request Info</a>',
          `>${this.props.buttonText}</a>`,
        )
      }
      this.setState({ formHtml: formDoc.body.innerHTML })
      this.postFormLoadProcessing()
    } catch (error) {
      console.error('Sparkroom Error:', error)
    }
  }

  postFormLoadProcessing() {
    watchInputs(this.ref.current)

    const pageDetails = {
      isQuiz: this.props.isQuiz,
      classes: new ClassList(this.props.pageClass),
      program: this.props.pageProgram,
      isProgramPage: this.props.isProgramPage,
      isClearwaterProgram: this.props.isClearwaterProgram,
    }

    new LeadIdService(
      this.ref.current.querySelector('input[name="FirstName"]'),
    ).boot()
    new ProgramSelectSetupService(
      this.ref.current.querySelector('select[name="Program_Online"]'),
      pageDetails,
    ).boot()
    new OnProgramSelectService(
      this.ref.current.querySelector('select[name="Program_Online"]'),
    ).boot()
    new FormRedirectService(
      pageDetails,
      this.ref.current.querySelector('input[name="redirect"]'),
      this.props.tracking.phone_number,
    ).boot()
    new FormRedirectService(
      pageDetails,
      this.ref.current.querySelector('input[name="errorUrl"]'),
      this.props.tracking.phone_number,
    ).boot()
    new FormRefererService(
      document.createElement('input'),
      this.ref.current.querySelector('form'),
      window.location.href,
    ).boot()
    new StateSelectSetupService(
      this.ref.current.querySelector('select[name="State"]'),
    ).boot()

    applyPhoneMask(this.ref.current.querySelector('input[name="Phone"]'))

    if (this.props.template === 'info2-program') {
      const emailDiv = document.getElementById('EmailDiv')
      const phoneDiv = document.getElementById('PhoneDiv')
      const parentNode = phoneDiv.parentNode

      if (emailDiv) {
        const emailLabel = emailDiv.querySelector('label')
        emailLabel.innerHTML = 'Email Address'
      }

      if (phoneDiv) {
        const phoneLabel = phoneDiv.querySelector('label')
        phoneLabel.innerHTML = 'Phone Number'
      }

      if (emailDiv && phoneDiv && parentNode) {
        parentNode.insertBefore(emailDiv, phoneDiv)
      }
    }
  }

  renderForm() {
    return (
      <div id="sparkroomFormApiDiv">
        {!this.state.formHtml ? (
          <div className="no-script-btn">
            <a className="submitStyle" href="tel:8773229754">
              {this.props.buttonText}
            </a>
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: this.state.formHtml }} />
        )}
      </div>
    )
  }

  render() {
    const { aboveForm, belowForm, className, pageClass } = this.props
    return (
      <>
        <div className={`${className} ${pageClass}`} ref={this.ref}>
          {aboveForm()}
          {this.renderForm()}
          {belowForm()}
        </div>
      </>
    )
  }
}

SparkroomForm = styled(SparkroomForm)`
  background-color: ${(props) => props.formColor};
  ${(props) =>
    !props.clean &&
    above.lg`
    margin-bottom: -8rem;
    transform: translateY(-10rem);
  `}
  .sparkroom-h3, .sparkroom-h4 {
    text-align: center;
    color: #fff;
    font-family: "Univers LT W01 55 Roman", Arial;
  }
  .sparkroom-h3 {
    padding-top: 1rem;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .disclaimer {
    color: #fff;
    font-size: .625rem;
    width: 85%;
    margin: auto;
    padding-bottom: 1rem;
  }
  form {
    width: 100%;
  }
  .form-group {
    background: #fff;
    ${(props) =>
      !props.clean &&
      `
      width: 80%;
    `}
    margin: 1rem auto;
    * {
      color: ${colors.umaDarkGray};
      padding: unset;
      max-width: 100%;
    }
    [color="RED"] {
      color: #f00;
    }
    .control-label {
      font-size: .8rem;
      font-weight: 700;
      padding: .4rem;
      margin: 0;
    }
    .form-control {
      width: 100%;
      height: 2rem;
      padding: .375rem .75rem;
      border: none;
      outline: none;
      box-shadow: none;
    }
    select {
      background-color: #fff;
    }
  }
  label.col-sm-8,
  #TCPAExpressConsentDiv,
  #Page1 {
    display: none;
  }
  .submitStyle {
    background-color: ${(props) => props.buttonColor};
    color: #fff;
    display: block;
    font-size: 1.2rem;
    font-weight: 800;
    ${(props) =>
      !props.clean &&
      `
      margin: 0 auto 1rem;
    `}
    padding: .7rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    ${(props) =>
      !props.clean &&
      `
      width: 80%;
    `}
    transition: all .25s ease-in-out;
    &[disabled='true'] {
      background-color: #999;
      pointer-events: none;
    }
  }
  .form-group .control-label {
    display: block;
    font-family: "Univers LT W01 65 Bold", Arial;
    padding-bottom: 0;
    text-align: left;
  }
  .form-group span {
    font-family: "Univers LT W01 45 Light Oblique", Arial;
    font-size: 0.5rem;
    padding: 0 .4rem .4rem .4rem;
  }

  ${(props) => {
    let pageClass = new ClassList(props.pageClass)
    if (
      !pageClass.contains('show-zip') &&
      !pageClass.contains('show-address')
    ) {
      return `
        #PostalCodeOrZipDiv {
          display: none;
        }
      `
    }
  }}


  &.page-template--pmx,
  &.hero--scalable, &.hero--scalable--constrained {
    margin: 0;
    ${above.lg`
      margin: 2rem 0;
      transform: none;
    `}
  }
  #sparkroomFormApiDiv .col-sm-8.col-sm-offset-2 {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
  }

  #StateDiv {
    position: relative;
    width: 80%;
  }
  #StateDiv select {
    border: none;
    max-width: 99%;
    position: relative;
    left: 0.15rem;
    width: 99%;
    height: 2rem;
    padding-top: .375rem;
    padding-bottom: .375rem;s
  }
  #StateDiv .control-label {
    position: relative;
    z-index: 9;
  }
  #StateDiv .control-label ::after {
    display: none;
  }

  #submitButtonDiv {
    flex: 0 0 100%;
  }
  #submitButtonDiv a {
    display: inline-block;
  }
`

SparkroomForm.propTypes = {
  aboveForm: PropTypes.func.isRequired,
  belowForm: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  formColor: PropTypes.string.isRequired,
  pageProgram: PropTypes.string.isRequired,
  isClearwaterProgram: PropTypes.bool.isRequired,
  isProgramPage: PropTypes.bool,
  isQuiz: PropTypes.bool,
  pageClass: PropTypes.string,
  template: PropTypes.string,
}

SparkroomForm.defaultProps = {
  isProgramPage: false,
  isQuiz: false,
  pageClass: '',
}

const mapStateToProps = (state) => {
  return {
    _r_formParams: state._r_formParams,
    _r_globals: state._r_globals,
  }
}

export default connect(mapStateToProps)(SparkroomForm)
