import { useCallback, useEffect, useState } from 'react'

import { FieldOptions, FieldOptionsWithID } from './fieldOptions'
import { FieldProps } from './fieldProps'

export interface FieldState extends Readonly<FieldProps> {
  update(props: FieldOptions): void
  validate(): void
}

function fieldConstructor(fieldOpts: FieldOptionsWithID): FieldProps {
  const defaults: FieldProps = {
    errors: [],
    formatter: undefined,
    hidden: false,
    id: '',
    label: '',
    markRequired: false,
    options: [],
    placeholder: '',
    queryOverwrites: [],
    touched: false,
    type: 'text',
    validators: [],
    value: '',
  }
  return { ...defaults, ...fieldOpts }
}

function nonStatefulAddQueryOverwrites(field: FieldState) {
  const params = new URLSearchParams(window.location.search)
  const value = field.queryOverwrites.reduce((acc, curr) => {
    const paramValue = params.get(curr)
    if (paramValue) return paramValue
    return acc
  }, '')
  return value ? { ...field, value } : field
}

function nonStatefulBlurField(field: FieldState) {
  return { ...field, touched: true }
}

function nonStatefulBlurFields(fields: FieldState[]) {
  return fields.map((f) => nonStatefulBlurField(f))
}

function nonStatefulMergeOptionsFirst(
  source: FieldState[],
  options: FieldOptionsWithID[],
) {
  const filteredSource = source
    .map((field) => {
      const opt = options.find((o) => o.id === field.id)
      if (opt) return null
      return field
    })
    .filter((f) => f !== null)
  const optionFields = options
    .map((opt) => {
      const field = source.find((f) => f.id === opt.id)
      if (!field) {
        // eslint-disable-next-line no-console
        console.warn(
          `Error merging field options: field with id \`${opt.id}\` does not exist in the default fields.`,
        )
        return null
      }
      return { ...field, ...opt }
    })
    .filter((f) => f !== null)
  return [...optionFields, ...filteredSource]
}

function nonStatefulMergeInPlace(
  source: FieldState[],
  options: FieldOptionsWithID[],
) {
  return source.map((field) => {
    const opt = options.find((f) => f.id === field.id)
    if (!opt) return field
    return { ...field, ...opt }
  })
}

export function nonStatefulIsValid(fieldState: FieldState[]) {
  let valid = true
  for (let i = 0; i < fieldState.length; i += 1) {
    const field = fieldState[i]
    if (field.errors.length > 0) valid = false
    if (!valid) break
  }
  return valid
}

function nonStatefulValidateField(field: FieldState, opts?: { blur: boolean }) {
  const updatedField = { ...field }
  const errs: string[] = []
  for (let i = 0; i < field.validators.length; i += 1) {
    const validate = field.validators[i]
    const err = validate(field.value)
    if (err) errs.push(err)
    if (opts?.blur) updatedField.touched = true
  }
  updatedField.errors = errs
  return updatedField
}

export function nonStatefulValidateFields(
  fields: FieldState[],
  opts?: { blur: boolean },
) {
  return fields.map((f) => nonStatefulValidateField(f, opts))
}

type UseFormProps = {
  fieldDefaults: FieldOptionsWithID[]
  fieldOptions: (string | FieldOptionsWithID)[]
}

type EncodingOptions = {
  omitEmpty: boolean
}

const useForm = ({ fieldDefaults, fieldOptions }: UseFormProps) => {
  // local state
  const [fieldState, setFieldState] = useState<FieldState[]>([])

  /**
   * Updates a single field in state.
   */
  const updateField = useCallback(
    (fieldID: string, props: Partial<FieldProps>) => {
      setFieldState((prevState) => {
        return nonStatefulMergeInPlace(prevState, [{ id: fieldID, ...props }])
      })
    },
    [setFieldState],
  )

  /**
   * Returns a function that updates a field in state.
   */
  const getUpdateField = useCallback(
    (fieldID: string) => (props: Partial<FieldProps>) => {
      return updateField(fieldID, props)
    },
    [updateField],
  )

  /**
   * Validates a single field in state.
   */
  const validateField = useCallback(
    (fieldID: string) => {
      setFieldState((prevState) => {
        const field = prevState.find((f) => f.id === fieldID)
        const updatedField = nonStatefulValidateField(field)
        const newState = nonStatefulMergeInPlace(prevState, [updatedField])
        return newState
      })
    },
    [setFieldState],
  )

  /**
   * Returns a function that validates a field in state.
   */
  const getValidateField = useCallback(
    (fieldID: string) => () => {
      return validateField(fieldID)
    },
    [validateField],
  )

  /**
   * Blurs all fields in state.
   */
  const blurAllFields = useCallback(() => {
    setFieldState((prevState) => nonStatefulBlurFields(prevState))
  }, [setFieldState])

  /**
   * Ensure there are no field errors in state.
   */
  const isValid = useCallback(() => {
    let valid = true
    for (let i = 0; i < fieldState.length; i += 1) {
      const field = fieldState[i]
      if (field.errors.length > 0) valid = false
      if (!valid) break
    }
    return valid
  }, [fieldState])

  /**
   * Get a JSON-encoded representation of the field state; an array of key/value
   * pairs.
   */
  const fieldJSON = useCallback(
    (opts?: EncodingOptions) => {
      if (opts?.omitEmpty) {
        return fieldState
          .map((field) => {
            if (field.value === '') return null
            return { key: field.id, value: field.value }
          })
          .filter((v) => v !== null)
      }
      return fieldState.map((field) => ({
        key: field.id,
        value: field.value,
      }))
    },
    [fieldState],
  )

  // Create the initial field state. This effect merges the field options into
  // the default field state.
  useEffect(() => {
    const defaults = fieldDefaults.map((f) => {
      const fieldProps = fieldConstructor(f)
      const state: FieldState = {
        ...fieldProps,
        update: getUpdateField(fieldProps.id),
        validate: getValidateField(fieldProps.id),
      }
      return state
    })
    const withOptions = fieldOptions.map((fieldOpts) => {
      if (typeof fieldOpts === 'string') {
        const field = defaults.find((f) => f.id === fieldOpts)
        const validatedField = nonStatefulValidateField(field)
        return validatedField
      }
      const field = defaults.find((f) => f.id === fieldOpts.id)
      const updatedField = { ...field, ...fieldOpts }
      const validatedField = nonStatefulValidateField(updatedField)
      return { ...validatedField, ...fieldOpts }
    })
    setFieldState(() => nonStatefulMergeOptionsFirst(defaults, withOptions))
  }, [
    fieldDefaults,
    fieldOptions,
    getUpdateField,
    getValidateField,
    setFieldState,
  ])

  useEffect(() => {
    setFieldState((prevState) => {
      return prevState.map((f) => nonStatefulAddQueryOverwrites(f))
    })
  }, [setFieldState])

  return {
    blurAllFields,
    fieldJSON,
    fieldState,
    isValid,
    updateField,
  }
}

export default useForm
