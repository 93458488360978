// Node Module Imports
import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'

// Component Imports
import DRTVHero from '../components/heroes/DRTVHero'
import Form from '../components/forms/AcormFormWrapper'
import GlobalFooter from '../components/GlobalFooter'
import HeaderWithMenu from '../components/HeadersAndHeroes/HeaderWithMenu'
import ImageTouts from '../components/cross-template-statics/ImageTouts'
import Layout from '../layouts/DefaultLayout'
import LogoSquare from '../components/LogoSquare'
import Menu from '../components/reusables/Menu'
import NumberTouts from '../components/cross-template-statics/NumberTouts'
import PS92020Hero from '../components/heroes/PS92020Hero'
import SEO from '../components/SEO'
import StickyMobileFooter from '../components/reusables/StickyMobileFooter'
import RememberTraceParams from '../components/effects/RememberTraceParams'

// Style Imports
import { above, colors } from '../styles/utilities'

// Hooks Imports
import useGa from '../hooks/useGa'

// Redux Imports
import { store } from '../redux'
import { AcornFormParams } from '../redux/actions'

// Utility Imports
import findInObject from '../utilities/findInObject'

const PS92020 = ({ className, data }) => {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

  const onlineOnly = findInObject('onlineOnly', data.markdown.frontmatter),
    settings = {
      allowNull: true,
    },
    leadsource = findInObject(
      'tracking.leadsource',
      data.markdown.frontmatter,
      settings,
    ),
    leadvendor = findInObject(
      'tracking.leadvendor',
      data.markdown.frontmatter,
      settings,
    ),
    vendor = findInObject(
      'tracking.vendor',
      data.markdown.frontmatter,
      settings,
    ),
    phoneNumber = findInObject('phone_number', data.markdown.frontmatter, {
      strictMode: true,
    })

  if (leadsource) {
    store.dispatch(AcornFormParams.updateParamValue('LeadSource', leadsource))
  }
  if (leadvendor) {
    store.dispatch(AcornFormParams.updateParamValue('LeadVendor', leadvendor))
  }
  if (vendor) {
    store.dispatch(AcornFormParams.updateParamValue('Vendor', vendor))
  }

  const excludePrograms = data?.markdown?.frontmatter?.v1?.form?.excludePrograms?.map(
    (p) => p.code,
  )

  const formEvent = useGa()

  const sortPrograms = (programEdges) => {
    return programEdges.sort((a, b) => {
      let aNorm = a.node.meta.program_full_name.toLowerCase(),
        bNorm = b.node.meta.program_full_name.toLowerCase()
      if (aNorm < bNorm) {
        return -1
      } else if (aNorm > bNorm) {
        return 1
      }
      return 0
    })
  }

  const sortedOnlinePrograms = sortPrograms(data.onlineDegreePrograms.edges),
    sortedClearwaterPrograms = sortPrograms(
      data.clearwaterDiplomaPrograms.edges,
    )

  const closeModal = (e) => {
    if (displayMobileMenu && e.target === e.currentTarget) {
      setDisplayMobileMenu(false)
    }
  }

  const selectedCampus = 'Clearwater'

  const programLinkPathMapperOnline = [
    'health-and-human-services-2',
    'health-information-technology-2',
    'medical-administrative-assistant-2',
    'medical-office-billing-specialist-2',
    'pharmacy-technician',
    'healthcare-accounting',
    'healthcare-management-2',
    'medical-billing-coding-2',
  ]

  const programLinkPathMapperClearwater = [
    'dental-assistant-2',
    'medical-assistant-2',
    'nursing-assistant-2',
    'patient-care-technician-2',
    'phlebotomy-2',
    'dental-assistant-2',
    'medical-assistant-2',
  ]

  return (
    <Layout>
      <RememberTraceParams whichForm="acorn" />

      <SEO
        title="Healthcare Heroes"
        description="The nation needs heroes in healthcare. The journey begins at Ultimate Medical Academy."
      />

      <div className={`${className} ${displayMobileMenu && `menu-open`}`}>
        <div className="header-wrapper">
          <div className="container">
            <HeaderWithMenu
              phoneNumber={phoneNumber}
              displayMobileMenu={displayMobileMenu}
              setDisplayMobileMenu={setDisplayMobileMenu}
              selectedCampus={selectedCampus}
            />
          </div>
        </div>

        <div className="form-wrapper py-4 d-lg-none">
          <div className="container">
            <Form
              beforeSubmitCallback={() =>
                formEvent({
                  category: 'rfi-form',
                  action: 'submit',
                  label: 'under-hero',
                })
              }
              excludePrograms={excludePrograms}
              clearwaterOptionLabel="Clearwater Campus"
              redirect={`/confirmation/?phone=${phoneNumber}&campaign=healthcare-heroes`}
              aboveForm={() => (
                <>
                  <h2 className="mt-0 text-white mb-1 form-font">
                    Healthcare Career Training
                  </h2>
                  <p className="text-white mt-0 form-font bold">
                    Learn How We Can Help You Succeed
                  </p>
                </>
              )}
              overrideFormLabels={{
                Campus: 'How would you like to study?',
              }}
              className="form-container"
              removeFormParamsAndSpecifyDefaultValues={(() => {
                if (onlineOnly) {
                  return {
                    Campus: '45',
                  }
                } else {
                  return {
                    State: 'GU',
                  }
                }
              })()}
            />
          </div>
        </div>

        <PS92020Hero
          bgPosition="30% center"
          bgImgSm={data.markdown.frontmatter.hero.img_sm.path.publicURL}
          bgImgLg={data.markdown.frontmatter.hero.img_lg.path.publicURL}
          phoneNumber={phoneNumber}
          className="d-lg-none"
        >
          <div className="container hero-content">
            <div className="row pt-5 ">
              <div className="col-lg-7">
                <p className="mb-0 mt-0">
                  <span>
                    The
                    <br />
                    Nation
                    <br />
                    Needs
                    <br />
                  </span>
                  <span className="bold">
                    Healthcare
                    <br />
                    Heroes.
                  </span>
                </p>
                <div className="arrow">
                  <svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="0,0 0,100 50,50" />
                  </svg>
                  <p className="m-0 p-2">Classes Starting Soon</p>
                </div>
              </div>
            </div>
          </div>
        </PS92020Hero>

        <DRTVHero
          bgPosition="30% center"
          bgImgSm={data.markdown.frontmatter.hero.img_sm.path.publicURL}
          bgImgLg={data.markdown.frontmatter.hero.img_lg.path.publicURL}
          hideLogoAndPhone={true}
          phoneNumber={phoneNumber}
          className="d-none d-lg-block"
        >
          <div className="container hero-content">
            <div className="row pt-5 ">
              <div className="col-lg-7">
                <p className="mb-0 mt-0">
                  <span>
                    The Nation
                    <br />
                    Needs
                    <br />
                  </span>
                  <span className="bold">Healthcare Heroes.</span>
                </p>
                <div className="arrow">
                  <svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="0,0 0,100 50,50" />
                  </svg>
                  <p className="m-0 p-2">Classes Starting Soon</p>
                </div>
              </div>
            </div>
          </div>
        </DRTVHero>

        <div className="page-content">
          <div className="container py-lg-5">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 py-5 pt-lg-0">
                <MDXRenderer>{data.markdown.body}</MDXRenderer>
              </div>
              <div className="col-lg-5 offset-lg-1 p-0 order-1 order-lg-2 d-none d-lg-block">
                <div className="form-wrap--lg p-4">
                  <Form
                    beforeSubmitCallback={() =>
                      formEvent({
                        category: 'rfi-form',
                        action: 'submit',
                        label: 'under-hero',
                      })
                    }
                    excludePrograms={excludePrograms}
                    clearwaterOptionLabel="Clearwater Campus"
                    redirect={`/confirmation/?phone=${phoneNumber}&campaign=healthcare-heroes`}
                    aboveForm={() => (
                      <>
                        <h2 className="mt-0 text-white mb-1 text-center form-font bold">
                          Learn How
                        </h2>
                        <p className="text-white mt-0 text-center form-font bold">
                          We Can Help You Succeed
                        </p>
                      </>
                    )}
                    className="form--lg"
                    overrideFormLabels={{
                      Campus: 'How would you like to study?',
                    }}
                    removeFormParamsAndSpecifyDefaultValues={(() => {
                      if (onlineOnly) {
                        return {
                          Campus: '45',
                        }
                      } else {
                        return {
                          State: 'GU',
                        }
                      }
                    })()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="program-stack wp-content">
          {!onlineOnly && (
            <div className="clearwater">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage: `
                        url(${data.markdown.frontmatter.clearwaterPrograms.img.path.publicURL})
                      `,
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 p-0 text order-1 order-md-2">
                    <h3 className="heading m-0 p-3">Clearwater</h3>
                    <p className="px-3 mb-0 bold">
                      Experience blended learning* at our Clearwater Campus and
                      get a mix of hands-on training with online instruction.
                    </p>
                    <ul className="p-3">
                      {sortedClearwaterPrograms.map(({ node }, idx) => (
                        <li key={node.meta.program_full_name}>
                          <Link
                            className="Link"
                            to={`/clearwater/${programLinkPathMapperClearwater[idx]}`}
                          >
                            {node.meta.program_full_name}
                          </Link>
                        </li>
                      ))}
                      {data.clearwaterDegreePrograms.edges.map(
                        ({ node }, idx) => (
                          <li key={node.meta.program_full_name}>
                            <Link
                              className="Link"
                              to={`/clearwater/${programLinkPathMapperClearwater[idx]}`}
                            >
                              {node.meta.program_full_name}
                            </Link>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="online">
            <div className="container">
              <div className="row d-flex">
                <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                  <div
                    className="bg-img"
                    style={{
                      backgroundImage: `
                      url(${data.markdown.frontmatter.onlinePrograms.img.path.publicURL})
                    `,
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 p-0 text order-1 order-md-2">
                  <h3 className="heading m-0 p-3">Online</h3>
                  <p className="px-3 mb-0 bold">
                    Earn a degree online from the comfort of your home with a
                    flexible schedule.<sup>1</sup>
                  </p>
                  <ul className="p-3">
                    {sortedOnlinePrograms.map(({ node }, idx) => (
                      <li key={node.meta.program_full_name}>
                        <Link
                          className="Link"
                          to={`/online/${programLinkPathMapperOnline[idx]}`}
                        >
                          {node.meta.program_full_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NumberTouts />

        <ImageTouts
          content={() => {
            if (onlineOnly) {
              return (
                <>
                  <h2 className="blue">Heroes are stronger together.</h2>
                  <p>
                    When you enroll at UMA you can count on our student support
                    services to be there for you. We are ready to help with:
                  </p>
                </>
              )
            }
            return (
              <>
                <h2 className="blue">Heroes are stronger together.</h2>
                <p>
                  When you enroll at UMA — online or at our campus located in
                  Clearwater, FL — you can count on our student support services
                  to be there for you. We are ready to help with:
                </p>
              </>
            )
          }}
        />

        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <LogoSquare />
              </div>
              <div className="col-12 col-md-8">
                <p>
                  If you’re committed to doing more, to seeking a new path, to
                  making a difference in your life, your family’s life, and the
                  lives of others, UMA is ready to help you get started. Begin
                  your journey today.
                </p>
              </div>
            </div>
          </div>
        </div>

        {
          <>
            <div
              aria-label="Navigation Toggle"
              role="button"
              tabIndex="0"
              className={`mobile-menu-wrap ${displayMobileMenu && `active`}`}
              onClick={closeModal}
              onKeyDown={(e) =>
                e.keyCode === 13 ? setDisplayMobileMenu(false) : null
              }
            ></div>
            <div className={`mobile-menu ${displayMobileMenu && `active`}`}>
              <Menu
                excludePrograms={excludePrograms}
                selectedCampus={selectedCampus}
                setDisplayMobileMenu={setDisplayMobileMenu}
              />
            </div>
          </>
        }
      </div>

      <div className="container py-4">
        {!onlineOnly && (
          <p className="m-0">
            <small>
              * Nursing Assistant diploma program remains fully on-campus.
            </small>
          </p>
        )}
        <p className="m-0">
          <small>
            <sup>1</sup> Set schedules required for externship/practicum courses
            in select programs. Coursework is still subject to deadlines set by
            instructors.
          </small>
        </p>
      </div>

      <GlobalFooter phone={phoneNumber} sticky={true} />
      {/* <StickyMobileFooter phone={phoneNumber} chatBtnId="LP_DIV_1432733350793" /> */}
    </Layout>
  )
}

export default styled(PS92020)`
  p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1,
  h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .header-wrapper {
    background-color: ${colors.umaLightBlue};
    padding: 0.75rem 0;
  }

  [class^='form-wrap'] {
    h2.form-font {
      font-size: 2rem;
    }
    p.form-font {
      font-size: 1rem;
      ${above.lg`
        font-size: 1.25rem;
      `}
    }
  }

  .form-wrapper {
    background-color: ${colors.umaLightBlue};
    > .container {
      padding: 0 7px;
    }
    .form-container {
      margin: 0 auto;
      padding: 0;
      max-width: 500px;
      h2,
      p {
        text-align: center;
      }
    }

    form {
      flex-wrap: wrap;
      justify-content: space-between;

      ${above.lg`
        display: flex;
      `}
    }

    form .input-wrap,
    form .button-wrap {
      flex: 0 0 48.5%;
      margin: 10px 6.75px;
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      select {
        font-size: 1rem;
      }
    }
    form .button-wrap {
      display: flex;
      margin: 1.5rem 6.75px;
    }
  }

  .hero-content {
    .row {
      padding-top: 8rem;

      ${above.lg`
        padding-top: 2rem;
        padding-bottom: 2rem;      
      `}
    }

    p {
      color: #fff;
      font-size: 3rem;
      line-height: 1;
      text-transform: uppercase;

      ${above.lg`
        font-size: 4rem;
      `}
    }

    .arrow {
      background-color: ${colors.umaOrange};
      box-shadow: 8px 8px 6px hsla(0, 0%, 0%, 0.4);
      border-radius: 1px;
      display: inline-block;
      margin-top: 1.5rem;
      position: relative;
      max-width: 88%;

      svg {
        border-radius: 1px;
        fill: ${colors.umaOrange};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        width: auto;
      }
    }

    .arrow p {
      font-size: 1.5rem;

      ${above.md`
        font-size: 2rem;
      `}
    }
  }

  .page-content {
    h1 {
      margin-top: 0;
    }

    li {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    strong {
      color: ${colors.umaTeal};
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: bold;
    }
  }

  .program-stack {
    color: white;
    margin-top: 3rem;

    .heading {
      color: white;
    }

    .Link {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .online,
    .clearwater {
      position: relative;

      .bg-img-wrap,
      .text {
        ${above.md`
          margin-top: -3.45rem;
        `}
      }

      .bg-img-wrap {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        overflow: hidden;
      }

      .text {
        background-color: ${colors.umaDarkBlue};
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        overflow: hidden;
      }
    }

    .online::before,
    .clearwater::before {
      content: '';
      display: block;
      background-color: ${colors.umaTeal};
      position: absolute;
      top: 3.45rem;
      right: 0;
      bottom: 3.45rem;
      left: 0;

      ${above.md`
        top: 0; bottom: -3.45rem;
        padding-bottom: 3.45rem;
      `}
    }

    .online {
      margin-bottom: 4rem;
      margin-top: 15rem;
      .heading {
        background-color: ${colors.umaPurple};
      }
    }

    .clearwater {
      ${above.md`
        margin-bottom: 4rem;
      `}
      .heading {
        background-color: ${colors.umaOrange};
      }
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .phone {
    border-radius: 2px;
    color: ${colors.umaDarkBlue};
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    font-weight: normal;
    text-decoration: none;

    svg {
      transform: scaleX(-1);
      font-size: 1.5rem;

      ${above.sm`
        margin-right: 0.5rem;
        font-size: 1rem;
      `}
    }
  }

  .form-wrap--lg {
    position: relative;
    ${above.lg`
      box-shadow: -8px -8px 6px hsla(0, 0%, 0%, 0.4);
      margin-top: -10rem;
    `}

    &::before {
      background-color: ${colors.umaLightBlue};
      border-radius: 2px;
      content: '';
      display: block;
      opacity: 0.9;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    .form--lg {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .mobile-menu-wrap {
    background: ${colors.umaLightBlue};
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: -100%;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    &.active {
      display: block;
      left: 0;
      opacity: 0.9;
    }
  }

  .mobile-menu {
    background-color: ${colors.umaDarkGray};
    color: #fff;
    font-size: 0.9rem;
    max-width: 350px;
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: all 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;

    width: 80%;
    z-index: 9;

    &.active {
      left: 0;

      a {
        padding: 0.2rem;
      }
      .close-wrap {
        text-align: right;
      }
    }

    a {
      display: block;
      color: #fff;
      line-height: 1.1rem;
      padding: 10rem 0;
      text-decoration: none;
      transition: color 0.2s ease-in-out, padding 0.4s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        color: ${colors.umaGreenHover};
      }
    }

    ul {
      list-style: none;
      margin: 0.5rem 0 2rem;
      padding: 0 0 0 1rem;
    }

    li {
      line-height: 1.2rem;
      margin: 0.75rem 0 0.75rem 0.5rem;
      text-indent: -1.1rem;

      svg {
        margin-bottom: -2px;
        margin-right: 0.2rem;
      }
    }

    .menu-heading {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }
`

export const query = graphql`
  query($id: String!) {
    markdown: mdx(id: { eq: $id }) {
      frontmatter {
        onlineOnly
        phone_number
        v1 {
          form {
            excludePrograms {
              code
            }
          }
        }
        tracking {
          leadsource
          leadvendor
          vendor
        }
        hero {
          img_sm {
            path {
              publicURL
            }
          }
          img_lg {
            path {
              publicURL
            }
          }
        }
        clearwaterPrograms {
          img {
            path {
              publicURL
            }
          }
        }
        onlinePrograms {
          img {
            path {
              publicURL
            }
          }
        }
      }
      body
    }

    clearwaterDiplomaPrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "diploma" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    clearwaterDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_code }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    onlineDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "45" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_full_name
          }
        }
      }
    }
  }
`
