import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/utilities';
import i1 from '../../images/icons/one-on-one-tutoring.png';
import i2 from '../../images/icons/job-search-assistance.png';
import i3 from '../../images/icons/job-interview-coaching.png';
import i4 from '../../images/icons/academic-assistance.png';
import i5 from '../../images/icons/dedicated-advisor.png';
import i6 from '../../images/icons/technical-support.png';
import ChevronBackgroundStroke from '../reusables/ChevronBackgroundStroke';

const ImageTouts = ({className, content}) => {

  return (
  <ChevronBackgroundStroke className={`${className}`}>
    <div className="container text-center">
      {content()}
      <div className="row touts d-flex align-items-end pt-4 pb-3">
        <div className="col-6 col-md-4 pb-4">
          <img src={i4} alt="Academic Assistance" />
          <p>Academic assistance</p>
        </div>
        <div className="col-6 col-md-4 pb-4">
          <img src={i5} alt="A Dedicated Advisor for Guidance Through Life's Challenges" />
          <p>A dedicated advisor</p>
        </div>
        <div className="col-6 col-md-4 pb-4">
          <img src={i6} alt="24/7 Technical Support" />
          <p>24/7 technical support</p>
        </div>
        <div className="col-6 col-md-4 pb-4">
          <img src={i2} alt="Job Search Assistance" />
          <p>Job search assistance</p>
        </div>
        <div className="col-6 col-md-4 pb-4">
          <img src={i1} alt="Resume Writing Guidance" />
          <p>Resume writing guidance</p>
        </div>
        <div className="col-6 col-md-4 pb-4">
          <img src={i3} alt="Job Interview Coaching" />
          <p>Job interview coaching</p>
        </div>
      </div>
    </div>
  </ChevronBackgroundStroke>
  )

};

ImageTouts.propTypes = {
  className: PropTypes.string,
  content: PropTypes.func
};

export default styled(ImageTouts)`
  border-top: 1.25rem solid ${colors.umaYellow};
  padding: 3rem 0;

  .touts {
    font-weight: bold;
  }
`;