// Action Creators
const initService = (service) => {
  return {
    type: "INIT_SERVICE",
    payload: {
      service: service
    }
  }
}

const updateLeadId = (leadid) => {
  return {
    type: 'UPDATE_LEADID',
    payload: {
      leadid
    }
  };
};

const addOrUpdateFormParam = (param) => {
  return {
    type: 'ADD_OR_UPDATE_FORM_PARAM',
    payload: {
      param
    }
  }
};

const addOrUpdateFormParams = (params) => {
  return {
    type: 'ADD_OR_UPDATE_FORM_PARAMS',
    payload: {
      params
    }
  }
};

const addOrUpdateGlobal = (key, value) => {
  let payload = {};
  payload[key] = value;
  return {
    type: 'ADD_OR_UPDATE_GLOBAL',
    payload: payload
  }
};

class AcornFormParams {
  static update = params => {
    return {
      type: 'UPDATE_ACORN_PARAMS',
      payload: [...params]
    }
  }
  static updateParamValue = (key, value) => {
    return {
      type: 'UPDATE_ACORN_PARAM_VALUE',
      payload: {key: key, value: value}
    }
  }
};

export { 
  initService, updateLeadId, addOrUpdateFormParam,
  addOrUpdateFormParams, addOrUpdateGlobal, AcornFormParams
};