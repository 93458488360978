// Node Module Imports
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import GlobalStyle from '../styles/Global'
import { TrackGa } from '../utilities'
import watchInputs from '../utilities/watchInputs'

class DefaultLayout extends React.Component {
  componentDidMount() {
    TrackGa.init({
      DEBUG: this.props._r_globals.environment === 'development',
      parentToObserve: document.querySelector('body'),
      clickables: [
        {
          selector:
            'img[src="https://www.ultimatemedical.edu/wp-content/uploads/2016/04/liveEngage-circle-e1482121031291.png"]',
          events: 'click',
          reselect: true,
          ga: [
            'send',
            'event',
            'chat',
            'click',
            'click to chat - desktop - Learn',
          ],
        },
      ],
    })
    watchInputs()
  }

  render() {
    let { children } = this.props

    return (
      <>
        <Helmet>
          <title>
            {this.props.seoTitle ? `${this.props.seoTitle} | ` : ''}Ultimate
            Medical Academy
          </title>
        </Helmet>
        {children}
        <GlobalStyle />
        <div className="container disclaimer">
          <p
            className="py-3 my-0"
            style={{ fontSize: '0.65rem', lineHeight: '1.25' }}
          >
            Where national data regarding earnings or employability within a
            particular field are cited, individual readers should take note that
            national averages may not accurately reflect the earnings of workers
            in their particular part of the country, and may include earners at
            all stages of their career, not just entry-level wages for recent
            graduates.
          </p>
          <p className="my-0" style={{ fontSize: '0.65rem' }}>
            Set schedules required for on-site externship/practicum courses in
            select programs and in all programs, coursework deadlines are set by
            instructors.
          </p>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { _r_globals } = state
  return { _r_globals: _r_globals }
}

export default styled(connect(mapStateToProps)(DefaultLayout))``
