import React from 'react';
import { graphql, Link } from 'gatsby';
import parse from 'react-html-parser';
import { store } from '../redux';
import useGa from '../hooks/useGa';
import SEO from '../components/SEO';
import styled from 'styled-components';
import Layout from '../layouts/DefaultLayout';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import LogoSquare from '../components/LogoSquare';
import { AcornFormParams } from '../redux/actions';
import { above, colors } from '../styles/utilities';
import findInObject from '../utilities/findInObject';
import GlobalFooter from '../components/GlobalFooter';
import ImageTouts from '../components/cross-template-statics/ImageTouts';
import Form from '../components/forms/AcormFormWrapper';
import { NarrowContainer } from '../styles/elements/NarrowContainer';
import DRTVHero from '../components/heroes/DRTVHero';
import NumberTouts from '../components/cross-template-statics/NumberTouts';
import StickyMobileFooter from '../components/reusables/StickyMobileFooter';
import RememberTraceParams from '../components/effects/RememberTraceParams';

const DRTV = ({className, data}) => {

  const onlineOnly = findInObject('onlineOnly', data.markdown.frontmatter);

  const settings = {
    allowNull: true
  }

  const leadsource = 
          findInObject('tracking.leadsource', data.markdown.frontmatter, settings),
        leadvendor = 
          findInObject('tracking.leadvendor', data.markdown.frontmatter, settings),
        vendor = 
          findInObject('tracking.vendor', data.markdown.frontmatter, settings),
        phoneNumber =
          findInObject('phone_number', data.markdown.frontmatter, {strictMode: true});

  if(leadsource) {
    store.dispatch(AcornFormParams.updateParamValue('LeadSource', leadsource));
  }
  if(leadvendor) {
    store.dispatch(AcornFormParams.updateParamValue('LeadVendor', leadvendor));
  }
  if(vendor) {
    store.dispatch(AcornFormParams.updateParamValue('Vendor', vendor));
  }

  const excludePrograms = data?.markdown?.frontmatter?.v1?.form?.excludePrograms?.map(p => p.code)

  const formEvent = useGa();

  const sortPrograms = programEdges => {
    return programEdges.sort((a, b) => {
      let aNorm = a.node.meta.program_full_name.toLowerCase(),
          bNorm = b.node.meta.program_full_name.toLowerCase();
      if(aNorm < bNorm) {
        return -1;
      }
      else if(aNorm > bNorm) {
        return 1;
      }
      return 0;
    });
  };

  const sortedOnlinePrograms = sortPrograms(data.onlineDegreePrograms.edges),
        sortedClearwaterPrograms = sortPrograms(data.clearwaterDiplomaPrograms.edges);

  const programLinkPathMapperOnline = [
    "health-and-human-services-2",
    "health-information-technology-2",
    "medical-administrative-assistant-2",
    "medical-office-billing-specialist-2",
    "pharmacy-technician",
    "healthcare-accounting",
    "healthcare-management-2",
    "medical-billing-coding-2"
  ];

  const programLinkPathMapperClearwater = [
    "dental-assistant-2",
    "medical-assistant-2",
    "nursing-assistant-2",
    "patient-care-technician-2",
    "phlebotomy-2",
    "dental-assistant-2",
    "medical-assistant-2"
  ];

  const renderHeroText = () => {
    if(data?.markdown?.frontmatter?.hero?.text) {
      return parse(data.markdown.frontmatter.hero.text);
    }
    return (
      <>
        <span>The Nation<br />Needs<br /></span>
        <span className="bold">Healthcare Heroes.</span>
      </>
    );
  };

  return (
    <Layout>

    <RememberTraceParams whichForm="acorn" />

    <SEO 
      title="Healthcare Heroes"
      description="The nation needs heroes in healthcare. The journey begins at Ultimate Medical Academy." />

    <div className={className}>

      <DRTVHero
        bgPosiiton="30% center"
        bgImgSm={data.markdown.frontmatter.hero.img_sm.path.publicURL}
        bgImgLg={data.markdown.frontmatter.hero.img_lg.path.publicURL}
        phoneNumber={phoneNumber} >
          <div className="container hero-content">
            <div className="row pt-5 ">
              <div className="col-xl-7">
                <p className="mb-0 mt-0">
                  {renderHeroText()}
                </p>
                <div className="arrow">
                  <svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="0,0 0,100 50,50" />
                  </svg>
                  <p className="m-0 p-2">Classes Starting Soon</p>
                </div>
              </div>
            </div>
          </div>
      </DRTVHero>

      <NarrowContainer className="page-content py-xl-5">
        <div className="row">
          <div className="col-xl-6 order-2 order-xl-1 pt-5 pt-xl-0">
            <MDXRenderer>{data.markdown.body}</MDXRenderer>
          </div>
          <div className="col-xl-5 offset-xl-1 p-0 order-1 order-xl-2">
            <div className="drtv-form-wrap p-4">
              <Form
                beforeSubmitCallback={() => formEvent({
                  category: 'rfi-form', action: 'submit', label: 'under-hero'
                })}
                redirect={`/confirmation/?campaign=healthcare-heroes&phone=${phoneNumber}`}
                aboveForm={() => (
                  <h2 className="mt-0 text-white">PURSUE A HEALTHCARE CAREER</h2>
                )}
                excludePrograms={excludePrograms}
                className="drtv-form"
                overrideFormLabels={{
                  Campus: 'How would you like to study?'
                }} 
                removeFormParamsAndSpecifyDefaultValues={(() => {
                  if(onlineOnly) {
                    return {
                      Campus: '45'
                    }
                  }
                  else {
                    return {
                      State: 'GU'
                    }
                  }
                })()} />
              </div>
          </div>
        </div>
      </NarrowContainer>

      <div className="program-stack wp-content">
        <div className="online">
          <div className="container">
            <div className="row d-flex">
              <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                <div 
                  className="bg-img" 
                  style={{
                    backgroundImage: `
                      url(${data.markdown.frontmatter.onlinePrograms.img.path.publicURL})
                    `
                  }} />
              </div>
              <div className="col-12 col-md-6 p-0 text order-1 order-md-2">
                <h3 className="heading m-0 p-3">Online</h3>
                <p className="px-3 mb-0 bold">Earn an associate degree online from the comfort of your home with a flexible schedule.<sup>1</sup></p>
                <ul className="p-3">
                  {sortedOnlinePrograms.map(({node}, idx) => (
                    <li key={node.meta.program_full_name}>
                      <Link className="Link" to={`/online/${programLinkPathMapperOnline[idx]}`}>{node.meta.program_full_name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {!onlineOnly && (
          <div className="clearwater">
            <div className="container">
              <div className="row d-flex">
                <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                  <div 
                    className="bg-img" 
                    style={{
                      backgroundImage: `
                        url(${data.markdown.frontmatter.clearwaterPrograms.img.path.publicURL})
                      `
                    }} />
                </div>
                <div className="col-12 col-md-6 p-0 text order-1 order-md-2">
                  <h3 className="heading m-0 p-3">Clearwater</h3>
                  <p className="px-3 mb-0 bold">Study at the Clearwater campus and gain hands-on training.</p>
                  <ul className="p-3">
                    {sortedClearwaterPrograms.map(({node}, idx) => (
                      <li key={node.meta.program_full_name}>
                        <Link className="Link" to={`/clearwater/${programLinkPathMapperClearwater[idx]}`}>{node.meta.program_full_name}</Link>
                      </li>
                    ))}
                    {data.clearwaterDegreePrograms.edges.map(({node}, idx) => (
                      <li key={node.meta.program_full_name}>
                        <Link className="Link" to={`/clearwater/${programLinkPathMapperClearwater[idx]}`}>{node.meta.program_full_name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <NumberTouts onlineOnly={onlineOnly} />

      <ImageTouts content={() => {
        if(onlineOnly) {
          return (
            <>
            <h2 className="blue">Heroes are stronger together.</h2>
            <p>When you enroll at UMA you can count on our student support services to be there for you. We are ready to help with:</p>
            </>
          );
        }
        return (
          <>
          <h2 className="blue">Heroes are stronger together.</h2>
          <p>When you enroll at UMA — online or at our campus located in Clearwater, FL — you can count on our student support services to be there for you. We are ready to help with:</p>
          </>
        )
      }} />

      <div className="footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <LogoSquare />
            </div>
            <div className="col-12 col-md-8">
              <p>If you’re committed to doing more, to seeking a new path, and to making a difference in your life, UMA is ready to help you get started. Begin your journey today.</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div className="container py-4">
      {!onlineOnly && (
        <p className="m-0"><small>* Nursing Assistant diploma program remains fully on-campus.</small></p>
      )}
      <p className="m-0"><small><sup>1</sup> Set schedules required for externship/practicum courses in select programs.</small></p>
    </div>

    <GlobalFooter phone={phoneNumber} sticky={true} />
    {/* <StickyMobileFooter phone={phoneNumber} chatBtnId="LP_DIV_1432733350793" /> */}

    </Layout>
  )

};

export default styled(DRTV)`

  p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1, h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
  }
    
  .hero-content {

    .row {
      padding-top: 8rem;

      ${above.xl`
        padding-top: 2rem;
        padding-bottom: 2rem;      
      `}
    }
    
    p {
      color: #fff;
      font-size: 3rem;
      line-height: 1;
      text-transform: uppercase;

      ${above.xl`
        font-size: 4rem;
      `}
    }

    .arrow {
      background-color: ${colors.umaOrange};
      box-shadow: 8px 8px 6px hsla(0, 0%, 0%, 0.4);
      border-radius: 1px;
      display: inline-block;
      margin-top: 1.5rem;
      position: relative;
      max-width: 88%;

      svg {
        border-radius: 1px;
        fill: ${colors.umaOrange};
        position: absolute;
          top: 0; bottom: 0; right: 0;
        height: 100%;
        transform: translateX(100%);
        width: auto;
      }
    }

    .arrow p {
      font-size: 1.5rem;

      ${above.md`
        font-size: 2rem;
      `}
    }
  }

  .page-content {
    max-width: 75rem;

    ${above.xl`
      max-width: 72rem;
    `}

    h1 {
      margin-top: 0;
    }

    li {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    strong {
      color: ${colors.umaTeal};
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: bold;
    }
  }

  .program-stack {
    color: white;
    margin-top: 3rem;

    ${above.md`
      margin-top: 8rem;
    `}

    .heading {
      color: white;
    }

    .Link {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .online, .clearwater {
      position: relative;

      .bg-img-wrap, .text {
        ${above.md`
          margin-top: -3.45rem;
        `}
      }

      .bg-img-wrap {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        overflow: hidden;
      }

      .text {
        background-color: ${colors.umaDarkBlue};
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        overflow: hidden;
      }
    }

    .online::before, .clearwater::before {
      content: '';
      display: block;
      background-color: ${colors.umaTeal};
      position: absolute;
        top: 3.45rem; right: 0; bottom: 3.45rem; left: 0;

      ${above.md`
        top: 0; bottom: -3.45rem;
        padding-bottom: 3.45rem;
      `}
    }

    .online {
      margin-bottom: 4rem;
      
      .heading {
        background-color: ${colors.umaPurple};
      }
    }

    .clearwater {
      ${above.md`
        margin-bottom: 4rem;
        margin-top: 15rem;
      `}
      .heading {
        background-color: ${colors.umaOrange};
      }
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .drtv-form-wrap {
    position: relative;
    ${above.xl`
      box-shadow: -8px -8px 6px hsla(0, 0%, 0%, 0.4);
      margin-top: -10rem;
    `}

      &::before {
        background-color: ${colors.umaTeal};
        border-radius: 2px;
        content: '';
        display: block;
        opacity: 0.9;
        position: absolute;
          top: 0; right: 0; bottom: 0; left: 0;
        width: 100%;
        z-index: -1;
      }

    .drtv-form {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  
`;

export const query = graphql`
  query($id: String!) {

    markdown: mdx(id: {eq: $id}) {
      frontmatter {
        onlineOnly
        phone_number
        v1 {
          form {
            excludePrograms {
              code
            }
          }
        }
        tracking {
          leadsource
          leadvendor
          vendor
        }
        hero {
          img_sm {
            path {
              publicURL
            }
          }
          img_lg {
            path {
              publicURL
            }
          }
          text
        }
        clearwaterPrograms {
          img {
            path {
              publicURL
            }
          }
        }
        onlinePrograms {
          img {
            path {
              publicURL
            }
          }
        }
      }
      body
    }

    clearwaterDiplomaPrograms: allWordpressWpPrograms(
      filter: {
        meta: {
          program_campus: {
            eq: "47"
          }
          program_type: {
            eq: "diploma"
          }
        }
      }
      sort: {
        fields: meta___program_full_name
      }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    clearwaterDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: {
          program_campus: {
            eq: "47"
          }
          program_type: {
            eq: "degree"
          }
        }
      }
      sort: {
        fields: meta___program_code
      }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    onlineDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: {
          program_campus: {
            eq: "45"
          }
          program_type: {
            eq: "degree"
          }
        }
      }
      sort: {
        fields: meta___program_full_name
      }
    ) {
      edges {
        node {
          meta {
            program_full_name
          }
        }
      }
    }

  }
`;