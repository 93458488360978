// Node Module Imports
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Component Imports
import DefaultLayout from '../layouts/DefaultLayout'

// Style Imports
import { above, colors } from '../styles/utilities'

// Asset Imports
import LogoRelevancy from '../components/LogoRelevancy'
import faceM from '../images/relevancy/1/mobile/face.jpg'
import faceD from '../images/relevancy/1/desktop/face.jpg'
import chevPatternPurple from '../images/relevancy/1/common/pattern-chev-purple.png'

// Utility Imports
import { stripPhoneNumber } from '../utilities'
import QueryStringParser from '@ultimatemedical/uma-utils/dist/query-string/parser'

const ConfirmationRel = ({ className }) => {
  const year = new Date().getFullYear()
  const [phoneNumber, setPhoneNumber] = useState('888-202-2877')

  useEffect(() => {
    const qsParser = new QueryStringParser(window.location.search)
    setPhoneNumber(qsParser.get('phone', phoneNumber))
  }, [phoneNumber])

  return (
    <DefaultLayout>
      <div className={className}>
        <div className="header-mobile">
          <img
            src={faceM}
            alt="12.5 Million Jobs Projected by 2028. Bureau of Labor Statistics. Employment by major occupational group. Table 1.2 in the downloadable XLSLX file, lines 395-J and 467-J. https://www.bls.gov/emp/tables/emp-by-major-occupational-group.htm"
            className="face-it mobile"
          />
          <LogoRelevancy />
          <p className="thank-you">
            Thank you for your interest in Ultimate Medical Academy. We will be
            in touch shortly to help you explore your healthcare education
            options.
          </p>
        </div>
        <div className="header-desktop">
          <div className="face-it desktop"></div>
          <div className="thank-you">
            <LogoRelevancy />
            <div className="text-wrapper">
              <p className="blue bigger">
                Thank you for your interest in Ultimate Medical Academy.
              </p>
              <p className="blue bigger">
                We will be in touch shortly to help you explore your healthcare
                education options.
              </p>
              <p className="blue smaller">
                Should you have questions before we contact you, please reach
                out to our team at{' '}
                <span className="nowrap">
                  <a
                    className="ga-call"
                    ga-label="confirmation-make-a-difference"
                    href={`tel:${stripPhoneNumber(phoneNumber)}`}
                  >
                    {phoneNumber}
                  </a>
                  .
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="about-uma-outer">
          <div className="about-uma-inner">
            <p>
              <strong>About UMA</strong>
            </p>
            <p>
              Ultimate Medical Academy educates students for careers in
              healthcare that can make a difference in their lives, and the
              lives of others. We are a dynamic educational institution
              committed to equipping and empowering students to excel in
              healthcare careers.
            </p>
            <p>
              UMA is proud to have supported more than 72,000 students through
              graduation.
            </p>
            <small>
              © {year} UMA Education, Inc. d/b/a Ultimate Medical Academy |{' '}
              <a
                href="https://www.ultimatemedical.edu/about/citations/"
                className="td-n-hu"
              >
                Marketing Statistics
              </a>{' '}
              |{' '}
              <a
                href="https://www.ultimatemedical.edu/privacy-policy/"
                className="td-n-hu"
              >
                Privacy Policy
              </a>
            </small>
            <br />
            <br />
            <small>
              Ultimate Medical Academy does not recruit or enroll residents of
              the European Union.
            </small>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default styled(ConfirmationRel)`
  .header-mobile {
    position: relative;
    ${above.md`
      display: none;
    `}
    .face-it {
      width: 100%;
    }
    [class^="LogoRelevancy"] {
      position: absolute;
      top: 2%;
      left: calc(50% - 125px);
    }
    .thank-you {
      font-size: 1.5rem;
      line-height: 1.7rem;
      margin: 2rem 0;
      padding: 0 calc(100% / 12);
    }
  }
  .header-desktop {
    display: none;
    max-width: 1200px;
    margin: auto;
    ${above.md`
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;
    `}
    .face-it.desktop {
      background-image: url(${faceD});
      background-position: right center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      right: 1.5rem;
      flex: 0 0 55%;
    }
    .thank-you {
      min-height: 600px;
      flex: 0 0 45%;
      background: url('${chevPatternPurple}');
      border-left: 2px solid hsla(0, 0%, 0%, 0.2);
      border-right: 2px solid hsla(0, 0%, 0%, 0.2);
      padding: 2rem 2rem 3rem 2rem;
      [class^="LogoRelevancy"] {
        text-align: center;
      }
      .text-wrapper {
        .blue {
          color: ${colors.umaDarkBlue};
          &.bigger {
            font-size: 2rem;
            line-height: 2.2rem;
            margin: 1.87rem 0;
          }
          &.smaller {
            font-size: 1.5rem;
            line-height: 1.7rem;
          }
        }
      }
    }
  }
  .about-uma-outer {
    .about-uma-inner {
      margin: 0 calc(100% / 12);
      padding: 2rem 0;
      border-top: 1px solid ${colors.umaPurple};
      ${above.xl`
          max-width: 1200px;
          margin: auto;
      `}
      }
      p {
        color: ${colors.umaDarkGray};
        font-size: 1.5rem;
        line-height: 1.7rem;
      }
    }
  }
`

// alt tags
// links to PDFs
