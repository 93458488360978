import { unionBy, find } from 'lodash'
import { hiddenFormParamsInitalState } from '../initial-state'
import { hiddenFormParamsAction } from '../actions'

const hiddenFormParamsReducer = (previousState=hiddenFormParamsInitalState, action) => {

  if(action.type === hiddenFormParamsAction.actions.UPDATE_MULTIPLE) {
    return unionBy(action.payload, previousState, 'key')
  }

  if(action.type === hiddenFormParamsAction.actions.UPDATE_SINGLE) {
    const newState = [...previousState];
    const obj = find(newState, o => { 
      return o.key === action.payload.key 
    });
    if(obj) { 
      obj.value = action.payload.value; 
      return newState;
    }
  }

  return previousState;
}

export default hiddenFormParamsReducer