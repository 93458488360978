import React from 'react'

import PropTypes from 'prop-types'
import { FaBars, FaPhone } from 'react-icons/fa'
import styled from 'styled-components'

import rectLogo from '../../images/logos/rectangle-logo-with-r.png'
import { above } from '../../styles/utilities'
import LogoHomeLink from '../LogoHomeLink'

const HeaderWithMenu = ({
  className,
  displayMobileMenu,
  hideMenu,
  logoIsLink,
  phoneNumber,
  setDisplayMobileMenu,
  selectedCampus,
}) => {
  return (
    <div className={className}>
      <div className="d-flex justify-content-between align-items-center">
        {!hideMenu && (
          <div className="item fabars">
            <button
              type="button"
              onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
            >
              <span className="bars-wrap">
                <FaBars className="icon link" />
              </span>
              <span className="d-none d-md-inline-block"> UMA Programs</span>
            </button>
          </div>
        )}
        <div
          className={hideMenu ? 'item logo-wrap text-left' : 'item logo-wrap'}
        >
          {logoIsLink ? (
            <LogoHomeLink className="logo" selectedCampus={selectedCampus} />
          ) : (
            <img
              className="logo"
              src={rectLogo}
              alt="Ultimate Medical Academy"
              width="250"
            />
          )}
        </div>
        <div className="item phone-wrap">
          <a
            href={`tel:${phoneNumber}`}
            className="phone ga-call link td-n-hu"
            ga-label="header"
          >
            <span>
              <FaPhone className="fa-phone icon" />
            </span>
            <span className="number d-none d-md-inline-block">
              {phoneNumber}
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default styled(HeaderWithMenu)`
  position: relative;
  width: 100%;
  z-index: 9;

  .icon {
    color: white;
    font-size: 1.5rem;

    &.fa-phone {
      font-size: 1.2rem;
    }
  }

  .phone .icon {
    margin-right: 0.5rem;
    transform: scaleX(-1);
  }

  .phone .number {
    color: white;
  }

  .link {
    cursor: pointer;
  }

  .d-flex {
    align-items: flex-end;
    justify-content: space-between;
  }

  .item {
    flex: 1 1 auto;
  }

  .item.fabars {
    ${above.md`
      display: inline-flex;
      flex: 0 1 auto;
      justify-content: flex-end;
      order: 2;
    `}

    button {
      align-items: flex-end;
      background-color: transparent;
      border: none;
      color: #fff;
      cursor: pointer;
      display: flex;
      padding-left: 0;
    }
  }

  .bars-wrap {
    font-size: 0;
    line-height: 0;
  }

  .icon.link {
    margin-right: 0.5rem;
  }

  .item.logo-wrap {
    flex: 1 1 auto;
    text-align: center;

    ${above.md`
      order: 0;
      text-align: left;
    `}
  }

  .item.logo-wrap.text-left {
    text-align: left;
  }

  .item.phone-wrap {
    text-align: right;

    ${above.md`
      order: 1;
      margin: 0 1.5rem;
      text-align: right;
    `}

    a {
      vertical-align: baseline;
    }
  }

  .td-n-hu:after {
    background: #fff;
    position: relative;
    top: 0.25rem;
  }
`

HeaderWithMenu.propTypes = {
  className: PropTypes.string,
  hideMenu: PropTypes.bool,
  logoIsLink: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
  selectedCampus: PropTypes.string.isRequired,
}

HeaderWithMenu.defaultProps = {
  className: '',
  hideMenu: false,
  logoIsLink: true,
}
