import { UMAPhoneNumber } from './phoneNumbers'

export enum UMAAddressKey {
  Clearwater = 'umaClearwater',
  Online = 'umaOnline',
  Operations = 'umaOperations',
}

export type Address = {
  addressLineOne: string
  addressLineTwo: string
  googleMapsLink: string
  includeInFooter: boolean
  name: string
  phoneNumber: UMAPhoneNumber
}

export type UMAAddresses = Record<UMAAddressKey, Address>

export const addresses: UMAAddresses = {
  [UMAAddressKey.Clearwater]: {
    addressLineOne: '1255 Cleveland St.',
    addressLineTwo: 'Clearwater, FL 33755',
    googleMapsLink:
      'https://www.google.com/maps/place/Ultimate+Medical+Academy+(Clearwater)/@28.0000739,-82.7616858,11z/data=!4m8!1m2!2m1!1sultimate+medical+academy!3m4!1s0x88c2f0fafb922709:0xa3a29b711aef2026!8m2!3d27.9652556!4d-82.7855559',
    includeInFooter: true,
    name: 'UMA (Clearwater)',
    phoneNumber: UMAPhoneNumber.Clearwater,
  },
  [UMAAddressKey.Online]: {
    addressLineOne: '9309 N. Florida Ave.',
    addressLineTwo: 'Tampa, FL 33612',
    googleMapsLink:
      'https://www.google.com/maps/place/Ultimate+Medical+Academy+Online+Operations/@28.0000739,-82.7616858,11z/data=!4m8!1m2!2m1!1sultimate+medical+academy!3m4!1s0x88c2c6dcc14008af:0x4c603885c8a30196!8m2!3d28.0350342!4d-82.4576448',
    includeInFooter: true,
    name: 'UMA (Online)',
    phoneNumber: UMAPhoneNumber.Online,
  },
  [UMAAddressKey.Operations]: {
    addressLineOne: '9309 N. Florida Ave., Suite 100',
    addressLineTwo: 'Tampa, FL 33612',
    googleMapsLink:
      'https://www.google.com/maps/place/Ultimate+Medical+Academy+Online+Operations/@28.0000739,-82.7616858,11z/data=!4m8!1m2!2m1!1sultimate+medical+academy!3m4!1s0x88c2c6dcc14008af:0x4c603885c8a30196!8m2!3d28.0350342!4d-82.4576448',
    includeInFooter: false,
    name: 'UMA (Operations)',
    phoneNumber: UMAPhoneNumber.Operations,
  },
}
