import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import BgChevronStrokeCoolGray from '../../images/svgs/bgChevronStrokeCoolGray.svg';

const ChevronBackgroundStroke = ({children, className}) => (
  <div className={`${className}`}>
    <div 
      className="bg--chev"
      style={{backgroundImage: `url(${BgChevronStrokeCoolGray})`}} />
    {children}
  </div>
);

ChevronBackgroundStroke.propTypes = {
  className: PropTypes.string
};

export default styled(ChevronBackgroundStroke)`
  position: relative;

  .bg--chev {
    background-position: center;
    background-repeat: repeat;
    background-size: 162px 72px;
    opacity: 0.25;
    position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
  }
`;