// ok becuase we're using implicit label controls
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'

import { FieldState } from '../../hooks/useForm'

const RadioField = ({ field }: { field: FieldState }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.update({ value: e.target.value })
    field.validate()
  }
  const onBlur = () => {
    field.update({ touched: true })
  }

  return (
    <fieldset className="input-wrapper">
      <legend>
        <span className="d-flex justify-content-between label-and-error">
          <span className="d-block label-text">
            {field.label}
            {field.markRequired && <span className="req"> *</span>}
          </span>
          {field.touched && field.errors?.[0] && (
            <span className="d-block error">{field.errors?.[0]}</span>
          )}
        </span>
      </legend>
      {field.options &&
        field.options.map((option) => (
          <label key={option.value}>
            <input
              onBlur={onBlur}
              onChange={onChange}
              type="radio"
              name={field.id}
              value={option.value}
            />
            <span className="">{option.label}</span>
          </label>
        ))}
    </fieldset>
  )
}

export default RadioField
