enum campusEnum {
  All = "UNSPEC",
  Online = "online",
  Clearwater = "clearwater"
}

interface umaState {
  campus: campusEnum,
  phone: string
}

export { campusEnum, umaState }