// Node Module Imports
import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

// Compoent Imports
import EnrollmentStartBottomSection from '../../components/ScopedToTemplates/enrollment-start/EnrollmentStartBottomSection';
import HeaderLogoLink from '../../components/HeadersAndHeroes/HeaderLogoLink';
import Layout from '../../components/Layout';
import VideoWithTouts from '../../components/reusables/VideoWithTouts';

const TemplateEnrollmentStart = ({data, location}) => {

  const { page, template } = data;
  const templateStyle = page.frontmatter.template.style;

  return (
    <Layout template="enrollment-start" seo={{
      page_title: page.frontmatter.seo.title,
      meta_description: page.frontmatter.seo.description
    }}>
      <HeaderLogoLink 
        linkInfo={template.frontmatter.headerLink}
        templateStyle={templateStyle} />
      <VideoWithTouts
        loc={location}
        videoTouts={page.frontmatter.videoLinks}
        videoInfo={page.frontmatter.video}
        renderAboveVideo={() => <MDXRenderer>{page.body}</MDXRenderer> } />
      <EnrollmentStartBottomSection templateStyle={templateStyle} />
    </Layout>
  )
}

export default TemplateEnrollmentStart;

export const query = graphql`
  query($id: String!) {

    template: mdx(fileAbsolutePath: {regex: "/markdown/templates/enrollment-start/enrollment-start.md/"}) {
      frontmatter {
        headerLink {
          text
          href
          external
        }
        linkBelowVideos {
          text
          href
          external
        }
        questionsBlurb {
          childMdx {
            body
          }
        }
      }
    }

    page: mdx(id: {eq: $id}) {
      frontmatter {
        seo {
          title
        }
        template {
          style
        }
        video {
          length
          embedCode
          posterAlt
          poster {
            childImageSharp {
              id
              fixed(quality: 100, width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        videoLinks {
          a {
            childMdx {
              fileAbsolutePath
              excerpt
              body
              frontmatter {
                video {
                  posterSmAlt
                  posterSm {
                    childImageSharp {
                      id
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          b {
            childMdx {
              fileAbsolutePath
              body
              excerpt
              frontmatter {
                video {
                  posterSmAlt
                  posterSm {
                    childImageSharp {
                      id
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          c {
            childMdx {
              fileAbsolutePath
              body
              excerpt
              frontmatter {
                video {
                  posterSmAlt
                  posterSm {
                    childImageSharp {
                      id
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      body
    }
  }
`;