import React from 'react';
import { graphql, Link } from 'gatsby';
import { colors } from '../styles/utilities';
import styled from 'styled-components';
import DefaultLayout from '../layouts/DefaultLayout';
import Filter from '../components/Filter';
import { WiSunrise, WiSunset } from "react-icons/wi";
import { WpPage } from '../utilities/models';
import getPagePath from '../utilities/getPagePath';

class SecretPageList extends React.Component {


  /**
   * constructor
   * 
   * @param {object} props
   * @return void
   */
  constructor(props) {
    super(props);

    this.templates = [
      "Chat Now", 
      "Stitch In", 
      "Brand",
      "Call Now",
      "Enrollment Start"
    ];

    this.activeFilters = [];

    this.componentDidMount = this.componentDidMount.bind(this);
    this.initializePageList = this.initializePageList.bind(this);
    this.createInitialPageList = this.createInitialPageList.bind(this);
    this.createPageInstance = this.createPageInstance.bind(this);
    this.renderPageList = this.renderPageList.bind(this);
    this.sortPageList = this.sortPageList.bind(this);
    this.handleSort = this.handleSort.bind(this);

    this.state = {
      darkMode: false,
      pageList: [],
      sortState: [],
    };
  }


  /**
   * componentDidMount
   * 
   * When the component is ready, we'll update its state
   * with the pagelist data.
   * 
   * @return void
   */
  componentDidMount() {
    this.initializePageList();
  }


  /**
   * initializePageList
   * 
   * Meant to be called when the component mounts. This sets the 
   * state with the original page list.
   * 
   * @return void
   */
  initializePageList() {
    let pageList = this.createInitialPageList();
    this.setState({pageList});
  }


  /**
   * createInitialPageList
   * 
   * Get a copy of the original list of pages before any frontend
   * sorting or filtering takes place. For each page, create a 
   * standardized page object.
   * 
   * @return {array} an array of nodes from gatsby-source-wordpress
   */
  createInitialPageList() {

    const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };

    let initialPageList = this.props.data.allWordpressPage.edges.map(page => {
      return this.createPageInstance(page);
    });

    let mdxPages = this.props.data.allMdx.edges.map(mdxPage => {
      
      let created_at = new Date(mdxPage.node.frontmatter.published);
      created_at = created_at.toLocaleDateString('en-US', dateOptions);

      let updated_at = new Date(mdxPage.node.frontmatter.updated);
      updated_at = updated_at.toLocaleDateString('en-US', dateOptions);
      
      let page = new WpPage({
        path: getPagePath({
          absolutePath: mdxPage.node.fileAbsolutePath,
          folderToServeAsRootForSlug: '/src/markdown/pages/',
          exclude: [
            /\.mdx?/,
          ]
        }).slice(1),
        template: 'Enrollment Start',
        created_at: created_at,
        updated_at: updated_at,
        display: true,
      });
      
      if(this.templates.indexOf(page.template) !== -1) {
        page.published = true;
      }
      else {
        page.published = false;
      }
      return page;
    });
    return [...initialPageList, ...mdxPages];
  }


  /**
   * createPageInstance
   * 
   * Create a WpPage instance.
   * 
   * @return {WpPage} a WpPage object
   */
  createPageInstance(wordPressPage) {

    let wpp = wordPressPage;

    let page = new WpPage({
      path: wpp.node.path,
      template: wpp.node.acf.uma_page_template.label,
      created_at: wpp.node.date,
      updated_at: wpp.node.modified,
      display: true
    });

    if(this.templates.indexOf(page.template) !== -1) {
      page.published = true;
    }
    else {
      page.published = false;
    }

    return page;

  }


  /**
   * renderPageList
   * 
   * Get a copy of the pageList from state and for each page, create
   * a table row.
   * 
   * @return {array} an array of jsx
   */
  renderPageList() {

    if(!this.state.pageList.length) return null;

    let pageList = this.state.pageList,
        tableRows = null,
        count = 0;
    
    tableRows = pageList.map(page => {
      if(page !== null && page.display && page.passesFilters()) {
        count++;
        return (
          <tr key={count} >
            <td>{count}</td>
            <td><Link to={`/${page.path}`}>{`/${page.path}`}</Link></td>
            <td>{page.template}</td>
            <td>{page.created_at}</td>
            <td>{page.updated_at}</td>
            <td>{page.published ? 'Yes' : 'No'}</td>
          </tr>
        )
      }
      return null;
    });

    return tableRows;
  }


  /**
   * sortPageList
   * 
   * Given a pagelist and sorting configuration, sort the provided page list.
   * 
   * @param {array} pageList The current page list.
   * @param {object} sortTo What we're sorting to (i.e. asc or desc, sort type, sort name)
   * @return {array} pageList an array of page objects
   */
  sortPageList(pageList, sortTo) {

    if(sortTo.type === 'date') {
      pageList.sort((a, b) => {
        if(sortTo.desc) {
          return new Date(b[sortTo.name]) - new Date(a[sortTo.name]);
        }
        else return new Date(a[sortTo.name]) - new Date(b[sortTo.name]);
      });
    }

    else if(sortTo.type === 'alpha') {
      sortTo.desc = !sortTo.desc;
      pageList.sort((a, b) => {
        if (sortTo.desc === true) {
          return a[sortTo.name].toLowerCase().replace(' ', '') < b[sortTo.name].toLowerCase().replace(' ', '') ? 1 : -1;
        } else {
          return a[sortTo.name].toLowerCase().replace(' ', '') < b[sortTo.name].toLowerCase().replace(' ', '') ? -1 : 1;
        }
      });
    }

    else if(sortTo.type === 'boolean') {
      pageList.sort((a, b) => {
        if (sortTo.desc === true) {
          return a[sortTo.name] === true ? -1: 1;
        } else {
          return a[sortTo.name] === true ? 1 : -1;
        }
      });
    }

    return pageList;
  }


  /**
   * handleSort
   * 
   * Given a sorting configuration, update the sort state and page list, 
   * then update the state with these new values.
   * 
   * @param {object} sortTo What we're sorting to (i.e. asc or desc, sort type, sort name)
   * @return {void}
   */
  handleSort(sortTo) {

    let pageList       = this.state.pageList,
        sortState      = this.state.sortState,
        alreadyExists  = false;

    sortState.forEach((sortFrom, i) => {
      if(sortTo.name === sortFrom.name) {
        sortState[i].desc = !sortState[i].desc;
        sortTo.desc = sortState[i].desc;
        pageList = this.sortPageList(pageList, sortTo);
        alreadyExists = true;
      }
    });

    if(!alreadyExists) {
      sortTo.desc = true;
      sortState.push({...sortTo});
      pageList = this.sortPageList(pageList, sortTo);
    }

    this.setState({pageList, sortState});
  }

  render() {
    return(
      <DefaultLayout seoTitle="Page List">
        <div className={`${this.props.className} ${this.state.darkMode ? 'theme--dark' : 'theme--light'}`}>
          <div className="page-header">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <h1>UMA Aspen Pages</h1>
                  <div>
                    <button 
                      className="button--svg" 
                      onClick={() => {
                        this.setState({darkMode: !this.state.darkMode})
                      }}
                    >
                      {
                        this.state.darkMode
                          ? <WiSunrise />
                          : <WiSunset />
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container page-container">
            <div className="row">
              <aside className="col-lg-2 page-section page-section--filters">
                <h2>Filters</h2>
                <div className="filters">
                  <fieldset>
                    <legend>Page</legend>
                    <Filter
                      group="page"
                      label="Published"
                      value="published_true"
                      name="published_true"
                      filter={{
                        column: 'published',
                        columnValue: true
                      }}
                      getParentState={() => {
                        return {...this.state};
                      }}
                      setParentState={(items) => this.setState(items)}
                    />
                    <Filter
                      group="page"
                      label="Not Published"
                      value="published_false"
                      name="published_false"
                      filter={{
                        column: 'published',
                        columnValue: false
                      }}
                      getParentState={() => {
                        return {...this.state};
                      }}
                      setParentState={(items) => this.setState(items)}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Template</legend>
                    {this.templates.map(template => {
                      return (
                        <Filter
                          group="template"
                          label={template}
                          value={template}
                          name={template}
                          filter={{
                            column: 'template',
                            columnValue: template
                          }}
                          // key={`${Math.random().toString(36).substring(4)}`}
                          key={template}
                          getParentState={() => ({...this.state})}
                          setParentState={(items) => this.setState(items)}
                        />
                      )
                    })}
                    <Filter
                      group="template"
                      label="No Template"
                      value="No Template"
                      name="No Template"
                      filter={{
                        column: 'template',
                        columnValue: "No Template"
                      }}
                      // key={`${Math.random().toString(36).substring(4)}`}
                      getParentState={() => ({...this.state})}
                      setParentState={(items) => this.setState(items)}
                    />
                  </fieldset>
                </div>
              </aside>
              <div className="col-lg-10 page-section">
                <table>
                  <thead>
                    <tr>
                      <td>Count</td>
                      <td className="button--th">
                      <button
                          onClick={() => this.handleSort({name: 'path', type: 'alpha'})}
                        >Path</button>
                      </td>
                      <td className="button--th">
                        <button
                          onClick={() => this.handleSort({name: 'template', type: 'alpha'})}
                        >Template</button>
                      </td>
                      <td className="button--th">
                        <button
                          onClick={() => this.handleSort({name: 'created_at', type: 'date'})}
                        >Created</button>
                      </td>
                      <td className="button--th">
                        <button
                          onClick={() => this.handleSort({name: 'updated_at', type: 'date'})}
                        >Modified</button>
                      </td>
                      <td className="button--th">
                      <button
                          onClick={() => this.handleSort({name: 'published', type: 'boolean'})}
                        >Published</button>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderPageList()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default styled(SecretPageList)`
  padding: 0;
  font-size: 0.8rem;
  min-height: 100vh;

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
    margin-top: 0;
  }

  a {
    display: inline-block;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: left;
    width: 100%;
  }

  .button--svg {
    font-size: 0;
    line-height: 0;
    width: auto;
  }

  .text-right {
    text-align: right;
  }

  table {
    margin: 0 auto;
    width: 100%;
  }

  td {
    //display: inline-block;
    padding: 0.5rem;
  }
  thead tr {
  }

  .page-header {
    background-color: ${colors.blueDarkPastel};
    h1 {
      color: #fff;
    }
    svg {
      color: white;
      height: 2rem;
      width: 2rem;
    }
  }

  .page-section {
    margin-top: 2rem;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    margin-bottom: 0.75rem;
    legend {
      background-color: ${colors.blueDarkPastel};
      border-radius: 2px;
      color: white;
      margin-bottom: 0.25rem;
      padding: 0.25rem;
      width: 100%;
    }
  }

  

  &.theme--dark {
    background-color: #111;
    color: #efefef;

    h1, h2 {
      color: #efefef;
    }

    a {
      color: dodgerblue;
    }

    button {
      color: #efefef;
    }

    svg {
      color: #efefef;
    }

    tr:nth-of-type(even) {
      background-color: #2f2f2f;
    }
    thead tr {
      background-color: #2f2f2f;
    }

    .page-header {
      background-color: #2f2f2f;
    }

    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
      legend {
        background-color: #2f2f2f;
        border-radius: 2px;
        color: white;
        padding: 0.25rem;
        width: 100%;
      }
    }
  }

  &.theme--light {
    background-color: #fff;
    color: #111;

    a {
      color: dodgerblue;
    }

    tr:nth-of-type(even) {
      background-color: #efefef;
    }
    thead tr {
      background-color: #efefef;
    }
  }
`;

export const query = graphql`{

  allWordpressPage {
    edges {
      node {
        path
        modified(formatString: "YYYY-MM-DD HH:mm:ss")
        date(formatString: "YYYY-MM-DD HH:mm:ss")

        acf {
          uma_page_template {
            value
            label
          }
        }
      }
    }
  }

  allMdx(filter: {
    frontmatter: {
      template: {
        name: {
          eq: "enrollment-start"
        }
      }
      type: {
        eq: "page"
      }
    }
  }) {
    edges {
      node {
        fileAbsolutePath
        frontmatter {
          published
          updated
        }
      }
    }
  }

}`;

