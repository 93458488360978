/**
 * !!
 * IF YOU UPDATE THESE ALSO UPDATE 
 * /src/style/bootstrap/bootstrap.scss
 * !!
 */

 export const space = {
  a: '0.125rem',
  b: '0.25rem',
  c: '0.5rem',
  d: '0.75rem',
  e: '1rem',
  f: '1.5rem',
  g: '2rem',
  h: '3rem',
  i: '4rem',
  j: '6rem',
  k: '8rem',
  l: '12rem',
  m: '16rem',
  n: '24rem',
  o: '32rem',
  p: '40rem',
  q: '48rem'
}