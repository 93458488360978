import React from 'react'

import { FieldState } from '../../../hooks/useForm'
import InputField from '../../form-fields/InputField'
import RadioField from '../../form-fields/RadioField'
import SelectField from '../../form-fields/SelectField'

function isTextType(field: FieldState): boolean {
  return field.type === 'text' || field.type === 'tel' || field.type === 'email'
}

type FieldRouterProps = {
  fields: FieldState[]
}

const FieldRouter = ({ fields }: FieldRouterProps) => {
  const publicFields = fields.map((field) => {
    // we're only mapping over public fields right now
    if (field.hidden) return null

    if (isTextType(field)) {
      return <InputField field={field} key={field.id} />
    }

    if (field.type === 'select') {
      return <SelectField field={field} key={field.id} />
    }

    if (field.type === 'radio') {
      return <RadioField field={field} key={field.id} />
    }

    return null
  })

  return (
    <>
      {publicFields}
      {fields.map(
        (field) =>
          field.hidden &&
          field.value && (
            <input
              type="hidden"
              name={field.id}
              value={field.value}
              key={field.id}
            />
          ),
      )}
    </>
  )
}

export default FieldRouter
