const initialFormConfig = [
  {
    key: 'debug',
    value:
      process.env.BRANCH !== 'QA-test' && process.env.BRANCH !== 'production'
        ? 'Y'
        : false,
    excludeIfFalsy: true,
  },
  {
    key: 'SID',
    value: '13462',
  },
  {
    key: 'partial',
    value: true,
  },
  {
    key: 'template',
    value: 1,
  },
  {
    key: 'TCPAConsent',
    value: 'Y',
  },
  {
    key: 'cache',
    value: false,
  },
  {
    key: 'redirect',
    value: encodeURIComponent(
      'https://learn.ultimatemedical.edu/confirmation/',
    ),
  },

  // ----------------------------------------------
  // TODO
  // ----------------------------------------------
  // page where we ask the person to chat or call
  // because we are having trouble processing forms
  // notify us if this happens
  {
    key: 'errorUrl',
    value: encodeURIComponent(
      'https://learn.ultimatemedical.edu/confirmation/',
    ),
  },
  {
    key: 'VendorLeadID',
    override: /^vendorleadid$/gi,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_adgroup',
    override: /utm_adgroup/,
    excludeIfFalsy: true,
  },
  {
    key: 'adgroupid',
    override: /utm_content/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_campaign',
    override: /utm_campaign/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_source',
    override: /utm_source/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_term',
    override: /utm_term/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_medium',
    override: /utm_medium/,
    excludeIfFalsy: true,
  },
  {
    key: 'gclid',
    override: /gclid/,
    excludeIfFalsy: true,
  },
]

export { initialFormConfig }
