// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import defaultSocialImage from '@Images/seo/default-share.png'

function Seo({ seo }) {

  const title = seo.title || 'Ultimate Medical Academy'
  const description = seo.description || 'Ultimate Medical Academy'
  const shareImage = seo?.shareImage?.default?.file?.publicURL || defaultSocialImage
  const lang = seo?.lang || "en"

  return (
    <Helmet
      htmlAttributes={{
        lang: lang
      }}
      title={title}
      titleTemplate={`%s | Ultimate Medical Academy`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Ultimate Medical Academy',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: 'og:image',
          content: shareImage
        }
      ]}
    />
  )
}

Seo.defaultProps = {
  seo: undefined
}

Seo.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    lang: PropTypes.string,
    shareImage: PropTypes.shape({
      default: PropTypes.shape({
        file: PropTypes.shape({
          publicURL: PropTypes.string
        })
      })
    })
  }).isRequired
}

export default Seo

export const query = graphql`
  fragment Seo on MarkdownRemark {
    frontmatter {
      seo {
        title
        description
        shareImage {
          default {
            file {
              publicURL
            }
          }
        }
      }
    }
  }
`