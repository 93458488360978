// Node Module Imports
import React, { useEffect } from 'react'
import styled from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'

// Image Imports
import Chev from '../images/relevancy/common/chevron.svg'
import LogoRelevancy from '../components/LogoRelevancy'
import faceM from '../images/relevancy/1/mobile/face.jpg'
import faceD from '../images/relevancy/1/desktop/face.jpg'
import Together from '../images/relevancy/1/common/together.png'
import HeroesD from '../images/relevancy/1/desktop/heroes-d.jpg'
import ReadyM from '../images/relevancy/1/mobile/Ready-Mobile.png'
import DoThisM from '../images/relevancy/1/mobile/Lets-Do-This.jpg'
import ReadyD from '../images/relevancy/1/desktop/Ready-Desktop.png'
import DoThisD from '../images/relevancy/1/desktop/Lets-Do-This.jpg'
import TakeNextStepM from '../images/relevancy/1/mobile/take-next-step.png'
import RequestInfoM from '../images/relevancy/1/mobile/request-info-btn.png'
import TakeNextStepD from '../images/relevancy/1/desktop/take-next-step.png'
import peopleWalkingM from '../images/relevancy/1/mobile/people-walking.png'
import peopleWalkingD from '../images/relevancy/1/desktop/people-walking.jpg'
import RequestInfoD from '../images/relevancy/1/desktop/request-info-btn.png'
import MillionMobile from '../images/relevancy/1/mobile/12-5-Million-Mobile.jpg'
import MillionDesktop from '../images/relevancy/1/desktop/12-5-Million-Desktop.jpg'
import chevPatternPurple from '../images/relevancy/1/common/pattern-chev-purple.png'
import fypp from '../images/relevancy/1/common/Face-Your-Potential-Video-Placeholder.jpg'
import ChallengingTimesD from '../images/relevancy/1/desktop/Challenging-Times-Desktop.png'

// Component Imports
import DefaultLayout from '../layouts/DefaultLayout'
import YouTubeEmbed from '../components/reusables/YouTubeEmbed'
import SparkroomFormBase from '../components/SparkroomFormBase'
import VideoLazyload from '../components/reusables/VideoLazyload'
import StickyMobileFooter from '../components/reusables/StickyMobileFooter'

// Other Imports
import { store } from '../redux'
import getUrl from '../utilities/getUrl'
import { above, colors } from '../styles/utilities'
import { addOrUpdateFormParams } from '../redux/actions'
import { ClassList, stripPhoneNumber } from '../utilities'
import { determineNeededFormParams } from '../utilities/forms'

const PsMakeADifference = (props) => {
  const year = new Date().getFullYear()
  const phoneNumber = '888-613-2445'

  useEffect(() => {
    // Initialize the smooth scroll polyfill for IE and Safari
    // Empty array as second argument means this runs only after the first render
    smoothscroll.polyfill()
  }, [])

  let formParams = determineNeededFormParams(new ClassList(''), 'Online', {
    vendor: 'FBOOK',
    lead_vendor: 'FBOOK',
    lead_source: 'FBOOK3',
  })
  formParams = [
    ...formParams,
    {
      key: 'redirect',
      value: `${getUrl}/confirmation-make-a-difference`,
    },
    {
      key: 'errorUrl',
      value: `${getUrl}/confirmation-make-a-difference`,
    },
  ]
  store.dispatch(addOrUpdateFormParams(formParams))

  // Focus on the input
  const focusOnInput = () => {
    const firstFormInput = document.getElementById('FirstName')
    firstFormInput.focus()
  }

  // Scroll to the form
  const handleClickRequestInfo = () => {
    const formWrapper = document.querySelector('.form')

    formWrapper.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

    setTimeout(focusOnInput, 1000)
  }

  return (
    <DefaultLayout>
      <div className={props.className}>
        <div className="hero-and-form container--b">
          <div className="hero flex-50--strict">
            <LogoRelevancy className="logo d-md-none" />
            <img
              src={faceM}
              alt="12.5 Million Jobs Projected by 2028. Bureau of Labor Statistics. Employment by major occupational group. Table 1.2 in the downloadable XLSLX file, lines 395-J and 467-J. https://www.bls.gov/emp/tables/emp-by-major-occupational-group.htm"
              className="face d-md-none"
            />
          </div>

          <div className="form flex-50--strict">
            <div className="container--a">
              <LogoRelevancy className="logo d-none d-md-block" />
              <p className="heading">Start your education in healthcare.</p>
              <SparkroomFormBase
                tracking={{
                  phone_number: phoneNumber,
                }}
                onPostFormLoad={() => {
                  const formButton = document.querySelector(
                    '.form .submitStyle',
                  )
                  if (formButton) {
                    formButton.innerHTML = `REQUEST INFO <img src="${Chev}" alt="" />`
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="section--video">
          <div className="top" />
          <div className="container--a">
            <VideoLazyload
              posterAlt="Face your potential video placeholder. Click or press enter to play."
              posterSrc={fypp}
            >
              <YouTubeEmbed
                className="video-iframe"
                width={999}
                videoId="uSb8b9N47T0"
                title="Face Your Potential"
                style={{
                  maxWidth: '100%',
                }}
              />
            </VideoLazyload>
            <p className="heading d-md-none">
              These are challenging times where every role can make a
              difference.
            </p>
            <p className="heading d-md-none">Join an industry of heroes.</p>
            <img
              src={ChallengingTimesD}
              alt="These are challenging times where every role cam make a difference."
              className="d-none d-md-inline-block pt-5"
            />
          </div>
        </div>

        <div className="hci">
          <div className="container--a">
            <img
              src={HeroesD}
              alt="Join an Industry of Heroes"
              className="d-none d-md-block heroes"
            />
            <img
              src={MillionMobile}
              alt="12.5 Million Jobs Projected by 2028. Bureau of Labor Statistics. Employment by major occupational group. Table 1.2 in the downloadable XLSLX file, lines 395-J and 467-J. https://www.bls.gov/emp/tables/emp-by-major-occupational-group.htm"
              className="img--overlay d-md-none"
            />
            <img
              src={MillionDesktop}
              alt="12.5 Million Jobs Projected by 2028. Bureau of Labor Statistics. Employment by major occupational group. Table 1.2 in the downloadable XLSLX file, lines 395-J and 467-J. https://www.bls.gov/emp/tables/emp-by-major-occupational-group.htm"
              className="img--overlay d-none d-md-block"
            />
          </div>
        </div>

        <div className="now-is-the-time">
          <div className="container--a">
            <p>
              If you've ever dreamt of pursuing a career in healthcare, now is
              the time to start.
            </p>
            <img
              src={ReadyM}
              alt="Ready to take the next step?"
              className="d-md-none"
            />
            <img
              src={ReadyD}
              alt="Ready to take the next step?"
              className="d-none d-md-block"
            />
          </div>
        </div>

        <div className="take-next-step">
          <div className="take-next-step-img" />
          <img
            src={DoThisM}
            alt="Let's do this."
            className="do-this-m d-block d-md-none"
          />
          <img
            src={DoThisD}
            alt="Let's do this."
            className="do-this-d d-none d-md-block"
          />
          <img src={Together} alt="Together" />
          <div className="container--a">
            <p className="heading">You got this. We got you.</p>
            <p className="tns__body">
              Get the healthcare training you want&mdash;online and on your
              schedule<sup>2</sup>.
            </p>
          </div>
        </div>

        <div className="request-info">
          <div className="container--b">
            <button
              onClick={handleClickRequestInfo}
              className="d-md-none pointer btn--strip"
            >
              <img src={RequestInfoM} alt="Request Info" />
            </button>
            <button
              onClick={handleClickRequestInfo}
              className="d-none d-md-inline-block pointer btn--strip"
            >
              <img src={RequestInfoD} alt="Request Info" />
            </button>
            <p>
              Or call us now to get started at
              <span className="nowrap">
                <span>
                  {' '}
                  <a
                    className="ga-call"
                    ga-label="make-a-difference"
                    href={`tel:${stripPhoneNumber(phoneNumber)}`}
                  >
                    {phoneNumber}
                  </a>
                </span>
              </span>
            </p>
          </div>
        </div>

        <div className="container--b pb-5 about-uma-outer">
          <div className="about-uma-inner">
            <h3>About UMA</h3>
            <p>
              Ultimate Medical Academy educates students for careers in
              healthcare that can make a difference in their lives, and the
              lives of others. We are a dynamic educational institution
              committed to equipping and empoering students to excel in
              healthcare careers.
            </p>
            <p>
              UMA is proud to have supported more than 55,000 students through
              graduation.
            </p>
            <small>
              1 Bureau of Labor Statistics. Employment by major occupational
              group. Table 1.2 in the downloadable XLSX file, lines 395-J and
              467-J.{' '}
              <a
                className="td-n td-n-hu"
                href="https://www.bls.gov/emp/tables/emp-by-major-occupational-group.htm"
              >
                https://www.bls.gov/emp/tables/emp-by-major-occupational-group.htm
              </a>
            </small>
            <br />
            <small>
              2 Set schedules required for externship/practicum courses in
              select programs.
            </small>
            <br />
            <br />
            <small>
              © {year} UMA Education, Inc. d/b/a Ultimate Medical Academy |{' '}
              <a
                href="https://www.ultimatemedical.edu/about/citations/"
                className="td-n-hu"
              >
                Marketing Statistics
              </a>{' '}
              |{' '}
              <a
                href="https://www.ultimatemedical.edu/privacy-policy/"
                className="td-n-hu"
              >
                Privacy Policy
              </a>
            </small>
            <br />
            <br />
            <small>
              Ultimate Medical Academy does not recruit or enroll residents of
              the European Union.
            </small>
          </div>
        </div>
        {/* <StickyMobileFooter
          phone={phoneNumber}
          chatBtnId="LP_DIV_1432733350793"
        /> */}
      </div>
    </DefaultLayout>
  )
}

export default styled(PsMakeADifference)`
  padding-bottom: 2rem;
  ${above.md`
    padding-bottom: 0;
  `}
  .container--a {
    margin: 0 auto;
    max-width: 325px;

    ${above.md`
      max-width: 1200px;
      padding: 0 15px;
    `}
  }

  .container--b {
    margin: 0 auto;
    max-width: 1200px;

    &.hero-and-form {
      max-width: 1445px;
    }
  }

  .flex-50--strict {
    flex: 0 0 50%;
  }

  img {
    max-width: 100%;
  }

  .heading {
    color: ${colors.umaDarkBlue};
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.25;
  }

  .hero-and-form {
    ${above.md`
      display: flex;
    `}
  }

  .video-iframe {
    height: 133.7px;

    ${above.md`
      height: 411px;
    `}
  }

  .hero {
    font-size: 0;
    text-align: center;

    ${above.md`
      background-image: url(${faceD});
      background-position: right center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      right: 1.5rem;
    `}

    .face {
      width: 100%;
    }

    .logo {
      position: absolute;
      top: 1rem;
      right: 0;
      left: 0;
    }
  }

  .form {
    background: url('${chevPatternPurple}');
    border-left: 1px solid #c1c1c1;
    border-right: 1px solid #c1c1c1;
    padding: 2rem 0;

    ${above.md`
      max-width: 502px;
      padding: 0.5rem 4rem 2rem;
    `}

    .logo {
      margin-bottom: 1.4rem;
      text-align: center;
    }

    p {
      margin: 0 0 1.5rem 0;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .disclaimer {
      font-size: 10px;
      margin-top: 1rem;
    }
  }

  .section--video {
    padding: 2.5rem 0;
    position: relative;
    text-align: left;

    ${above.md`
      text-align: center;
    `}

    .top {
      background-color: #b2dcec;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 140px;
      width: 100%;
      z-index: -1;

      ${above.md`
        height: 375px;
      `}
    }

    .heading {
      font-size: 1.4rem;
      position: relative;
    }
  }

  .hci {
    background-image: url(${peopleWalkingM});
    background-size: cover;
    position: relative;

    ${above.md`
      background-image: url(${peopleWalkingD});
      margin-top: 2rem;
    `}

    .container--a {
      min-height: 543px;
      position: relative;

      ${above.md`
        min-height: 700px;
      `}
    }

    .heroes {
      margin: 0 auto;
      transform: translateY(-50%);
    }

    .img--overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ${above.md`
        left: 15px;
        transform: translate(0, -50%);
      `}
    }
  }

  .request-info {
    text-align: center;
  }

  .about-uma-outer {
    margin-top: 2rem;
    .about-uma-inner {
      border-top: 1px solid ${colors.umaDarkGray};
      margin-top: 2rem;
      margin: 0 calc(100% / 12);
      * {
        color: ${colors.umaDarkGray};
      }
    }
  }

  form {
    color: ${colors.umaDarkGray};
    width: 100%;

    #Page1 {
      display: none;
    }

    #TCPAExpressConsentDiv {
      display: none;
    }

    #PostalCodeOrZipDiv {
      display: none;
    }

    .form-group {
      background-color: #fff;
      border: 2px solid hsla(0, 0%, 0%, 0.3);
      border-radius: 4px;
      margin-bottom: 1rem;
      padding: 0.25rem 0.5rem;

      ${above.md`
        border-radius: 1px;
      `}

      span {
        font-size: 0.5rem;
        font-style: normal !important;
      }
    }

    input,
    select {
      background-color: transparent;
      border: none;
      font-size: 0.9rem;
      outline: none;
      width: 100%;
    }

    select {
      margin-left: -8px;
    }

    [class^='col-'] {
      max-width: 100%;
      padding: 0;
    }

    #submitButtonDiv {
      background-color: ${colors.umaGreen};

      a {
        box-shadow: -8px 8px 16px hsla(0, 0%, 0%, 0.5);
        color: #fff;
        display: block;
        font-weight: bold;
        padding: 0.5rem 0;
        text-decoration: none;
        text-transform: uppercase;
      }

      img {
        transform: rotate(-90deg);
        width: 14px;
        height: auto;
      }
    }

    .control-label {
      display: inline-block;
      font-size: 0.8rem;
      margin: 0;
      padding: 0;
      width: auto;

      font {
        float: right;
        margin-left: 0.1rem;
      }
    }
  }

  .now-is-the-time {
    font-size: 1.4rem;
    margin-bottom: 2rem;

    p {
      line-height: 1.25;
    }

    .container--a {
      ${above.md`
        margin: 0 auto;
        max-width: 700px;
        text-align: center;
      `}
    }
  }

  .take-next-step {
    text-align: center;

    .do-this-m {
      margin: -30px auto 0.5rem;
    }

    .do-this-d {
      margin: -45px auto 0.5rem;
    }

    .heading {
      text-align: left;

      ${above.md`
        text-align: center;
      `}
    }

    .tns__body {
      font-size: 1.45rem;
      font-weight: 100;
      line-height: 1.25;
      text-align: left;

      ${above.md`
        font-size: 2rem;
        text-align: center;
      `}
    }
  }

  .take-next-step-img {
    background-image: url(${TakeNextStepM});
    background-repeat: no-repeat;
    background-size: cover;
    height: 279px;
    width: 100%;

    ${above.md`
      background-image: url(${TakeNextStepD});
      height: 626px;
      margin: 0 auto;
      max-width: 1200px;
    `}
  }
`
