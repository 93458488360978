import React from 'react'

import cx from 'classnames'

type ChatCTAProps = {
  className?: string
  /** The liveperson ID. */
  id: string
  themes?: string | string[]
}

const ChatCTA = ({ className, id, themes }: ChatCTAProps) => {
  return (
    <button
      className={cx(themes, className)}
      // id={id}
      type="button"
      onClick={() => {
        if (globalThis.Genesys) globalThis.Genesys('command', 'Messenger.open')
      }}
    >
      Chat Now
    </button>
  )
}

ChatCTA.defaultProps = {
  className: undefined,
  themes: '',
}

export default ChatCTA
