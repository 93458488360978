import React from 'react'

import cx from 'classnames'

import ChatCTA from '../../../v3/components/ctas/ChatCTA'
import {
  headerTheme,
  greenTheme,
} from '../../../v3/components/ctas/ChatCTA/themes.module.css'
import PhoneCTA from '../../../v3/components/ctas/Phone'
import DefaultFooter from '../../../v3/components/footers/DefaultFooter'
import HeaderThinStrip from '../../../v3/components/headers/HeaderThinStrip/HeaderThinStrip'
import BaseLayout from '../../../v3/layouts/Base'
import { formatPhoneNumber } from '../../../v3/lib/util/format'
import desktopHero from './hero-desktop.webp'
import mobileHero from './hero-mobile.webp'
import officeImage from './office-image.webp'
import * as styles from './style.module.css'

const IndustryCareContent = () => {
  return (
    <>
      <h2>Join an industry of care.</h2>
      <p>
        Are you ready to take the next step and begin pursuing your healthcare
        career path? Start your journey right here in the Tampa Bay area with
        the student support you need.
      </p>
      <p>
        Our Clearwater campus offers flexible clinical programs and hands-on
        training in laboratories equipped to support your academic success with
        blended learning that allows you to learn online as well.<sup>1</sup>
      </p>
    </>
  )
}

const CLWLNPage = () => {
  const phone = formatPhoneNumber('727-674-2617')
  return (
    <BaseLayout>
      {/* Header */}
      <HeaderThinStrip
        ctas={() => (
          <div className="d-flex align-items-center">
            <PhoneCTA className="mr-3 d-none d-md-flex" phone={phone} />
            <ChatCTA
              className="d-none d-md-flex justify-content-center align-items-center"
              id="LP_DIV_clw_ln_3"
              themes={[headerTheme, greenTheme]}
            />
          </div>
        )}
      />

      {/* Heroes */}
      <img
        src={mobileHero}
        alt="Study Online. Train on Campus. Grow with Care."
        className={cx('d-md-none', styles.hero)}
      />
      <img
        src={desktopHero}
        alt="Study Online. Train on Campus. Grow with Care."
        className={cx('d-none d-md-block', styles.hero)}
      />

      {/* Mobile sidebar */}
      <div className={cx('d-md-none', styles.sidebarMobile)}>
        <div className="container">
          <div className="row">
            <div className="col-8 offset-2 d-flex flex-column align-items-center text-center">
              <p>Get hands-on training and student support.</p>
              <PhoneCTA displayIcon={false} phone={phone} />
              <p>Talk with a UMA Admissions team member now!</p>
            </div>
          </div>
        </div>
      </div>

      {/* Content area */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7">
            <h1 className={cx(styles.h1)}>
              Follow your calling to care.{' '}
              <span className="h2 d-block mt-4">
                See how it could become a pathway to a healthcare career.
              </span>
            </h1>
            <p>
              With multiple programs starting monthly, you&apos;ll have a
              dedicated support system as you pursue an education in a field
              that has helped countless others. When you enroll in one of our
              healthcare programs, you can become part of more than 10,000
              overall UMA students nationwide.
            </p>
          </div>
          <div
            className={cx(
              'd-none d-md-flex col-md-5 col-xl-4 offset-xl-1 justify-content-end',
            )}
          >
            <div className={cx('d-inline-block', styles.sidebar)}>
              <p>Get hands-on training and student support.</p>
              <PhoneCTA displayIcon={false} phone={phone} />
              <p>Talk with a UMA Admissions team member now!</p>
            </div>
          </div>
        </div>
      </div>

      {/* mobile bg area */}
      <div className={cx('d-md-none', styles.bgSectionMobile)}>
        <div className="container">
          <IndustryCareContent />
        </div>
      </div>

      {/* desktop bg area */}
      <div className={cx('container d-none d-md-block', styles.bgSection)}>
        <div
          className={cx('row d-flex align-items-center', styles.bgSectionRow)}
        >
          <div className="col-6">
            <IndustryCareContent />
          </div>
          <div className="col-6">
            <img src={officeImage} alt="" loading="lazy" />
          </div>
        </div>
      </div>

      <img
        src={officeImage}
        alt=""
        loading="lazy"
        className={cx('d-md-none', styles.officeImage)}
      />

      <div className="container py-5">
        <h2 className={cx(styles.h1, 'mt-0')}>Why UMA?</h2>
        <p>
          We&apos;re a nonprofit institution with a national footprint that
          offers comprehensive, accredited<sup>2</sup> healthcare education.
          More than that, we&apos;re a school that cares for students and it
          shows in our:
        </p>
        <div className="row text-center">
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <span className={cx(styles.bigNumber, 'd-block')}>30+</span>
            <span className={cx('d-block')}>Years in Healthcare Education</span>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <span className={cx(styles.bigNumber, 'd-block')}>85K+</span>
            <span className={cx('d-block')}>UMA Alumni Overall</span>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <span className={cx(styles.bigNumber, 'd-block')}>7</span>
            <span className={cx('d-block')}>Healthcare Programs</span>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <span className={cx(styles.bigNumber, 'd-block')}>50+</span>
            <span className={cx('d-block')}>Local Community Partners</span>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <span className={cx(styles.bigNumber, 'd-block')}>1 to 1</span>
            <span className={cx('d-block')}>Student Support</span>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <span className={cx(styles.bigNumber, 'd-block')}>24/7</span>
            <span className={cx('d-block')}>Technical Support</span>
          </div>
        </div>
        <p>
          The healthcare field is growing — a projected 16.97 million open
          positions from 2020 through 2030.<sup>3</sup> Pursue your career path
          today. At every step, we&apos;ll be here to help.
        </p>
      </div>

      <DefaultFooter phone={phone} />

      <div className="container">
        <ol className="list-style-none">
          <li className="mb-2" style={{ lineHeight: '1' }}>
            <small style={{ lineHeight: '1' }}>
              1. Nursing Assistant diploma program remains fully on campus.
            </small>
          </li>
          <li className="mb-2" style={{ lineHeight: '1' }}>
            <small>
              2. UMA is institutionally accredited by the Accrediting Bureau of
              Health Education Schools (ABHES). The Accrediting Bureau of Health
              Education Schools (ABHES) is recognized by the U.S. Secretary of
              Education as a national accrediting agency for the accreditation
              of private, postsecondary institutions in the United States
              offering predominantly allied health education programs. The
              Clearwater location is on file with ABHES as the main campus of
              UMA, and the Online location is considered a non-main campus.
            </small>
          </li>
          <li className="mb-2" style={{ lineHeight: '1' }}>
            <small style={{ lineHeight: '1' }}>
              3. Bureau of Labor Statistics. Employment by detailed occupation.
              Table 1.2 in the downloadable XLSX file, lines 389-J and 453-J.
              bls.gov/emp/tables/emp-by-major-occupational-group.htm
            </small>
          </li>
        </ol>
      </div>
    </BaseLayout>
  )
}

export default CLWLNPage
