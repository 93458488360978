class ElementWatcher {

  constructor() {
    this.node = null;
    this.action = null;
    this.removeListener = true;
    this.callback = mutation => {
      console.log(`${this.constructor.name} observed a mutation but the onUpdate function has not been defined.`);
    };
    this.onUpdateWrapper = this.onUpdateWrapper.bind(this);
  }

  watch(element, action) {

    this.node = element;
    this.action = action;

    if(!this.node) {
      console.warn(`${this.constructor.name} could not find the specified Element in the DOM.`);
    }

    return this;
  }

  always() {
    this.removeListener = false;
    return this;
  }

  onUpdate(fn) {
    this.callback = fn;
    return this;
  }

  boot() {
    if(!this.node) {
      console.warn(`${this.constructor.name} could not find the specified Element in the DOM.`);
      return this;
    }
    if(this.action === 'mutation') {
      this.watchForMutation(this.node);
    }
    else {
      this.node.addEventListener(this.action, this.onUpdateWrapper);
    }
    return this;
  }

	watchForMutation(element) {
    const observer = new MutationObserver((mutations, observer) => {
      for(var mutation of mutations) {
        this.onUpdateWrapper(mutation);
        if(this.removeListener) {
          observer.disconnect();
        }
      }
    });
    observer.observe(element, {
      attributes: true
    });
    return this;
  }

  onUpdateWrapper(mutation) {
    if(this.removeListener) {
      this.node.removeEventListener(this.action, this.onUpdateWrapper);
    }
    this.callback(mutation);
  }

}

export { ElementWatcher };