import React, { useState } from 'react'
import htmr from 'htmr'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import sp from '@Util/shortcodes/parser'
import { above, space } from '@Style/utilities'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ProgramToggle = ({background, campus, className, image, title, toggles}) => {

  const gatsbyImage = getImage(image.full.file.childImageSharp.gatsbyImageData)
  const programs = useStaticQuery(graphql`{
    onlinedegree: allWordpressWpPrograms(filter: {
      meta: {
        program_campus: {eq: "45"}
        program_type: {eq: "degree"}
      }
    }) {
      ...ModuleProgramTogglePrograms
    }
    onlinediploma: allWordpressWpPrograms(filter: {
      meta: {
        program_campus: {eq: "45"}
        program_type: {eq: "diploma"}
      }
    }) {
      ...ModuleProgramTogglePrograms
    }
    clearwaterdegree: allWordpressWpPrograms(filter: {
      meta: {
        program_campus: {eq: "47"}
        program_type: {eq: "degree"}
      }
    }) {
      ...ModuleProgramTogglePrograms
    }
    clearwaterdiploma: allWordpressWpPrograms(filter: {
      meta: {
        program_campus: {eq: "47"}
        program_type: {eq: "diploma"}
      }
    }) {
      ...ModuleProgramTogglePrograms
    }
  }`)

  const [programType, setProgramType] = useState(toggles.tabs[0].programType)

  const renderPrograms = (programs) => (
    <ul>
      {programs.map(p => (
        <li key={p.node.id}>{p.node.meta.program_full_name}</li>
      ))}
    </ul>
  )

  const renderBody = (toggles, campus, programType) => {
    return toggles.tabs.map(t => {
      if(t.programType === programType) {
        return (
          <div key={t.name}>
            {htmr(sp(t.body.md.html))}
            {renderPrograms(programs[`${campus}${programType}`].edges)}
          </div>
        )
      }
      return null
    })
  }

  return (
    <section className={cx(className)}>
      <div className={cx('container')}>
        <div className={cx('row', 'd-flex')}>
          
          <div className={cx('col-lg-6 col-image d-flex')}>
            <GatsbyImage
              className="image"
              image={gatsbyImage}
              alt={image.full.alt} />
          </div>
          
          <div className={cx('col-lg-6 col-content md-content')}>
            <div className={cx('title')}>
              {htmr(sp(title.md.html))}
            </div>
            <div className={cx('toggles d-flex')}>
              {toggles.tabs.map(t => (
                <button
                  key={t.name}
                  onClick={() => setProgramType(t.programType)}
                  disabled={programType === t.programType}>
                    {t.name}</button>
              ))}
            </div>
            <div className={cx('body')}>
              {renderBody(toggles, campus, programType)}
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

ProgramToggle.propTypes = {
  background: PropTypes.string.isRequired,
  campus: PropTypes.string.isRequired,
  image: PropTypes.shape({
    full: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      file: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  title: PropTypes.shape({
    background: PropTypes.string.isRequired,
    md: PropTypes.object.isRequired
  }).isRequired,
  toggles: PropTypes.shape({
    background: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    notActive: PropTypes.shape({
      background: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      programType: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      body: PropTypes.shape({
        md: PropTypes.object.isRequired
      }).isRequired
    })).isRequired
  }).isRequired,
}

export const query = graphql`
  fragment ModuleProgramToggle on MarkdownRemark {
    frontmatter {
      modules {
        programToggle {
          background
          campus
          image {
            full {
              alt
              file {
                childImageSharp {
                  gatsbyImageData(quality: 85, layout: FULL_WIDTH)
                }
              }
            }
          }
          padding
          title {
            background
            md {
              html
            }
          }
          toggles {
            background
            color
            notActive {
              background
              color
            }
            tabs {
              name
              programType
              background
              color
              body {
                md {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
  fragment ModuleProgramTogglePrograms on wordpress__wp_programsConnection {
    edges {
      node {
        id
        meta {
          program_full_name
          program_type
        }
      }
    }
  }
`

export default styled(ProgramToggle)`
  background: ${props => sp(props.background)};

  ${above.md`
    padding: ${props => sp(props.padding)};
  `}

  h2, h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .col-image {
    padding-left: 0;
    padding-right: 0;
    .image {
      width: 100%;
    }
  }

  .col-content {
    background: ${props => sp(props.toggles.background)};
    padding-left: 0;
    padding-right: 0;
    * {
      color: ${props => sp(props.toggles.color)} !important;
    }
    ul {
      padding: 0;
      margin: 0;
    }
  }

  .title {
    background: ${props => sp(props.title.background)};
    padding: ${space.e};
  }

  .body {
    padding: ${space.e} ${space.e} ${space.h} ${space.e};
  }

  .toggles button {
    background: ${props => sp(props.toggles.notActive.background)};
    border: 0;
    border-radius: 0;
    color: ${props => sp(props.toggles.notActive.color)} !important;
    flex: 1 1 50%;

    &:hover {
      cursor: pointer;
    }

    &[disabled] {
      background: ${props => sp(props.toggles.background)};
      color: ${props => sp(props.toggles.color)} !important;
      cursor: auto;
      padding: ${space.d};
    }
  }
`