/**
 * Strips a phone  number of all special characters and formatting.
 */
export const stripPhoneNumber = (n: string): string =>
  // eslint-disable-next-line implicit-arrow-linebreak
  n.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /, '')

/**
 * Takes a phone number and returns a formatted version (999-999-9999).
 */
export const maskPhone = (s: string): string => {
  let sCopy = s.replace(/[-()]/g, '').slice(0, 10)
  if (sCopy.length > 6) {
    sCopy = [
      sCopy.slice(0, 3),
      '-',
      sCopy.slice(3, 6),
      '-',
      sCopy.slice(6),
    ].join('')
    return sCopy
  }
  if (sCopy.length > 3) {
    sCopy = [sCopy.slice(0, 3), '-', sCopy.slice(3)].join('')
    return sCopy
  }
  return sCopy
}

/**
 * Takes an unformatted phone number and returns a formatted version
 * (999-999-9999).
 */
export const dressPhoneNumber = (phoneNumber: string) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`
  }
  return null
}

export type Phone = {
  /** The href value for an `<a>` tag. */
  href: string
  /** The formatted, user-facing version of the phone number. */
  label: string
  /** The phone number without any special characters. */
  stripped: string
}

/**
 * Formats a phone number to UMA standard.
 */
export const formatPhoneNumber = (phoneNumber: string): Phone => {
  const stripped = stripPhoneNumber(phoneNumber)
  return {
    href: `tel:${stripped}`,
    label: dressPhoneNumber(stripped),
    stripped,
  }
}
