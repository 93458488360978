import React, { useContext } from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import GlobalStyle from '../../styles/Global'
import Footnotes from '../lib/components/footnotes/Footnotes'
import { ModalContext } from '../lib/context/ModalContext'

type LayoutProps = {
  children: React.ReactNode
  className?: string
}

const BaseLayout = ({ children, className }: LayoutProps) => {
  const { activeModal, isModalActive } = useContext(ModalContext)

  return (
    <>
      <div className={cx(className, { 'modal-active': isModalActive })}>
        <GlobalStyle />
        {children}
        <Footnotes />
      </div>
      {isModalActive && activeModal()}
    </>
  )
}

BaseLayout.defaultProps = {
  className: '',
}

export default styled(BaseLayout)`
  &.modal-active {
    display: none;
  }
`
