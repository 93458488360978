// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import { FaPhone } from 'react-icons/fa';
import { MdChat } from 'react-icons/md';
import styled from 'styled-components';

// Style Imports
import { above, colors } from '../../styles/utilities';

// utility imports
import { stripPhoneNumber } from '../../utilities/stripPhoneNumber';

// hooks
import useGa from '../../hooks/useGa';

const StickyMobileFooter = ({chatBtnId, className, phone, stickyFuchsia}) => {

  const submitEvent = useGa();

  return (
  <div className={className + " fixed-footer"}>
    <a href={`tel:${stripPhoneNumber(phone)}`} className="call phone-number ga-call fb-track-click" ga-label="mobile footer">
      <FaPhone />
      <p>Click to Call</p>
    </a>
    {/* eslint-disable-next-line */}
    <div 
      className="chat ga-chat"
      onClick={() => {
        submitEvent({category: "chat", action: "click", label: "click to chat - mobile footer - learn"})
      }}>
        <div className="live-person-wrapper" id={chatBtnId}></div>
        <MdChat />
        <button>Click to Chat</button>
    </div>
  </div>
  );
};

export default styled(StickyMobileFooter)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.stickyFuchsia ? `${colors.umaTestFuchsia}` : `${colors.umaPink}` };
  display: flex;
  justify-content: center;
  z-index: 9;

  ${above.md`
    display: none !important;
  `}

  * {
    color: #fff;
    text-decoration: none;
  }
  a, button {
    font-size: .75rem;
  }
  button {
    background: none;
    border: none;
  }
  svg {
    font-size: .9rem;
    margin-bottom: .3rem;
  }
  > div, a {
    width: 100%;
    padding: .7rem 0 .5rem 0;
    border: 2px solid #fff;
    :first-child {
      border-left: 0;
      border-right-width: 1px;
    }
    :last-child {
      border-left-width: 1px;
      border-right: 0;
    }
  }
  .call, .chat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .call svg {
    transform: rotateY(180deg);
  }
  .chat {
    position: relative;
    div {
    font-size: .8rem;
    }
    .live-person-wrapper > div {
      position: absolute !important;
      z-index: 99999 !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
    }
  }
  p {
    margin: 0;
  }
`;

StickyMobileFooter.propTypes = {
  chatBtnId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
}