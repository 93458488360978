// Node Module Imports
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image/withIEPolyfill';

// Style Imports
import { above, colors } from '../../styles/utilities';

const VideoTout = ({bodyText, className, imgAlt, imgSrc, linkPath}) => {

  const wobbleOnScrollForMobile = (e) => {
    const videoTouts = [...document.querySelectorAll('.video-tout')];
    const bottoms = videoTouts.map(vt => vt.getBoundingClientRect().bottom);
    videoTouts.forEach((vt, idx) => {
      if (bottoms[idx] < 400) {
        vt.classList.add('wobble');
      }
    })      
  }

  useEffect(() => {
    if (window.innerWidth < 500) {
      document.addEventListener('scroll', wobbleOnScrollForMobile);
    }
  });

  return (
    <Link className={`${className} video-tout`} to={linkPath}>
      <Img
        alt={imgAlt}
        fluid={imgSrc}
        objectPosition="center top"
        style={{height: '200px', width: '100%'}}
      />
      <div>
        <MDXRenderer>{bodyText}</MDXRenderer>
      </div>
    </Link>
  )
};

export default styled(VideoTout)`
  transition: all .4s cubic-bezier(.53,-0.59,.16,1.04);
  color: ${colors.umaDarkGray};
  @keyframes wobble {
    0% { transform: scale(1) rotate(0deg); }
    25% { transform: scale(1.05) rotate(-2deg); }
    50% { transform: scale(1) rotate(0deg); }
    75% { transform: scale(1.07) rotate(2deg); }
    100% { transform: scale(1) rotate(0deg); }
  }
  &.wobble {
    animation: wobble .8s 1;
    h1 {
      color: ${colors.umaDarkBlue};
    }
  }
  &:hover {
    transform: scale(1.08);
    h1 {
      color: ${colors.umaDarkBlue};
    }
  }
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  > * {
    flex: 0 0 50%;
    padding: 1rem;
  }
  ${above.md`
    display: inline-block;
    margin: 0 1rem;
    flex: 0 0 30%;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  `}
  h1 {
    font-size: 1.3rem;
  }
  p {
    color: ${colors.umaDarkGray};
  }
`;