// Node Module Imports
import React from 'react';
import styled from 'styled-components';
import { FaPhone } from 'react-icons/fa';
import { Col, Container, Row } from 'react-bootstrap';

// Asset Imports
import confVidMp4 from '../videos/confirmation/ultimate-medical-academy.mp4';
import confVidPoster from '../videos/confirmation/uma-frame1.jpg';
import confVidWebm from '../videos/confirmation/ultimate-medical-academy.webm';
import rectangleLogo from '../images/logos/rectangle-logo-with-r.png';

// Component Imports
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import SocialIcons from '../components/reusables/SocialIcons';

// Style Imports
import { above, colors } from '../styles/utilities';

// Utility imports
import { getUrlParam } from '../utilities';
import { stripPhoneNumber } from '../utilities/stripPhoneNumber';

class confirmation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '888-205-2510'
    };
  }

  componentDidMount() {

    var s = document.createElement('script');
		s.async = true;
		s.defer = true;
		s.src = (document.location.protocol + '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5');
		var body = document.querySelector('body');
    body.appendChild(s);
    
    let phone = getUrlParam('phone');

    if (phone) {

      let regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
          match = new RegExp(regex).test(phone);

      if (match) {
        this.setState({phoneNumber: phone});
      }
    }
  }

  render() {

    return (
      <Layout phone={this.state.phoneNumber}>

      <SEO title="Thank You" />

        <div className={this.props.className}>
          <header>
            <Container>
              <img src={rectangleLogo} alt="Ultimate Medical Academy logo" className="logo" />
              <div className="phone-number-wrap">
                <FaPhone className="fa-phone" />
                {/* {tracking.phone_number} */}
                <a className="phone-number" href={`tel:${stripPhoneNumber(this.state.phoneNumber)}`}>{this.state.phoneNumber}</a>
              </div>
            </Container>
          </header>

          <main>
            <div className="main">
              <div className="video-container">
                <video playsInline autoPlay muted loop poster={confVidPoster} id="bgvid">
                  <source src={confVidMp4} type="video/mp4" />
                  <source src={confVidWebm} type="video/webm" />
                </video>
                <div className="screen screen--burn"></div>
              </div>
              <div className="text-overlay">
                <Container>
                  <Row>
                    <Col xs={12} lg={7} xl={8}>
                      <h3>You’re On Your Way!</h3>
                      <h3>Congrats on taking the first step towards a brighter future. Watch for an email with more info on UMA. You’ll also receive a call from our admissions team (an 813 or 727 number) to answer all your questions.</h3>
                      <SocialIcons className="social-icons" />
                    </Col>
                    <div className="d-none d-lg-block col-lg-5 col-xl-4">
                      <div className="facebook--splash">
                        <h5>Check out what UMA students and alumni are saying on Facebook</h5>
                        <div>
                        <div className="fb-page" data-href="https://www.facebook.com/umaonline/" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                          <div className="fb-xfbml-parse-ignore">
                            <blockquote css={{display: 'none'}} cite="https://www.facebook.com/umaonline/">
                              <a href="https://www.facebook.com/umaonline/">Ultimate Medical Academy</a>
                            </blockquote>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="container d-lg-none facebook--mobile">
              <h5>Check out what UMA students and alumni are saying on Facebook</h5>
              <div className="fb-page" data-href="https://www.facebook.com/umaonline/" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                <div className="fb-xfbml-parse-ignore">
                  <blockquote css={{display: 'none'}} cite="https://www.facebook.com/umaonline/">
                    <a href="https://www.facebook.com/umaonline/">Ultimate Medical Academy</a>
                  </blockquote>
                </div>
              </div>
            </div>
          </main>
        </div>
      </Layout>
    )
  }

}

export default styled(confirmation)`
  
  header {
    background-color: ${colors.umaLightBlue};
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .phone-number-wrap {
      color: #fff;
      svg {
        margin-right: 0.3rem;
        transform: translateY(0.1rem);
      }
      .phone-number {
        color: #fff;
        text-decoration: none;
      }
    }
    .logo {
    width: 220px;
    padding: 1rem 0;
    }
  }

  .facebook--mobile {
    p, h1, h2, h3, h4, h5, h6 {
      color: ${colors.umaDarkGray};
      margin: 0;
      margin-bottom: 15px;
    }
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .main {

    color: #fff;
    position: relative;
    font-size: 1rem;
    
    ${above.lg`
      min-height: 90vh;
    `}

    ${above.xl`
      font-size: 1.75rem;
    `}
    
    p, h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }

    .facebook--splash {
      p, h1, h2, h3, h4, h5, h6 {
        color: ${colors.umaDarkGray};
        margin: 15px 0;
      }
      ${above.xl`
        font-size: 1.25rem;
      `}
      background-color: #fff;
      border-radius: 2px;
      display: block;
      padding: 15px;
      iframe {
        max-width: 100%;
      }
    }

    .screen {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
    }

    .screen--burn {
      background-color: rgba(0,0,0,0.25);
    }

    .video-container {
      
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .text-overlay {
      align-items: center;
      padding: 100px 0;
    }

  }

`;
