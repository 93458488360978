// Node Modules
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import parse from 'react-html-parser';
import styled from 'styled-components';

// Styles
import { above } from '../../styles/utilities';

// Utils
import sp from '../../utilities/shortcodeParser';

const LeadReengagementImageSection = ({ className }) => {
  const data = useStaticQuery(graphql`{
    imgBusyParent: file(absolutePath: {regex: "/lead-reengagement/busy-parent/"}) {
      childImageSharp {
        fluid(quality:100, maxWidth:800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgFinancingEducation: file(absolutePath: {regex: "/lead-reengagement/financing-education/"}) {
      childImageSharp {
        fluid(quality:100, maxWidth:800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgFlexibleSchedules: file(absolutePath: {regex: "/lead-reengagement/flexible-schedules/"}) {
      childImageSharp {
        fluid(quality:100, maxWidth:800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgJobSearch: file(absolutePath: {regex: "/lead-reengagement/job-search/"}) {
      childImageSharp {
        fluid(quality:100, maxWidth:800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgStudentSupport: file(absolutePath: {regex: "/lead-reengagement/student-support/"}) {
      childImageSharp {
        fluid(quality:100, maxWidth:800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgTechSolutions: file(absolutePath: {regex: "/lead-reengagement/tech-solutions/"}) {
      childImageSharp {
        fluid(quality:100, maxWidth:800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`);

  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="image-card col-12 col-md-6" id="flexible-schedules">
            <Img fluid={data.imgFlexibleSchedules.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            <h4 className="all-caps my-3">FLEXIBLE SCHEDULES</h4>
            <p className="my-0">
              Are you like many of our students who juggle responsibilities at home and at work while dealing with other challenges? You might be wondering how school will fit in. Take classes from the comfort of home or almost anywhere, at a time that fits your schedule. Studying online could be a good way to earn your education on your own time.
              {parse(sp("{{ function: 'fn', text: 'Set schedules required for on-site externship/practicum courses in select programs and coursework deadlines are set by instructors.' }}"))}
            </p>
          </div>
          <div className="image-card col-12 col-md-6" id="technology-solutions-or-assistance">
            <Img fluid={data.imgTechSolutions.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            <h4 className="all-caps my-3">TECHNOLOGY SOLUTIONS OR ASSISTANCE?</h4>
            <p className="my-0">Handle your coursework and daily student tasks on an easy-to-use learning platform. Our student’s learning experience includes mobile access to courses and richer content. Did you know we even offer laptops for those who qualify? That means you can take your coursework  anywhere with Internet access. Our programs include 24/7 tech support, mobile access, and academic coaching, so you feel comfortable knowing help is there if you need it.</p>
          </div>
          <div className="image-card col-12 col-md-6" id="busy-parent">
            <Img fluid={data.imgBusyParent.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            <h4 className="all-caps my-3">BUSY PARENT?</h4>
            <p className="my-0">Caring for a child while trying to further your education can be challenging. If you qualify, there are federally funded programs out there that could help with childcare such as Head Start and/or Early Head Start. Applying for these programs may seem tricky, but we have Learner Services advisors to help you with that process.</p>
          </div>
          <div className="image-card col-12 col-md-6" id="get-student-support">
            <Img fluid={data.imgStudentSupport.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            <h4 className="all-caps my-3">GET STUDENT SUPPORT</h4>
            <p className="my-0">What drives UMA? It’s the care we give our students. We believe that care is the other half of education. That’s why we have teams of dedicated individuals ready to help you, from admissions to student finance and every step throughout your academic journey. In fact, as a UMA student, you can reach out to our team of over 100 Learner Services advisors to guide you toward resources that may be able to help you overcome certain obstacles throughout your program.</p>
          </div>
          <div className="image-card col-12 col-md-6" id="help-with-your-job-search">
            <Img fluid={data.imgJobSearch.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            <h4 className="all-caps my-3">HELP WITH YOUR JOB SEARCH</h4>
            <p className="my-0">We know that your journey doesn’t end at graduation. In fact, a new journey will begin for you — your job search. We have more than 200 career support team members who can assist you with resume writing, mock interview preparation, narrowing your job search, and more.</p>
          </div>
          <div className="image-card col-12 col-md-6" id="financing-your-education">
            <Img fluid={data.imgFinancingEducation.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            <h4 className="all-caps my-3">FINANCING YOUR EDUCATION</h4>
            <p className="my-0">Worrying about how to pay for your education can seem like an overwhelming obstacle. Let our Student Finance team help answer any questions you have about how you may be able to finance your tuition costs. Our Student Finance advisors can  listen to your needs, explain each step in the process, and guide you toward available resources, if qualified.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(LeadReengagementImageSection)`
  padding: 2rem 0;
  .image-card {
    padding: 1rem 2rem;
    ${above.lg`
      padding: 1rem 3rem;
    `}
  }
`;

LeadReengagementImageSection.propTypes = {
  className: PropTypes.string.isRequired,
};
