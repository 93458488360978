import React from 'react'

import { graphql, Link } from 'gatsby'
import htmr from 'htmr'
import styled from 'styled-components'

import ImageToutsCareMantra from '../components/cross-template-statics/ImageToutsCareMantra'
import NumberTouts from '../components/cross-template-statics/NumberTouts'
import HeroScalableLogo from '../components/HeadersAndHeroes/HeroScalableLogo'
import PS92020Hero from '../components/heroes/PS92020Hero'
import Layout from '../components/Layout'
import LogoSquare from '../components/LogoSquare'
import Quote from '../components/reusables/Quote'
import VideoLazyload from '../components/reusables/VideoLazyload'
import YouTubeEmbed from '../components/reusables/YouTubeEmbed'
import SEO from '../components/SEO'
import { store } from '../redux'
import { AcornFormParams } from '../redux/actions'
import { above, colors } from '../styles/utilities'
import findInObject from '../utilities/findInObject'
import CNContentTop from './chat-now/CNContentTop'
import CNHeader from './chat-now/CNHeader'
import { NarrowContainer } from '../styles/elements'

const TemplateChatNowNewer = ({ className, data }) => {
  const onlineOnly = findInObject('onlineOnly', data.markdown.frontmatter)

  const settings = {
    allowNull: true,
  }

  const leadsource = findInObject(
    'tracking.leadsource',
    data.markdown.frontmatter,
    settings,
  )
  const leadvendor = findInObject(
    'tracking.leadvendor',
    data.markdown.frontmatter,
    settings,
  )
  const vendor = findInObject(
    'tracking.vendor',
    data.markdown.frontmatter,
    settings,
  )

  if (leadsource) {
    store.dispatch(AcornFormParams.updateParamValue('LeadSource', leadsource))
  }
  if (leadvendor) {
    store.dispatch(AcornFormParams.updateParamValue('LeadVendor', leadvendor))
  }
  if (vendor) {
    store.dispatch(AcornFormParams.updateParamValue('Vendor', vendor))
  }

  const sortPrograms = (programEdges) =>
    programEdges.sort((a, b) => {
      const aNorm = a.node.meta.program_full_name.toLowerCase()
      const bNorm = b.node.meta.program_full_name.toLowerCase()
      if (aNorm < bNorm) {
        return -1
      } else if (aNorm > bNorm) {
        return 1
      }
      return 0
    })

  let sortedOnlinePrograms = sortPrograms(data.onlineDegreePrograms.edges)
  sortedOnlinePrograms = sortedOnlinePrograms.filter(
    (p) => p.node.meta.program_code !== 'UNSPEC',
  )
  sortedOnlinePrograms = sortedOnlinePrograms.map((p) => {
    if (p.node.meta.program_code !== 'HHS') {
      p.node.slug = p.node.slug.replace('and-', '')
    }
    return p
  })

  const sortedClearwaterPrograms = sortPrograms(
    data.clearwaterDiplomaPrograms.edges,
  )

  const fm = data.markdown.frontmatter

  return (
    <Layout seo="seo" showDisclaimer sticky phone={fm.phone_number}>
      <SEO
        title="Answer Your Calling to Care"
        description="Life can be more rewarding when we care for others. With care, small ripples can become waves that have the potential to strengthen communities and inspire other acts of care."
      />
      <div className={className}>
        <CNHeader
          className="cn-header"
          chatButtonColor={colors.umaGreenNew}
          chatIDForHeader="LP_DIV_lead_nurturing"
          phone={fm.phone_number}
        />

        <HeroScalableLogo heroType="scalable" template="chat-now" />

        <PS92020Hero
          bgImgSm={fm.hero.img_sm.path.publicURL}
          bgImgLg={fm.hero.img_lg.path.publicURL}
          phoneNumber={fm.phone_number}
          className="hero-mobile"
        >
          <NarrowContainer className="container hero-content">
            <div className="row pt-5">
              <div className="col-12">
                <p className="mb-0 mt-0">
                  <span className="bold">
                    Answer Your
                    <br />
                    Calling To&nbsp;Care.
                  </span>
                </p>
                <div className="arrow">
                  <svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="0,0 0,100 50,50" />
                  </svg>
                  <p className="m-0 p-2">Classes Starting Soon</p>
                </div>
              </div>
            </div>
          </NarrowContainer>
        </PS92020Hero>

        <CNContentTop
          className="top-content"
          chatBox={{
            chat_box_color: {
              label: 'Green',
              value: colors.umaGreenNew,
            },
            chat_button_color: {
              label: 'Dark Blue',
              value: colors.umaDarkBlue,
            },
            disclaimer: 'Chat with a UMA representative today!',
            heading: 'Have Questions?<br>Want to learn more?',
          }}
          chatButtonColor={colors.umaDarkBlue}
          chatIDForChatBox="LP_DIV_lead_nurturing_3"
          chatIDForMobileContent="LP_DIV_lead_nurturing_2"
          sidebarTextColor={colors.umaBlue400}
          pageClass=""
          general={{
            logo_type: 'horizontal',
            chat_button_text: 'Chat Now',
            chat_id_header: 'LP_DIV_lead_nurturing',
            chat_id_mobile: 'LP_DIV_lead_nurturing_2',
            chat_id_sidebar: 'LP_DIV_lead_nurturing_3',
            header_chat_color_override: null,
            mobile_chat_color_override: { value: '#78BE20' },
          }}
          markdownContent={() => (
            <>
              {htmr(fm.content_section.above_video)}
              <VideoLazyload
                posterAlt="Care Pays Back video placeholder. Click or press enter to play."
                posterSrc={fm.content_section.video_poster.path.publicURL}
                includePlayIcon
              >
                <YouTubeEmbed
                  className="video-iframe"
                  width={999}
                  videoId={fm.content_section.video_id}
                  title="Care Pays Back"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </VideoLazyload>

              {htmr(fm.content_section.below_video)}
            </>
          )}
        />

        <div className="program-stack wp-content">
          <div className="online">
            <div className="container">
              <div className="row d-flex">
                <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                  <div
                    className="bg-img"
                    style={{
                      backgroundImage: `
                          url(${data.markdown.frontmatter.onlinePrograms.img.path.publicURL})
                        `,
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 p-0 text order-1 order-md-2">
                  <h3 className="heading m-0 p-3">Online</h3>
                  <p className="px-3 mb-0 bold">
                    Earn a degree online from the comfort of your home with a
                    flexible schedule.
                    <sup>1</sup>
                  </p>
                  <ul className="p-3">
                    {sortedOnlinePrograms.map(({ node }) => (
                      <li key={node.meta.program_full_name}>
                        <Link className="Link" to={`/online/${node.slug}-2`}>
                          {node.meta.program_full_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Quote
          bgColor={colors.umaPurpleNew}
          fields={{
            uma_brick_fact_quote: {
              fact: '',
              quote:
                'Teachers reached out. Everybody made sure I was on my game. They helped me stay on track. UMA was a great school… I like to help people. I get my joy from seeing someone else’s joy.',
              quote_author: 'Breanna B.<br/>UMA Graduate',
            },
          }}
        />

        <NumberTouts />

        <ImageToutsCareMantra
          content={() => {
            if (onlineOnly) {
              return (
                <>
                  <h2 className="blue">Care is the other half of education.</h2>
                  <p>
                    As you pursue your education with UMA, you can count on our
                    support services to be there for you. We care for you with:
                  </p>
                </>
              )
            }
            return (
              <>
                <h2 className="blue">Care is the other half of education.</h2>
                <p>
                  As you pursue your education with UMA, you can count on our
                  support services to be there for you. We care for you with:
                </p>
              </>
            )
          }}
        />

        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <LogoSquare />
              </div>
              <div className="col-12 col-md-8">
                <p>
                  If you’re committed to doing more, to seeking a new path, to
                  making a difference in your life, your family’s life, and the
                  lives of others, UMA is ready to help get you started. Begin
                  your journey today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footnotes container">
        <p>
          <small>
            1. Coursework is online except for on-site externship/practicum. Set
            schedules required for on-site externship/practicum courses in
            select programs and coursework deadlines are set by instructors.
          </small>
        </p>
      </div>
    </Layout>
  )
}

export default styled(TemplateChatNowNewer)`
  .hero-type--scalable {
    background-color: ${colors.umaDarkBlue};
  }

  .cn-header .gray-bar {
    background-color: ${colors.umaDarkBlue};
    .chat-launch {
      color: ${colors.umaBlue400};
    }
  }

  .hero-mobile {
    display: flex;
    align-items: flex-end;
    justify center;
    padding-bottom: 2rem;

    .d-sm-none.hero-img {
      background-position: right top;
      display: block !important;
      ${above.sm`
        display: block !important;
      `}
      ${above.lg`
        display: none !important;
      `}
    }

    .d-none.d-sm-block.hero-img {
      display: none !important;
      ${above.sm`
        display: none !important;
      `}
      ${above.lg`
        display: block !important;
        background-position: 168vw top;
      `}
      @media (min-width: 1060px) {
        background-position: 158vw top;
      }
      @media (min-width: 1160px) {
        background-position: 148vw top;
      }
      @media (min-width: 1360px) {
        background-position: 138vw top;
        // background-position: 1876px top;
      }
      @media (min-width: 1570px) {
        background-position: 50% top;
        // background-position: 1876px top;
      }
    }
  }

  .top-content > .chat-launch {
    transform: translateX(-50%);
    left: 50%;
    position: relative;
    margin-top: 2rem;
    font-size: 1.5rem;
    // background-color: ${colors.umaPurpleNew};
    // box-shadow: 0 2px 4px hsla(0, 0%, 0%, 25%);
    ${above.md`
    `}
  }

  .below-btn {
    padding: 1.5rem 0 2rem 0;
    margin: 10px 15px 10px 20px;
  }

  .content-left h1 {
    margin-top: 0;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1,
  h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .header-wrapper {
    background-color: ${colors.umaLightBlue};
    padding: 0.75rem 0;
  }

  [class^='form-wrap'] {
    h2.form-font {
      font-size: 2rem;
    }
    p.form-font {
      font-size: 1rem;
      ${above.lg`
        font-size: 1.25rem;
      `}
    }
  }

  .form-wrapper {
    background-color: ${colors.umaDarkBlue};
    > .container {
      padding: 0 7px;
    }
    .form-container {
      margin: 0 auto;
      padding: 0;
      max-width: 500px;
      h2,
      p {
        text-align: center;
      }
    }

    form {
      flex-wrap: wrap;
      justify-content: space-between;

      ${above.lg`
        display: flex;
      `}
    }

    form .input-wrap,
    form .button-wrap {
      flex: 0 0 48.5%;
      margin: 10px 6.75px;
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      select {
        font-size: 1rem;
      }
    }
    form .button-wrap {
      display: flex;
      margin: 1.5rem 6.75px;
    }
  }

  .hero-content {
    .row {
      padding-top: 8rem;

      ${above.lg`
        padding-top: 2rem;
        padding-bottom: 2rem;      
      `}
    }

    p {
      color: #fff;
      font-size: 2rem;
      line-height: 1;
      text-transform: uppercase;

      ${above.sm`
        font-size: 3rem;
      `}

      ${above.lg`
        font-size: 4rem;
      `}
    }

    .arrow {
      background-color: #ea4b5f;
      box-shadow: 8px 8px 6px hsla(0, 0%, 0%, 0.4);
      border-radius: 1px;
      display: inline-block;
      margin-top: 1.5rem;
      position: relative;
      max-width: 88%;

      svg {
        border-radius: 1px;
        fill: #ea4b5f;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        width: auto;
      }
    }

    .arrow p {
      font-size: 1.5rem;

      ${above.md`
        font-size: 2rem;
      `}
    }
  }

  .page-content {
    h1 {
      margin-top: 0;
    }

    li {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    strong {
      color: ${colors.umaTeal};
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: bold;
    }
  }

  .program-stack {
    color: white;

    .heading {
      color: white;
    }

    .Link {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .online,
    .clearwater {
      position: relative;

      .bg-img-wrap,
      .text {
        ${above.md`
          margin-top: -3.45rem;
        `}
      }

      .bg-img-wrap {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        overflow: hidden;
      }

      .text {
        background-color: ${colors.umaDarkBlue};
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        overflow: hidden;
      }
    }

    .online::before,
    .clearwater::before {
      content: '';
      display: block;
      background-color: ${colors.umaGreenNew};
      position: absolute;
      top: 3.45rem;
      right: 0;
      bottom: 3.45rem;
      left: 0;

      ${above.md`
        top: 0; bottom: -3.45rem;
        padding-bottom: 3.45rem;
      `}
    }

    .online {
      ${above.sm`
        margin-bottom: 3.5rem;
        margin-top: 4rem;
      `}
      ${above.md`
      margin-top: 8rem;
      `}
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }

    .clearwater {
      ${above.md`
        margin-bottom: 4rem;
      `}
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .phone {
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    font-weight: normal;
    text-decoration: none;

    svg {
      transform: scaleX(-1);
      font-size: 1.5rem;

      ${above.sm`
        margin-right: 0.5rem;
        font-size: 1rem;
      `}
    }
  }

  .form-wrap--lg {
    position: relative;
    ${above.lg`
      box-shadow: -8px -8px 6px hsla(0, 0%, 0%, 0.4);
      margin-top: -10rem;
    `}

    &::before {
      background-color: ${colors.umaLightBlue};
      border-radius: 2px;
      content: '';
      display: block;
      opacity: 0.9;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    .form--lg {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .mobile-menu-wrap {
    background: ${colors.umaLightBlue};
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: -100%;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    &.active {
      display: block;
      left: 0;
      opacity: 0.9;
    }
  }

  .mobile-menu {
    background-color: ${colors.umaDarkGray};
    color: #fff;
    font-size: 0.9rem;
    max-width: 350px;
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: all 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;

    width: 80%;
    z-index: 9;

    &.active {
      left: 0;

      a {
        padding: 0.2rem;
      }
      .close-wrap {
        text-align: right;
      }
    }

    a {
      display: block;
      color: #fff;
      line-height: 1.1rem;
      padding: 10rem 0;
      text-decoration: none;
      transition: color 0.2s ease-in-out, padding 0.4s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        color: ${colors.umaGreenHover};
      }
    }

    ul {
      list-style: none;
      margin: 0.5rem 0 2rem;
      padding: 0 0 0 1rem;
    }

    li {
      line-height: 1.2rem;
      margin: 0.75rem 0 0.75rem 0.5rem;
      text-indent: -1.1rem;

      svg {
        margin-bottom: -2px;
        margin-right: 0.2rem;
      }
    }

    .menu-heading {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }

  [class^='VideoLazyload'] {
    max-width: 700px;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    .video-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const query = graphql`
  query($id: String!) {
    markdown: mdx(id: { eq: $id }) {
      frontmatter {
        onlineOnly
        phone_number
        v1 {
          form {
            excludePrograms {
              code
            }
          }
        }
        tracking {
          leadsource
          leadvendor
          vendor
        }
        content_section {
          above_video
          video_id
          video_poster {
            path {
              publicURL
            }
          }
          below_video
        }
        hero {
          img_sm {
            path {
              publicURL
            }
          }
          img_lg {
            path {
              publicURL
            }
          }
          text
        }
        clearwaterPrograms {
          img {
            path {
              publicURL
            }
          }
        }
        onlinePrograms {
          img {
            path {
              publicURL
            }
          }
        }
      }
      body
    }

    clearwaterDiplomaPrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "diploma" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
          slug
        }
      }
    }

    clearwaterDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_code }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
          slug
        }
      }
    }

    onlineDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "45" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
          slug
        }
      }
    }
  }
`
