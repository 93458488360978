import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { above, colors } from '../../styles/utilities'

const NumberTouts = ({ className, clearwaterOnly, onlineOnly }) => {
  const programs = useStaticQuery(graphql`
    {
      query: allWordpressWpPrograms(
        sort: { fields: meta___program_full_name }
      ) {
        edges {
          node {
            meta {
              program_campus
              program_code
            }
          }
        }
      }
    }
  `)

  const onlineProgramsOnly = programs.query.edges.filter((p) => {
    return (
      p.node.meta.program_campus === '45' &&
      p.node.meta.program_code !== 'UNSPEC'
    )
  })

  const clearwaterProgramsOnly = programs.query.edges.filter((p) => {
    return p.node.meta.program_campus === '45'
  })

  const allPrograms = programs.query.edges.filter((p) => {
    return p.node.meta.program_code !== 'UNSPEC'
  })

  const numAllPrograms = allPrograms.length
  const numOnlinePrograms = onlineProgramsOnly.length
  const numClearwaterPrograms = clearwaterProgramsOnly.length

  const renderProgramLength = () => {
    if (clearwaterOnly) {
      return numClearwaterPrograms
    }
    if (onlineOnly) {
      return numOnlinePrograms
    }
    return numAllPrograms
  }

  return (
    <div className={`${className} py-5`}>
      <div className="container d-flex justify-content-center nt-row">
        <div className="left item">
          <div>
            <span className="big">{renderProgramLength()}</span>
          </div>
          <div>Healthcare Programs</div>
        </div>
        <div className="item">
          <div>
            <span className="big">14</span>
            <span className="med">k</span>
          </div>
          <div>Students</div>
        </div>
      </div>
      <div className="container d-flex justify-content-center nt-row">
        <div className="left item">
          <div>
            <span className="big">85</span>
            <span className="med">k+</span>
          </div>
          <div>Alumni</div>
        </div>
        <div className="item">
          <div>
            <span className="big">30</span>
            {/* <span className="med">+</span> */}
          </div>
          <div>Years in healthcare education</div>
        </div>
      </div>
    </div>
  )
}

NumberTouts.propTypes = {
  clearwaterOnly: PropTypes.bool,
  onlineOnly: PropTypes.bool,
}

NumberTouts.defaultProps = {
  clearwaterOnly: false,
  onlineOnly: false,
}

export default styled(NumberTouts)`
  text-transform: uppercase;

  .nt-row {
    padding: 1rem;
    &:first-of-type {
      border-bottom: 1px solid ${colors.umaCoolGray};
    }
  }

  .big,
  .med {
    color: ${colors.umaDarkBlue};
  }

  .big {
    font-size: 5rem;
    ${above.md`
      font-size: 10rem;
    `}
  }

  .med {
    font-size: 2.5rem;
    ${above.md`
      font-siez: 5rem;
    `}
  }

  .item {
    flex: 0 0 50%;
    text-align: center;
  }

  .left {
    border-right: 1px solid ${colors.umaCoolGray};
  }
`
