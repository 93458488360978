import { createStore } from 'redux';
import { reducers } from './reducers';

const store = createStore(
  reducers,
  typeof window !== "undefined"
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined
)

// store is the redux store
// select is a function that returns the piece of state that you're interested in
// onchange is the function you wanna run when the state changes
function observeStore(store, select, onChange) {
  let currentState;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}

export { store, observeStore };