import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { find, uniqBy } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { FaChevronRight, FaTimes } from 'react-icons/fa'

const Menu = ({ selectedCampus, excludePrograms, setDisplayMobileMenu }) => {
  const data = useStaticQuery(graphql`
    {
      programs: allWordpressUmaPrograms {
        edges {
          node {
            acf {
              uma_program_info {
                code
                campus {
                  value
                }
                full_name
                type
                links {
                  link_key
                  page_link {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const sortCB = (a, b) => {
    let aNorm = a.node.acf.uma_program_info.full_name.toLowerCase(),
      bNorm = b.node.acf.uma_program_info.full_name.toLowerCase()
    if (aNorm < bNorm) {
      return -1
    } else if (aNorm > bNorm) {
      return 1
    }
    return 0
  }

  const clearwaterPrograms = data.programs.edges
    .filter((program) => {
      return program.node.acf.uma_program_info.campus.value === 'clearwater'
    })
    .sort(sortCB)
  const cpNoDuplicates = uniqBy(clearwaterPrograms, function (program) {
    return program.node.acf.uma_program_info.full_name
  }).sort(sortCB)

  const onlineDegreePrograms = data.programs.edges
    .filter((program) => {
      return (
        program.node.acf.uma_program_info.campus.value === 'online' &&
        program.node.acf.uma_program_info.type === 'degree'
      )
    })
    .sort(sortCB)

  const renderPrograms = (programs, heading) => {
    if (programs.length <= 0) return null
    return (
      <>
        <div>{heading}</div>
        <ul>
          {programs.map((program) => {
            let programLink = find(program.node.acf.uma_program_info.links, {
              link_key: 'learn',
            })

            if (
              excludePrograms.includes(
                program.node.acf.uma_program_info.code.toLowerCase(),
              )
            ) {
              return null
            }

            programLink = new URL(programLink.page_link.url.replace('-2', ''))
            programLink = programLink.pathname.replace(/\/$/, '-2')

            return (
              <li key={program.node.acf.uma_program_info.full_name}>
                <Link to={`${programLink}`}>
                  <FaChevronRight />
                  <span>{program.node.acf.uma_program_info.full_name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </>
    )
  }

  return (
    <>
      <div className="close-wrap">
        <FaTimes
          role="button"
          tabIndex="0"
          onClick={() => setDisplayMobileMenu(false)}
          onKeyDown={(e) =>
            e.keyCode === 13 ? setDisplayMobileMenu(false) : null
          }
        />
      </div>
      {(selectedCampus === 'Clearwater' || selectedCampus === 'Both') &&
        renderPrograms(
          cpNoDuplicates,
          <div className="menu-heading">Clearwater Programs</div>,
        )}
      {(selectedCampus === 'Online' || selectedCampus === 'Both') &&
        renderPrograms(
          onlineDegreePrograms,
          <div className="menu-heading">Online Programs</div>,
        )}
    </>
  )
}

Menu.propTypes = {
  excludePrograms: PropTypes.arrayOf(PropTypes.string),
}

Menu.defaultProps = {
  excludePrograms: [],
  includePharmTech: false,
}

export default Menu
