/* eslint-disable import/prefer-default-export */

import { FieldOptionsWithID } from '../../hooks/useForm'
import { maskPhone } from '../format'
import { SparkroomFieldName } from '../sparkroom'
import { UMACampusCode } from '../uma/campus'
import { OtherProgramCode } from '../uma/program'
import { email, min, phone, required } from '../validator'
import campusOptions from './campusOptions'
import stateOptions from './stateOptions'

export function newStudentRequest(): FieldOptionsWithID[] {
  return [
    {
      hidden: true,
      id: SparkroomFieldName.Address1,
      value: 'Unspecified',
    },
    {
      hidden: true,
      id: SparkroomFieldName.AdGroupID,
      queryOverwrites: ['utm_content'],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.Campus,
      label: 'Campus',
      options: campusOptions,
      validators: [required()],
      value: UMACampusCode.Unknown,
    },
    {
      hidden: true,
      id: SparkroomFieldName.CID,
      value: 'SR_U_10365',
    },
    {
      hidden: true,
      id: SparkroomFieldName.City,
      value: 'Unspecified',
    },
    {
      hidden: true,
      id: SparkroomFieldName.ClientID,
      value: '',
    },
    {
      hidden: false,
      id: SparkroomFieldName.Email,
      label: 'Email',
      markRequired: true,
      type: 'email',
      validators: [required(), email()],
      value: '',
    },
    {
      hidden: false,
      id: SparkroomFieldName.FirstName,
      label: 'First Name',
      markRequired: true,
      type: 'text',
      validators: [required(), min(2)],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.GCLID,
      queryOverwrites: ['gclid'],
      value: '',
    },
    {
      hidden: false,
      id: SparkroomFieldName.LastName,
      label: 'Last Name',
      markRequired: true,
      type: 'text',
      validators: [required(), min(2)],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.LeadBuyerID,
      queryOverwrites: ['leadbuyerid'],
      value: 'UMA',
    },
    {
      hidden: true,
      id: SparkroomFieldName.LeadIDToken,
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.LeadSource,
      queryOverwrites: ['leadsource', 'ldsrc', 'ld_src'],
      value: 'INTS-PPC',
    },
    {
      hidden: true,
      id: SparkroomFieldName.LeadSource2,
      queryOverwrites: ['leadsource2', 'ldsrc2'],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.LeadVendor,
      queryOverwrites: ['leadvendor'],
      value: 'PSM',
    },
    {
      hidden: true,
      id: SparkroomFieldName.PathLabel,
      value: '',
    },
    {
      formatter: maskPhone,
      hidden: false,
      id: SparkroomFieldName.Phone,
      label: 'Phone',
      markRequired: true,
      type: 'tel',
      validators: [required(), phone()],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.PostalCodeOrZIP,
      value: '00000',
    },
    {
      hidden: true,
      id: SparkroomFieldName.ProgramOnline,
      label: 'Program of Interest',
      value: OtherProgramCode.Unknown,
    },
    {
      hidden: true,
      id: SparkroomFieldName.RelaxVal,
      value: '1',
    },
    {
      hidden: true,
      id: SparkroomFieldName.SID,
      value: '13462',
    },
    {
      hidden: true,
      id: SparkroomFieldName.State,
      label: 'State',
      options: stateOptions,
      validators: [required()],
      value: 'GU',
    },
    {
      hidden: true,
      id: SparkroomFieldName.TCPAConsent,
      value: 'Y',
    },
    {
      hidden: true,
      id: SparkroomFieldName.Vendor,
      queryOverwrites: ['vendor'],
      value: 'PSM',
    },
    {
      hidden: true,
      id: SparkroomFieldName.VendorLeadID,
      queryOverwrites: ['vendorleadid'],
      value: '',
    },

    // TODO is `gclid` the right URL param?
    {
      hidden: true,
      id: SparkroomFieldName.UTMAdgroup,
      queryOverwrites: ['utm_adgroup'],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.UTMCampaign,
      queryOverwrites: ['utm_campaign'],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.UTMMedium,
      queryOverwrites: ['utm_medium'],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.UTMSource,
      queryOverwrites: ['utm_source'],
      value: '',
    },
    {
      hidden: true,
      id: SparkroomFieldName.UTMTerm,
      queryOverwrites: ['utm_term'],
      value: '',
    },
  ]

  // TODO: Google Click ID
}
