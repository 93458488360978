// Node Module Imports
import htmr from 'htmr'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import sp from '../../utilities/shortcodeParser'
import { Container, Row, Col } from 'react-bootstrap'

// Style Imports
import { above, colors } from '../../styles/utilities';

const programs = ({className, fields, progList, selectedCampus}) => {

  let title = 'UMA Programs'
  if(selectedCampus === 'All') {
    title = 'All UMA Programs'
  }
  else if(selectedCampus === 'Online') {
    // todo: eslint fix warning
    title = sp(
      'UMA Online{{ shortcode: "footnote", content: "${disclaimers.onlineProgram}" }} Programs'
    )
  }
  else if(selectedCampus === 'Clearwater') {
    title = 'UMA Clearwater Programs'
  }

  return (
  <section className={className}>
    <Container fluid>
      <Row>
        <Col xs={12} md={6} className="left-col">
          <Img fluid={fields.uma_brick_programs.image.localFile.childImageSharp.fluid}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                width: '100%',
              }}
              objectFit="cover"
              objectPosition="center top"
          />
        </Col>
        <Col xs={12} md={6} className="right-col">
          <h3 className="program-heading fff h2">{htmr(title)}</h3>
          <ul className="list">
            {progList.map((prog, idx) => <li key={idx}>{prog.node.acf.uma_program_info.full_name}</li>)}
          </ul>
        </Col>
      </Row>
    </Container>
  </section>
  )

};

export default styled(programs)`
  ul {
    margin: 0;
    padding: 0;
  }
  .left-col {
    min-height: 250px;
    overflow: hidden;
    padding: 0;
    position:relative;
    img {
      width: 100%;
    }
  }
  .right-col {
    padding: 1rem;
    ${ above.md`
      padding: 1rem 1rem 8rem 1rem;
    ` }
    background-color: ${colors.umaTeal};
    color: #fff;
    .program-heading {
      text-transform: uppercase;
      margin: 1rem;
    }
    .list>li {
      list-style: none;
      line-height: 1.5rem;
      :before {
        color: ${colors.umaOrange};
        content: "\u25BA";
        margin: .5rem .5rem 0 1rem;
      }
    }
  }
`;

programs.propTypes = {
  className: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  progList: PropTypes.array.isRequired,
  selectedCampus: PropTypes.string.isRequired,
}

// TODO: Remove this component, and replace it with side-by-side bricks that look the same