import React from 'react'

import imageSource from '../../assets/icons/laptop.png'
import Tout from './Tout'

const UMAOnlinePrograms = () => (
  <Tout
    image={{ alt: 'laptop', source: imageSource }}
    text="UMA online programs"
  />
)

export default UMAOnlinePrograms
