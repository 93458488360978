// Node MOdule Imports
import React from 'react';
import styled from 'styled-components';
import { FaPhone } from 'react-icons/fa'
import PropTypes from 'prop-types';

// Style Imports
import { above, below, colors } from '../../../../styles/utilities';
import { NarrowContainer } from '../../../../styles/elements';

// Utility imports
import { stripPhoneNumber } from '../../../../utilities/stripPhoneNumber';

const FooterLeftGray = ({className, phone, renderAbhesLogo, renderFooterText}) => {
  return(
    <section className={`${className} footer-`}>
      <NarrowContainer>
        <div className="row">
          <div className="col-md-9 footer__info">
            <div className="abhes-img">  
              {/* <img src={abhes} alt="Accrediting Bureau of Health Education Schools" /> */}
              {renderAbhesLogo()}
            </div>
            <div className="abhes-text">
              {renderFooterText()}
            </div>
          </div>
          <div className="col-md-3">
            <div className="phone-number">
              <a href={`tel:${stripPhoneNumber(phone)}`} className="phone ga-call fb-track-click td-n-hu" ga-label="footer">
                <FaPhone className="fa-phone" />
                {phone}
              </a>
            </div>
          </div>
        </div>
      </NarrowContainer>
    </section>
  )
};

FooterLeftGray.propTypes = {
  phone: PropTypes.string.isRequired
};

export default styled(FooterLeftGray)`
  background: ${colors.umaDarkGray};
  padding: ${(footnotes, sticky) => footnotes && sticky ? `4rem` : `1.2rem 0`};
  ${above.md`
      padding: 2.2rem 0;
  `};
  .container {
    display: flex;
    align-items: center;
    ${below.md`
      display: block;
    `};
    .abhes-img, .abhes-text {
      display: inline-block;
      ${below.md`
        display: block;
        text-align: center;
      `};
    }
    .abhes-text {
      color: #fff;
      font-size: 75%;
      ${below.md`
        margin-top: 1rem;
      `};
      p {
        line-height: normal;
        margin-top: 0;

        ${above.md`
          margin-left: 0.75rem;
        `}
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: ${colors.umaYellow};
        }
      }
    }
    .row {
      width: 100%;
    }

    .footer__info {
      ${above.md`
        align-items: center;
        display: flex;
      `}
    }

    .phone-number {
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 1.2rem;
      justify-content: center;
      line-height: normal;
      padding-top: 30px;



      a {
        color: #fff;
        text-decoration: none;
      }

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .td-n-hu:after {
    background: #fff;
  }
`;