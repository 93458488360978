/* eslint-disable import/prefer-default-export */
import FormParamBuilder from '@ultimatemedical/uma-utils/dist/v2/formParam/builder'
import QueryStringParser from '@ultimatemedical/uma-utils/dist/v2/queryString/parser'

import constants from './constants'
import { getGAClientID } from './cookie'

/**
 * buildSparkroomRequestString
 *
 * Build the fully qualified Sparkroom request to load the
 * appropriate form.
 *
 * @param {void}
 * @return {string} the sparkroom request URL
 */
export const buildSparkroomRequestString = (formParams, search = '') => {
  const sparkroomBase = 'https://forms.sparkroom.com/Sparkroom/servlet/form'
  const qsp = new QueryStringParser(search)

  const fpb = new FormParamBuilder(formParams)
    .withOverrides(
      JSON.parse(
        localStorage.getItem(constants.LOCALSTORAGE_KEY_TRACE) || '{}',
      ),
      { pairs: qsp.all() },
    )
    .build()

  let request = fpb.getURLString()

  const clientID = getGAClientID()
  if (clientID) {
    const formattedClientID = clientID
    request += `&clientId=${formattedClientID}`
  }

  request = sparkroomBase + request
  return request
}
