import axios from 'axios'

import { TeamsPayload } from '../util/teams'

const useTeams = () => {
  const sendToTeams = async (notification: TeamsPayload) => {
    // send to teams
    try {
      await axios.post('/.netlify/functions/teams-v1', notification, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return { teamsError: null }
    } catch (e) {
      return { teamsError: e }
    }
  }
  return { sendToTeams }
}

export default useTeams
