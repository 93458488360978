import React, { useEffect, useRef, useState } from 'react'

import Form from '@ultimatemedical/acorn/dist/FormSelectCampus'
import FormParamBuilder from '@ultimatemedical/uma-utils/dist/v2/formParam/builder'
import QueryStringParser from '@ultimatemedical/uma-utils/dist/v2/queryString/parser'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import parse from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { colors } from '../../styles/utilities'
import constants from '../../utilities/constants'
import { reqInfoDisc } from '../../utilities/disclaimers'
import { hiddenFormParamsAction } from '../redux/actions'
import { hiddenFormParamsSelector, umaSelector } from '../redux/selectors'
import addQueryStringParams from '../util/addQueryStringParams'

const AcornFormWrapper = ({ aboveForm, className, overrideFormLabels }) => {
  // hooks
  const dispatch = useDispatch()

  // default local state
  const campus = useSelector(umaSelector.campus)
  const phone = useSelector(umaSelector.phone)
  const [leadID, setLeadID] = useState('')
  const [processedFormParams, setProcessedFormParams] = useState([])
  const [redirect, setRedirect] = useState('/confirmation')

  // form ref
  // used to query for inputs to change their order on page load
  const formRef = useRef()
  useEffect(() => {
    const phoneInput = formRef.current.querySelector('input[name="Phone"]')
      .parentNode
    const emailInput = formRef.current.querySelector('input[name="Email"]')
      .parentNode
    emailInput.parentNode.insertBefore(emailInput, phoneInput)
  }, [formRef])

  // build form params (hidden inputs)
  // we're initializing with values from redux,
  // then adding local storage overrides,
  // then query string overrides
  const rawFormParams = useSelector(hiddenFormParamsSelector)
  useEffect(() => {
    const qsp = new QueryStringParser(window.location.search)
    const fpb = new FormParamBuilder(rawFormParams)
      .withOverrides(
        JSON.parse(
          localStorage.getItem(constants.LOCALSTORAGE_KEY_TRACE) || '{}',
        ),
        { pairs: qsp.all() },
      )
      .build()
    setProcessedFormParams(fpb.getKeyValuePairs())
  }, [rawFormParams])

  // set the redirect
  useEffect(() => {
    setRedirect((r) => addQueryStringParams(r, { lid: leadID, phone }))
  }, [phone, leadID])

  return (
    <div className={`${className} form-wrap`} ref={formRef}>
      {aboveForm && aboveForm()}
      <Form
        requiredFieldMarkers
        redirect={redirect}
        formParams={processedFormParams}
        leadIdCallback={(token) => {
          setLeadID(token)
          dispatch(
            hiddenFormParamsAction.updateMultiple([
              {
                key: 'leadid_token',
                value: token,
              },
            ]),
          )
        }}
        overrideFormLabels={
          overrideFormLabels || {
            Email: 'Email Address',
          }
        }
        removeFormParamsAndSpecifyDefaultValues={{ Campus: campus }}
        submitButtonText={parse('Request Info')}
      />
      <p className="disclaimer mb-0">
        <small>{reqInfoDisc}</small>
      </p>
    </div>
  )
}

export const query = graphql`
  fragment Form on UMAFrontmatterForm {
    background
    hiddenParams {
      VendorLeadID
      utm_adgroup
      utm_campaign
      utm_source
      utm_term
      utm_medium
      LeadSource
      LeadVendor
      Vendor
      LeadBuyerID
    }
  }
`

AcornFormWrapper.propTypes = {
  aboveForm: PropTypes.func,
  beforeSubmitCallback: PropTypes.func,
  className: PropTypes.string,
  overrideFormLabels: PropTypes.object,
  redirect: PropTypes.string,
}

const StyledAcornFormWrapper = styled(AcornFormWrapper)`
  padding: 1rem;
  font-size: 0.9rem;

  input,
  select {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.45rem;
    width: 100%;
  }

  .input-wrap {
    background-color: #fff;
    border-radius: 1px;
    margin: 1rem 0;
    position: relative;
  }

  button {
    background-color: ${colors.dev.green.b()};
    border: none;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    padding: 1rem;
    text-transform: uppercase;
    width: 100%;
    transition: all 0.2s ease-in-out;

    &:focus,
    &:hover {
      background-color: ${colors.dev.green.c()};
      color: ${colors.dev.green.a()};
    }

    &:focus {
      outline: 2px solid ${colors.dev.green.d()};
    }

    &[disabled] {
      background-color: ${colors.umaDarkGray};
      cursor: not-allowed;
    }
  }

  .label-and-error {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0.5rem 0.25rem 0.75rem;
    width: 100%;
    font-size: 0.75rem;
    font-weight: bold;

    label {
      white-space: nowrap;
    }

    .error {
      color: ${colors.umaRed};
      font-size: 0.7rem;
      text-align: right;
      font-weight: normal;
    }
  }

  .disclaimer {
    color: #fff;
    font-size: 0.9rem;
    line-height: 1;
  }

  .success.top-msg,
  .error.top-msg {
    background-color: ${colors.umaGreen};
    border-radius: 2px;
    color: #fff;
    padding: 0.5rem;
    line-height: 1;

    p {
      margin: 0;
    }
  }

  .error.top-msg {
    background-color: ${colors.umaRed};

    a {
      color: #fff;
    }
  }

  .required {
    color: ${colors.umaRed};
  }
`

export default StyledAcornFormWrapper
