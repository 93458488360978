import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../styles/utilities'
import ResumeWriting from '../../images/icons/care-mantra/care-mantra-resume-writing.png'
import JobSearch from '../../images/icons/care-mantra/care-mantra-job-search.png'
import JobInterview from '../../images/icons/care-mantra/care-mantra-job-interview.png'
import DedicatedAdvisor from '../../images/icons/care-mantra/care-mantra-dedicated-advisor.png'
import TechSupport from '../../images/icons/care-mantra/care-mantra-tech-support.png'
import FinancialAid from '../../images/icons/care-mantra/care-mantra-financial-aid.png'
import Laptops from '../../images/icons/care-mantra/care-mantra-laptops.png'
import OneOnOne from '../../images/icons/care-mantra/care-mantra-one-on-one.png'
import ResourceGuidance from '../../images/icons/care-mantra/care-mantra-resource-guidance.png'
import ChevronBackgroundStroke from '../reusables/ChevronBackgroundStroke'

const ImageToutsCareMantra = ({ className, content }) => {
  return (
    <ChevronBackgroundStroke className={`${className}`}>
      <div className="container text-center" id="image-touts">
        {content()}
        <div className="row touts d-flex align-items-start pt-4 pb-3">
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={OneOnOne} alt="Academic Assistance" />
            </div>
            <p>One-on-one academic support</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={ResourceGuidance} alt="Resource Guidance" />
            </div>
            <p>Resource guidance through life&apos;s challenges</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={JobSearch} alt="Job Search Assistance" />
            </div>
            <p>Job search assistance</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={TechSupport} alt="24/7 Technical Support" />
            </div>
            <p>24/7 technical support</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={Laptops} alt="Laptops" />
            </div>
            <p>Laptops (for those who&nbsp;qualify)</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={JobInterview} alt="Job Interview Coaching" />
            </div>
            <p>Job interview coaching</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img
                src={DedicatedAdvisor}
                alt="A Dedicated Advisor for Guidance Through Life's Challenges"
              />
            </div>
            <p>A dedicated advisor</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={FinancialAid} alt="Financial Aid" />
            </div>
            <p>Financial aid (for those who&nbsp;qualify)</p>
          </div>
          <div className="col-6 col-md-4 pb-4">
            <div className="tout-icon-wrapper">
              <img src={ResumeWriting} alt="Resume Writing Guidance" />
            </div>
            <p>Resume writing guidance</p>
          </div>
        </div>
      </div>
    </ChevronBackgroundStroke>
  )
}

ImageToutsCareMantra.propTypes = {
  className: PropTypes.string,
  content: PropTypes.func,
}

export default styled(ImageToutsCareMantra)`
  border-top: 1.25rem solid ${colors.umaYellow};
  padding: 3rem 0;

  .touts {
    font-weight: bold;
  }
  .tout-icon-wrapper {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
