import React from 'react'

import { lighten } from 'polished'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

import useGa from '../../hooks/useGa'
import { NarrowContainer } from '../../styles/elements'
import { above, colors } from '../../styles/utilities'

const CNHeader = ({
  chatButtonColor,
  chatIDForHeader,
  className,
  phone,
  template,
}) => {
  const submitEvent = useGa()

  return (
    <Container fluid className={`${className} pd-0`}>
      <div className="gray-bar">
        <NarrowContainer className="phone-chat">
          {template === 'call-now' ? (
            <>
              {/* eslint-disable-next-line */}
              <a
                href={`tel:${phone}`}
                className="td-n-hu phone call-now ga-call fb-track-click"
                onClick={() => {
                  submitEvent({
                    action: 'click',
                    category: 'call',
                    label: 'click to call - desktop header - learn',
                  })
                }}
              >
                <span>{phone}</span>
              </a>
            </>
          ) : (
            <>
              <span className="phone">{phone}</span>
              {/* eslint-disable-next-line */}
              <div className="chat">
                <button
                  // id={chatIDForHeader}
                  className="chat-btn ga-chat chat-launch"
                  onClick={() => {
                    if (window.Genesys) {
                      window.Genesys('command', 'Messenger.open')
                    }
                    submitEvent({
                      action: 'click',
                      category: 'chat',
                      label: 'click to chat - desktop header - learn',
                    })
                  }}
                  type="button"
                >
                  Chat Now
                </button>
              </div>
            </>
          )}
        </NarrowContainer>
      </div>
    </Container>
  )
}

export default styled(CNHeader)`
  position: relative;
  color: #fff;
  text-align: center;
  ${above.md`
    text-align: left;
  `};
  &.pd-0 {
    padding: 0;
  }
  .gray-bar {
    background-color: ${colors.umaDarkGray};
    display: none;
    ${above.md`
      display: block;
    `};
  }
  .phone-chat {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .phone {
      font-weight: 300;
      margin-right: 35px;
      display: inline-flex;
      align-items: center;

      &.call-now {
        color: white;
        display: inline-block;
        padding: 0.75rem 1rem;

        &:after {
          background-color: white;
        }
      }
    }
    .chat {
      background-color: ${(props) => props.chatButtonColor};
      cursor: pointer;
      display: inline-flex;
      min-height: 29px;
      min-width: 118.16px;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: ${(props) => lighten(0.05, props.chatButtonColor)};
      }

      .LPMcontainer {
        margin: 0 !important;
      }
      .chat-btn {
        background-color: ${(props) => props.chatButtonColor};
        border: 0;
        color: #fff;
        cursor: pointer;
        padding: 5px 25px;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: ${(props) => lighten(0.05, props.chatButtonColor)};
        }
      }
    }
  }
  .logo {
    transform: unset;
    width: 180px;
    ${above.md`
      transform: translateY(50%);
      width: unset;
    `};
  }
`

CNHeader.propTypes = {
  chatButtonColor: PropTypes.string.isRequired,
  chatIDForHeader: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
}
