// Node Module Imports
import React from 'react';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';

// Asset Imports
import Logo from '../Logo';

// Style Imports
import { above, colors } from '../../styles/utilities';
import { NarrowContainer, UnderlinedAGa } from '../../styles/elements';

const HeaderLogoLink = ({className, linkInfo, templateStyle}) => (
  <div className={className}>
    <NarrowContainer>
      <Logo />
      {templateStyle === 2 ? (
        <UnderlinedAGa className="ga-portal" gaLabel="header" href={linkInfo.href} color={colors.umaDarkBlue} htmlTarget="_blank" htmlRel="noopener noreferrer">{linkInfo.text}<FaChevronRight /></UnderlinedAGa>
      ) : (
        <span className="black right-side">Call Admissions <span className="nowrap"><a className="black phone ga-call" href="tel:877-205-6225" ga-label="header">877-205-6225</a></span></span>
      )}
    </NarrowContainer>
  </div>
);

export default styled(HeaderLogoLink)`
  background-color: ${colors.umaUltimateLightGray};
  padding: .5rem 0;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-side {
      text-align: right;
      font-size: .7rem;
      padding-left: 1rem;
      ${above.sm`
        font-size: 1rem;
      `}
      .black {
        color: #000;
        text-decoration: none;
      }
    }
    a {
      color: ${colors.umaDarkBlue};
      svg {
        color: ${colors.umaOrange};
        transform: translateY(.1rem);
      }
    }
  }
`; 