import { store } from '../redux';
import { ElementWatcher } from '../utilities';
import { initService, addOrUpdateGlobal } from '../redux/actions';

/*
 |----------------------------------------------------------
 | LeadIdService
 |----------------------------------------------------------
 | The LeadIdService is responsible for adding the lead id 
 | vendor script to the page, watching the leadid_token
 | input value for a change, and updating the redux 
 | store when the value changes.
 |
 | Because this service interacts with the DOM, it can only
 | be run after a component mounts.
 |
 */
class LeadIdService {


	/**
	 * constructor
	 * 
	 * The LeadIdService is initialized with an Element that 
	 * you'd like to monitor for a change.
	 * 
	 * @param {Element} element
	 * @return {void}
	 */
	constructor(element) {
		this.element = element;
		this.elementWatcher = new ElementWatcher();
	}
	
	
	/**
	 * boot
	 * 
	 * When boot is called, we begin watching the supplied
	 * element ONLY if the leadId hasn't already been 
	 * provided. We only need to do this once.
	 * 
	 * @param {void}
	 * @return {void}
	 */
  boot() {
		store.dispatch(initService('LEADSOURCE'));
		this.elementWatcher.watch(this.element, 'input');
		this.elementWatcher.onUpdate(event => this.startLeadIdService()).boot();
	}


	/**
	 * startLeadIdService
	 * 
	 * Being watching the leadid_token input for a change. If
	 * The leadid vendor script isn't on the page yet, then 
	 * we need to add it. 
	 * 
	 * @param {void}
	 * @return {void}
	 */
	startLeadIdService() {

		let leadSourceWatcher = new ElementWatcher();
		leadSourceWatcher.watch(document.querySelector('input[name="leadid_token"]'), 'mutation');
		leadSourceWatcher.onUpdate(event => {
			store.dispatch(addOrUpdateGlobal('lead_id', event.target.value));
		}).boot();

		// if the lead id vendor script isn't already on the 
		// page, then we call it in
    if(!document.getElementById('LeadiDscript_campaign')) {
		  var s = document.createElement('script');
		  s.id = 'LeadiDscript_campaign';
		  s.async = true;
		  s.defer = true;
		  s.src = (document.location.protocol + '//d1tprjo2w7krrh.cloudfront.net/campaign/3ad8c5fe-ecf8-3114-eca7-71d5b58f820f.js');
		  var body = document.querySelector('body');
			body.appendChild(s);
		}
	}
	
}

export { LeadIdService };