// Node Module Imports
import React, { Component } from "react";
import { graphql } from 'gatsby';
import { mergeWith } from 'lodash';

// Component Imports
import Bricks from '../components/reusables/Bricks';
import CNContentTop from './chat-now/CNContentTop';
import CNHeader from './chat-now/CNHeader';
import HeroGlobal from '../components/HeadersAndHeroes/HeroGlobal';
import Layout from '../components/Layout';
import { colors } from '../styles/utilities';
import RememberTraceParams from '../components/effects/RememberTraceParams';

export default class TemplateCallNow extends Component {

  constructor(props) {
    super(props);
    this.defaults = {

      headerBtnColor: colors.umaGreen,
      mobileBtnColor: colors.umaGreen,

      general_settings: {
        chat_id_header: '',
        chat_id_sidebar: '', 
        chat_id_mobile: ''
      },

      sidebar: {
        chat_box_color: {
          value: colors.umaDarkBlue
        },
        chat_button_color: {
          value: colors.umaGreen
        }
      }

    };
  }

  overwriteIfNotNull(objValue, srcValue) {
    if(!!srcValue) {
      return srcValue;
    }
    return objValue;
  }

  render() {

    const page = this.props.data.wordpressPage;
    const bricks = this.props.data.allWordpressUmaBricks.edges;
    const brickSections = this.props.pageContext.brickSections;
    const selectedCampus = page.acf.uma_program_association.campus.label;
    const wpSidebar = page.acf.uma_template_call_now.sidebar;
    const sidebar = mergeWith({}, this.defaults.sidebar, wpSidebar, this.overwriteIfNotNull);
    const genset = page.acf.uma_template_call_now.general_settings;
    const general_settings = mergeWith({}, this.defaults.general_settings, genset, this.overwriteIfNotNull);

    const { 
      hero,
      tracking 
    } = page.acf.uma_template_call_now;

    let headerBtnColor = general_settings.header_button_color_override && general_settings.header_button_color_override.value;
    if(!headerBtnColor) {
      if(sidebar && sidebar.chat_button_color && sidebar.chat_button_color.value) {
        headerBtnColor = sidebar.chat_button_color.value;
      }
      else {
        headerBtnColor = this.defaults.headerBtnColor;
      }
    }
    
    let mobileBtnColor = general_settings.mobile_hero_button_color_override && general_settings.mobile_hero_button_color_override.value;
    if(!mobileBtnColor) {
      if(sidebar && sidebar.chat_button_color && sidebar.chat_button_color.value) {
        mobileBtnColor = sidebar.chat_button_color.value;
      }
      else {
        mobileBtnColor = this.defaults.mobileBtnColor;
      }
    }

    return (
      <Layout
        seo={page.acf.uma_page_settings_seo}
        showDisclaimer={true}
        sticky={page.acf.uma_page_settings_sticky_footer}
        phone={page.acf.uma_template_call_now.tracking.phone_number}>
        <RememberTraceParams whichForm="sparkroom" />
        <CNHeader
          chatButtonColor={headerBtnColor}
          chatIDForHeader={general_settings.chat_id_header}
          phone={tracking.phone_number}
          template="call-now" />
        <HeroGlobal
          bgColorLeft={hero.bg_color_left}
          bgColorRight={hero.bg_color_right}
          displayLogo={true}
          hero={hero}
          imgBig={hero.image_big}
          imgSmall={hero.image_small}
          pageClass={this.props.pageContext.pageClass}
          template="chat-now" />
        <CNContentTop
          chatBox={sidebar}
          chatButtonColor={mobileBtnColor}
          chatIDForChatBox={general_settings.chat_id_sidebar}
          chatIDForMobileContent={general_settings.chat_id_mobile}
          general={general_settings}
          phone={tracking.phone_number}
          content={page.content}
          template="call-now"
          pageClass={this.props.pageContext.pageClass}
          doNotMoveChatBox={page.wordpress_id === 824 ? true : false} />
        <Bricks
          queriedBricks={bricks}
          programs={this.props.data.allWordpressUmaPrograms.edges}
          selectedCampus={selectedCampus} 
          brickSections={brickSections} />
      </Layout>
    )
  }
};

export const query = graphql`
  query($id: String!, $brickIds: [Int]!, $programs: [String]) {

    wordpressPage(id: {eq: $id}) {
      wordpress_id
      title
      content
      acf {
        uma_program_association {
          campus {
            label
          }
        }
        uma_page_settings_sticky_footer {
          display_sticky_footer
          chat_button_id_select {
            label
            value
          }
          chat_button_id
        }
        uma_page_settings_seo {
          page_title
          meta_description
        }
        uma_template_call_now {
          general_settings {
            header_button_color_override {
              value
            }
            mobile_hero_button_color_override {
              value
            }
          }
          hero {
            bg_color_left
            bg_color_right
            image_small {
              source_url
              alt_text
              title
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth:1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            image_big {
              source_url
              alt_text
              title
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          sidebar {
            heading
            disclaimer
            chat_box_color{
              label
              value
            }
            chat_button_color {
              label
              value
            }
          }
          tracking {
            vendor
            lead_source
            phone_number
          }
        }
      }
    }
    allWordpressUmaBricks(filter: {wordpress_id: {in: $brickIds}}) {
      edges {
        node {
          wordpress_id
          post_title
          acf {
            uma_brick {
              type {
                label
                value
              }
            }
            uma_brick_fact_quote {
              fact
              quote
              quote_author
            }
            uma_brick_programs {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth:1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            uma_brick_tout {
              icon {
                localFile {
                  childImageSharp {
                    fixed(quality:100, width:125) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              name
              text
            }
            uma_brick_video {
              poster {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              video_embed_code
            }
            uma_brick_programs_list_degree_diploma {
              text
            }
            uma_brick_wysiwyg
            uma_brick_image_box {
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth:1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allWordpressUmaPrograms(filter: {acf: {uma_program_info: {code: {in: $programs}}}}) {
      edges {
        node {
          acf {
            uma_program_info {
              campus {
                value
              }
              full_name
              type
              classes_in_program {
                class_name
              }
              campus {
                value
              }
              links {
                link_key
                page_link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;