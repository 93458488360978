import React, { useContext } from 'react'

import cx from 'classnames'
import { FaBars, FaPhone } from 'react-icons/fa'
import styled from 'styled-components'

import logo from '../../../images/logos/rectangle-logo-with-r.png'
import { colors } from '../../../styles/utilities'
import { ModalContext } from '../../lib/context/ModalContext'
import { formatPhoneNumber } from '../../lib/util/format'
import ModalCTA from '../ctas/ModalCTA'
import Modal from '../modals/Modal'

export type HeaderThreeCTAProps = {
  /** The liveperson chat ID. */
  chatID: string
  /** The classname for this header. */
  className?: string
  /** Override the mobile button for this header. The default is a hamburger icon. */
  mobileButtonOverride?: () => React.ReactElement
  /** The content inside the modal. */
  modal: () => React.ReactElement
  /** The phone number to display in this header. */
  phoneNumber: string
}

const HeaderThreeCTA = ({
  chatID,
  className,
  mobileButtonOverride,
  modal,
  phoneNumber,
}: HeaderThreeCTAProps) => {
  const { toggleModal } = useContext(ModalContext)

  const localModal = () => <Modal>{modal()}</Modal>

  return (
    <div className={cx(className)}>
      <div className={cx('bg-gray-d', 'fff', 'd-none', 'd-md-block')}>
        <div
          className={cx(
            'container d-flex justify-content-end align-items-center',
          )}
        >
          <div className="phone menu-item px-3">
            <a href={`tel:${formatPhoneNumber(phoneNumber).stripped}`}>
              <FaPhone className="mr-2" />
              {formatPhoneNumber(phoneNumber).label}
            </a>
          </div>
          <div className="chat menu-item px-3">
            <div id={chatID} className="chat-btn" />
          </div>
          <ModalCTA className="menu-item menu-item--lg" modal={modal} />
        </div>
      </div>
      <div className="bg-gray-u logo-wrapper">
        <div className="container d-flex justify-content-between align-items-center">
          <img alt="Ultimate Medical Academy" src={logo} />
          {mobileButtonOverride && mobileButtonOverride()}
          {!mobileButtonOverride && (
            <button
              type="button"
              className="fa-bars-button d-md-none"
              onClick={() => toggleModal(localModal)}
            >
              <FaBars className="fa-bars" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

HeaderThreeCTA.defaultProps = {
  className: '',
  mobileButtonOverride: undefined,
}

export default styled(HeaderThreeCTA)`
  .fa-bars-button {
    background: none;
    color: ${colors.umaDarkGray};
  }
  .menu-item a {
    color: #fff;
    text-decoration: none;
  }
  .menu-item .chat-btn {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-align: center;
    // explicit width so the liveperson chat button doesn't come in and
    // cause layout shift
    width: 85px;

    [data-lp-event='click'] {
      cursor: pointer;
    }
  }

  .menu-item {
    font-size: 1rem;
  }
  .menu-item--lg {
    font-size: 1.5rem;
  }

  .logo-wrapper {
    padding: 0.25rem 0;
  }

  .logo-wrapper {
    button {
      border: 0;
      cursor: pointer;
    }
  }

  .fa-bars {
    font-size: 1.5rem;
  }
`
