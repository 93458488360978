/* eslint-disable import/prefer-default-export */

export function getCookie(cookieName: string): string {
  const cookie = {}
  document.cookie.split(';').forEach((el) => {
    const [key, value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie?.[cookieName] ? cookie[cookieName] : ''
}

export function getGAClientID(): string {
  const fullClientID = getCookie('_ga')
  const clientID = fullClientID?.split('.')?.splice(2).join('.')
  return clientID
}
