import React from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { below } from '../../styles/utilities/mediaQueries'
import { makeid } from '../lib/util/strings'

export type ContentAndFormProps = {
  className?: string
  content: () => React.ReactNode
  form: () => React.ReactNode
  touts: {
    desktopColumnCount: number
    components: (() => React.ReactNode)[]
  }
}

const ContentAndForm = ({
  className,
  content,
  form,
  touts,
}: ContentAndFormProps) => (
  <div
    className={cx(className, 'container', 'd-flex', 'content-and-form-wrapper')}
  >
    <div className="container content-container order-2 order-lg-1 p-lg-0">
      <div className="row d-flex">
        <div className="col-12">
          <div className="row">
            <div className="col-12">{content()}</div>
          </div>
          <div className="row">
            {touts.components.map((tout, idx) => (
              <div
                key={makeid()}
                className={cx(
                  'col-6',
                  `col-lg-${touts.desktopColumnCount}`,
                  {
                    'mt-4': idx === 0,
                  },
                  'text-center',
                )}
              >
                {tout()}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="container form-container order-1 order-lg-2 p-0">
      <div className="row m-0">
        <div className="col-12 col-lg-10 offset-lg-2 p-0">{form()}</div>
      </div>
    </div>
  </div>
)

ContentAndForm.defaultProps = {
  className: '',
}

export default styled(ContentAndForm)`
  &.content-and-form-wrapper {
    ${below.md`
      flex-wrap: wrap;
    `}
    ${below.lg`
      padding: 0;
      margin: 0;
      max-width: 100%;
    `}
  }

  .form-container {
    ${below.md`
      max-width: 100%;
      flex: 0 0 100%;
    `}
    ${below.lg`
      padding: 0;
    `}
  }

  .content-container {
    ${below.md`
      flex: 0 0 100%;
    `}
  }
`
