
const hiddenFormParamsAction = {}

hiddenFormParamsAction.actions = {
  UPDATE_MULTIPLE: 'UPDATE_HIDDEN_FORM_PARAMS',
  UPDATE_SINGLE: 'UPDATE_HIDDEN_FORM_PARAM'
}

hiddenFormParamsAction.updateMultiple = function(params) {
  return {
    type: this.actions.UPDATE_MULTIPLE,
    payload: [...params]
  }
}

hiddenFormParamsAction.updateSingle = function(key, value) {
  return {
    type: this.actions.UPDATE_SINGLE,
    payload: {key: key, value: value}
  }
}

export default hiddenFormParamsAction