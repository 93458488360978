// Node Module Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import parse from 'react-html-parser';
import { Container } from 'react-bootstrap';
import { lighten } from 'polished';
import { FaTimes } from 'react-icons/fa';

// Style Imports
import { colors } from '../../styles/utilities';

// Utility Imports
import { shortcodeParser } from '../../utilities';

const Tout = ({associatedPrograms, className, fields, template}) => {
  
  const icon = fields.uma_brick_tout.icon.localFile.childImageSharp.fixed;
  const { name, text } = fields.uma_brick_tout;
  const [ showInfo, setShowInfo ] = useState(false);

  const renderClasses = () => (
    associatedPrograms && (
      <ul>
        {
          associatedPrograms.map(program => (
            program.node.acf.uma_program_info.classes_in_program &&
            program.node.acf.uma_program_info.classes_in_program.map((cl, idx) => (
              <li key={idx}>{cl.class_name}</li>
            ))
          ))
        }
      </ul>
    )
  );

  const showToutInfo = function(e) {
    setShowInfo(true);
  };

  const hideToutInfo = function(e) {
    setShowInfo(false);
  };
  
  if(template === 'default') {
    return (
      <div className={`${className} template--${template}`}>
        <Container className="tout__wrap">
          <div className="tout__item tout__item--icon">
            <Img fixed={icon} alt="" />
          </div>
          <div className="tout__item tout__item--text">
            <h2>{name}</h2>
            <div>{parse(shortcodeParser(text))}</div>
            {
              typeof fields.uma_brick_tout.name !== undefined
              && fields.uma_brick_tout.name === 'Classes You Would Take'
                ? renderClasses()
                : null
            }
          </div>
        </Container>
      </div>
    )
  }

  else if(template === 'info2ProgramSingle') {
    return (
      <div className={`${className} template--${template}`}>

        <button onClick={showToutInfo}>
          <Container className="tout__wrap">
            <div className="tout__item tout__item--icon">
              <Img fixed={icon} alt="" />
            </div>
            <div className="tout__item tout__item--text">
              <h2>{name}</h2>
            </div>
          </Container>
        </button>

        {
          showInfo && (
          <div className="tout__info">
            <div className="tout__info__close-wrap">
              <button className="tout__info__close" onClick={hideToutInfo}>
                <FaTimes />
              </button>
            </div>
            <div
              className="tout__item tout__item--content"
              data-active={showInfo}
            >
              <div className="tout__item tout__item--icon">
                <Img fixed={icon} alt="" />
              </div>
              <div className="tout__item tout__item--text">
                <h2>{name}</h2>
                <div>{parse(shortcodeParser(text))}</div>
                {
                  typeof fields.uma_brick_tout.name !== undefined
                  && fields.uma_brick_tout.name === 'Classes You Would Take'
                    ? renderClasses()
                    : null
                }
              </div>
            </div>
          </div>
          )
        }

      </div>
    );
  }

  return null;
}

export default styled(Tout)`
  color: ${props => props.layout === 'checkerboard' ? `${props.bgColor}` : props.bgColor === '#FFFFFF' ? `${colors.umaDarkGray}` : '#fff'};
  background: ${props => `${props.bgColor}`};

  h2 {
    color: ${props => props.bgColor === '#FFFFFF' ? `${colors.umaDarkGray}` : '#fff' };
  }

  img {
    width: 30px;
  }
  &.checkerboard {
    background-image: ${props => `linear-gradient(to right, ${props.bgColor}, ${props.bgColor} 50%, #fff 50%, ${props.bgColor} 50%, #fff 50%)`};
    color: ${props => props.layout === 'checkerboard' ? `${props.bgColor}` : props.bgColor === '#FFFFFF' ? `${colors.umaDarkGray}` : '#fff'};
    h2 {
      color: ${props => props.layout === 'checkerboard' ? `${props.bgColor}` : props.bgColor === '#FFFFFF' ? `${colors.umaDarkGray}` : '#fff'};
    }
    &.idx-1, &.idx-3, &.idx-5, &.idx-7 {
      background-image: ${props => `linear-gradient(to right, #fff, #fff 50%, ${props.bgColor} 50%, #fff 50%, ${props.bgColor} 50%)`};
    }
  }

  &.template--info2ProgramSingle {
    button {
      &:active,
      &:focus,
      &:hover {
        background: ${props => lighten(0.05, props.bgColor)};
      }
    }
    .tout__info {
      background-color: ${props => `${props.bgColor}`};
    }
  }

  .tout__info__close {
    svg {
      fill: white;
    }
  }
`;

Tout.propTypes = {
  associatedPrograms: PropTypes.array.isRequired,
  bgColor: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
  template: PropTypes.string
}

Tout.defaultProps = {
  template: 'default'
}