/* eslint-disable import/prefer-default-export */
export enum UMAPhoneNumber {
  Admissions = '8882134473',
  Advisor = '8554546841',
  Alumni = '8882160535',
  CareerServices = '8883158211',
  Clearwater = '7272988685',
  DisabilityServices = '8883331454',
  Employers = '8773066185',
  FinancialAid = '8882125421',
  FormDisclaimer = '8445904692',
  Media = '8136766175',
  Online = '8882052510',
  Operations = '8138982588',
  Registrar = '8772418786',
  WorkHere = '8136058400',
}
