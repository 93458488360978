// Node Module Imports
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Component Imports
import DefaultLayout from '../layouts/DefaultLayout'

// Style Imports
import { above, colors } from '../styles/utilities'

// Asset Imports
import downloadTheGuide from '../images/relevancy/mobile/download-the-guide.png'
import LogoRelevancy from '../components/LogoRelevancy'
import HHSGuide from '../images/relevancy/common/hhs-guide.jpg'
import chevPatternPurple from '../images/relevancy/1/common/pattern-chev-purple.png'

// Utility Imports
import { stripPhoneNumber } from '../utilities'
import useGa from '../hooks/useGa'
import QueryStringParser from '@ultimatemedical/uma-utils/dist/query-string/parser'

const ConfirmationHCGForHHS = ({ className }) => {
  const year = new Date().getFullYear()
  const submitEvent = useGa()

  const [phoneNumber, setPhoneNumber] = useState('888-202-2877')

  useEffect(() => {
    const qsParser = new QueryStringParser(window.location.search)
    setPhoneNumber(qsParser.get('phone', phoneNumber))
  }, [phoneNumber])

  return (
    <DefaultLayout>
      <div className={className}>
        <div className="header">
          <LogoRelevancy />
          <img
            src={HHSGuide}
            alt="Ultimate Medical Academy's Healthcare Career Guide"
          />
          <a
            href="/pdfs/HHS-Career-Guide.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="ga-guide"
            onClick={() => {
              submitEvent({
                category: 'download guide',
                action: 'click',
                label: 'download guide - Learn',
              })
            }}
          >
            <img
              src={downloadTheGuide}
              alt="Download the Healthcare Career Guide. Click here."
            />
          </a>
        </div>
        <div className="one-step-closer">
          <p>
            You're now one step closer to discovering career options within the
            healthcare industry. Should you have any questions, please reach out
            to our team at{' '}
            <span className="nowrap">
              <a
                className="ga-call"
                ga-label="confirmation-next-step"
                href={`tel:${stripPhoneNumber(phoneNumber)}`}
              >
                {phoneNumber}
              </a>
              .
            </span>
          </p>
        </div>
        <div className="about-uma-outer">
          <div className="about-uma-inner">
            <p>
              <strong>About UMA</strong>
            </p>
            <p>
              Ultimate Medical Academy educates students for careers in
              healthcare that can make a difference in their lives, and the
              lives of others. We are a dynamic educational institution
              committed to equipping and empowering students to excel in
              healthcare careers.
            </p>
            <p>
              UMA is proud to have supported more than 72,000 students through
              graduation.
            </p>
            <small>
              © {year} UMA Education, Inc. d/b/a Ultimate Medical Academy |{' '}
              <a
                href="https://www.ultimatemedical.edu/about/citations/"
                className="td-n-hu"
              >
                Marketing Statistics
              </a>{' '}
              |{' '}
              <a
                href="https://www.ultimatemedical.edu/privacy-policy/"
                className="td-n-hu"
              >
                Privacy Policy
              </a>
            </small>
            <br />
            <br />
            <small>
              Ultimate Medical Academy does not recruit or enroll residents of
              the European Union.
            </small>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default styled(ConfirmationHCGForHHS)`
  .header {
    background: url('${chevPatternPurple}');
    text-align: center;
    padding: 2rem 0;
    [class^='LogoRelevancy'] {
      margin-bottom: 2rem;
    }
    img {
      display: block;
      margin: auto;
    }
  }
  .one-step-closer {
    margin: 3rem calc(100% / 12);
    p {
      color: ${colors.umaDarkGray};
      font-size: 2rem;
      line-height: 2.2rem;
    }
  }
  .about-uma-outer {
    .about-uma-inner {
      margin: 0 calc(100% / 12);
      padding: 2rem 0;
      border-top: 1px solid ${colors.umaDarkGray};
      ${above.xl`
          max-width: 1200px;
          margin: auto;
      `}
    }
    p {
      color: ${colors.umaDarkGray};
      font-size: 1.5rem;
      line-height: 1.7rem;
      margin: 1rem 0;
    }
  }
`
