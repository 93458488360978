import React from 'react'

import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import { above } from '../../../../styles/utilities'
import heroImg from '../../../../v3/assets/images/heroes/hero-chevron/lady-desk-phone-folder.jpg'
import Accreditation from '../../../../v3/components/bricks/Accreditation'
import ModalCTA from '../../../../v3/components/ctas/ModalCTA'
import HollyFooter from '../../../../v3/components/footers/HollyFooter'
import StickyMobileFooter from '../../../../v3/components/footers/StickyMobileFooter'
import HeaderThreeCTA from '../../../../v3/components/headers/HeaderThreeCTA'
import HeroChevron from '../../../../v3/components/heroes/HeroChevron'
import EngagingOnlineLearning from '../../../../v3/components/touts/EngagingOnlineLearning'
import FinancialAid from '../../../../v3/components/touts/FinancialAid'
import JobSearchAssistance from '../../../../v3/components/touts/JobSearchAssistance'
import UMAOnlinePrograms from '../../../../v3/components/touts/UMAOnlinePrograms'
import Chevron, {
  ChevronDirection,
} from '../../../../v3/components/uma-svgs/Chevron'
import BrandContent from '../../../../v3/content/Brand'
import Layout from '../../../../v3/layouts/Base'
import ContentAndForm from '../../../../v3/layouts/ContentAndForm'
import StudentForm from '../../../../v3/lib/components/forms/student/StudentForm'
import { FieldOptionsWithID } from '../../../../v3/lib/hooks/useForm'
import { SparkroomFieldName } from '../../../../v3/lib/util/sparkroom'
import { UMACampusCode } from '../../../../v3/lib/util/uma'
import {
  lightBackgroundTheme,
  placeholderTheme,
} from '../../../../v3/themes/forms'

type V3IndexProps = {
  className: string
}

const V3Index = ({ className }: V3IndexProps) => {
  // page constants
  const chatID = 'LP_DIV_lead_nurturing_3'
  const phoneNumber = '877-322-9754'

  // the form's success action
  const formAction = (leadID: string) => {
    navigate(`/confirmation?phone=${phoneNumber}&lid=${leadID}`)
  }

  // the form's button (need to override because of custom styling on this LP)
  const formButton = ({ isSubmitting }) => (
    <button
      type="submit"
      disabled={isSubmitting}
      className="d-flex align-items-center justify-content-center position-relative"
    >
      <Chevron color="#fff" direction={ChevronDirection.Right} width="18px" />
      &nbsp;Request Info
    </button>
  )

  const fields: FieldOptionsWithID[] = [
    {
      id: SparkroomFieldName.FirstName,
      label: '',
      markRequired: false,
      placeholder: 'First Name',
    },
    {
      id: SparkroomFieldName.LastName,
      label: '',
      markRequired: false,
      placeholder: 'Last Name',
    },
    {
      id: SparkroomFieldName.Email,
      label: '',
      markRequired: false,
      placeholder: 'Email',
    },
    {
      id: SparkroomFieldName.Phone,
      label: '',
      markRequired: false,
      placeholder: 'Phone',
    },
    {
      hidden: false,
      id: SparkroomFieldName.State,
      label: '',
      markRequired: false,
      placeholder: 'State',
      type: 'select',
      value: '',
    },
    {
      id: SparkroomFieldName.Campus,
      value: UMACampusCode.Online,
    },
  ]

  const modalForm = () => (
    <div className="modal-form">
      <div className="form-heading">
        <h2 className="m-0 black">
          Learn how we can
          <br />
          help you succeed.
        </h2>
        <p className="mt-2 black">All fields required.</p>
      </div>
      <StudentForm
        action={formAction}
        button={formButton}
        fields={fields}
        themes={[placeholderTheme, lightBackgroundTheme]}
      />
    </div>
  )

  return (
    <>
      <Layout className={className}>
        <Helmet>
          <title>UMA Online</title>
          <link rel="stylesheet" href="https://use.typekit.net/ipy7bjo.css" />
        </Helmet>

        <HeaderThreeCTA
          chatID={chatID}
          mobileButtonOverride={() => (
            <ModalCTA className="d-md-none" modal={() => modalForm()} />
          )}
          modal={() => modalForm()}
          phoneNumber={phoneNumber}
        />

        <HeroChevron imageSource={heroImg}>
          <h1>Healthcare Career Training</h1>
        </HeroChevron>

        <ContentAndForm
          content={() => <BrandContent className="content pt-5 li--chevron" />}
          form={() => (
            <div className="desktop-form">
              <div className="bg-blue-d py-4 px-3">
                <div className="form-heading">
                  <h2 className="m-0">
                    Learn how we can
                    <br />
                    help you succeed.
                  </h2>
                  <p className="mt-2">All fields required.</p>
                </div>
                <StudentForm
                  action={formAction}
                  button={formButton}
                  fields={fields}
                  themes={[placeholderTheme]}
                />
              </div>
            </div>
          )}
          touts={{
            components: [
              () => <UMAOnlinePrograms />,
              () => <JobSearchAssistance />,
              () => <FinancialAid />,
              () => <EngagingOnlineLearning />,
            ],
            desktopColumnCount: 3,
          }}
        />

        <Accreditation className="accreditation" />

        <HollyFooter />
      </Layout>
      {/* <StickyMobileFooter chatID={chatID} phoneNumber={phoneNumber} /> */}
    </>
  )
}

export default styled(V3Index)`
  .hero-content-wrap {
    align-items: start !important;
    ${above.lg`align-items: center !important;`}
  }

  .hero-chevron {
    .bg-img-wrap {
      right: -358px;
      ${above.md`right: -200px;`}
      ${above.lg`right: -100px;`}
      ${above.xl`right: 0;`}
    }
  }

  h1 {
    font-family: jubilat, sans-serif;
    font-size: 2.4rem;
    font-style: italic;
    line-height: 1;

    ${above.md`font-size: 2.75rem;`}
    ${above.lg`font-size: 4rem;`}
    ${above.xl`font-size: 5rem;`}
  }

  .content p,
  .content ul,
  .content li {
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .accreditation {
    font-weight: bold;
  }

  .form-heading {
    margin: 0 auto;
    max-width: 18rem;
    text-align: center;
  }

  .desktop-form {
    ${above.lg`transform: translateY(-6rem);`}
    .form-heading, .form-heading h2 {
      color: #fff;
    }
    .disclaimer {
      color: #fff;
    }
  }

  button[type='submit'] {
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 500;
  }
`
