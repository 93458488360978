import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { above } from '../../styles/utilities';
import HeroScalableLogo from './HeroScalableLogo';

const HeroScalable = ({
  bgColorLeft,
  bgColorRight,
  className,
  displayLogo,
  imgBig,
  imgSmall,
  template
}) => {
  return (
    <section className={`${className}`}>
      { displayLogo && <HeroScalableLogo heroType="scalable-constrained" template={template} /> }
      <Img 
          className="img-small"
          fluid={imgSmall.localFile.childImageSharp.fluid}
          alt={imgSmall.alt_text}
          placeholderStyle={{
            display: 'none'
          }}
          durationFadeIn={250}
        />
      <div className="container">
        <Img 
          className="img-big"
          fluid={imgBig.localFile.childImageSharp.fluid}
          alt={imgBig.alt_text}
          placeholderStyle={{
            display: 'none'
          }}
          durationFadeIn={250}
        />
      </div>
    </section>
  )
}

HeroScalable.propTypes = {
  bgColorLeft: PropTypes.string.isRequired,
  bgColorRight: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  imgBig: PropTypes.object.isRequired,
  imgSmall: PropTypes.object.isRequired,
  template: PropTypes.string.isRequired
}

export default styled(HeroScalable)`

  background: linear-gradient(90deg, ${ props => props.bgColorLeft } 25%, ${ props => props.bgColorRight } 75%);
  position: relative;

  .img-big {
    display: none;
  }

  ${above.md`
    .img-small {
      display: none;
    }
    .img-big {
      display: block;
    }
  `};

`;