import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import HeroScalableLogo from './HeroScalableLogo';
import { above, colors } from '../../styles/utilities';
import parse from 'react-html-parser';
import { shortcodeParser } from '../../utilities';
import { NarrowContainer } from '../../styles/elements';

const HeroBackgroundImage = ({
  className, hero, imgBig, 
  imgSmall, pageClass, template
}) => {
  return (
    <div className={`${className} ${pageClass || ''}`}>
      <div className="hero__bg">
        <Img fluid={imgSmall.localFile.childImageSharp.fluid} 
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
          }}
          className="hero-bg--mobile" 
          placeholderStyle={{
            display: 'none'
          }}
          objectFit={`cover`}
          durationFadeIn={250}
        />
        <Img fluid={imgBig.localFile.childImageSharp.fluid} 
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
          }}
          className="hero-bg--desktop" 
          placeholderStyle={{
            display: 'none'
          }}
          objectFit={`cover`}
          durationFadeIn={250}
        />
      </div>
      {pageClass === 'use-text-shadow' && <div className="burn"></div>}
      <NarrowContainer className="hero__content">
        <HeroScalableLogo heroType="bg-image" template={template} />
        <div className="main-heading">


          <h2>
            {
              hero.hero_text_bold &&
              <em>{parse(shortcodeParser(hero.hero_text_bold))} </em>
            }
            {
              <span>{parse(shortcodeParser(hero.hero_text_not_bold))}</span>
            }
          </h2>


        </div>
      </NarrowContainer>
    </div>
  )
}

HeroBackgroundImage.propTypes = {
  className: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired
}

export default styled(HeroBackgroundImage)`

  display: flex;
  position: relative;

  .hero__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    .hero-bg--mobile {
      ${above.sm`
        display: none;
      `}
    }

    .hero-bg--desktop {
      display: none;

      &:after {
        display: none;
      }

      ${above.sm`
        display: block;
      `}
    }

  }

  .hero__content {
    position: relative;

    .logo-phone {
      align-items: center;
      color: #000;
      padding: 1rem;
      text-align: left;

      ${above.sm`
        display: flex;
        justify-content: space-between;
      `}

      .phone-number {
        background: hsla(0,0%,100%,0.4);
        padding: .5rem;
        font-size: 1.2rem;
        border-radius: 5px;
        display: none;
        ${above.sm`
          display: block;
        `}
      }
      
      .fa-phone {
        margin-right: .4em;
      }
    }

    .main-heading, .mobile-heading {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 6rem 0;
      text-align: left;

      h2 {
        color: ${colors.umaDarkBlue};
        font-size: 1.7rem;
        font-weight: 200;
        margin: 0;
        max-width: 60%;

        ${above.sm`
          font-size: 2.375rem;
          max-width: 51%;
        `}

        ${above.lg`
          font-size: 4rem;
        `}

        em {
          font-size: 1.9rem;
          font-weight: 600;
          ${above.sm`
            font-size: 2.5rem;
          `}
          ${above.lg`
            font-size: 4rem;
          `}
        }

        small {
          display: inline-block;
          font-size: 1.2rem;
          margin-top: 0.9rem;
          vertical-align: top;
        }

      }
    }
  }

  &.use-text-shadow {

    .hero__content h2 {
      color: white;
      font-family: "Univers LT W01 65 Bold",Arial;
      font-size: 3.75rem;
      font-weight: 700;
      max-width: 100%;
      text-shadow: 1px 1px 2px hsla(0, 0%, 0%, 0.8), 2px 2px 6px hsla(0, 0%, 0%, 0.2);
      width: 100%;

      ${above.lg`
        max-width: 60%;
      `};
    }

    .burn {
      background: radial-gradient(circle at bottom left, hsla(0, 0%, 0%, 0.5), transparent);
      content: '';
      display: block;
      position: absolute;
        top: 0; bottom: 0; right: 0; left: 0;
    }
  }
`;