import { uma } from '../../types'

class UMAInitialState implements uma.umaState {

  campus: uma.campusEnum
  phone: string

  constructor() {
    this.campus = uma.campusEnum.All
    this.phone = "888-205-2510"
  }
}

export default new UMAInitialState