import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { above } from '../styles/utilities';

const Logo = ({className}) => (
  <div className={`${className}`}>
    <StaticQuery
      query={graphql`
      {
        file(sourceInstanceName: {eq: "images"}, name: {eq: "rectangle-logo-with-r"}) {
          childImageSharp {
            fluid(maxWidth: 250, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      `}
      render={data => {
        if ( data.file.childImageSharp !== null ) {
          return (
            <Img
              loading="eager"
              className="img--logo"
              fluid={data.file.childImageSharp.fluid}
              style={{
                display: 'inline-block',
                width: '250px',
                padding: '0'
              }}
              placeholderStyle={{
                display: 'none'
              }}
            />
          )
        }
        return null;
      }}
    />
  </div>
);

export default styled(Logo)`
      
  font-size: 0;
  line-height: 0;

  .img--logo {
    max-width: 180px;
    min-width: 180px;
    position: relative;
  }

  ${above.lg`
    .img--logo {
      max-width: 250px;
      min-width: 250px;
    }
  `}
`