import React from 'react'

import { lighten } from 'polished'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import parse from 'react-html-parser'
import styled from 'styled-components'

import useGa from '../../hooks/useGa'
import { NarrowContainer } from '../../styles/elements'
import { colors, above } from '../../styles/utilities'
import { shortcodeParser } from '../../utilities'
import CNChatBox from './CNChatBox'

const CNContentTop = ({
  chatButtonColor,
  chatIDForChatBox,
  chatIDForMobileContent,
  className,
  content,
  chatBox,
  chatText,
  chatTextColor,
  doNotMoveChatBox,
  id,
  markdownContent,
  moveMobileChatCallButtonDown,
  pageClass,
  phone,
  renderContent,
  sidebarTextColor,
  template,
  useRenderPropForContent,
}) => {
  const submitEvent = useGa()

  return (
    <div className={className}>
      {
        // eslint-disable-next-line
        template === 'call-now' ? (
          // eslint-disable-next-line
          <a
            id={id}
            className="btn-text ga-call d-md-none chat fb-track-click"
            href={`tel:${phone}`}
            onClick={() => {
              submitEvent({
                action: 'click',
                category: 'call',
                label: 'click to call - mobile hero - learn',
              })
            }}
          >
            Call Us
          </a>
        ) : (
          <button
            // id={chatIDForMobileContent}
            className="chat ga-chat chat-launch"
            onClick={() => {
              if (window.Genesys) window.Genesys('command', 'Messenger.open')
              submitEvent({
                action: 'click',
                category: 'chat',
                label: 'click to chat - mobile hero - learn',
              })
            }}
            type="button"
          >
            {chatText || 'Chat Now'}
          </button>
        )
      }
      <section className="content">
        <NarrowContainer>
          <Row>
            <Col md={8} className="content-left wp-content">
              {content &&
                !useRenderPropForContent &&
                parse(shortcodeParser(content))}
              {markdownContent && !useRenderPropForContent && markdownContent()}
              {useRenderPropForContent && renderContent()}
            </Col>
            <Col md={4} className="content-right">
              <CNChatBox
                chatBox={chatBox}
                chatText={chatText}
                chatTextColor={chatTextColor}
                chatIDForChatBox={chatIDForChatBox}
                componentTextColor={sidebarTextColor}
                doNotMoveChatBox={doNotMoveChatBox}
                template={template}
                phone={phone}
              />
            </Col>
          </Row>
        </NarrowContainer>
      </section>
    </div>
  )
}

export default styled(CNContentTop)`
  .img-wrapper {
    > .container {
      padding: 0;
      overflow: hidden;
      > .img-big {
        max-width: 100%;
        display: none;
        ${above.md`
        display: block;
        `};
      }
      > .img-small {
        width: 100%;
        ${above.md`
          display: none;
        `};
      }
    }
  }
  .content-left {
    padding: 2rem 15px;
    h1 {
      font-family: 'Univers LT W01 65 Bold', Arial, Helvetica, Arial, sans-serif;
      font-weight: 600;
      color: ${colors.umaDarkBlue};
    }
  }
  .content-right {
    padding: 0;
    ${above.md`
      padding: 15px;
    `};
  }
  .chat {
    &:hover,
    &:focus {
      filter: brightness(1.1);
    }

    transition: all 0.2s ease-in-out;
    align-items: center;
    background-color: ${(props) => props.chatButtonColor};
    border: 0;
    border-radius: 2px;
    color: ${(props) => props.chatTextColor || '#fff'};
    cursor: pointer;
    display: inline-flex;
    font-weight: 700;
    justify-content: center;
    min-height: 31px;
    padding: 1rem 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    transform: ${(props) =>
      props.moveMobileChatCallButtonDown
        ? 'translate(25vw, 50%)'
        : 'translate(25vw, -150%)'};
    width: 50vw;

    .LPMcontainer {
      margin: 0 !important;
      width: 100%;
      div {
        cursor: pointer;
        padding: 5px 25px;
        transition: background-color 0.2s ease;
        &:hover {
          background-color: ${(props) => lighten(0.05, props.chatButtonColor)};
        }
      }
    }
    ${above.md`
      display: none;
    `};
  }
`

CNContentTop.propTypes = {
  chatBox: PropTypes.object.isRequired,
  chatButtonColor: PropTypes.string.isRequired,
  chatTextColor: PropTypes.string,
  chatIDForChatBox: PropTypes.string.isRequired,
  chatIDForMobileContent: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  content: PropTypes.string,
  general: PropTypes.object.isRequired,
  id: PropTypes.string,
  renderContent: PropTypes.func,
  sidebarTextColor: PropTypes.string,
  userenderPropForCOntent: PropTypes.bool,
}

CNContentTop.defaultProps = {
  chatButtonColor: colors.umaGreen,
  id: '',
  sidebarTextColor: '#fff',
  useRenderPropForContent: false,
}
