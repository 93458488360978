import { Fact } from './types'

export type TeamsPayload = {
  success: boolean
  payload: {
    '@context': string
    '@type': string
    contentType: string
    sections: { facts: Fact[] }[]
    summary: string
    text: string
    themeColor: string
    title: string
  }
}

// props are the arguments provided to the newTeamsPayload function
type Props = {
  facts: Fact[]
  success: boolean
}

export function newTeamsPayload({ facts, success }: Props): TeamsPayload {
  const themeColor = success ? '#5cb85c' : '#ff0033'
  const title = success ? 'Form Submission Received' : 'Form Submission Error'
  return {
    payload: {
      '@context': 'http://schema.org/extensions',
      '@type': 'MessageCard',
      contentType: 'application/vnd.microsoft.teams.card.o365connector',
      sections: [{ facts }],
      summary: title,
      text: 'The details are below.',
      themeColor,
      title,
    },
    success,
  }
}
