export const GlobalVars = {

  bag: {},

  get: function(key) {
    return typeof this.bag[key] !== undefined ? this.bag[key] : null;
  },

  set: function(key, value) {
    this.bag[key] = value;
  }
};