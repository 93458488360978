import { useCallback, useEffect, useState } from 'react'

import { getCookie } from '../util/cookie'

/**
 * Adds the leadID vendor to the document head if it isn't already there.
 */
function addLeadIDScriptToHead({ callbackName }: { callbackName: string }) {
  const isThereAlready = document.getElementById('LeadiDscript_campaign')
  if (!isThereAlready) {
    const s = document.createElement('script')
    s.id = 'LeadiDscript_campaign'
    s.async = true
    s.defer = true
    s.src = `${document.location.protocol}//d1tprjo2w7krrh.cloudfront.net/campaign/3ad8c5fe-ecf8-3114-eca7-71d5b58f820f.js?callback=${callbackName}`
    const body = document.querySelector('body')
    body?.appendChild(s)
  }
}

const useLeadID = () => {
  const [leadID, setLeadID] = useState<string>('')

  /**
   * getLeadID is a callback that populates the hook's local leadID value.
   */
  const populateLeadID = useCallback(() => {
    /**
     * In the HTTP request for the leadID, we can specify a querystring param
     * "callback" that is equal to the name of a function attached to the window
     * (e.g. `callback=updateLeadIDToken`). The following callback will add a
     * cookie: `uma_leadid_token`.
     */
    globalThis.updateLeadIDToken = (token: string) => {
      // set the cookie
      document.cookie = `uma_leadid_token=${token}`
      // set local state
      setLeadID(token)
    }

    // check for cookied lead_id
    const cookiedLeadID = getCookie('uma_leadid_token')

    if (!cookiedLeadID) {
      addLeadIDScriptToHead({ callbackName: 'updateLeadIDToken' })
    } else {
      setLeadID(cookiedLeadID)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('input', populateLeadID, { once: true })
    return () => window.removeEventListener('input', populateLeadID)
  }, [populateLeadID])

  return { leadID }
}

export default useLeadID
