// Node Module Imports
import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import parse from 'react-html-parser';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';


// Component Imports
import FooterLeftGray from './reusables/Globals/Footers/FooterLeftGray';
import FooterWhiteCentered from './reusables/Globals/Footers/FooterWhiteCentered';

// Style Imports
import { NarrowContainer } from '../styles/elements';
import { below, colors } from '../styles/utilities';
import { footnoteState } from '../utilities';

const GlobalFooter = ({className, sticky, phone, template}) => {

  const footnotes = footnoteState.getFootnotes();
  // if(footnotes && footnotes.length > 0) {
  //   footnoteState.reset();
  // }

  const globalFooterQuery = useStaticQuery(graphql`
    {
      mdx(fileAbsolutePath: {regex: "/markdown/global/footer.md/"}) {
        id
        body
      }
      file(relativePath: {eq: "logos/ABHES-logo.png"}) {
        id
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      {template === "enrollment-start" ? (
        <FooterWhiteCentered
          renderFooterText={() => <MDXRenderer>{globalFooterQuery.mdx.body}</MDXRenderer>} 
        />
      ) : (
        <FooterLeftGray
          phone={phone}
          renderFooterText={() => <MDXRenderer>{globalFooterQuery.mdx.body}</MDXRenderer>}
          renderAbhesLogo={() => <Img alt="ABHES Logo" fixed={globalFooterQuery.file.childImageSharp.fixed} />}
        />
      )}
      {footnotes && (
        <StyledFootnotes>
          <NarrowContainer>
            <ol className="footnotes--list">
              { footnotes.map( (footnote, idx) => <li key={idx}>{ parse(footnote.text) }</li> ) }
            </ol>
          </NarrowContainer>
        </StyledFootnotes>
      )}
    </>
    )
  };

const StyledFootnotes = styled.section`
  ${below.md`
      padding-bottom: ${(footnotes, sticky) => footnotes && sticky ? `0` : `4rem`};
  `};
  background-color: ${colors.umaLightGray};
  color: ${colors.umaDarkGray};
  padding: 15px 0;
  line-height: 1rem;

  ol.footnotes--list {
    font-size: 0.65rem;
    margin: 0;
    padding: 0 15px;
    li {
      line-height: 1rem;
    }
  }
`;

export default GlobalFooter;

GlobalFooter.propTypes = {
  template: PropTypes.string,
}

GlobalFooter.defaultProps = {
  template: 'not-set'
}