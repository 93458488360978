// Node Module Imports
import React from 'react'

import { graphql } from 'gatsby'
import styled from 'styled-components'

// Component Imports
import ImageToutsCareMantra from '../components/cross-template-statics/ImageToutsCareMantra'
import ImageToutsInStride from '../components/cross-template-statics/ImageToutsInStride'
import HeroScalableLogo from '../components/HeadersAndHeroes/HeroScalableLogo'
import PS92020Hero from '../components/heroes/PS92020Hero'
import LayoutChickfila from '../components/LayoutChickfila'
import LogoSquare from '../components/LogoSquare'
import Quote from '../components/reusables/Quote'
import VideoLazyload from '../components/reusables/VideoLazyload'
import YouTubeEmbed from '../components/reusables/YouTubeEmbed'
import SEO from '../components/SEO'
// Style Imports
// Redux Imports
import careMantraLg from '../images/care-mantra/care-mantra-lg.jpg'
import careMantraPrograms from '../images/care-mantra/care-mantra-programs.jpg'
import careMantraSm from '../images/care-mantra/care-mantra-sm.jpg'
import videoPoster from '../images/ychbc/ychbc-video-poster.webp'
import { above, colors } from '../styles/utilities'
import CNContentTop from '../templates/chat-now/CNContentTop'
import CNHeader from '../templates/chat-now/CNHeader'

const ChickfilaPage = ({ className, data }) => {
  const phoneNumber = '877-285-0800'
  // console.log({ data })
  // const sortPrograms = (programEdges) =>
  //   programEdges.sort((a, b) => {
  //     const aNorm = a.node.meta.program_full_name.toLowerCase()
  //     const bNorm = b.node.meta.program_full_name.toLowerCase()
  //     if (aNorm < bNorm) {
  //       return -1
  //     } else if (aNorm > bNorm) {
  //       return 1
  //     }
  //     return 0
  //   })

  // let sortedOnlinePrograms = sortPrograms(data.onlineDegreePrograms.edges)
  // sortedOnlinePrograms = sortedOnlinePrograms.filter(
  //   (p) => p.node.meta.program_code !== 'UNSPEC',
  // )
  // sortedOnlinePrograms = sortedOnlinePrograms.map((p) => {
  //   if (p.node.meta.program_code !== 'HHS') {
  //     p.node.slug = p.node.slug.replace('and-', '')
  //   }
  //   return p
  // })

  const chickfliaASDegreePrograms = [
    'Health and Human Services',
    'Healthcare Accounting',
    'Healthcare Management',
    'Health Information Management',
    'Health Sciences - Medical Administrative Assistant',
    'Health Sciences - Medical Office and Billing Specialist',
    'Medical Billing and Coding',
  ]
  const chickfilaDiplomaPrograms = [
    'Medical Billing and Coding',
    'Medical Administrative Assistant',
    'Medical Office and Billing Specialist',
  ]
  chickfliaASDegreePrograms.sort((a, b) => a.localeCompare(b))
  chickfilaDiplomaPrograms.sort((a, b) => a.localeCompare(b))

  return (
    <LayoutChickfila seo="seo" showDisclaimer sticky phone={phoneNumber}>
      <SEO
        title="Answer Your Calling to Care"
        description="Life can be more rewarding when we care for others. With care, small ripples can become waves that have the potential to strengthen communities and inspire other acts of care."
      />
      <div className={className}>
        <CNHeader
          chatButtonColor="#0095CB"
          chatIDForHeader="LP_DIV_lead_nurturing"
          phone={phoneNumber}
          template="call-now"
        />

        <HeroScalableLogo heroType="scalable" template="chat-now" />

        <PS92020Hero
          bgImgSm={careMantraSm}
          bgImgLg={careMantraLg}
          phoneNumber={phoneNumber}
          className="d-lg-none"
        >
          <div className="container hero-content">
            <div className="row pt-5 ">
              <div className="col-lg-7">
                <p className="mb-0 mt-0">
                  <span className="bold">
                    Answer Your
                    <br />
                    Calling To&nbsp;Care.
                  </span>
                </p>
                <div className="arrow">
                  <svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="0,0 0,100 50,50" />
                  </svg>
                  <p className="m-0 p-2">Classes Starting Soon</p>
                </div>
              </div>
            </div>
          </div>
        </PS92020Hero>

        <PS92020Hero
          bgPosition="50% center"
          bgImgSm={careMantraSm}
          bgImgLg={careMantraLg}
          hideLogoAndPhone
          phoneNumber={phoneNumber}
          className="d-none d-lg-block"
        >
          <div className="container hero-content">
            <div className="row pt-5 ">
              <div className="col-lg-7">
                <p className="mb-0 mt-0">
                  <span className="bold">
                    Answer Your Calling To&nbsp;Care.
                  </span>
                </p>
                <div className="arrow">
                  <svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="0,0 0,100 50,50" />
                  </svg>
                  <p className="m-0 p-2">Classes Starting Soon</p>
                </div>
              </div>
            </div>
          </div>
        </PS92020Hero>

        <CNContentTop
          chatBox={{
            chat_box_color: {
              label: 'Green',
              value: '#78BE20',
            },
            chat_button_color: {
              label: 'Light Blue',
              value: '#0095CB',
            },
            disclaimer: 'Chat with a UMA representative!',
            heading: 'Have Questions?<br>Want to learn more?',
          }}
          chatButtonColor="#78BE20"
          chatIDForChatBox="LP_DIV_lead_nurturing_3"
          chatIDForMobileContent="LP_DIV_lead_nurturing_2"
          pageClass=""
          template="call-now"
          phone={phoneNumber}
          general={{
            logo_type: 'horizontal',
            chat_button_text: 'Chat Now',
            chat_id_header: 'LP_DIV_lead_nurturing',
            chat_id_mobile: 'LP_DIV_lead_nurturing_2',
            chat_id_sidebar: 'LP_DIV_lead_nurturing_3',
            header_chat_color_override: null,
            mobile_chat_color_override: { value: '#78BE20' },
          }}
          markdownContent={() => (
            <>
              <h1>Learn How We’ll Support You as a UMA Student</h1>
              <p>Student care can create a ripple effect of goodness.</p>
              <p>
                As a UMA student, we’ll care for you so you can go on to care
                for others in the field one day. Because you can’t help but
                care.
              </p>
              <p>
                <strong>At UMA, we see how much you care.</strong>
              </p>
              <VideoLazyload
                posterAlt="2024 You Can't Help but Care Video Poster. Click or press enter to play."
                posterSrc={videoPoster}
                includePlayIcon
              >
                <YouTubeEmbed
                  className="video-iframe"
                  width={999}
                  videoId="RyxuNey1Bls"
                  title="You Can't Help But Care"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </VideoLazyload>

              <h2 className="mt-5">
                Don’t worry &mdash; you won’t have to do this alone.
              </h2>
              <p>At UMA, you’ll have access to:</p>
              <ul>
                <li>One-on-one academic support</li>
                <li>24/7 technical support</li>
                <li>Financial aid (for those who qualify)</li>
                <li>Laptops (for those who qualify)</li>
              </ul>
            </>
          )}
        />

        <div className="program-stack wp-content">
          <div className="online">
            <div className="container">
              <div className="row d-flex">
                <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                  <div
                    className="bg-img"
                    style={{
                      backgroundImage: `
                          url(${careMantraPrograms})
                        `,
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 p-0 text order-1 order-md-2">
                  <h3 className="heading m-0 p-3">Online</h3>
                  <p className="px-3 mb-0 bold">
                    Earn a degree online from the comfort of your home with a
                    flexible schedule.
                    <sup>1</sup>
                  </p>
                  <span
                    className="underline pl-3"
                    style={{ marginTop: '1rem' }}
                  >
                    Associate Degree Programs:
                  </span>
                  <ul className="px-3" style={{ paddingBottom: '1rem' }}>
                    {chickfliaASDegreePrograms.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                  <span className="underline pl-3">Diploma Programs:</span>
                  <ul className="px-3" style={{ paddingBottom: '1rem' }}>
                    {chickfilaDiplomaPrograms.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Quote
          bgColor="#68478D"
          fields={{
            uma_brick_fact_quote: {
              fact: '',
              quote:
                'UMA fit me great because I was able to still work ... 40 hours a week, plus still be an afterschool mom with my kids and to get work done … it was an excellent fit for me because it worked out with my hectic schedule.',
              quote_author: 'La’Roya B.<br/>2023 UMA Graduate',
            },
          }}
        />

        <ImageToutsInStride
          content={() => {
            return (
              <>
                <h2 className="blue">Care is the other half of education.</h2>
                <p>
                  As you pursue your education with UMA, you can count on our
                  support services to be there for you. <br className="break" />
                  We care for you with:
                </p>
              </>
            )
          }}
        />

        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <LogoSquare />
              </div>
              <div className="col-12 col-md-8">
                <p>
                  If you’re committed to doing more, to seeking a new path, to
                  making a difference in your life, your family’s life, and the
                  lives of others, UMA is ready to help get you started. Begin
                  your journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutChickfila>
  )
}

export default styled(ChickfilaPage)`
  p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1,
  h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .header-wrapper {
    background-color: ${colors.umaLightBlue};
    padding: 0.75rem 0;
  }

  [class^='form-wrap'] {
    h2.form-font {
      font-size: 2rem;
    }
    p.form-font {
      font-size: 1rem;
      ${above.lg`
        font-size: 1.25rem;
      `}
    }
  }

  .form-wrapper {
    background-color: ${colors.umaDarkBlue};
    > .container {
      padding: 0 7px;
    }
    .form-container {
      margin: 0 auto;
      padding: 0;
      max-width: 500px;
      h2,
      p {
        text-align: center;
      }
    }

    form {
      flex-wrap: wrap;
      justify-content: space-between;

      ${above.lg`
        display: flex;
      `}
    }

    form .input-wrap,
    form .button-wrap {
      flex: 0 0 48.5%;
      margin: 10px 6.75px;
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      select {
        font-size: 1rem;
      }
    }
    form .button-wrap {
      display: flex;
      margin: 1.5rem 6.75px;
    }
  }

  .hero-content {
    .row {
      padding-top: 8rem;

      ${above.lg`
        padding-top: 2rem;
        padding-bottom: 2rem;      
      `}
    }

    p {
      color: #fff;
      font-size: 3rem;
      line-height: 1;
      text-transform: uppercase;

      ${above.lg`
        font-size: 4rem;
      `}
    }

    .arrow {
      background-color: ${colors.umaOrange};
      box-shadow: 8px 8px 6px hsla(0, 0%, 0%, 0.4);
      border-radius: 1px;
      display: inline-block;
      margin-top: 1.5rem;
      position: relative;
      max-width: 88%;

      svg {
        border-radius: 1px;
        fill: ${colors.umaOrange};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        width: auto;
      }
    }

    .arrow p {
      font-size: 1.5rem;

      ${above.md`
        font-size: 2rem;
      `}
    }
  }

  .page-content {
    h1 {
      margin-top: 0;
    }

    li {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    strong {
      color: ${colors.umaTeal};
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: bold;
    }
  }

  .program-stack {
    color: white;

    .heading {
      color: white;
    }
    .underline {
      font-size: 1.1rem;
      line-height: 1.5rem;
      display: block;
      color: white;
      text-decoration: underline;
    }

    .Link {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .online,
    .clearwater {
      position: relative;

      .bg-img-wrap,
      .text {
        ${above.md`
          margin-top: -3.45rem;
        `}
      }

      .bg-img-wrap {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        overflow: hidden;
      }

      .text {
        background-color: ${colors.umaDarkBlue};
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        overflow: hidden;
      }
    }

    .online::before,
    .clearwater::before {
      content: '';
      display: block;
      background-color: ${colors.umaGreen};
      position: absolute;
      top: 3.45rem;
      right: 0;
      bottom: 3.45rem;
      left: 0;

      ${above.md`
        top: 0; bottom: -3.45rem;
        padding-bottom: 3.45rem;
      `}
    }

    .online {
      ${above.sm`
        margin-bottom: 3.5rem;
        margin-top: 4rem;
      `}
      ${above.md`
      margin-top: 8rem;
      `}
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }

    .clearwater {
      ${above.md`
        margin-bottom: 4rem;
      `}
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .phone {
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    font-weight: normal;
    text-decoration: none;

    svg {
      transform: scaleX(-1);
      font-size: 1.5rem;

      ${above.sm`
        margin-right: 0.5rem;
        font-size: 1rem;
      `}
    }
  }

  .form-wrap--lg {
    position: relative;
    ${above.lg`
      box-shadow: -8px -8px 6px hsla(0, 0%, 0%, 0.4);
      margin-top: -10rem;
    `}

    &::before {
      background-color: ${colors.umaLightBlue};
      border-radius: 2px;
      content: '';
      display: block;
      opacity: 0.9;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    .form--lg {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .mobile-menu-wrap {
    background: ${colors.umaLightBlue};
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: -100%;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    &.active {
      display: block;
      left: 0;
      opacity: 0.9;
    }
  }

  .mobile-menu {
    background-color: ${colors.umaDarkGray};
    color: #fff;
    font-size: 0.9rem;
    max-width: 350px;
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: all 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;

    width: 80%;
    z-index: 9;

    &.active {
      left: 0;

      a {
        padding: 0.2rem;
      }
      .close-wrap {
        text-align: right;
      }
    }

    a {
      display: block;
      color: #fff;
      line-height: 1.1rem;
      padding: 10rem 0;
      text-decoration: none;
      transition: color 0.2s ease-in-out, padding 0.4s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        color: ${colors.umaGreenHover};
      }
    }

    ul {
      list-style: none;
      margin: 0.5rem 0 2rem;
      padding: 0 0 0 1rem;
    }

    li {
      line-height: 1.2rem;
      margin: 0.75rem 0 0.75rem 0.5rem;
      text-indent: -1.1rem;

      svg {
        margin-bottom: -2px;
        margin-right: 0.2rem;
      }
    }

    .menu-heading {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }

  [class^='VideoLazyload'] {
    max-width: 700px;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    .video-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .break {
    display: none;
  }
  @media (min-width: 1024px) {
    .break {
      display: block;
    }
  }
`

// export const query = graphql`
//   {
//     onlineDegreePrograms: allWordpressWpPrograms(
//       filter: {
//         meta: {
//           program_campus: { eq: "45" }
//           program_type: { eq: "degree" }
//           program_code: { in: ["HCA", "HCM", "HHS", "HIM", "", "HHS", "HCA"] }
//         }
//       }
//       sort: { fields: meta___program_full_name }
//     ) {
//       edges {
//         node {
//           meta {
//             program_code
//             program_full_name
//           }
//           slug
//         }
//       }
//     }
//   }
// `
