// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'react-html-parser';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

// Component Imports
import SparkroomForm from '../../components/SparkroomForm';

// Style Imports
import { below } from '../../styles/utilities';
import { NarrowContainer } from '../../styles/elements';

// Utility Imports
import { reqInfoDisc, shortcodeParser } from '../../utilities';

const bpContentAndForm = ({
  className,
  content,
  form,
  isProgramPage,
  pageClass,
  selectedCampus,
  selectedProgram,
  tracking
}) => (
  <section className={`${className}`}>
    <NarrowContainer>
      <Row className="content-form-wrapper">
        <Col xs={12} lg={7} className="left-col">
          <div className="wp-content">{parse(shortcodeParser(content))}</div>
        </Col>
        <Col xs={12} lg={5} className="right-col">
          <SparkroomForm
            buttonColor={form.button_color.value}
            className="sr-form-wrapper"
            tracking={tracking}
            buttonText={form.button_text}
            formColor={form.form_bg_color}
            pageProgram={selectedProgram}
            isClearwaterProgram={selectedCampus !== 'Clearwater' ? false : true}
            isProgramPage={isProgramPage}
            pageClass={pageClass}
            aboveForm={() => (
              <>
                <div className="sparkroom-h3 h1">{parse(shortcodeParser(form.cta_text_lg))}</div>
                <div className="sparkroom-h4 h3">{parse(shortcodeParser(form.cta_text_sm))}</div>
              </>
            )}
            belowForm={() => (
              <p className="disclaimer">{reqInfoDisc}</p>
            )}
          />
        </Col>
      </Row>
    </NarrowContainer>
  </section>
);

export default styled(bpContentAndForm)`
  .sr-form-wrapper {
    h3, h4 {
      font-family: "Univers LT W01 65 Bold", Arial;
      margin: 0 0 0.35rem;
    }
    .row {
      margin: 0;
    }
  }

  ${below.lg`
    .content-form-wrapper {
      display: flex;
      >:first-child {
        order: 2;
      }
      >:last-child {
        order: 1;
        padding: 0;
      }
    }`
  }
  .left-col {
    font-size: .9rem;
  }
  .wp-content {
    padding: 3rem 0;
  }
`;

bpContentAndForm.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  isProgramPage: PropTypes.bool.isRequired,
  pageClass: PropTypes.string,
  selectedCampus: PropTypes.string.isRequired,
  selectedProgram: PropTypes.string.isRequired,
  tracking: PropTypes.object.isRequired
}

bpContentAndForm.defaultProps = {
	pageClass: ''
}