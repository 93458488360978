import React from 'react'
import PropTypes from 'prop-types'
import useGa from '../../hooks/useGa'
import styled from 'styled-components'
import { FaPhone } from 'react-icons/fa'
import Logo from '../../components/Logo'
import { above, colors } from '../../styles/utilities'
import { stripPhoneNumber } from '../../utilities/stripPhoneNumber'

const DRTVHero = ({
  bgImgSm,
  bgImgLg,
  bgPosition,
  children,
  className,
  hideLogoAndPhone,
  phoneNumber,
}) => {
  const phoneEvent = useGa()

  return (
    <div className={`section--hero drtv-hero-scalable ${className}`}>
      <div
        className="d-md-none hero-img"
        style={{
          backgroundImage: `url(${bgImgSm})`,
        }}
      />
      <div
        className="d-none d-md-block hero-img"
        style={{
          backgroundImage: `url(${bgImgLg})`,
        }}
      />

      {!hideLogoAndPhone && (
        <div className="container d-flex logo-phone-wrapper align-items-end">
          <Logo />
          <a
            href={`tel:${stripPhoneNumber(phoneNumber)}`}
            className="phone"
            onClick={() =>
              phoneEvent({
                category: 'phone',
                action: 'click',
                label: 'header',
              })
            }
          >
            <span>
              <FaPhone />
            </span>
            <span className="d-none d-sm-inline-block">{phoneNumber}</span>
          </a>
        </div>
      )}

      {children}
    </div>
  )
}

DRTVHero.propTypes = {
  bgImgSm: PropTypes.string.isRequired,
  bgImgLg: PropTypes.string.isRequired,
  bgPosition: PropTypes.string,
  hideLogoAndPhone: PropTypes.bool,
  phoneNumber: PropTypes.string,
}

DRTVHero.defaultProps = {
  hideLogoAndPhone: false,
  bgPosition: '30% center',
}

export default styled(DRTVHero)`
  padding: 2rem 0;
  position: relative;
  min-height: 112vw;
  ${above.md`
    min-height: 40vw;
  `}
  ${above.xl`
    min-height: 30vw;
  `}

  .hero-img {
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;

    ${above.sm`
      background-position: ${(props) => props.bgPosition};
    `}
  }

  .phone {
    background-color: hsla(255, 100%, 100%, 0.99);
    border-radius: 2px;
    box-shadow: -2px 2px 2px hsla(0, 0%, 0%, 0.5);
    color: ${colors.umaDarkBlue};
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    padding: 0.5rem;
    text-decoration: none;

    ${above.md`
      font-size: 1.5rem;
      padding: 0.75rem;
    `}

    svg {
      transform: scaleX(-1);
      font-size: 1.5rem;

      ${above.sm`
        margin-right: 0.5rem;
        font-size: 1rem;
      `}
    }
  }
  .logo-phone-wrapper {
    justify-content: space-between;
    ${above.md`
      justify-content: flex-start;
    `}
    .phone {
      ${above.md`
        margin-left: 10vw;
      `}
    }
  }
`
