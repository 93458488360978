const getPagePath = function(options) {

  if(!options) {
    console.warn('getPagePath needs an options object.');
    return;
  }

  if(!options.absolutePath) {
    console.warn('getPagePath needs an absolutePath in the options object');
    return;
  }

  if(!options.folderToServeAsRootForSlug) {
    return options.absolutePath;
  }

  let found = false,
      result = options.absolutePath;

  found = options.absolutePath.indexOf(options.folderToServeAsRootForSlug);

  if(found !== -1) {
    result = options.absolutePath.substring(found + options.folderToServeAsRootForSlug.length);
  }

  if(options.exclude) {
    options.exclude.forEach(pattern => {
      result = result.replace(pattern, '');
    });
  }

  return `/${result}`;
};

module.exports = getPagePath;