// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Style Imports
import { above, hexToHSL } from '../../styles/utilities';

const masonryContainer = ({children, className, key, template}) => (
  <div className={`${className} template--${template}`} key={key}>
    <div className="masonry-container-inner">
      {children}
    </div>
  </div>
);

export default styled(masonryContainer)`
  background-color: ${props => `hsla(${hexToHSL(props.bgColor)},0.35)`};

  h1 {
      margin: 0;
    }
  &.checkerboard .masonry-container-inner {
    .tout__wrap {
      display: flex;
      align-items: center;
    }
    .tout__item {
      flex: 0 0 50%;
      padding: 50px 0;
      &--text {
        h2 {
          font-size: 2.2rem;
          margin: .5rem;
        }
        div {
          font-size: 1.2rem;
        }
        li, p {
          text-align: left;
        }
      }
      &--icon {
        //text-align: center;
      }
    }

    div:nth-child(even) .tout__wrap {
      .tout__item--text {
        //align-items: center;
        display: flex;
        flex-direction: column;
        order: 0;
      }
      .tout__item--icon {
        order: 1;
      }
    }
  }
  &.horizontal .masonry-container-inner {
    display: flex;
    flex-wrap: wrap;
    > div {
      flex: 1 1 100%;
      ${above.sm`
        flex: 1 1 50%;
      `}
      ${above.lg`
        flex: 1 1 25%;
      `}
      .tout__wrap {
        text-align: center;
        .tout__item {
          &--icon {
            min-height: 11rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &--text {
            li {
              text-align: left;
            }
          }
        }
      }
    }
  }
  &.vertical_symmetrical .masonry-container-inner, .vertical_alternating .masonry-container-inner {
    .tout__wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 0;
      .tout__item {
        display: inline-block;
        text-align: center;
        li {
          text-align: left;
        }
        &--icon {
          flex: 0 0 50%;
        }
        &--text {
          flex: 0 0 50%;
          text-align: left;
        }
      }
    }
  }
  &.vertical_alternating .masonry-container-inner {
    .tout__wrap .tout__item {
      &--icon {
        text-align: center;
      }
      &--text {
        text-align: center;
        li {
          margin-left: 25%;
        }
      }
    }
    div:nth-child(even) .tout__item {
      &--icon {
        order: 1;
      }
      &--text {
        order: 0;
      }
    }
  }

  &.side_by_side {
    padding: 0 calc(50vw - (175px + 2rem));
    ${above.md`
      padding: 2rem 4vw;
    `}
    ${above.xl`
      padding: 3rem calc((50vw - 600px) + 2rem);
    `}

    .masonry-container-inner {
      box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      position: relative;
      
      ${above.md`
        flex-direction: row;
      `}

      .component--wysiwyg, .component--program-toggle {
        order: 2;

        ${above.md`
          order: initial;
        `}
      }

      * {
        font-size: 0.9rem;
        line-height: 1.25rem;

        ${above.xl`
          font-size: 1rem;
          line-height: 1.5rem;
        `}
      }
      .h1 {
        font-size: 2rem;
        line-height: normal
      }
      h2 {
        font-size: 1.15rem;

        ${above.xl`
          font-size: 1.35rem;
        `}
      }
      .small {
        font-size: 70%;
      }

      > div {
        flex: 0 0 50%;
        position: relative;
      }

      .component--program-toggle {
        ${above.sm`
        `}
        ${above.md`
        `}
        ${above.xl`
          min-height: 24rem;
        `}

        .programs-header * {
          padding-left: .75rem;
        }
      }

      .component--wysiwyg {
        padding: 2rem;
        ${above.sm`
        `}
        ${above.md`
        `}
        ${above.lg`
          min-height: 18rem;
        `}
        ${above.xl`
          min-height: 24rem;
        `}
      }

      .component--imageBox, .component--video {
        min-height: 14rem;
        ${above.sm`
        `}
        ${above.md`
        `}
        ${above.md`
          min-height: auto;
        `}
      }

      .component--imageBox {
        .gatsby-image-wrapper {
          position: absolute !important;
          top: 0;
          bottom: 0;
          width: 100%;
        }
      }

      .component--video {
        button {
          border: none;
          bottom: 0;
          background: transparent;
          cursor: pointer;
          outline: none;
          padding: 0;
          position: absolute;
          top: 0;
          width: 100%;

          &:active,
          &:hover {
            border: none;
            outline: none;
          }

          .gatsby-image-wrapper {
            position: absolute !important;
            top: 0;
            bottom: 0;
            width: 100%;
          }

          iframe {
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }
    }
  }

`

masonryContainer.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  template: PropTypes.string
}

masonryContainer.defaultProps = {
  bgColor: '#fff',
  template: 'default'
}