import React from 'react'

import Footnote from '../lib/components/footnotes/Footnote'
import { certPrep, online } from '../lib/footnotes/disclaimers'

type BrandContentProps = {
  className?: string
}

const BrandContent = ({ className }: BrandContentProps) => (
  <div className={className}>
    <h2 className="mt-0">
      Ultimate Medical Academy – Online
      <Footnote content={online} />
    </h2>
    <p>
      As a UMA student, you get personalized attention from the very beginning,
      throughout your career training, and after you graduate. Here are just a
      few Ultimate Student Services that are included in tuition:
    </p>
    <ul>
      <li>One-on-one Academic Support</li>
      <li>
        Certification Preparation
        <Footnote content={certPrep} />
      </li>
      <li>24/7 Technical Support</li>
      <li>Job Interview Coaching</li>
      <li>Job Search Assistance</li>
      <li>Laptops for those who qualify</li>
    </ul>
    <p>
      Ultimate Medical Academy has a relationship with thousands of employers
      across the U.S. to help our graduating students find job opportunities.
    </p>
  </div>
)

BrandContent.defaultProps = {
  className: '',
}

export default BrandContent
