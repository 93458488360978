import React, { useEffect, useState } from 'react'

import cx from 'classnames'

import abhesLogo from '../../../assets/images/logos/abhes-logo.png'
import { Phone } from '../../../lib/util/format'
import PhoneCTA from '../../ctas/Phone'
import * as styles from './style.module.css'

type DefaultFooterProps = {
  className?: string
  phone?: Phone
}

const DefaultFooter = ({ className, phone }: DefaultFooterProps) => {
  const [year, setYear] = useState(0)
  useEffect(() => {
    const date = new Date()
    setYear(date.getFullYear())
  }, [])
  return (
    <div className={cx(styles.defaultFooter, className)}>
      <div className={cx('container py-4')}>
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-start">
            <img src={abhesLogo} alt="ABHES" loading="lazy" />
          </div>
          <div className="col-10 offset-1 col-lg-8 offset-lg-0">
            <p>
              UMA is institutionally accredited by the Accrediting Bureau of
              Health Education Schools (ABHES.org), a national accrediting
              agency that is recognized by the U.S. Secretary of Education.
            </p>
            <p>
              &copy;&nbsp;{year} Ultimate Medical Academy, a nonprofit
              healthcare educational institution |{' '}
              <a
                href="https://www.ultimatemedical.edu/about/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          {phone && (
            <div className="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end">
              <PhoneCTA phone={phone} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

DefaultFooter.defaultProps = {
  className: undefined,
  phone: undefined,
}

export default DefaultFooter
