import React from 'react'
import cx from 'classnames'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import sp from '@Util/shortcodes/parser'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ModuleHeroScalableConstrained = ({
  backgroundColors,
  className,
  hiddenFootnotes,
  image
}) => {

  const gatsbyImageFull = getImage(image.full.file.childImageSharp.gatsbyImageData)
  const gatsbyImageSmall = getImage(image.small.file.childImageSharp.gatsbyImageData)
  const backgroundStyle = `linear-gradient(to right, ${sp(backgroundColors.left)} 0% 50%, ${sp(backgroundColors.right)} 51% 100%)`

  hiddenFootnotes.forEach(f => sp(f.footnote))

  return (
    <section className={cx(className)} style={{background: backgroundStyle}}>
      <div className={cx('d-none d-sm-block text-center')}>
        <GatsbyImage
          className={cx('hero-image-large')}
          image={gatsbyImageFull}
          alt={image.full.alt} />
      </div>
      <div className={cx('d-sm-none')}>
        <GatsbyImage
          className={cx('hero-image-sm')}
          image={gatsbyImageSmall}
          alt={image.small.alt} />
      </div>
    </section>
  )
}

const imageShape = {
  alt: PropTypes.string.isRequired,
  file: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired
    }).isRequired
  }).isRequired
}

ModuleHeroScalableConstrained.propTypes = {
  backgroundColors: PropTypes.shape({
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
  }),
  hiddenFootnotes: PropTypes.arrayOf(PropTypes.shape({
    footnote: PropTypes.string.isRequired
  }).isRequired),
  image: PropTypes.shape({
    full: PropTypes.shape(imageShape),
    small: PropTypes.shape(imageShape)
  })
}

export const query = graphql`
  fragment ModuleHeroScalableConstrained on MarkdownRemark {
    frontmatter {
      modules {
        heroScalableConstrained {
          backgroundColors {
            left
            right
          }
          hiddenFootnotes {
            footnote
          }
          image {
            full {
              alt
              file {
                childImageSharp {
                  gatsbyImageData(quality: 95)
                }
              }
            }
            small {
              alt
              file {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default styled(ModuleHeroScalableConstrained)`
  .hero-image-large {
    margin: 0 auto;
  }
  .hero-image-sm {
    width: 100%;
  }
  .border-bottom {
    height: 2px;
    width: 100%;
  }
`