// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Asset Imports
import facebook from '../../images/social/inline-fb.svg';
import instagram from '../../images/social/inline-instagram.svg';
import linkedin from '../../images/social/inline-linkedin.svg';
import pinterest from '../../images/social/inline-pintrest.svg';
import twitter from '../../images/social/inline-twitter.svg';
import youtube from '../../images/social/inline-youtube.svg';

// Style Imports
import { above } from '../../styles/utilities';

const socialIcons = ({className}) => (
  <div className={className}>
    <ul className={className}>
      <li>
        <a href="https://www.facebook.com/umaonline" target="_blank" rel="noopener noreferrer">
          <img src={facebook} alt="Facebook Icon" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/UltimateMedical" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="Twitter Icon" />
        </a>
      </li>
      <li>
        <a href="http://www.youtube.com/user/ultimatemedical" target="_blank" rel="noopener noreferrer">
          <img src={youtube} alt="YouTube Icon" />
        </a>
      </li>
      <li>
        <a href="http://www.instagram.com/ultimatemedicalacademy" target="_blank" rel="noopener noreferrer">
          <img src={instagram} alt="Instagram Icon" />
        </a>
      </li>
      <li>
        <a href="http://pinterest.com/ultimatemedical" target="_blank" rel="noopener noreferrer">
          <img src={pinterest} alt="Pinterest Icon" />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/edu/ultimate-medical-academy-33489" target="_blank" rel="noopener noreferrer">
          <img src={linkedin} alt="LinkedIn Icon" />
        </a>
      </li>
    </ul>
  </div>
)

export default styled(socialIcons)`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${above.md`
    justify-content: flex-start;
  `}
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 .5rem;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  img {
    height: 5rem;
    opacity: .75;
    :hover {
      opacity: 1;
    }
  }
`;

socialIcons.propTypes = {
  className: PropTypes.string.isRequired
}