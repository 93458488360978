// Node Module Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Asset Imports
import UMALogo from '../../images/logos/rectangle-logo-with-r.png';

// Component Imports
import Question from './Question';
import Program from './Program';
import SparkroomForm from '../SparkroomForm';

// Style Imports
import { colors } from '../../styles/utilities';

// Utility Function Imports
import { reqInfoDisc } from '../../utilities';

class Quiz extends Component {

  state = {
    allQuestions: [],
    answers: [],
    responses: [],
    questionsShown: [],
    programCodes: [],
    transitionLeftToRight: false,
    clearwaterPrograms: ['da', 'ma', 'na', 'pct', 'ph']
  };

  // Fetches questions endpoint and answers endpint, sets them in state, adds questions[0] to state.current.
  async componentDidMount() {
    const listOfQuestions = await fetch('https://www.ultimatemedical.edu/wp-json/uma-quiz/v2/nodes').then(res => res.json());
    const answers = await fetch('https://www.ultimatemedical.edu/wp-json/uma-quiz/v2/programs').then(res => res.json());
    const allQuestions = this.props.isClearwaterQuiz ? listOfQuestions.slice(1) : listOfQuestions;
    this.setState({
      allQuestions,
      answers,
      questionsShown: [allQuestions.shift()]
    });
    const body = document.querySelector('body');
    body.classList.add('body-chevron');
  }

  // Passed up from the Quiz component when the user makes a selection on each question
  handleSelection = (answerID, programCodes) => {
    const { allQuestions, questionsShown } = this.state;
    const currentQuestions = questionsShown;
    const nextQuestion = allQuestions.find(question => question.id === answerID);
    this.setState({ transitionLeftToRight: false });
    answerID !== 'end' ? this.setState({ questionsShown: [...currentQuestions, nextQuestion] }) : this.setState({ programCodes: [...programCodes] });
  }

  // Takes the user back to the previous question
  goBack = () => {
    this.setState({ transitionLeftToRight: true });
    const allQuestions = this.state.questionsShown;
    const allResponses = this.state.responses;
    this.state.programCodes.length ? this.setState({
      programCodes: [],
    }) : this.setState({
      questionsShown: allQuestions.slice(0, -1),
      responses: allResponses.slice(0, -1)
    })
  }

  retakeQuiz = () => {
    this.setState({ transitionLeftToRight: true });
    const allQuestions = this.state.questionsShown;
    // not sure this is needed in this function because it's not used
    // const allResponses = this.state.responses;
    this.setState({
      questionsShown: allQuestions.slice(0, 1),
      responses: [],
      programCodes: []
    })
  }

  renderQA = () => {
    const { allQuestions, questionsShown, answers, programCodes, clearwaterPrograms } = this.state;
    let primaryProgram = answers.find(answer => answer.id === programCodes[0]);
    let secondaryProgram = programCodes.slice(1) || null;
    secondaryProgram = answers.filter(answer => secondaryProgram.indexOf(answer.id) >= 0);
    return !primaryProgram ? questionsShown
      .filter(question => questionsShown.indexOf(question) === questionsShown.length - 1)
      .map(q => (
        <Question
          key={q.id}
          handleSelection={this.handleSelection}
          index={allQuestions.indexOf(q)}
          question={q.question}
          id={q.id}
          children={q.children}
        />)) : (
          // The div.program-wrapper should probably be refactored into a <ProramWrapper /> with a render prop to generate the <Program /> component. This will deflate some of the bloated markup in this file. Are render props even available with React 15.x?
        <div className="program-wrapper">
          <h2>Thank you for taking the UMA Quiz. Your results are below.</h2>
          <Program program={primaryProgram} />
          { secondaryProgram.length ? <h6>You may also be interested in:</h6> : null }
          { secondaryProgram.length ? secondaryProgram.map(program => (
            <div key={program.id} className="other-programs"><a href={program.url}>{program.name}</a></div>
          )) : null }
          <p>Complete this form and we’ll email you info on how to get started at UMA, financial aid, selecting the right program, and connecting with other students. We’ll also give you a call to ensure all your questions are answered so you can make the right choice.</p>
          <SparkroomForm  
            buttonColor={colors.umaGreen}
            buttonText=""
            formColor={colors.umaOrange}
            isQuiz={true}
            formProgramCode={programCodes[0].toUpperCase()}
            isClearwaterProgram={this.props.isClearwaterQuiz ? true : clearwaterPrograms.indexOf(programCodes[0]) >= 0 ? true : false}
            tracking={{phone_number: '877-322-9754'}}
            aboveForm={() => (
              <>
                <div className="sparkroom-h3 h1">Learn How</div>
                <div className="sparkroom-h4 h3">We Can Help You Succeed</div>
              </>
            )}
            belowForm={() => (
              <p className="disclaimer">{reqInfoDisc}</p>
            )}
          />
        </div>
    )
  }

  render() {
    const { questionsShown, programCodes, transitionLeftToRight } = this.state;
    const { className } = this.props;
    return (
      <div className={transitionLeftToRight ? `${className} quiz left-to-right` : `${className} quiz right-to-left`}>
        <img src={UMALogo} alt="Ultimate Medical Academy" title="Ultimate Medical Academy" className="uma-logo" />
        {this.renderQA()}
        <div className="button-wrapper">
          <button className={ programCodes.length ? 'previous-question prev-and-retake' : programCodes.length || questionsShown.length > 1 ? 'previous-question' : 'previous-question display-none' } onClick={this.goBack}>Go to Previous Question</button>
          <button className={ programCodes.length ? 'retake-quiz' : 'retake-quiz display-none' } onClick={this.retakeQuiz}>Retake the Quiz</button>
        </div>
      </div>
    )
  }
}

export default styled(Quiz)`
  text-align: center;
  .Question, .program-wrapper, .button-wrapper {
    max-width: 650px;
  }
  .uma-logo {
    max-width: 80%;
    margin: auto;
    padding: 1rem 0;
  }
  .button-wrapper {
    position: fixed;
    display: flex;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    .previous-question {
      background-color: ${colors.umaDarkBlue};
      border-right: 2px solid #fff;
      color: #fff;
      width: 100%;
      height: 56px;
      margin: 0;
      &.prev-and-retake {
        width: 50%;
      }
      &.display-none {
        display: none;
      }
    }
    .retake-quiz {
      border: 2px solid ${colors.umaDarkBlue};
      width: 50%;
      margin: 0;
      height: 56px;
      background: #fff;
      color: ${colors.umaDarkBlue};
      &.display-none {
        display: none;
      }
    }
  }
  .Question, .program-wrapper {
    padding-bottom: 5rem;
    font-size: 12px;
    position: absolute;
    > * {
      max-width: 90%;
      margin: auto;
    }
    h2 {
      text-align: center;
      margin-bottom: .6rem;
    }
  }
  /* Component transition when moving to the next question or showing programs */
  &.quiz.right-to-left {
    .Question-enter {
      transform: translateX(100%);
      opacity: 0;
      transition: all .8s;
      transition-timing-function: cubic-bezier(.4,0,0,2);
      &.Question-enter-active {
        transform: translateX(0%);
        opacity: 1;
      }
    }
    .Question-leave {
      transform: translateX(0%);
      opacity: 1;
      transition: all .8s;
      transition-timing-function: cubic-bezier(.4,0,0,2);
      &.Question-leave-active {
        transform: translateX(-100%);
        opacity: 0;
      }
    }
  }
  /* Component transition when navigating using the 'Go to Previous Question' button */
  &.quiz.left-to-right {
    .Question-enter {
      transform: translateX(-100%);
      opacity: 0;
      transition: all .8s;
      transition-timing-function: cubic-bezier(.4,0,0,2);
      &.Question-enter-active {
        transform: translateX(0%);
        opacity: 1;
      }
    }
    .Question-leave {
      transform: translateX(0%);
      opacity: 1;
      transition: all .8s;
      transition-timing-function: cubic-bezier(.4,0,0,2);
      &.Question-leave-active {
        transform: translateX(100%);
        opacity: 0;
      }
    }
  }
  .program-wrapper {
    .other-programs {
      display: inline-block;
      background: #fafafa;
      padding: 5px;
      margin: 5px 5px 5px 3rem;
      font-size: 1rem;
      font-weight: 500;
    }
    h6 {
      margin: 1rem auto;
      font-size: 1.2rem;
    }
    p:not(.program-description) {
      margin: .8rem auto;
      text-align: center;
    }
    a:not(.submitStyle) {
      color: ${colors.umaDarkBlue};
      text-decoration: none;
    }
  }
`;

Quiz.propTypes = {
  isClearwaterQuiz: PropTypes.bool.isRequired
}