import { store } from '../redux';
import QueryStringBuilder from '@ultimatemedical/uma-utils/dist/v2/formParam/builder';
import { FaHandHoldingHeart } from 'react-icons/fa';

/*
 |----------------------------------------------------------
 | FormRedirectService
 |----------------------------------------------------------
 | The FormRedirectService 
 |
 */
class FormRedirectService {


  constructor(page, redirectField, phoneNumber=null) {
		this.page = page;
		this.redirectField = redirectField;
		this.phoneNumber = phoneNumber;
  }


  boot() {

    this.setFullRedirect();

    store.subscribe(() => {
      this.setFullRedirect();
    });

  }


  /**
	 * setFullRedirect
	 * 
	 * @param {void}
	 * @return {string}
	 */
	setFullRedirect() {

		let queryString = this.buildRedirectQueryString();

		// the redirect value is set initially, so we can just use that as the base
		let redirectValue = decodeURIComponent(this.redirectField.value);
		redirectValue = redirectValue.split("?");
		redirectValue = redirectValue[0];

		let redirect = redirectValue + encodeURIComponent(queryString);
		let redirectHCG = encodeURIComponent(`https://learn.ultimatemedical.edu/confirmation-next-step/${queryString}`);
		let redirectHCGForHHS = encodeURIComponent(`https://learn.ultimatemedical.edu/confirmation-career-guide-hhs/${queryString}`);
		
		if(this.redirectField) {
			if (this.page.classes.contains('confirmation-hcg')) {
				this.redirectField.value = redirectHCG;
			} else if (window.location.pathname === '/online/health-and-human-services-4') {
				this.redirectField.value = redirectHCGForHHS;
			} else {
				this.redirectField.value = redirect;
			}
		}
	}


	/**
	 * buildRedirectQueryString
	 * 
	 * Build the query string for the confirmation page.
	 * 
	 * @param {void}
	 * @return {string}
	 */
	buildRedirectQueryString() {

		let redux,
				redirectComponents = [];

		redux = store.getState()._r_globals;
		redirectComponents = [
			{
				key: 'lid',
				value: redux.hasOwnProperty('lead_id') ? redux.lead_id : null,
				excludeIfFalsy: true
			},
			{
				key: 'phone',
				value: this.phoneNumber,
				excludeIfFalsy: true
			},
			{
				key: 'program',
				value: redux.hasOwnProperty('program_of_interest')
								? redux.program_of_interest : null,
				excludeIfFalsy: true
			}
		];

    return new QueryStringBuilder(redirectComponents).build().getURLString();

	}
	
}

export { FormRedirectService };