import React, { useContext } from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { ModalContext } from '../../lib/context/ModalContext'
import Modal from '../modals/Modal'
import Chevron, { ChevronDirection } from '../uma-svgs/Chevron'

type ModalCTAProps = {
  className?: string
  modal: () => React.ReactElement
}

const ModalCTA = ({ className, modal }: ModalCTAProps) => {
  const { toggleModal } = useContext(ModalContext)
  const localModal = () => <Modal>{modal()}</Modal>
  return (
    <button
      onClick={() => toggleModal(localModal)}
      className={cx(className, 'px-3 mx-3 d-flex align-items-center')}
      type="button"
    >
      <Chevron color="#fff" direction={ChevronDirection.Right} width="18px" />
      &nbsp;Get Started
    </button>
  )
}

ModalCTA.defaultProps = {
  className: '',
}

export default styled(ModalCTA)`
  background-color: rgb(104, 165, 29);
  background-image: linear-gradient(
    -180deg,
    rgb(120, 191, 34),
    rgb(104, 165, 29) 90%
  );
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 0.5rem 0;
  transition: background-image 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: rgb(104, 165, 29);
    background-image: none;
  }
`
