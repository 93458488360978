class StateSelectSetupService {

  constructor(stateSelectField) {
    if(typeof document === 'undefined') {
      console.log(`Please be sure run ${this.constructor.name} only after the component mounts.`);
      return;
    }
    this.stateSelectField = stateSelectField;
  }

  boot() {
    if(this.stateSelectField) {
      this.removeValueFromDefaultSelect();
    }
  }

  /**
   * removeValueFromDefaultSelect
   * 
   * Remove the value from the default selected option.
   * e.g. Change the value of the "Select One..." option 
   * from "N_A" to "" so it won't pass validation if another
   * option is not selected.
   * 
   */
  removeValueFromDefaultSelect() {
    let defaultSelected = this.stateSelectField.querySelectorAll('option[value="N_A"]');
    defaultSelected = [...defaultSelected];
    if(defaultSelected.length) {
      defaultSelected.forEach(ds => {
        ds.setAttribute('value', '');
      });
    }
  }

}

export { StateSelectSetupService };