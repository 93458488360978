import axios from 'axios'

import { TeamsPayload } from '../util/teams'

type Response = {
  notification: null | TeamsPayload
  sparkroomError: any
}

const useSparkroom = () => {
  const sendToSparkroom = async (
    json: { key: string; value: string }[],
  ): Promise<Response> => {
    // send to sparkroom
    try {
      const response = await axios.post<{ notification: string }>(
        '/.netlify/functions/sparkroom-v1',
        json,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return {
        notification: JSON.parse(response.data.notification) as TeamsPayload,
        sparkroomError: null,
      }
    } catch (e) {
      return {
        notification: null,
        sparkroomError: e,
      }
    }
  }
  return { sendToSparkroom }
}

export default useSparkroom
