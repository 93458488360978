// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';

export default function imageBox({fields}) {
  return (
    <div className="component--imageBox">
      <Image fluid={fields.uma_brick_image_box.localFile.childImageSharp.fluid} />
    </div>
  )
};

imageBox.propTypes = {
  bgColor: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired
}