
const hiddenFormParamsSelector = (state) => {
  return state.v2_hiddenFormParams
}

const umaSelector = {
  campus(state) {
    return state.v2_uma.campus
  },
  phone(state) {
    return state.v2_uma.phone
  }
}

export { 
  hiddenFormParamsSelector,
  umaSelector
}