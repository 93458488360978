/* eslint-disable camelcase, sort-keys */

/**
 * SparkroomFieldName contains the keys that we need to send to sparkroom
 * with student form submissions.
 */
export enum SparkroomFieldName {
  /**
   * The field key for the user's street address.
   */
  Address1 = 'Address1',

  /**
   * The field key for the adgroupid field.
   */
  AdGroupID = 'adgroupid',

  /**
   * The field key for the user's campus.
   */
  Campus = 'Campus',

  /**
   * The field key for UMA's CID (provided by sparkroom).
   */
  CID = 'CID',

  /**
   * The field key for the user's city.
   */
  City = 'City',

  /**
   * The field key for the user's google client id.
   */
  ClientID = 'clientId',

  /**
   * The field key for the user's email.
   */
  Email = 'Email',

  /**
   * The field key for the user's first name.
   */
  FirstName = 'FirstName',

  /**
   * The field key for the user's google click ID. This field is used for lead
   * attribution.
   */
  GCLID = 'gclid',

  /**
   * The field key for the user's last name.
   */
  LastName = 'LastName',

  /**
   * The field key for the lead buyer id. This field is used for lead
   * attribution.
   */
  LeadBuyerID = 'LeadBuyerID',

  /**
   * The field key for the user's lead ID.
   */
  LeadIDToken = 'leadid_token',

  /**
   * The field key for leadsource. This field is used for lead attribution.
   */
  LeadSource = 'LeadSource',

  /**
   * The field key for leadsource. This field is used for meta information about
   * a lead.
   */
  LeadSource2 = 'LeadSource2',

  /**
   * The field key for leadvendor. This field is used for lead attribution.
   */
  LeadVendor = 'LeadVendor',

  /**
   * The field key for pathlabel (the URL from which the user submitted the
   * form).
   */
  PathLabel = 'PathLabel',

  /**
   * The field key for the user's zip code.
   */
  PostalCodeOrZIP = 'PostalCodeOrZIP',

  /**
   * The field key for the user's phone number.
   */
  Phone = 'Phone',

  /**
   * The field key for the user's program.
   */
  ProgramOnline = 'Program_Online',

  /**
   * The field key for relaxval. It's a sparkroom field, although I'm not
   * entirely sure what it does.
   */
  RelaxVal = 'RelaxVal',

  /**
   * The field key for UMA's SID (provided by sparkroom).
   */
  SID = 'SID',

  /**
   * The field key for the user's state.
   */
  State = 'State',

  /**
   * The field key for the user's TCPA consent.
   */
  TCPAConsent = 'TCPAConsent',

  /**
   * The field key for vendor. This field is used for lead attribution.
   */
  Vendor = 'Vendor',

  /**
   * The field key for vendorleadid. This field is used for lead attribution.
   */
  VendorLeadID = 'VendorLeadID',

  /**
   * The field key for utm_adgroup. This field is used for lead attribution.
   */
  UTMAdgroup = 'utm_adgroup',

  /**
   * The field key for utm_campaign. This field is used for lead attribution.
   */
  UTMCampaign = 'utm_campaign',

  /**
   * The field key for utm_medium. This field is used for lead attribution.
   */
  UTMMedium = 'utm_medium',

  /**
   * The field key for utm_source. This field is used for lead attribution.
   */
  UTMSource = 'utm_source',

  /**
   * The field key for utm_term. This field is used for lead attribution.
   */
  UTMTerm = 'utm_term',
}

export default SparkroomFieldName
