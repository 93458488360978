import { store } from '../redux';
import { ElementWatcher } from '../utilities';
import { initService, addOrUpdateGlobal } from '../redux/actions';

/*
 |----------------------------------------------------------
 | ProgramSelectService
 |----------------------------------------------------------
 | The ProgramSelectService is responsible for monitoring 
 | the given Element for change (e.g. a Progam select field)
 | and updating the redux store with the new value.
 |
 */
class OnProgramSelectService {

  constructor(element) {
    this.element = element;
    this.elementWatcher = new ElementWatcher();
  }
  
  boot() {
    store.dispatch(initService('PROGRAM_SELECT'));
    this.elementWatcher.always().watch(this.element, 'change');
    this.elementWatcher.onUpdate(event => {
      store.dispatch(addOrUpdateGlobal('program_of_interest', event.target.value));
    }).boot();

	}
	
}

export { OnProgramSelectService };