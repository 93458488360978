const addQueryStringParams = (url, params) => {

  let queryStr = Object.keys(params).reduce((acc, cur) => {
    acc = `${acc}${cur}=${params[cur]}&`;
    return acc;
  }, "");

  queryStr = queryStr.slice(0, -1);

  if(url && url.indexOf("?") === -1 && queryStr) {
    return `${url}?${queryStr}`;
  }

  else if(url && queryStr) {
    return `${url}&${queryStr}`;
  }

  return url;
};

export default addQueryStringParams;