// Node Module Imports
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import parse from 'react-html-parser';
import styled from 'styled-components';

// Component Imports
import Bricks from '../../components/reusables/Bricks';
import Img from 'gatsby-image/withIEPolyfill';
import HeaderWithMenu from '../../components/HeadersAndHeroes/HeaderWithMenu';
import Layout from '../../components/Layout';
import Menu from '../../components/reusables/Menu';
import SparkroomForm from '../../components/SparkroomForm';
import RememberTraceParams from '../../components/effects/RememberTraceParams';

// Style Imports
import { above, colors } from '../../styles/utilities';

// Redux Imports
import { store }  from '../../redux';
import { addOrUpdateFormParams } from '../../redux/actions';

// Utility Imports
import { reqInfoDisc, shortcodeParser } from '../../utilities';
import { ClassList } from '../../utilities/ClassList';
import { determineNeededFormParams } from '../../utilities/forms';


const TemplateBrandOld = props => {

  const [displayMobileMenu, setDisplayMobileMenu] = useState(false); 

  const wp = props.data.wordpressPage;
  const bricks = props.data.allWordpressUmaBricks.edges;
  const pageContent = props.data.wordpressPage.content;
  const selectedProgram = props.pageContext.programs[0];
  const brickSections = props.pageContext.brickSections;
  const pageClass = new ClassList(props.pageContext.pageClass);
  const tracking = props.data.wordpressPage.acf.uma_template_brand.tracking;
  const isProgramPage = props.pageContext.programs.length === 1 ? true : false;
  const selectedCampus = props.data.wordpressPage.acf.uma_program_association.campus.label;
  const phoneNumber = props.data.wordpressPage.acf.uma_template_brand.tracking.phone_number;
  const includeStateSelect = props.pageContext?.form_state;
  const removeStickyMobileFooter = props.pageContext?.removeStickyFooter === true;

  const formParams = determineNeededFormParams(pageClass, selectedCampus, tracking);
  store.dispatch(addOrUpdateFormParams(formParams));

  const subhead = props.data.oldTemplate.frontmatter.form?.subhead ?? `Learn How We Can Help You Succeed`;

  const closeModal = e => {
    if(displayMobileMenu && e.target === e.currentTarget) {
      setDisplayMobileMenu(false);
    }
  }

  const displayStickyMobileFooter = () => {
    if (removeStickyMobileFooter === true) return removeStickyMobileFooter;
    return wp.acf.uma_page_settings_sticky_footer;
  }

  return (
    <Layout
      phone={tracking.phone_number}
      seo={wp.acf.uma_page_settings_seo}
      showDisclaimer={true}
      sticky={displayStickyMobileFooter()}>

        <RememberTraceParams whichForm="sparkroom" />

      <div className={`${props.className} ${displayMobileMenu && `menu-open`}`}>
        <div className="hero">
          <div className="container">
            <HeaderWithMenu
              phoneNumber={phoneNumber}
              displayMobileMenu={displayMobileMenu}
              setDisplayMobileMenu={setDisplayMobileMenu}
              selectedCampus={selectedCampus}
            />
          </div>
          <div className="container">
            <div className="content">
              <h1>{wp.acf.uma_template_brand.hero.hero_text_full}</h1>
              <p className="bold">{subhead}</p>
              <SparkroomForm
                aboveForm={() => null} 
                belowForm={() => null}
                buttonColor={ colors.umaGreen }
                buttonText="Request Info"
                className="sparkroom-form"
                clean={true}
                formColor="transparent"
                isClearwaterProgram={selectedCampus !== 'Clearwater' ? false : true}
                isProgramPage={isProgramPage}
                pageProgram={selectedProgram}
                template="info2-program"
                tracking={tracking}
                includeStateSelect={includeStateSelect}
              />
              <p className="disclaimer">{reqInfoDisc}</p>
            </div>
          </div>
        </div>
        <div className="block-wrap">
          <div className="block"></div>
        </div>
        <div className="container">
          <div className="row wp-content">
            <div className="col-md-3">
              <Img
                alt={props.data.oldTemplate.frontmatter.contentImg.alt}
                fluid={props.data.oldTemplate.frontmatter.contentImg.path.childImageSharp.fluid}
              />
            </div>
            <div className="col-md-9">
              {parse(shortcodeParser(pageContent))}
            </div>
          </div>
        </div>
        <Bricks
          queriedBricks={bricks}
          programs={props.data.allWordpressUmaPrograms.edges}
          selectedCampus={selectedCampus} 
          brickSections={brickSections}
          template={pageClass.contains('bricks-template-default') ? 'default' : 'info2ProgramSingle'}
        />

          <div
            aria-label="Navigation Wrapper"
            role="button"
            tabIndex="0"
            className={`mobile-menu-wrap ${displayMobileMenu && `active`}`}
            onClick={closeModal}
            onKeyDown={e => e.keyCode === 13 ? setDisplayMobileMenu(false) : null} />
            
          <div className={`mobile-menu ${displayMobileMenu && `active`}`}>
            <Menu
              excludePrograms={['hs-pht']}
              setDisplayMobileMenu={setDisplayMobileMenu}
              selectedCampus={selectedCampus} />
          </div>

      </div>
    </Layout>
  )
}

export default styled(TemplateBrandOld)`

  line-height: normal;
  position: relative;

  .hero {
    background-color: ${ colors.umaLightBlue };
    color: white;
    padding: 0.75rem 0;
    text-align: center;

    h1, h2, h3, h4, h5, h6 {
      color: white;
      margin: 0;
    }

    p {
      font-size: 1.4rem;
      line-height: normal;
      margin-top: 0.5rem;
    }

    .disclaimer {
      font-size: 0.72rem;
      line-height: 1rem;
      margin-top: 1.5rem;
    }

    .content {
      padding: 2rem 0;
    }
  }

  .wp-content {

    padding: 1rem 0 2rem;

    h1:first-of-type,
    h2:first-of-type,
    h3:first-of-type {
      margin-top: 0;
    }

    .gatsby-image-wrapper {
      margin: 0 auto 1rem auto;
      max-width: 200px;

      ${above.md`
        max-width: 250px;
      `}
    }
  }

  .mobile-menu-wrap {
    background: ${colors.umaLightBlue};
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
      top: 0; bottom: 0; right: 0; left: -100%;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    &.active {
      display: block;
      left: 0;
      opacity: 0.9;
    }
  }

  .mobile-menu {
    background-color: ${colors.umaDarkGray};
    color: #fff;
    font-size: 0.9rem;
    max-width: 350px;
    padding: 1rem;
    position: fixed; 
      top: 0; bottom: 0; left: -100%;
    transition: all 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;

    width: 80%;
    z-index: 9;

    &.active {
      left: 0;

      a {
        padding: 0.2rem;
      }
    }

    a {
      display: block;
      color: #fff;
      line-height: 1.1rem;
      padding: 10rem 0;
      text-decoration: none; 
      transition: color 0.2s ease-in-out, padding 0.4s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        color: ${colors.umaGreenHover};
      }

    }

    ul {
      list-style: none;
      margin: 0.5rem 0 2rem;
      padding: 0 0 0 1rem;
    }

    li {
      line-height: 1.2rem;
      margin: 0.75rem 0 0.75rem 0.5rem;
      text-indent: -1.1rem;

      svg {
        margin-bottom: -2px;
        margin-right: 0.2rem;
      }
    }

    .menu-heading {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }

  .disclaimer {
    font-size: 0.8rem;
    line-height: normal;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .block-wrap {
    text-align: center;
  }

  .block {
    background-color: ${colors.umaLightBlue};
    display: inline-block;
    height: 40px;
    margin: 0 auto;
    position: relative;
    transform: translateY(-50%) rotate(45deg);
    width: 40px;
    z-index: -1;
  }

  .sparkroom-form {
    text-align: center;

    form {
      ${above.lg`
        display: flex;
        flex-wrap: wrap;
      `}
    }

    .form-group {
      ${above.lg`
        flex: 0 0 48%;
        margin: 0.5rem;
      `}
    }

    #submitButtonDiv {
      margin: 0.5rem auto 0 auto;
      text-align: center;
    }

    #submitMessageDiv {
      margin: 0.5rem auto 0 auto;
      text-align: center;
    }

    .submitStyle {
      padding: 0.75rem 2rem;
    }

    .row {
      margin: 0;
      padding: 0;
    }
  }


  .component--tout {
    flex: 0 0 49%;

    h2 {
      font-size: 1rem;
      line-height: normal;
    }
    
    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      display: flex;
      height: 100%;
      outline: none;
      transition: 0.2s all ease-in-out;
      width: 100%;
    }

    .tout__info {
      cursor: auto;
      margin: 0 10px;
      position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
      z-index: 1;
    }

    .tout__info__close-wrap {
      position: absolute;
      right: 0;
      text-align: right;
    }

    .tout__info__close {
      cursor: pointer;
      display: inline-block;
      padding: 2rem;
    }

    .tout__item--content {
      padding: 2rem;

      ${above.md`
        display: flex;

        h2 {
          margin-top: 0;
        }
      `}
    }

    .tout__item--content[data-active="true"] .tout__item--text {
      ${above.md`
        margin-left: 2rem;
      `}
    }
  }

  .template--info2ProgramSingle:first-of-type {

    .masonry-container-inner {
      display: flex;
      justify-content: center;
      max-width: 75em;
      position: relative;
      margin: 0 auto 2rem auto;
      width: 100%;

      ${above.md`
        justify-content: space-around;
      `}

      > div {
        flex: 0 0 47%;

        ${above.md`
          flex: 0 0 20%;
        `}
      }
    }
  }

  section[class^='Quote'], section[class*=' Quote'] {
    display: none;

    ${above.md`
      display: block;
    `}

    .container {
      max-width: 60rem;
      width: 84%;
    }
  }

  .lq-div {
    display: none;
  }

  .factoid {
    font-size: 2rem;
    font-weight: bold;
    line-height: normal;
    margin: 0 auto;
  }

  .quote {
    display: inline-block;
    margin: 0 auto;
    position: relative;

    &:before {
      content: '“';
      display: inline-block;
      font-family: Georgia,serif;
      font-size: 5rem;
      position: absolute;
        top: 50%; left: -1.5rem;
    }
  }

  .ital {
    display: block;
    font-size: 1rem;
    margin-top: 0.75rem;
  }

  .sparkroom-form form {
    .form-group {
      border: 1px solid ${colors.umaCoolGray};
      padding: 0.25rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      text-align: left;
    }

    .submitStyle {
      margin-top: 1rem;
    }

    label {
      display: inline-block;
      font-size: 12px;
      width: auto;

      &:after {
        content: '*';
        color: ${colors.umaRed};
        display: inline-block;
        margin-left: 0.5rem;
      }

      font {
        display: none;
      }
    }
  }

  .close-wrap {
    text-align: right;

    svg {
      cursor: pointer;
    }
  }

  .bold {
    font-weight: bold;
  }

`;

export const query = graphql`
  query($id: String!, $brickIds: [Int]!, $programs: [String], $slugRegex: String) {

    wordpressPage(id: {eq: $id}) {
      title
      content
      acf {
        uma_page_settings_sticky_footer {
          display_sticky_footer
          chat_button_id_select {
            label
            value
          }
          chat_button_id
        }
        uma_page_settings_seo {
          page_title
          meta_description
        }
        uma_template_brand {
          form {
            button_text
            cta_text_lg
            cta_text_sm
            form_bg_color
            button_color {
              value
            }
          }
          hero {
            hero_image_lg {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            hero_image_sm {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 640, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            logo_type
            hero_text_full
          }
          tracking {
            phone_number
            vendor
            lead_source
          }
        }
        uma_program_association {
          campus {
            label
          }
        }
      }
    }

    allWordpressUmaBricks(filter: {wordpress_id: {in: $brickIds}}) {
      edges {
        node {
          wordpress_id
          post_title
          acf {
            uma_brick {
              type {
                label
                value
              }
            }
            uma_brick_fact_quote {
              fact
              quote
              quote_author
            }
            uma_brick_programs {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth:1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            uma_brick_tout {
              icon {
                localFile {
                  childImageSharp {
                    fixed(quality:100, width:125) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              name
              text
            }
            uma_brick_video {
              poster {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              video_embed_code
            }
            uma_brick_programs_list_degree_diploma {
              text
            }
            uma_brick_wysiwyg
            uma_brick_image_box {
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth:1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allWordpressUmaPrograms(filter: {acf: {uma_program_info: {code: {in: $programs}}}}) {
      edges {
        node {
          acf {
            uma_program_info {
              campus {
                value
              }
              full_name
              type
              classes_in_program {
                class_name
              }
              links {
                link_key
                page_link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }

    oldTemplate: mdx(fileAbsolutePath: {
      regex: $slugRegex
    }) {
      frontmatter {
        contentImg {
          alt
          path {
            childImageSharp {
              fluid(quality:100, maxWidth:250) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        form {
          subhead
        }
      }
    }
    
  }
`;