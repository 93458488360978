// Node Module Imprts
import React from 'react';

// Component Imports
import Layout from '../components/Layout';
import Quiz from '../components/quiz/Quiz';

const QuizPage = (props) => (
  <Layout showDisclaimer={false}>
    <Quiz isClearwaterQuiz={false} />
  </Layout>
);
export default QuizPage;