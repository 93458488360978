import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import watchInputs from '../utilities/watchInputs';
import { 
	ClassList, applyPhoneMask, reqInfoDisc, buildSparkroomRequestString
} from '../utilities';
import { 
	LeadIdService, OnProgramSelectService, ProgramSelectSetupService,
	FormRedirectService, FormRefererService, StateSelectSetupService
} from '../services';

class SparkroomFormBase extends Component {


	/**
	 * constructor
	 * 
	 * @param {void}
	 * @return {void}
	 */
	constructor(props) {
		super(props);
		this.ref = createRef();
		this.componentDidMount = this.componentDidMount.bind(this);
		this.postFormLoadProcessing = this.postFormLoadProcessing.bind(this);
		this.onPostFormLoad = props.onPostFormLoad;
		this.state = {
			formHtml: null
		};
	}

	async componentDidMount() {
		let response,
        body,
        request;

    try {

		  request = buildSparkroomRequestString(
				this.props._r_formParams, window.location.search
			);
			response = await fetch(request);

      if(!response.ok) {
        throw new Error('Network response was not ok.');
      }

			body = await response.text();
			this.setState({formHtml: body});
    	this.postFormLoadProcessing();

		} catch (error) {
      console.error('Sparkroom Error:', error);
    }
	}


	postFormLoadProcessing() {

		watchInputs(this.ref.current);

		const pageDetails = {
			isQuiz: this.props.isQuiz,
			classes: new ClassList(this.props.pageClass),
			program: this.props.pageProgram,
			isProgramPage: this.props.isProgramPage,
			isClearwaterProgram: this.props.isClearwaterProgram
		};

		new LeadIdService(
			this.ref.current.querySelector('input[name="FirstName"]')
		).boot();
		new ProgramSelectSetupService(
			this.ref.current.querySelector('select[name="Program_Online"]'),
			pageDetails
		).boot();
		new OnProgramSelectService(
			this.ref.current.querySelector('select[name="Program_Online"]')
		).boot();
		new FormRedirectService(
			pageDetails,
			this.ref.current.querySelector('input[name="redirect"]'),
			this.props.tracking.phone_number
		).boot();
		new FormRedirectService(
			pageDetails,
			this.ref.current.querySelector('input[name="errorUrl"]'),
			this.props.tracking.phone_number
		).boot();
		new FormRefererService(
			document.createElement('input'),
			this.ref.current.querySelector('form'),
			window.location.href
		).boot();
		new StateSelectSetupService(
			this.ref.current.querySelector('select[name="State"]')
		).boot();

		applyPhoneMask(this.ref.current.querySelector('input[name="Phone"]'));

		if(this.props.template === 'info2-program') {
			
			const emailDiv = document.getElementById('EmailDiv');
			const phoneDiv = document.getElementById('PhoneDiv');
			const parentNode = phoneDiv.parentNode;


			if(emailDiv) {
				const emailLabel = emailDiv.querySelector('label');
				emailLabel.innerHTML = 'Email Address';
			}
			
			if(phoneDiv) {
				const phoneLabel = phoneDiv.querySelector('label');
				phoneLabel.innerHTML = 'Phone Number';
			}

			if(emailDiv && phoneDiv && parentNode) {
				parentNode.insertBefore(emailDiv, phoneDiv);
			}
		}

		if(this.onPostFormLoad instanceof Function) {
			this.onPostFormLoad();
		}

  }

	renderForm() {

		return (
			<div id="sparkroomFormApiDiv">
        {
          !this.state.formHtml 
            ? <div className="no-script-btn"><a className="submitStyle" href="tel:8773229754">{ this.props.buttonText }</a></div>
            : <div dangerouslySetInnerHTML={{__html: this.state.formHtml}} />
        }
				{ !this.props.customDisclaimer && <p className="disclaimer">{reqInfoDisc}</p> }
      </div> 
		)

	}
	
	render() {
		const { aboveForm, belowForm, className, pageClass } = this.props;
		return (
			<>
				<div className={`${className} ${pageClass}`} ref={this.ref}>
					{aboveForm()}
					{this.renderForm()}
					{belowForm()}
				</div>
			</>
		)
	}
};

SparkroomFormBase.propTypes = {
	aboveForm: PropTypes.func.isRequired,
	belowForm: PropTypes.func.isRequired,
	buttonText: PropTypes.string.isRequired,
	className: PropTypes.string,
	customDisclaimer: PropTypes.bool.isRequired,
	pageProgram: PropTypes.string.isRequired,
	isClearwaterProgram: PropTypes.bool.isRequired,
	isProgramPage: PropTypes.bool,
	isQuiz: PropTypes.bool,
	pageClass: PropTypes.string,
	template: PropTypes.string,
	onPostFormLoad: PropTypes.func
}

SparkroomFormBase.defaultProps = {
	aboveForm: () => null,
	belowForm: () => null,
	buttonText: "Request Info",
	className: "spformwrap",
	customDisclaimer: false,
	pageProgram: '',
	isClearwaterProgram: false,
	isProgramPage: false,
	isQuiz: false,
	pageClass: '',
	template: ''
}

const mapStateToProps = state => {
	return { 
		_r_formParams: state._r_formParams,
		_r_globals: state._r_globals
	};
};

export default connect(mapStateToProps)(SparkroomFormBase);