import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HeroScalableLogo from './HeroScalableLogo';
import { above } from '../../styles/utilities';

const HeroScalable = ({className, displayLogo, imgBig, imgSmall, template}) => {
  return (
    <section className={`${className}`}>
      { displayLogo && <HeroScalableLogo heroType="scalable" template={template} /> }
      <Img 
        className="img-big"
        fluid={imgBig.localFile.childImageSharp.fluid || imgBig.childImageSharp.fluid}
        alt={imgBig.alt_text}
        placeholderStyle={{
          display: 'none'
        }}
        durationFadeIn={250}
      />
      <Img 
        className="img-small"
        fluid={imgSmall.localFile.childImageSharp.fluid || imgSmall.childImageSharp.fluid}
        alt={imgSmall.alt_text}
        placeholderStyle={{
          display: 'none'
        }}
        durationFadeIn={250}
      />
    </section>
  )
}

HeroScalable.propTypes = {
  displayLogo: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  imgBig: PropTypes.object.isRequired,
  imgSmall: PropTypes.object.isRequired,
  template: PropTypes.string.isRequired
}

export default styled(HeroScalable)`

  position: relative;

  .img-big {
    display: none;
  }

  ${above.md`
    .img-small {
      display: none;
    }
    .img-big {
      display: block;
    }
  `};

`;