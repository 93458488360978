import React from 'react'

import cx from 'classnames'
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa'
import styled from 'styled-components'

import { above, colors } from '../../../../styles/utilities'

export type SocialLinksProps = {
  className?: string
}

const SocialLinks = ({ className }: SocialLinksProps) => (
  <div className={cx(className, 'social-icons')}>
    <a
      href="https://www.facebook.com/umaonline/"
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Ultimate Medical Academy on Facebook"
    >
      <FaFacebookF className="facebook" />
    </a>
    <a
      href="https://twitter.com/UltimateMedical"
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Ultimate Medical Academy on Twitter"
    >
      <FaTwitter className="twitter" />
    </a>
    <a
      href="https://www.pinterest.com/ultimatemedical/"
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Ultimate Medical Academy on Pinterest"
    >
      <FaPinterest className="pinterest" />
    </a>
    <a
      href="https://www.linkedin.com/school/ultimate-medical-academy/"
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Ultimate Medical Academy on LinkedIn"
    >
      <FaLinkedin className="linkedin" />
    </a>
    <a
      href="https://www.youtube.com/channel/UCZKEpFCt6DQKM_eyZ3jJhGw"
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Ultimate Medical Academy on YouTube"
    >
      <FaYoutube className="youtube" />
    </a>
    <a
      href="https://www.instagram.com/ultimatemedicalacademy/"
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Ultimate Medical Academy on Instagram"
    >
      <FaInstagram className="instagram" />
    </a>
  </div>
)

export default styled(SocialLinks)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  ${above.sm`
    justify-content: flex-start;
  `}
  a {
    margin: 0 0.625rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    svg {
      font-size: 1.875rem;
      color: ${colors.umaDarkGray};
      transition: all 0.3s ease-in-out;
      ${above.sm`
        font-size: 2.5rem;
      `}
      ${above.md`
        font-size: 2.5rem;
      `}
      &:hover {
        transform: scale(1.1);
      }
      &.facebook:hover,
      &.facebook:focus {
        color: ${colors.social.facebook};
      }
      &.instagram:hover,
      &.instagram:focus {
        color: ${colors.social.instagram};
      }
      &.linkedin:hover,
      &.linkedin:focus {
        color: ${colors.social.linkedIn};
      }
      &.pinterest:hover,
      &.pinterest:focus {
        color: ${colors.social.pinterest};
      }
      &.twitter:hover,
      &.twitter:focus {
        color: ${colors.social.twitter};
      }
      &.youtube:hover,
      &.youtube:focus {
        color: ${colors.social.youTube};
      }
    }
  }
`
