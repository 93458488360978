import React from 'react'

import { graphql } from 'gatsby'
import htmr from 'htmr'
import styled from 'styled-components'

import ImageToutsCareMantra from '../components/cross-template-statics/ImageToutsCareMantra'
import RememberTraceParams from '../components/effects/RememberTraceParams'
import Form from '../components/forms/AcormFormWrapper'
import GlobalFooter from '../components/GlobalFooter'
import DRTVHeroScalable from '../components/heroes/DRTVHeroScalable'
import LogoSquare from '../components/LogoSquare'
import VideoLazyload from '../components/reusables/VideoLazyload'
import YouTubeEmbed from '../components/reusables/YouTubeEmbed'
import HowCarePaysBack from '../components/ScopedToTemplates/we-care-call/HowCarePaysBack'
import SimpleQuote from '../components/ScopedToTemplates/we-care-call/SimpleQuote'
import SEO from '../components/SEO'
import useGa from '../hooks/useGa'
import Layout from '../layouts/DefaultLayout'
import { store } from '../redux'
import { AcornFormParams } from '../redux/actions'
import { above, colors } from '../styles/utilities'
import findInObject from '../utilities/findInObject'
import sp from '../utilities/shortcodeParser'

const CareMantraDsFrankenstein = ({ className, data }) => {
  const onlineOnly = findInObject('onlineOnly', data.markdown.frontmatter)

  const settings = {
    allowNull: true,
  }

  const leadsource = findInObject(
    'tracking.leadsource',
    data.markdown.frontmatter,
    settings,
  )
  const leadvendor = findInObject(
    'tracking.leadvendor',
    data.markdown.frontmatter,
    settings,
  )
  const vendor = findInObject(
    'tracking.vendor',
    data.markdown.frontmatter,
    settings,
  )
  const phoneNumber = findInObject('phone_number', data.markdown.frontmatter, {
    strictMode: true,
  })

  if (leadsource) {
    store.dispatch(AcornFormParams.updateParamValue('LeadSource', leadsource))
  }
  if (leadvendor) {
    store.dispatch(AcornFormParams.updateParamValue('LeadVendor', leadvendor))
  }
  if (vendor) {
    store.dispatch(AcornFormParams.updateParamValue('Vendor', vendor))
  }

  const excludePrograms = data?.markdown?.frontmatter?.v1?.form?.excludePrograms?.map(
    (p) => p.code,
  )

  const formEvent = useGa()

  const sortPrograms = (programEdges) => {
    return programEdges.sort((a, b) => {
      const aNorm = a.node.meta.program_full_name.toLowerCase()
      const bNorm = b.node.meta.program_full_name.toLowerCase()
      if (aNorm < bNorm) {
        return -1
      } else if (aNorm > bNorm) {
        return 1
      }
      return 0
    })
  }

  let sortedOnlinePrograms = sortPrograms(data.onlineDegreePrograms.edges)
  sortedOnlinePrograms = sortedOnlinePrograms.filter(
    (p) => p.node.meta.program_code !== 'UNSPEC',
  )
  sortedOnlinePrograms = sortedOnlinePrograms.map((p) => {
    if (p.node.meta.program_code !== 'HHS') {
      p.node.slug = p.node.slug.replace('and-', '')
    }
    return p
  })

  return (
    <Layout>
      <RememberTraceParams whichForm="acorn" />

      <SEO
        title="Answer Your Calling to Care"
        description="Life can be more rewarding when we care for others. With care, small ripples can become waves that have the potential to strengthen communities and inspire other acts of care."
      />

      <div className={className}>
        <DRTVHeroScalable
          bgPosition="50% left"
          bgImgSm={data.markdown.frontmatter.hero.img_sm.path.publicURL}
          bgImgLg={data.markdown.frontmatter.hero.img_lg.path.publicURL}
          phoneNumber={phoneNumber}
        />

        <div className="container py-sm-4 py-xl-5">
          <div className="row">
            <div className="col-xl-6 order-2 order-xl-1 pt-5 pt-xl-0">
              {htmr(sp(data.markdown.frontmatter.content.aboveVideo))}
              <VideoLazyload
                posterAlt="We care."
                posterSrc={
                  data.markdown.frontmatter.content.videoPoster.path.publicURL
                }
                includePlayIcon
              >
                <YouTubeEmbed
                  className="video-iframe"
                  width={999}
                  videoId={data.markdown.frontmatter.content.video}
                  title="Care Pays Back"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </VideoLazyload>
              {/* this content below the video is hard coded not from the .md file */}
              <p>
                Because when you can’t help but care, the world can’t help but
                notice. We can help turn your passion to care for others into a
                career path with one of our 11 flexible study, online healthcare
                programs.<sup>1</sup> More than 85,000 alumni nationwide have
                earned their healthcare education with UMA.
              </p>
            </div>
            <div
              className="col-xl-5 offset-xl-1 p-0 order-1 order-xl-2"
              id="request-info-form"
            >
              <div className="drtv-form-wrap p-4">
                <Form
                  beforeSubmitCallback={() =>
                    formEvent({
                      category: 'rfi-form',
                      action: 'submit',
                      label: 'under-hero',
                    })
                  }
                  redirect={`/confirmation/?campaign=we-care&phone=${phoneNumber}`}
                  aboveForm={() => (
                    <h2 className="mt-0 text-white text-center">
                      Pursue a healthcare career.
                    </h2>
                  )}
                  excludePrograms={excludePrograms}
                  className="drtv-form"
                  overrideFormLabels={{
                    Campus: 'How would you like to study?',
                  }}
                  removeFormParamsAndSpecifyDefaultValues={(() => {
                    if (onlineOnly) {
                      return {
                        Campus: '45',
                      }
                    }
                    return {
                      State: 'GU',
                    }
                  })()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="program-stack wp-content" id="virtual-events">
          <div className="online">
            <div className="container">
              <div className="row d-flex">
                <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                  <div
                    className="bg-img"
                    style={{
                      backgroundImage: `
                      url(${data.markdown.frontmatter.onlinePrograms.img.path.publicURL})
                    `,
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 p-4 text order-1 order-md-2">
                  <h2 className="text-white">
                    Go back to school online!
                    <br />
                    You can do this. We can help.
                  </h2>
                  <p>
                    Experience what it’s like to be an online student and UMA’s
                    transformative student support by attending one of our
                    upcoming virtual events.
                  </p>
                  <ul>
                    <li>
                      Find out how our support specialists help others like you.{' '}
                      <a
                        className="fff"
                        href="https://uma22.vfairs.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Register for Virtual&nbsp;Fair
                      </a>
                    </li>
                    <li>
                      Learn more about the passion you’d like to pursue.{' '}
                      <a
                        className="fff"
                        href="https://umawebinars.vfairs.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Sign&nbsp;Up for On-Demand Webinar&nbsp;Series
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HowCarePaysBack />

        <ImageToutsCareMantra
          content={() => (
            <>
              <h2>START YOUR STUDENT JOURNEY TODAY!</h2>
              <p>
                As a UMA student, you’ll receive individual student services
                such as:
              </p>
            </>
          )}
        />

        <SimpleQuote />

        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <LogoSquare />
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <p>
                  If you’re committed to doing more, to seeking a new path, and
                  to making a difference in your life, UMA is ready to help get
                  you started. Begin your journey today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        {!onlineOnly && (
          <p className="m-0">
            <small>
              <sup>2</sup> Nursing Assistant diploma program remains fully
              on-campus.
            </small>
          </p>
        )}
        <p className="m-0 mb-1">
          <small>
            <sup>1</sup> Set schedules required for on-site externship/practicum
            courses in select programs and coursework deadlines are set by
            instructors.
          </small>
        </p>
      </div>

      <GlobalFooter phone={phoneNumber} sticky />
    </Layout>
  )
}

export default styled(CareMantraDsFrankenstein)`
  p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .section--hero {
    background-color: ${colors.umaDarkBlue};
    min-height: 126vw;
    padding: 1rem 0 2rem;

    ${above.md`
      min-height: 40vw;
    `}
    ${above.xl`
      min-height: 30vw;
    `}

    .phone {
      z-index: 0;
    }
  }

  .section--hero .d-md-none.hero-img {
    margin-top: 3rem;
    z-index: 0;
  }

  .section--hero .d-none.d-md-block.hero-img {
    z-index: 0;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1,
  h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .hero-content {
    .row {
      padding-top: 8rem;

      ${above.xl`
        padding-top: 2rem;
        padding-bottom: 2rem;      
      `}
    }

    p {
      color: #fff;
      font-size: 3rem;
      line-height: 1;
      text-transform: uppercase;

      ${above.xl`
        font-size: 4rem;
      `}
    }

    .arrow {
      background-color: ${colors.umaOrange};
      box-shadow: 8px 8px 6px hsla(0, 0%, 0%, 0.4);
      border-radius: 1px;
      display: inline-block;
      margin-top: 1.5rem;
      position: relative;
      max-width: 88%;

      svg {
        border-radius: 1px;
        fill: ${colors.umaOrange};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        width: auto;
      }
    }

    .arrow p {
      font-size: 1.5rem;

      ${above.md`
        font-size: 2rem;
      `}
    }
  }

  .page-content {
    max-width: 75rem;

    ${above.xl`
      max-width: 72rem;
    `}

    h1 {
      margin-top: 0;
    }

    li {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    strong {
      line-height: 1.25;
      font-weight: bold;
    }
  }

  .program-stack {
    color: white;
    margin-top: 3rem;

    ${above.md`
      margin-top: 8rem;
    `}

    .heading {
      color: white;
    }

    .Link {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .online,
    .clearwater {
      position: relative;

      .bg-img-wrap,
      .text {
        ${above.md`
          margin-top: -3.45rem;
        `}
      }

      .bg-img-wrap {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        overflow: hidden;
      }

      .text {
        background-color: ${colors.umaDarkBlue};
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        overflow: hidden;
      }
    }

    .online::before,
    .clearwater::before {
      content: '';
      display: block;
      background-color: ${colors.umaGreenNew};
      position: absolute;
      top: 3.45rem;
      right: 0;
      bottom: 3.45rem;
      left: 0;

      ${above.md`
        top: 0; bottom: -3.45rem;
        padding-bottom: 3.45rem;
      `}
    }

    .online {
      ${above.lg`
        margin-bottom: 4rem;
      `}

      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }

    .clearwater {
      ${above.md`
        margin-bottom: 4rem;
        margin-top: 15rem;
      `}
      .heading {
        background-color: ${colors.umaOrange};
      }
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .drtv-form-wrap {
    position: relative;
    z-index: 0;

    ${above.xl`
      box-shadow: -8px -8px 6px hsla(0, 0%, 0%, 0.4);
      margin-top: -10rem;
    `}

    &::before {
      background-color: ${colors.umaDarkBlue};
      border-radius: 2px;
      content: '';
      display: block;
      opacity: 0.9;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    .drtv-form {
      margin: 0 auto;
      max-width: 500px;

      button[type='submit'] {
        background-color: ${colors.umaGreenNew};
        color: ${colors.umaBlue400};
      }
    }
  }
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    .video-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const query = graphql`
  query($id: String!) {
    markdown: mdx(id: { eq: $id }) {
      frontmatter {
        onlineOnly
        phone_number
        v1 {
          form {
            excludePrograms {
              code
            }
          }
        }
        tracking {
          leadsource
          leadvendor
          vendor
        }
        hero {
          img_sm {
            path {
              publicURL
            }
          }
          img_lg {
            path {
              publicURL
            }
          }
          text
        }
        clearwaterPrograms {
          img {
            path {
              publicURL
            }
          }
        }
        onlinePrograms {
          img {
            path {
              publicURL
            }
          }
        }
        content {
          aboveVideo
          video
          videoPoster {
            path {
              publicURL
            }
          }
        }
      }
      body
    }

    clearwaterDiplomaPrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "diploma" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    clearwaterDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_code }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    onlineDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "45" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
          slug
        }
      }
    }

    onlineDiplomaPrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "45" }, program_type: { eq: "diploma" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
          slug
        }
      }
    }
  }
`
