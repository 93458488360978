import React from 'react';
import PropTypes from 'prop-types';

const YouTubeEmbed = ({className, height, style, title, videoId, width}) => (

  <iframe
    className={className}
    width={width}
    height={height}
    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&rel=0`}
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
    title={title}
    style={style}
  />

);

YouTubeEmbed.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]).isRequired
}

YouTubeEmbed.defaultProps = {
  height: false,
  width: false
};

export default YouTubeEmbed;