import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from '../../../styles/utilities/index'

const SimpleQuote = ({ className }) => (
  <div className={className} id="simple-quote">
    <div className="container p-5 text-center">
      <p className="quote fff">
        “It’s a place where the staff cares not only about your academic
        progress but also about personal things, like how you’re feeling. UMA
        saw me through my struggles and celebrated my achievements. And now I am
        living a healthy and successful life.”
      </p>
      <p className="author fff mb-0">Angel P. | UMA Graduate</p>
    </div>
  </div>
)

export default styled(SimpleQuote)`
  background-color: ${colors.umaDarkGray};
  padding: ;
`

SimpleQuote.propTypes = {
  className: PropTypes.string,
}

SimpleQuote.defaultProps = {
  className: '',
}
