import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import GlobalStyle from '@Style/Global'
import Seo from '@Components/Seo'

const DefaultLayout = ({ children, className, seo }) => {
  return (
    <div className={cx('layout--default', className)}>
      <Seo seo={seo} />
      {children}
      <GlobalStyle />
    </div>
  )
}

DefaultLayout.propTypes = {
  className: PropTypes.string,
}

DefaultLayout.defaultProps = {}

export default DefaultLayout
