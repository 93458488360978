/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react')

const { Provider } = require('react-redux')

const { store } = require('./src/redux')
const { FootnoteProvider } = require('./src/v3/lib/context/FootnoteContext')
const { ModalProvider } = require('./src/v3/lib/context/ModalContext')

const RootElement = ({ element }) => {
  return (
    <ModalProvider>
      <FootnoteProvider>
        <Provider store={store}>{element}</Provider>
      </FootnoteProvider>
    </ModalProvider>
  )
}

exports.wrapRootElement = RootElement

const fbCallTrackingPages = [
  {
    href: '/power-in-numbers',
    function: () => {
      window.fbq('track', 'LN Call')
    },
  },
  {
    href: '/clearwater/care',
    function: () => {
      window.fbq('track', 'Contact', {
        buttonType: 'Click to Call',
      })
    },
  },
]

exports.onRouteUpdate = (location) => {
  const page = fbCallTrackingPages.find(
    (obj) => obj.href === location.location.pathname,
  )
  if (page) {
    document.querySelectorAll('.fb-track-click').forEach((el) => {
      el.addEventListener('click', (e) => {
        page.function()
      })
    })
  }
}
