import { ReduxAction, uma } from '../../types'
import { UMAAction } from '../actions'
import { UMAInitialState } from '../initial-state'


const umaReducer = (previousState: uma.umaState = UMAInitialState, action: ReduxAction) => {

  if(action.type === UMAAction.names.UPDATE_CAMPUS) {
    const newState = {...previousState}
    newState.campus = action.payload
    return newState
  }

  if(action.type === UMAAction.names.UPDATE_PHONE) {
    const newState = {...previousState}
    newState.phone = action.payload
    return newState
  }

  return previousState;
}

export default umaReducer