import React from 'react'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LeadReengagementImageSection from '../../components/cross-template-statics/LeadReengagementImageSection'
import HeaderWithoutMenu from '../../components/HeadersAndHeroes/HeaderWithoutMenu'
import HeroScalableNoLocalFile from '../../components/HeadersAndHeroes/HeroScalableNoLocalFile'
import Layout from '../../components/Layout'
import useGa from '../../hooks/useGa'
import { above } from '../../styles/utilities'
import { colors } from '../../styles/utilities/colors'
import CNContentTop from '../../templates/chat-now/CNContentTop'

const PressPlay = ({ className, data }) => {
  const formEvent = useGa()

  const phoneNumber = '877-253-0713'

  const { heroImgLg, heroImgSm } = data

  const chatBox = {
    chat_box_color: {
      label: '',
      value: '#0095C8',
    },
    chat_button_color: {
      label: '',
      value: '#78BE20',
    },
    disclaimer: '',
    heading: 'Pursue a Healthcare Career',
  }

  const pageContent =
    '<h1 class="h1 mt-0">Press play on your future.</h1><h3 class="h3">Pursue your healthcare education journey at Ultimate Medical Academy.</h3><p class="">Timing is everything. This could be your time to prioritize your goals and get the skills you need to pursue a field that is growing much faster than average.{{ function: "fn", text: "Bureau of Labor Statistics. Occupational Outlook Handbook. Healthcare Occupations. <a href=\'https://bls.gov/ooh/healthcare/home.htm\' rel=\'noopener noreferrer\' target=\'_blank\'>https://bls.gov/ooh/healthcare/home.htm</a>" }} In fact, America needs competent and compassionate healthcare workers. Let us help you un-pause your career path today.</p>'

  return (
    <Layout
      phone={phoneNumber}
      sticky={{
        chatBtnId: 'LP_DIV_1432733350793',
        chat_button_id_select: {
          label: 'Custom',
          value: 'LP_DIV_1432733350793',
        },
        display_sticky_footer: false,
      }}
    >
      <div className={className}>
        <div className="top-section">
          <div className="container">
            <HeaderWithoutMenu phoneNumber={phoneNumber} />
          </div>
        </div>

        <div className="hero-section">
          <HeroScalableNoLocalFile
            displayLogo={false}
            imgBig={heroImgLg}
            imgSmall={heroImgSm}
            template="brand"
          />
        </div>

        <div className="page-content">
          <CNContentTop
            chatBox={chatBox}
            chatButtonColor="#15EAC4"
            chatTextColor="#0d3553"
            chatIDForChatBox="LP_DIV_lead_nurturing_3"
            chatIDForMobileContent="LP_DIV_lead_nurturing_2"
            pageClass=""
            general={{}}
            content={pageContent}
          />
        </div>

        <div className="green-section-below-form md-content">
          <div className="container">
            <h3 className="fff mt-0 mb-2">
              Not to worry – you won’t have to do this alone.
            </h3>
            <p className="my-2">
              When it comes to support, here’s the UMA playlist:{' '}
            </p>
            <ul className="row">
              <div className="col-12 col-md-6">
                <li>One-on-one academic support</li>
                <li>24/7 technical support</li>
                <li>Financial aid (for those who qualify)</li>
              </div>
              <div className="col-12 col-md-6">
                <li>Job interview coaching</li>
                <li>Job search assistance</li>
                <li>Laptops (for those who qualify)</li>
              </div>
            </ul>
          </div>
        </div>

        <LeadReengagementImageSection />
      </div>
    </Layout>
  )
}

export default styled(PressPlay)`
  .top-section {
    background-color: ${colors.umaUltimateLightGray};
    padding: 1rem 0;
  }
  .content-left {
    .h1,
    .h3 {
      color: ${colors.umaDarkGray};
    }
  }
  [class^='CNChatBox'] {
    ${above.md`
      transform: translateY(-1rem);
    `}
  }
  .green-section-below-form {
    background-color: #f9f9f9;
    // color: #fff;
    padding: 2rem 0;
    li:before {
      // color: #fff;
    }
    h3 {
      color: #505759;
    }
  }
  .image-and-program-toggle {
    button.tab.inactive {
      cursor: pointer;
    }
  }

  [class^='CNChatBox'] {
    background-color: #1d6ba6;
    .btn-text {
      background-color: #15eac4;
    }
    .btn-text * {
      color: #0d3553;
    }
  }

  [class^='CNContentTop'] .chat {
    * {
      color: #0d3553;
      font-weight: 700;
    }
  }

  #LP_DIV_lead_nurturing_2 {
    min-height: 60.5px;
    padding: 1rem;
  }
`

PressPlay.propTypes = {
  className: PropTypes.string.isRequired,
}

export const query = graphql`
  {
    heroImgLg: file(
      absolutePath: { regex: "/lead-reengagement/lead-re-engagement-hero-lg/" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImgSm: file(
      absolutePath: { regex: "/lead-reengagement/lead-re-engagement-hero-sm/" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
