export const initialAcornFormConfig = [
  {
    key: 'SID',
    value: '13462',
  },
  {
    key: 'TCPAConsent',
    value: 'Y',
  },
  {
    key: 'VendorLeadID',
    override: /^vendorleadid$/gi,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_adgroup',
    override: /utm_adgroup/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_campaign',
    override: /utm_campaign/,
    excludeIfFalsy: true,
  },
  {
    key: 'adgroupid',
    override: /utm_content/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_source',
    override: /utm_source/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_term',
    override: /utm_term/,
    excludeIfFalsy: true,
  },
  {
    key: 'utm_medium',
    override: /utm_medium/,
    excludeIfFalsy: true,
  },
  {
    key: 'gclid',
    override: /gclid/,
    excludeIfFalsy: true,
  },
  {
    key: 'CID',
    override: /^cid$/gi,
    value: 'SR_U_10365',
  },
  {
    key: 'PostalCodeOrZip',
    value: '00000',
  },
  {
    key: 'LeadSource',
    override: /^(leadsource|ld_src)$/gi,
    value: 'INTS-PPC',
  },
  {
    key: 'LeadVendor',
    override: /^leadvendor$/gi,
    value: 'PSM',
  },
  {
    key: 'Vendor',
    override: /^vendor$/gi,
    value: 'PSM',
  },
  {
    key: 'LeadBuyerID',
    override: /^leadbuyerid$/gi,
    value: 'UMA',
  },
  {
    key: 'relax_val',
    value: 1,
  },
  {
    key: 'Address1',
    value: 'Unspecified',
  },
  {
    key: 'City',
    value: 'Unspecified',
  },
  {
    key: 'PathLabel',
    value: typeof window !== 'undefined' ? window.location.href : 'undefined',
  },
]
