// Node Module Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import parse from 'react-html-parser';
import { FaPlayCircle } from 'react-icons/fa';
import { colors } from '../../styles/utilities';

const VideoLazyload = ({
  children, className, includePlayIcon, posterAlt, posterGatsbyImgType,
  posterSrc, videoEmbedString
}) => {

  const [videoClicked, setVideoClicked] = useState(false);

  const clickVideo = () => {
    if(!videoClicked) {
      setVideoClicked(true);
    }
  };

  const keyPressVideo = e => {
    if(!videoClicked && e.key === 'Enter') {
      setVideoClicked(true);
    }
  };

  const renderGatsbyImage = () => {
    
    if(posterGatsbyImgType && posterGatsbyImgType === 'fixed') {
      return (
        <>
          <Img 
            alt={posterAlt}
            className="video-placeholder"
            fixed={posterSrc}
            objectPosition="left bottom"
          />
          {includePlayIcon && <FaPlayCircle className="play-icon" />}
        </>
      );
    }

    else if(posterGatsbyImgType && posterGatsbyImgType === 'fluid') {
      return (
        <>
          <Img 
            alt={posterAlt}
            className="video-placeholder"
            fluid={posterSrc}
            objectPosition="left bottom"
          />
          {includePlayIcon && <FaPlayCircle className="play-icon" />}
        </>
      );
    }

    return null;
    
  };

  const renderImage = () => (
    <>
      <img src={posterSrc} alt={posterAlt} />
      {includePlayIcon && <FaPlayCircle className="play-icon" />}
    </>
  );

  return (
    <div className={className}>
      <button 
        className="video-wrapper"
        onClick={clickVideo}
        onKeyPress={keyPressVideo}
      >
        {!videoClicked && posterGatsbyImgType && renderGatsbyImage()}
        {!videoClicked && !posterGatsbyImgType && renderImage()}
        {videoClicked && videoEmbedString && parse(videoEmbedString)}
        {videoClicked && children}
      </button>
    </div>
  );


};

VideoLazyload.propTypes = {
  className: PropTypes.string.isRequired,
  includePlayIcon: PropTypes.bool,
  posterAlt: PropTypes.string.isRequired,
  posterGatsbyImgType:PropTypes.oneOf(['fixed', 'fluid', false]),
  posterSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  videoEmbedString: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element
  ]).isRequired
}

VideoLazyload.defaultProps = {
  includePlayIcon: false,
  gatsbyImg: false,
  children: false,
  videoEmbedString: false
}

export default styled(VideoLazyload)`
  font-size: 0;
  button {
    cursor: pointer;
    border: none;
    font-size: 0;
    margin: 0;
    padding: 0;
    position: relative;
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 5rem;
      fill: ${colors.umaUltimateLightGray};
      transform: scale(1) translate(-50%, -50%);
      transition: all .2s ease-in-out;
    }
    &:hover {
      .play-icon {
        transform: scale(1.15) translate(-42%, -42%);
      }
    }
  }
`;