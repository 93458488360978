import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { above } from '../../styles/utilities';

const PS92020HeroScalable = ({bgImgSm, bgImgLg, bgPosition, children, className}) => {

  return (
    <div className={`section--hero ${className}`}>

      <div 
        className="d-sm-none hero-img"
        style={{
          backgroundImage: `url(${bgImgSm})`
        }} />
      <div 
        className="d-none d-sm-block hero-img"
        style={{
          backgroundImage: `url(${bgImgLg})`
        }} />

      {children}

    </div>
  );
};

PS92020HeroScalable.propTypes = {
  bgImgSm: PropTypes.string.isRequired,
  bgImgLg: PropTypes.string.isRequired,
  bgPosition: PropTypes.string
};

PS92020HeroScalable.defaultProps = {
  bgPosition: '30% center'
}

export default styled(PS92020HeroScalable)`
  padding: 2rem 0;
  position: relative;
  min-height: 112vw;
  ${above.sm`
    min-height: 45vw;
  `}

  .hero-img {
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;

    ${above.sm`
      background-position: ${props => props.bgPosition};
    `}
  }
`;
