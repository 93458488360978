// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LineQuote = ({className, character}) => (
  <div className={`${className} lq-div`}>
    <span className="lq-span">{character}</span>
  </div>
)

LineQuote.propTypes = {
  character: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  divMargin: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  lineWidth: PropTypes.number.isRequired,
  transY: PropTypes.number.isRequired
}

export default styled(LineQuote)`
  &.lq-div {
      margin: ${props => `${props.divMargin}`};
      overflow: hidden;
      line-height: 0.5;
    }
    .lq-span {
      transform: ${props => `translateY(${props.transY}rem)`};
      font-family: Georgia, serif;
      font-size: ${props => `${props.fontSize}rem`};
      display: inline-block;
      position: relative;
      color: ${props => props.color};
      :before, :after {
        position: absolute;
        content: "";
        border-bottom: ${props => `2px solid ${props.color}`};
        width: ${props => `${props.lineWidth}rem`};
      };
      :before {
        right: 100%;
        margin-right: 1rem;
      }
      :after {
        left: 100%;
        margin-left: 1rem;
      }
    }
`