import React, { useState } from 'react'

type VideoEmbedProps = {
  className?: string
  embed: () => React.ReactElement
  poster: () => React.ReactElement
}

const VideoEmbed = ({ className, embed, poster }: VideoEmbedProps) => {
  const [videoClicked, setVideoClicked] = useState(false)

  function handleClick() {
    if (!videoClicked) setVideoClicked(true)
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLButtonElement>) {
    if (!videoClicked && e.key === 'Enter') setVideoClicked(true)
  }

  return (
    <div className={className}>
      <button
        onClick={handleClick}
        onKeyPress={(e) => handleKeyPress(e)}
        type="button"
      >
        <div className="video-container">
          {poster()}
          {videoClicked && embed()}
        </div>
      </button>
    </div>
  )
}

VideoEmbed.defaultProps = {
  className: '',
}

export default VideoEmbed
