import { graphql } from 'gatsby';
import React, { Component } from 'react';
import { store } from '../../redux';
import { ClassList } from '../../utilities';
import Layout  from '../../components/Layout';
import Bricks from '../../components/reusables/Bricks';
import { addOrUpdateFormParams } from '../../redux/actions';
import HeroGlobal from '../../components/HeadersAndHeroes/HeroGlobal';
import BPContentAndForm from '../../templates/brand-programs/BPContentAndForm';
import { determineNeededFormParams } from '../../utilities/forms';
import RememberTraceParams from '../../components/effects/RememberTraceParams';

export default class BrandOrProgramsTemplate extends Component {

  render() {

    const { pageContext } = this.props;
    const { allWordpressUmaBricks, wordpressPage, allWordpressUmaPrograms } = this.props.data;
    const { content, /* title */ } = wordpressPage;
    const selectedCampus = wordpressPage.acf.uma_program_association.campus.label
    const { form, hero, tracking } = wordpressPage.acf.uma_template_brand;
    const bricks = allWordpressUmaBricks.edges;
    const brickSections = this.props.pageContext.brickSections;
    const selectedProgram = this.props.pageContext.programs[0];
    const isProgramPage = this.props.pageContext.programs.length === 1 ? true : false;
    const pageClass = new ClassList(pageContext.pageClass);

    const formParams = determineNeededFormParams(pageClass, selectedCampus, tracking);
    store.dispatch(addOrUpdateFormParams(formParams));

    // console.log({data});

    return (
      <Layout
        seo={wordpressPage.acf.uma_page_settings_seo}
        showDisclaimer={true}
        sticky={wordpressPage.acf.uma_page_settings_sticky_footer} 
        phone={tracking.phone_number}
      >

        <RememberTraceParams whichForm="sparkroom" />

        <HeroGlobal
          displayLogo={true}
          hero={hero}
          imgBig={hero.hero_image_lg}
          imgSmall={hero.hero_image_sm}
          pageClass={pageContext.pageClass}
          template="brand"
        />

        <BPContentAndForm
          content={content}
          form={form}
          isProgramPage={isProgramPage}
          selectedCampus={selectedCampus}
          selectedProgram={selectedProgram}
          tracking={tracking}
          pageClass={pageContext.pageClass}
        />
        <Bricks
          queriedBricks={bricks}
          programs={allWordpressUmaPrograms.edges}
          selectedCampus={selectedCampus} 
          brickSections={brickSections} 
        />
      </Layout>
    )
  }
};

export const query = graphql`
  query($id: String!, $brickIds: [Int]!, $programs: [String]) {

    wordpressPage(id: {eq: $id}) {
      title
      content
      acf {
        uma_page_settings_sticky_footer {
          display_sticky_footer
          chat_button_id_select {
            label
            value
          }
          chat_button_id
        }
        uma_page_settings_seo {
          page_title
          meta_description
        }
        uma_template_brand {
          form {
            button_text
            cta_text_lg
            cta_text_sm
            form_bg_color
            button_color {
              value
            }
          }
          hero {
            hero_image_lg {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            hero_image_sm {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 640, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            logo_type
            hero_text_full
            hero_text_bold
            hero_text_not_bold
          }
          tracking {
            phone_number
            vendor
            lead_source
          }
        }
        uma_program_association {
          campus {
            label
          }
        }
      }
    }

    allWordpressUmaBricks(filter: {wordpress_id: {in: $brickIds}}) {
      edges {
        node {
          wordpress_id
          post_title
          acf {
            uma_brick {
              type {
                label
                value
              }
            }
            uma_brick_fact_quote {
              fact
              quote
              quote_author
            }
            uma_brick_programs {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth:1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            uma_brick_tout {
              icon {
                localFile {
                  childImageSharp {
                    fixed(quality:100, width:125) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              name
              text
            }
            uma_brick_video {
              poster {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              video_embed_code
            }
            uma_brick_programs_list_degree_diploma {
              text
            }
            uma_brick_wysiwyg
            uma_brick_image_box {
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth:1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allWordpressUmaPrograms(filter: {acf: {uma_program_info: {code: {in: $programs}}}}) {
      edges {
        node {
          acf {
            uma_program_info {
              campus {
                value
              }
              full_name
              type
              classes_in_program {
                class_name
              }
              links {
                link_key
                page_link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
    
  }
`;