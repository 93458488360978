import React from 'react';
import styled from 'styled-components';
import squareLogo from '../images/logos/square-logo.svg';

const LogoSquare = ({className}) => (
  <div className={`${className}`}>
    <img src={squareLogo} alt="UMA Logo" />
  </div>
);

export default styled(LogoSquare)`
  width: 175px;
`