// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import parse from 'react-html-parser';

// Utility Imports
import { shortcodeParser } from '../../utilities';

const WYSIWYG = props => {
  return (
    <div className={props.className}>
      { parse( shortcodeParser( props.fields.uma_brick_wysiwyg ) ) }
    </div>
  )
  }

const colorIsWhite = (color) => {
  color = color.toLowerCase();
  return color === '#fff' || color === '#ffffff' || color === 'rgb(255, 255, 255)';
}

export default styled(WYSIWYG)`
  background-color: ${props => props.bgColor};
  color: ${props => colorIsWhite(props.bgColor) ? 'inherit' : '#fff'};
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: ${props => colorIsWhite(props.bgColor) ? 'inherit' : '#fff'};
  }
  .h1 {
    font-size: 2rem;
  }
  small {
    font-size: 70%;
  }
`;

WYSIWYG.propTypes = {
  bgColor: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
}