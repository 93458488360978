// Node Module Imports
import React, { Component } from "react";
import { graphql } from 'gatsby';

// Component Imports
import Bricks from '../../components/reusables/Bricks';
import CNContentTop from './CNContentTop';
import CNHeader from './CNHeader';
import HeroGlobal from '../../components/HeadersAndHeroes/HeroGlobal';
import Layout from '../../components/Layout';

export default class TemplateChatNow extends Component {

  render() {

    const { data, pageContext } = this.props;
    const page = data.wordpressPage;

    let { 
      general_settings,
      hero,
      sidebar,
      tracking 
    } = page.acf.uma_template_chat_now;


    const { allWordpressUmaBricks, wordpressPage, allWordpressUmaPrograms } = this.props.data;
    const bricks = allWordpressUmaBricks.edges;
    const brickSections = this.props.pageContext.brickSections;
    const selectedCampus = wordpressPage.acf.uma_program_association.campus.label;

    let headerChatBtnColor = general_settings.header_chat_color_override;
    headerChatBtnColor = headerChatBtnColor ? headerChatBtnColor.value : sidebar.chat_button_color.value
    
    let mobileChatBtnColor = general_settings.mobile_chat_color_override;
    mobileChatBtnColor = mobileChatBtnColor ? mobileChatBtnColor.value : sidebar.chat_button_color.value;

    return (
      <Layout
        seo={wordpressPage.acf.uma_page_settings_seo}
        showDisclaimer={true}
        sticky={page.acf.uma_page_settings_sticky_footer}
        phone={page.acf.uma_template_chat_now.tracking.phone_number}
      >
        <CNHeader
          chatButtonColor={headerChatBtnColor}
          chatIDForHeader={general_settings.chat_id_header}
          phone={tracking.phone_number} />
        <HeroGlobal
          bgColorLeft={hero.bg_color_left}
          bgColorRight={hero.bg_color_right}
          displayLogo={true}
          hero={hero}
          imgBig={hero.image_big}
          imgSmall={hero.image_small}
          pageClass={pageContext.pageClass}
          template="chat-now" />
        <CNContentTop
          chatBox={sidebar}
          chatButtonColor={mobileChatBtnColor}
          chatIDForChatBox={general_settings.chat_id_sidebar}
          chatIDForMobileContent={general_settings.chat_id_mobile}
          pageClass={pageContext.pageClass}
          general={general_settings}
          content={page.content} />
        <Bricks
          queriedBricks={bricks}
          programs={allWordpressUmaPrograms.edges}
          selectedCampus={selectedCampus} 
          brickSections={brickSections} />
      </Layout>
    )
  }
};

export const query = graphql`
  query($id: String!, $brickIds: [Int]!, $programs: [String]) {

    wordpressPage(id: {eq: $id}) {
      title
      content
      wordpress_id
      acf {
        uma_program_association {
          campus {
            label
          }
        }
        uma_page_settings_sticky_footer {
          display_sticky_footer
          chat_button_id_select {
            label
            value
          }
          chat_button_id
        }
        uma_page_settings_seo {
          page_title
          meta_description
        }
        uma_template_chat_now {
          general_settings {
            logo_type
            chat_button_text
            chat_id_header
            chat_id_mobile
            chat_id_sidebar
            header_chat_color_override {
              value
            }
            mobile_chat_color_override {
              value
            }
          }
          hero {
            bg_color_left
            bg_color_right
            # bg_color_top
            image_small {
              source_url
              alt_text
              title
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth:1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            image_big {
              source_url
              alt_text
              title
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          sidebar {
            heading
            disclaimer
            chat_box_color{
              label
              value
            }
            chat_button_color {
              label
              value
            }
          }
          tracking {
            vendor
            lead_source
            phone_number
          }
        }
      }
    }
    allWordpressUmaBricks(filter: {wordpress_id: {in: $brickIds}}) {
      edges {
        node {
          wordpress_id
          post_title
          acf {
            uma_brick {
              type {
                label
                value
              }
            }
            uma_brick_fact_quote {
              fact
              quote
              quote_author
            }
            uma_brick_programs {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth:1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            uma_brick_tout {
              icon {
                localFile {
                  childImageSharp {
                    fixed(quality:100, width:125) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              name
              text
            }
            uma_brick_video {
              poster {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality:100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              video_embed_code
            }
            uma_brick_programs_list_degree_diploma {
              text
            }
            uma_brick_wysiwyg
            uma_brick_image_box {
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth:1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allWordpressUmaPrograms(filter: {acf: {uma_program_info: {code: {in: $programs}}}}) {
      edges {
        node {
          acf {
            uma_program_info {
              campus {
                value
              }
              full_name
              type
              classes_in_program {
                class_name
              }
              campus {
                value
              }
              links {
                link_key
                page_link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;