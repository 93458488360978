export const getUrlParam = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] === variable){return pair[1];}
  }
  return(false);
}

export const getUrlParams = (queryString) => {
  queryString = queryString.substr(1);
  queryString = queryString.split('&').filter(paramString => paramString.length > 0);
  queryString = queryString.map(paramString => {
    let param = {};
    paramString = paramString.split('=');
    param.name = paramString[0];
    param.value = paramString[1] || ''
    return param;
  });
  return queryString;
}