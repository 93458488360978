/**
 | -------------------------------------------------------------
 | shortcodeParser
 | -------------------------------------------------------------
 *
 * The shortcodeParser is meant to be run as a filter over some
 * string of content.
 *
 * The current behavior is to look for the {{...}} pattern in a
 * string. If the pattern is found, it will attempt to parse
 * whatever is inside of the pattern as a JSON object. If it
 * fails, it will throw an error during compilation. If it
 * succeeds, if will pass the full chucnk of content, the
 * object created from the shortcode, and the matched regex
 * string to a filter function.
 *
 * You can inject variables into the shortcodeParser lifecycle
 * by inserting them into the "contentFilterVariables" object
 * below. You can then access these variables by using the
 * syntax ${prop.value}. These variables can be accessed
 * anywhere inside the shortcode (i.e. {{...}})
 *
 */
import json5 from 'json5'

import { company, disclaimers, quotes } from '../../../data'
import { colorsDev as colors, space } from '../../../styles/utilities'
import findInObject from '../../../utilities/findInObject'
import colorShortcode from './color'
import colorizeShortcode from './colorize'
import footnoteParser from './footnoteParser'

/**
 * Include functions in this array that you wish
 * to add to the shortcodeParser processing filter.
 * These functions will run anytime shortcodeParser
 * is called.
 *
 * These parsers need to be functions that take three
 * arguments - content, obj, and originalMatch. They need
 * to then return content.
 *
 * See footnoteParser for an implementation
 * example.
 *
 */
const contentFilterFunctions = [
  footnoteParser,
  colorShortcode,
  colorizeShortcode,
]

/**
 * Include variables here that you'd like to be able to
 * access when using the shortcode parser.
 *
 * For example, in Markdown files, you can access the variables
 * defined here like this:
 * {{ type: "footnote", "content": "${footnoteLanguage.programLength}" }}
 *
 * or more simply like this:
 * # Hello ${footnoteLanguage.programLength}
 *
 */
const contentFilterVariables = {
  colors,
  company,
  disclaimers,
  quotes,
  space,
}

/**
 * shortcodeParser
 *
 * TODO document
 *
 * @param {string} fullContentChunk
 * @returns {string} the resolved content chunk
 */
const shortcodeParser = (fullContentChunk) => {
  fullContentChunk = replaceVariables(fullContentChunk)
  fullContentChunk = resolveShortcodesRecursively(fullContentChunk)
  return fullContentChunk
}

/**
 * replaceVariables
 *
 * This method uses regex to find matches that have the "${...}"
 * structure. Once a match is found, it will attempt to match the structure of what's
 * inside to an object in the "accessibleVariables" object that the parser was
 * initialized with. It will replace the match with the value it finds or throw an
 * error if the value does not exist.
 *
 * @param {string} content
 */
const replaceVariables = (content) => {
  if (!content) return content

  const regex = RegExp(/\${\s*(.*?)\s*}/g)
  const matches = content.match(regex)

  if (matches && matches.length && matches.length > 0) {
    matches.forEach((match) => {
      const originalMatch = match
      match = match.replace(/\${/, '').replace(/}/, '')
      const replacement = findInObject(match, contentFilterVariables, {
        strictMode: true,
      })
      if (typeof replacement === 'function') {
        content = content.replace(originalMatch, replacement())
      } else {
        content = content.replace(originalMatch, replacement)
      }
    })
  }

  return content
}

/**
 * resolveShortcodesRecursively
 *
 * TODO
 *
 * @param {string} fullContentChunk
 */
const resolveShortcodesRecursively = (fullContentChunk) => {
  if (!fullContentChunk) return fullContentChunk

  let openBr = 0
  let shortcodeJsonChars = []
  const shortcodes = []

  fullContentChunk.split('').forEach((char, idx) => {
    // Find an instance of "{{" in the content and start counting
    if (char === '{' && idx > 0 && fullContentChunk[idx - 1] === '{') {
      openBr += 1
    }

    // Find an instance of "}}" in the content and decrement counter
    else if (char === '}' && idx > 0 && fullContentChunk[idx - 1] === '}') {
      openBr -= 1
    }

    // If our counter is greater than zero we want to save the characters,
    // as these would be the characters inside the {{ ... }}. This also is
    // inclusive of the inner "{}", which is convenient because we're going
    // to attempt to turn all this into an object anyway.
    if (openBr > 0) {
      shortcodeJsonChars.push(char)
    }

    // If our counter is 0 and we have chars, then we've found a full shortcode.
    // Now we'll push this to an array and collect more (sequential) shortcodes if
    // they exist.
    if (openBr === 0 && shortcodeJsonChars.length) {
      const shortcodeString = shortcodeJsonChars.join('')
      shortcodes.push(shortcodeString)
      shortcodeJsonChars = []
    }
  })

  // check if we've collected (sequential) shortcodes in the string. If we have, we'll
  // resolve them now.
  if (shortcodes.length) {
    shortcodes.forEach((shortcodeString) => {
      const shortcodeObject = createShortcodeObjectRepresentation(
        shortcodeString,
      )

      if (shortcodeObject.content) {
        // attempt to resolve inner shortcodes
        const innerResolve = resolveShortcodesRecursively(
          shortcodeObject.content,
        )

        // if the innerResolve is different than the original shortcode content,
        // then we've resolved a nested shortcode and need to update the full content
        // so that subsequent iterations have updated content
        if (innerResolve !== shortcodeObject.content) {
          fullContentChunk = fullContentChunk.replace(
            `{${shortcodeString}}`,
            innerResolve,
          )
        }
      }

      fullContentChunk = resolveShortcode(
        fullContentChunk,
        shortcodeObject,
        `{${shortcodeString}}`,
      )
    })
  }

  return fullContentChunk
}

/**
 * createShortcodeObjectRepresentation
 *
 * TODO
 *
 * @param {string} rawShortcodeString
 */
const createShortcodeObjectRepresentation = (rawShortcodeString) => {
  try {
    rawShortcodeString = rawShortcodeString
      .replace(/&#8220;/g, '"')
      .replace(/&#8221;/g, '"')
      .replace(/&#8216;/g, "'")
      .replace(/&#8217;/g, "'")
    return json5.parse(rawShortcodeString)
  } catch (err) {
    console.error("couldn't parse the following object: ", rawShortcodeString)
    throw new Error(
      'There was an error during shortcode processing. Check your JS object syntax.',
      err,
    )
  }
}

/**
 * resolveShortcode
 *
 * TODO
 *
 * @param {string} fullContentChunk
 * @param {object - {type: string!} }
 * @param {string} shortcodeString - The full match that was found ( e.g. "{{...}}" )
 */
const resolveShortcode = (
  fullContentChunk,
  shortcodeObject,
  shortcodeString,
) => {
  contentFilterFunctions.forEach((f) => {
    fullContentChunk = f(fullContentChunk, shortcodeObject, shortcodeString)
  })
  return fullContentChunk
}

export { shortcodeParser }
export default shortcodeParser
