// ok becuase we're using implicit label controls
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'

import cx from 'classnames'

import { FieldState } from '../../hooks/useForm'

const InputField = ({ field }: { field: FieldState }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (field.formatter) {
      field.update({
        value: field.formatter(e.target.value),
      })
    } else {
      field.update({ value: e.target.value })
    }
    field.validate()
  }
  const onBlur = () => {
    field.update({ touched: true })
  }

  return (
    <label className={cx('input-wrapper')}>
      <span className="d-flex justify-content-between label-and-error">
        <span className="d-block label-text">
          {field.label}
          {field.markRequired && <span className="req"> *</span>}
        </span>
        <span className="d-block error">
          {field.touched && (field.errors?.[0] || '')}
        </span>
      </span>
      <input
        placeholder={field.placeholder}
        onBlur={onBlur}
        onChange={onChange}
        type={field.type}
        value={field.value}
        name={field.id}
      />
    </label>
  )
}

export default InputField
