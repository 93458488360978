// Node Module Imports
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import parse from 'react-html-parser';

// import { FaYoutube } from 'react-icons/fa';
import { MdPlayArrow } from 'react-icons/md';

// Style Imports
import { above, colors } from '../../styles/utilities';

const VideoFC = ({className, embedCode, posterAlt, posterSrc}) => {

  const [videoClicked, setVideoClicked] = useState(false);
  const playIconWrapper = useRef();

  function handleClick() {
    if (!videoClicked) {
      setVideoClicked(true);
    }
  }

  function handleKeyPress(e) {
    if (!videoClicked && e.key === 'Enter') {
      setVideoClicked(true);
    }
  }

  function handleHover(action) {
    if (videoClicked) {
      return;
    } else {
      action === 'enter' ? playIconWrapper.current.classList.add('hover') : playIconWrapper.current.classList.remove('hover');
    }
  }

  return (
    <div className={className}>
      <button
        className="video-wrapper"
        onClick={handleClick}
        onKeyPress={(e) => handleKeyPress(e)}
        onMouseEnter={() => handleHover('enter')}
        onMouseLeave={() => handleHover('leave')}>
        {!videoClicked && (
          <>
            <Img
              className="video-placeholder"
              fixed={posterSrc}
              alt={posterAlt}
              objectPosition="center top"
              style={{width: '100%', height: '100%'}}
            />
            <span className="play-icon-wrapper" ref={playIconWrapper}>
              <MdPlayArrow />
            </span>
          </>
        )}
        {videoClicked && parse(embedCode)}
      </button>
    </div>
  );
};

export default styled(VideoFC)`
  @keyframes buttonPulse {
    0% { transform: scale(1); }
    24% { transform: scale(1.1); }
    48% { transform: scale(1); }
    74% { transform: scale(1.12); }
    100% { transform: scale(1); }
  }
  button {
    border: none;
    position: relative;
    height: 15rem;
    width: 100%;
    padding: 0;
    outline: none;
    &:hover {
      cursor: pointer;
    }
    ${above.sm`
      height: 24rem;
    `}
    ${above.md`
      height: 30rem;
    `}
    ${above.lg`
      height: 36rem;
    `}
    ${above.xl`
      height: 40rem;
    `}
    .play-icon-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      svg {
        color: #fff;
        background-color: ${colors.umaDarkGray}bb;
        border-radius: 50%;
        padding: 1rem;
        font-size: 4rem;
        ${above.sm`
          padding: 1rem;
          font-size: 6rem;
        `}
        ${above.md`
          padding: 1.4rem;
          font-size: 7.3rem;
        `}
        ${above.lg`
          padding: 1.5rem;
          font-size: 8.2rem;
        `}
        ${above.xl`
          padding: 1.4rem;
          font-size: 9.2rem;
        `}
        path {
          fill: #fff;
        }
      }
      &.hover {
        svg {
          background-color: ${colors.umaDarkGray}dd;
          animation: buttonPulse .6s 1;
        }
      }
    }
    iframe {
      height: 100%;
      width: 100%;
    }
  }
`;

VideoFC.propTypes = {
  className: PropTypes.string.isRequired,
}