import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import ChevronTwoTone from '../backgrounds/ChevronTwoTone'

type AccreditationProps = {
  className?: string
}

const Accreditation = ({ className }: AccreditationProps) => (
  <div className={className}>
    <ChevronTwoTone className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 text-center">
            <p className="lead white my-7 text-center">
              Institutionally Accredited by the Accrediting Bureau of Health
              Education Schools (ABHES.org).
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center flex-wrap">
            <div className="p-7">
              <StaticImage
                src="../../assets/images/external/accreditation/abhes-white.png"
                alt="Accrediting Bureau of Health Education Schools"
                placeholder="none"
              />
            </div>
            <div className="p-7">
              <StaticImage
                src="../../assets/images/external/accreditation/fde-white.png"
                alt="Florida Department of Education"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </div>
    </ChevronTwoTone>
  </div>
)

Accreditation.defaultProps = {
  className: '',
}

export default styled(Accreditation)`
  color: #fff;
`
