import React, { useContext } from 'react'

import FocusTrap from 'focus-trap-react'
import styled from 'styled-components'

import { colors } from '../../../styles/utilities'
import { ModalContext } from '../../lib/context/ModalContext'

export type ModalProps = {
  children: React.ReactNode
  className?: string
}

const Modal = ({ children, className }: ModalProps) => {
  const { closeModal } = useContext(ModalContext)

  return (
    <FocusTrap>
      <div className={className} role="dialog">
        <div
          // TODO: the modal background could be a click-to-close depending on
          // the theme.
          //
          // https://github.com/w3c/aria-practices/issues/325
          // My understanding here is that it's alright to use aria-hidden on a
          // backdrop element because it's obscuring the rest of the site's content.
          // The backdrop element serves as a "click to close" for mouse users, which
          // is a normal pattern, but there will always be a button to close the
          // modal as well for keyboard users.
          // aria-hidden
          className="modal-background"
          // onClick={() => closeModal()}
        />
        <div className="container modal-container py-4">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close mb-3 py-1 px-2"
              onClick={() => closeModal()}
            >
              &times;
            </button>
          </div>
          {children}
        </div>
      </div>
    </FocusTrap>
  )
}

Modal.defaultProps = {
  className: '',
}

export default styled(Modal)`
  .modal-background {
    background-color: ${colors.umaUltimateLightGray};
    position: fixed;
    inset: 0;
    z-index: -1;
  }

  .modal-container {
    margin: 0 auto;
    max-width: 500px;
  }

  .btn-close {
    border: 1px solid black;
    border-radius: 5px;
    background: none;
    color: #000;
    cursor: pointer;
  }
`
