import React from 'react'

import imageSource from '../../assets/icons/dialog.png'
import Tout from './Tout'

const EngagingOnlineLearning = () => (
  <Tout
    image={{ alt: 'dialog', source: imageSource }}
    text="Engaging online learning"
  />
)

export default EngagingOnlineLearning
