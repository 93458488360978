/* eslint-disable camelcase, operator-linebreak */

import programData from '../../_gen/programs.json'
import { UMACampusCode } from './campus'
import { UMAProgram, UMAProgramCode, UMAProgramType } from './program'
import { programLinkMap } from './programLink'

/**
 * A builder that exposes an API to easily filter through UMA programs. Want
 * online degree programs only? Try this:
 * ```
 * programMananger
 *  .campus(UMACampusCode.Online)
 *  .type(UMAProgramType.Degree)
 *  .result()
 * ```
 */
export type ProgramManager = ProgramBuilder

/**
 * A builder that exposes an API to easily filter through UMA programs. Want
 * online degree programs only? Try this:
 * ```
 * programMananger
 *  .campus(UMACampusCode.Online)
 *  .type(UMAProgramType.Degree)
 *  .result()
 * ```
 */
class ProgramBuilder {
  /**
   * Clearwater degree program state.
   */
  #clearwaterDegrees: UMAProgram[]

  /**
   * Clearwater diploma program state.
   */
  #clearwaterDiplomas: UMAProgram[]

  /**
   * Online degree program state.
   */
  #onlineDegrees: UMAProgram[]

  /**
   * Online diploma program state.
   */
  #onlineDiplomas: UMAProgram[]

  constructor({
    clearwaterDegrees,
    clearwaterDiplomas,
    onlineDegrees,
    onlineDiplomas,
  }: {
    clearwaterDegrees: UMAProgram[]
    clearwaterDiplomas: UMAProgram[]
    onlineDegrees: UMAProgram[]
    onlineDiplomas: UMAProgram[]
  }) {
    this.#clearwaterDegrees = clearwaterDegrees
    this.#clearwaterDiplomas = clearwaterDiplomas
    this.#onlineDegrees = onlineDegrees
    this.#onlineDiplomas = onlineDiplomas
  }

  /**
   * Returns a UMAProgram or null if one isn't found. Does not mutate the
   * builder's state.
   */
  only(code: UMAProgramCode): UMAProgram | null {
    const allPrograms = [
      ...this.#clearwaterDegrees,
      ...this.#clearwaterDiplomas,
      ...this.#onlineDegrees,
      ...this.#onlineDiplomas,
    ]
    return allPrograms.filter((program) => program.code === code)?.[0] || null
  }

  /**
   * Returns the resulting UMA Programs after query operations.
   */
  result() {
    return [
      ...this.#clearwaterDegrees,
      ...this.#clearwaterDiplomas,
      ...this.#onlineDegrees,
      ...this.#onlineDiplomas,
    ]
  }

  /**
   * Exclude programs by code from the result. Mutates the builder's state.
   * Returns the builder.
   */
  exclude(codes: UMAProgramCode[]): ProgramManager {
    const clearwaterDegreePrograms = this.#clearwaterDegrees.filter(
      (p) => !codes.includes(p.code),
    )
    const clearwaterDiplomaPrograms = this.#clearwaterDiplomas.filter(
      (p) => !codes.includes(p.code),
    )
    const onlineDegreePrograms = this.#onlineDegrees.filter(
      (p) => !codes.includes(p.code),
    )
    const onlineDiplomaPrograms = this.#onlineDiplomas.filter(
      (p) => !codes.includes(p.code),
    )
    this.#clearwaterDegrees = clearwaterDegreePrograms
    this.#clearwaterDiplomas = clearwaterDiplomaPrograms
    this.#onlineDegrees = onlineDegreePrograms
    this.#onlineDiplomas = onlineDiplomaPrograms
    return this
  }

  /**
   * Include programs by type (i.e. degree) in the result. Mutates the
   * builder's state. Returns the builder.
   */
  type(type: UMAProgramType): ProgramManager {
    if (type === UMAProgramType.Degree) {
      this.#clearwaterDiplomas = []
      this.#onlineDiplomas = []
    } else if (type === UMAProgramType.Diploma) {
      this.#clearwaterDegrees = []
      this.#onlineDegrees = []
    }
    return this
  }

  /**
   * Include programs by campus (i.e. online) in the result. Mutates the
   * builder's state. Returns the builder.
   */
  campus(campus: UMACampusCode): ProgramManager {
    if (campus === UMACampusCode.Online) {
      this.#clearwaterDegrees = []
      this.#clearwaterDiplomas = []
    } else if (campus === UMACampusCode.Clearwater) {
      this.#onlineDegrees = []
      this.#onlineDiplomas = []
    }
    return this
  }
}

function newProgramManagerFromData(data: any): ProgramManager {
  const clearwaterDegrees: UMAProgram[] = []
  const clearwaterDiplomas: UMAProgram[] = []
  const onlineDegrees: UMAProgram[] = []
  const onlineDiplomas: UMAProgram[] = []
  for (let i = 0; i < data.length; i += 1) {
    const d = data[i]
    const program: UMAProgram = {
      briefDescription: d.meta.program_brief_description,
      campus: d.meta.program_campus,
      classes: d.meta.program_classes.map((o) => ({ className: o.class_name })),
      code: d.meta.program_code,
      companionProgram: d.meta.program_companion_program,
      degreeName: d.meta.program_degree_name,
      fullName: d.meta.program_full_name,
      icon: d.meta.program_icon,
      isEnrolling: d.meta.program_is_enrolling,
      length: d.meta.program_length,
      link: programLinkMap[d.meta.program_code].link,
      metadesc: d.meta.seo_metadesc,
      seoTitle: d.meta.seo_title,
      seoTitleUseAcronym: d.meta.seo_title_use_acronym,
      shortName: d.meta.program_short_name,
      tuition: d.meta.program_tuition,
      type: d.meta.program_type,
    }
    if (!program.isEnrolling) {
      // eslint-disable-next-line no-continue
      continue
    }
    if (
      program.campus === UMACampusCode.Clearwater &&
      program.type === UMAProgramType.Degree
    ) {
      clearwaterDegrees.push(program)
    } else if (
      program.campus === UMACampusCode.Clearwater &&
      program.type === UMAProgramType.Diploma
    ) {
      clearwaterDiplomas.push(program)
    } else if (
      program.campus === UMACampusCode.Online &&
      program.type === UMAProgramType.Degree
    ) {
      onlineDegrees.push(program)
    } else if (
      program.campus === UMACampusCode.Online &&
      program.type === UMAProgramType.Diploma
    ) {
      onlineDiplomas.push(program)
    }
  }
  const pm = new ProgramBuilder({
    clearwaterDegrees,
    clearwaterDiplomas,
    onlineDegrees,
    onlineDiplomas,
  })
  return pm
}

export function newProgramManager(): ProgramBuilder {
  return newProgramManagerFromData(programData)
}
