import React from 'react'

import { Link } from 'gatsby'
import styled from 'styled-components'

import rectLogo from '../images/logos/rectangle-logo-with-r.png'
import { above } from '../styles/utilities'

type LogoProps = {
  className: string
  selectedCampus: 'Clearwater' | 'Online' | 'Both'
}

const Logo = ({ className, selectedCampus }: LogoProps) => {
  let campusForLink = ''
  if (selectedCampus === 'Clearwater') campusForLink = '/clearwater'
  if (selectedCampus === 'Online') campusForLink = '/'
  if (selectedCampus === 'Both') campusForLink = '/clearwater-online'

  return (
    <Link to={campusForLink} className={className}>
      <img
        alt="Ultimate Medical Academy"
        className="img--logo"
        loading="eager"
        src={rectLogo}
      />
    </Link>
  )
}

export default styled(Logo)`
  font-size: 0;
  line-height: 0;

  .img--logo {
    max-width: 180px;
    min-width: 180px;
    position: relative;
  }

  ${above.lg`
    .img--logo {
      max-width: 250px;
      min-width: 250px;
    }
  `}
`
