export class FormRefererService {

  constructor(childNode, parentNode, value) {
    this.childNode = childNode;
    this.parentNode = parentNode;
    this.value = value;
  }

  boot() {
    this.childNode.setAttribute('type', 'hidden');
    this.childNode.setAttribute('name', 'ReferUrl');
    this.childNode.setAttribute('value', encodeURIComponent(this.value));
    this.parentNode.appendChild(this.childNode);
  }

}