import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { above, colors } from '../../styles/utilities';
import { NarrowContainer } from '../../styles/elements';
import rectLogo from '../../images/logos/rectangle-logo-with-r.png';

const HeroScalableLogo = ({className, heroType, template}) => {
  return (
    <div className={`${className} hero-type--${heroType} template--${template}`}>
      <NarrowContainer>
        <img className="logo" src={rectLogo} alt="Ultimate Medical Academy" width="250" />
      </NarrowContainer>
    </div>
  )
}

HeroScalableLogo.propTypes = {
  className: PropTypes.string.isRequired,
  heroType: PropTypes.string.isRequired
}

export default styled(HeroScalableLogo)`

  font-size: 0;
  line-height: 0;

  .logo {
    width: 10rem;

    ${above.md`
      width: 20vw;
      max-width: 200px;
    `}
  }

  &.hero-type--scalable, &.hero-type--scalable-constrained {
    background-color: ${colors.umaLightBlue};
    padding: 0.4rem 0;
    position: relative;
    text-align: center;

    ${above.md`

      padding: 0;
      position: absolute;
        right: 0; left: 0;
      text-align: left;
      z-index: 1;

      .logo {
        position: absolute;
        ${props => {
          if (props.template === 'chat-now' || props.template === 'stitch-in') {
            return `transform: translateY(-35%);`;
          }
          else if (props.template === 'brand') {
            return `padding: 1rem 0;`
          }
        }}
      }
    `}
  }

  &.hero-type--bg-image {
    padding: 1rem 0;
  }
  
`;