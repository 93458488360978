// Node Module Imports
import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { lighten } from 'polished';

// Style Imports
import { colors } from '../../../styles/utilities';
import { NarrowContainer } from '../../../styles/elements';

const EnrollmentStartBottomSection = ({className, templateStyle}) => {

  const questionsBlurb = useStaticQuery(graphql`
    {
      mdx(fileAbsolutePath: {regex: "/markdown/templates/enrollment-start/questions.mdx/"}) {
        body
      }
    }
  `);

  return (
    <section className={className}>
      <NarrowContainer>
        {templateStyle !== 1 && (
          <a href="https://pumaden.ultimatemedical.edu/" className="ga-portal" ga-label="questions">
            <button className="visit-student-portal">Visit Student Portal</button>
          </a>
        )}
        <MDXRenderer>{questionsBlurb.mdx.body}</MDXRenderer>
      </NarrowContainer>
    </section>
  )
};

export default styled(EnrollmentStartBottomSection)`
  padding: 2rem 0 5rem 0;
  background-color: ${colors.umaUltimateLightGray};
  .container {
    max-width: 28rem;
    text-align: center;
    .visit-student-portal {
      font-weight: 900;
      background-color: ${colors.umaLightBlue};
      color: #fff;
      padding: .7rem;
      width: 100%;
      max-width: 25rem;
      &:hover {
        background-color: ${lighten(0.05, `${colors.umaLightBlue}`)};
        cursor: pointer;
      }
    }
    h2 {
      margin-top: 0;
      padding-top: 2rem;
    }
    a {
      color: ${colors.umaDarkGray};
      text-decoration: none;
      font-weight: bold;
    }
  }
`;