export * from './colors'
export * from './mediaQueries'
export * from './colorConverter'
export * from './space'

export const colorIsWhite = (color) => {
  color = color.toLowerCase()
  return (
    color === '#fff' || color === '#ffffff' || color === 'rgb(255, 255, 255)'
  )
}
