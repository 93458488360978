import React from 'react'

import { v4 as uuidv4 } from 'uuid'

import Module from '../modules'

const renderModules = (modules) => {
  return modules.map((module) => {
    const { ...attributes } = module[module.module]
    return (
      <Module
        frontmatter={module.frontmatter}
        attributes={attributes}
        type={module.module}
        key={uuidv4()}
      />
    )
  })
}

export default renderModules
