import { UMACampusCode } from './campus'

/*-------------------------------------------------------------------------
  Why? Because it's more clear. Seeing `OnlineDegreeCode.HS_MAA` makes more
  sense than seeing `"HS-MAA"` in the codebase. Plus, these enums act as a
  single source of truth for program codes and program types.
--------------------------------------------------------------------------*/

/**
 * UMA's program types.
 */
export enum UMAProgramType {
  Degree = 'degree',
  Diploma = 'diploma',
}

/**
 * UMA's online degree program codes. These are the codes that are used for
 * UMA's online programs 99.999% of the time.
 */
export enum OnlineDegreeCode {
  /** Healthcare Accounting */
  HCA = 'HCA',

  /** Healthcare Management */
  HCM = 'HCM',

  /** Health and Human Services */
  HHS = 'HHS',

  /** Health Information Management */
  HIM = 'HIM',

  /** Health Information Technology */
  HIT = 'UNSPEC',

  /** Health Sciences - Medical Administrative Assistant */
  HS_MAA = 'HS-MAA',

  /** Health Sciences - Medical Office and Billing Specialist */
  HS_MOBS = 'HS-MOBS',

  /** Health Sciences - Pharmacy Technician */
  HS_PHT = 'HS-PHT',

  /** Medical Billing and Coding */
  MBCOA = 'MBCOA',
}

/**
 * An array containing all of UMA's online degree program codes. These are the
 * codes that are used for UMA's online programs 99.999% of the time.
 */
export const onlineDegreeCodes = [
  OnlineDegreeCode.HCA,
  OnlineDegreeCode.HCM,
  OnlineDegreeCode.HHS,
  OnlineDegreeCode.HIM,
  OnlineDegreeCode.HS_MAA,
  OnlineDegreeCode.HS_MOBS,
  OnlineDegreeCode.HS_PHT,
  OnlineDegreeCode.MBCOA,
]

/**
 * UMA's online diploma program codes. These codes are rarely (if ever) used.
 * Prefer the online degree codes unless specifically told otherwise.
 */
export enum OnlineDiplomaCode {
  /** Medical Administrative Assistant (Diploma) */
  MAA = 'MAA',

  /** Medical Billing and Coding (Diploma) */
  MBC = 'MBC',

  /** Medical Office and Billing Specialist (Diploma) */
  MOBS = 'MOBS',
}

/**
 * An array containing all of UMA's online diploma program codes. These codes
 * are rarely (if ever) used. Prefer the online degree codes unless specifically
 * told otherwise.
 */
export const onlineDiplomaCodes = [
  OnlineDiplomaCode.MAA,
  OnlineDiplomaCode.MBC,
  OnlineDiplomaCode.MOBS,
]

/**
 * UMA's clearwater degree program codes. These codes are rarely (if ever) used.
 * Prefer the clearwater diploma codes unless specifically told otherwise.
 */
export enum ClearwaterDegreeCode {
  /** Health Sciences - Dental Assistant with Expanded Functions */
  HS_DA = 'HS-DA',

  /** Heath Sciences - Medical Assistant */
  HS_MA = 'HS-MA',
}

/**
 * An array containing all of UMA's clearwater degree program codes. These codes
 * are rarely (if ever) used. Prefer the clearwater diploma codes unless
 * specifically told otherwise.
 */
export const clearwaterDegreeCodes = [
  ClearwaterDegreeCode.HS_DA,
  ClearwaterDegreeCode.HS_MA,
]

/**
 * UMA's clearwater diploma program codes. These are the codes that are used for
 * UMA's clearwater programs 99.999% of the time.
 */
export enum ClearwaterDiplomaCode {
  /** Dental Assistant (Diploma) */
  DA = 'DA',

  /** Medical Assistant (Diploma) */
  MA = 'MA',

  /** Nursing Assistant (Diploma) */
  NA = 'NA',

  /** Patient Care Technician (Diploma) */
  PCT = 'PCT',

  /** Phlebotomy Technician (Diploma) */
  PH = 'PH',
}

/**
 * An array containing all of UMA's clearwater diploma program codes. These are
 * the codes that are used for UMA's clearwater programs 99.999% of the time.
 */
export const clearwaterDiplomaCodes = [
  ClearwaterDiplomaCode.DA,
  ClearwaterDiplomaCode.MA,
  ClearwaterDiplomaCode.NA,
  ClearwaterDiplomaCode.PCT,
  ClearwaterDiplomaCode.PH,
]

/**
 * Other program codes (i.e. unknown program).
 */
export enum OtherProgramCode {
  Empty = '',
  Unknown = 'UNSPEC',
}

/**
 * A generic type representing any valid UMA program code.
 */
export type UMAProgramCode =
  | OnlineDegreeCode
  | OnlineDiplomaCode
  | ClearwaterDegreeCode
  | ClearwaterDiplomaCode
  | OtherProgramCode

/**
 * Represents a UMA Program.
 */
export type UMAProgram = {
  briefDescription: string
  campus: UMACampusCode
  classes: { className: string }[]
  code: UMAProgramCode
  companionProgram: UMAProgramCode
  degreeName: string
  fullName: string
  icon: string
  isEnrolling: boolean
  length: string
  link: string
  shortName: string
  tuition: string
  type: UMAProgramType
  metadesc: string
  seoTitle: string
  seoTitleUseAcronym: string
}
