import React from 'react'

export type ToutProps = {
  image: { alt: string; source: string }
  text: string
}

const Tout = ({ image, text }: ToutProps) => (
  <>
    <p>
      <img src={image.source} alt={image.alt} />
    </p>
    <p>
      <strong>{text}</strong>
    </p>
  </>
)

export default Tout
