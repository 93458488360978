import React from 'react'

import styled from 'styled-components'

import { FormTheme, labelTheme } from '../../../../themes/forms'

// Reduce multiple themes into one string of css.
function reduceToString(arr: FormTheme[]) {
  return arr.reduce((acc, cur) => {
    let newState = acc
    newState += cur.css()
    return newState
  }, '')
}

type StyledFormBaseProps = {
  themes: FormTheme[]
}

// The StyledFormBase is the styled component. We'll wrap it with a React
// Component.
const StyledFormBase = styled.div`
  ${(props: StyledFormBaseProps) => reduceToString(props.themes)}
`

export type StyledFormProps = {
  children: React.ReactNode
  className?: string
  themes?: FormTheme[]
}

// StyledForm's responsiblity is to grab the full themes from the enum values
// and pass it to the styled component.
const StyledForm = ({ children, className, themes }: StyledFormProps) => {
  return (
    <StyledFormBase className={className} themes={themes}>
      {children}
    </StyledFormBase>
  )
}

StyledForm.defaultProps = {
  className: '',
  themes: [labelTheme],
}

export default StyledForm
