/* eslint-disable sort-keys */
import { AxiosResponse } from 'axios'
import { XMLParser } from 'fast-xml-parser'

import { Fact, newTeamsPayload, TeamsPayload } from '../teams'

const SUCCESSFUL_SR_CODES = ['SR-200', 'SR-522']

type SparkroomResponse = {
  axiosResponse: AxiosResponse
  message: string
  reference: string
  result: string
  sparkroomStatus: string
  success: boolean
}

function parseSparkroomResponse(
  axiosResponse: AxiosResponse,
): SparkroomResponse {
  const parser = new XMLParser()
  const xml = parser.parse(axiosResponse.data)

  const result = xml.SPARKROOM_RESPONSE.RESULT
  const sparkroomStatus = xml.SPARKROOM_RESPONSE.STATUS_CODE
  const message = xml.SPARKROOM_RESPONSE.MESSAGE
  const reference = xml.SPARKROOM_RESPONSE.REFERENCE

  const success = SUCCESSFUL_SR_CODES.indexOf(sparkroomStatus || 'null') !== -1

  return {
    axiosResponse,
    message: message || 'undefined',
    reference,
    result: result || 'undefined',
    sparkroomStatus: sparkroomStatus || 'undefined',
    success,
  }
}

function sparkroomResponseToFacts(
  srResponse: SparkroomResponse,
  payload: string,
  referer: string,
): Fact[] {
  const facts = [
    {
      name: 'Location',
      value: referer,
    },
    {
      name: 'Server Response Code',
      value: srResponse.axiosResponse.status,
    },
    {
      name: 'Sparkroom Status',
      value: srResponse.sparkroomStatus,
    },
    {
      name: 'Sparkroom Result',
      value: srResponse.result,
    },
    {
      name: 'Sparkroom Message',
      value: srResponse.message,
    },
    {
      name: 'Raw Response Data',
      value: srResponse.axiosResponse.data,
    },
    {
      name: 'Payload',
      value: payload,
    },
  ]
  return facts
}

export function sparkroomResponseToTeamsPayload(
  r: AxiosResponse,
  payload: string,
  referer: string,
): TeamsPayload {
  const srResponse = parseSparkroomResponse(r)
  const facts = sparkroomResponseToFacts(srResponse, payload, referer)
  const notification = newTeamsPayload({ facts, success: srResponse.success })
  return notification
}

export const sparkroomResponses = {
  'SR-200': {
    desc: 'Good Lead',
    alert: false,
  },
  'SR-202': {
    desc: 'Unable to Score',
    alert: false,
  },
  'SR-205': {
    desc: 'Lead Queued for Asynchronous Verification',
    alert: false,
  },
  'SR-501': {
    desc: 'Filter Violation',
    alert: false,
  },
  'SR-502': {
    desc: 'Invalid Phone Number',
    alert: false,
  },
  'SR-503': {
    desc: 'Invalid Contact Info',
    alert: false,
  },
  'SR-504': {
    desc: 'Not Specified Person',
    alert: false,
  },
  'SR-505': {
    desc: 'Duplicate Lead',
    alert: false,
  },
  'SR-506': {
    desc: 'Never Applied',
    alert: false,
  },
  'SR-507': {
    desc: 'No English',
    alert: false,
  },
  'SR-508': {
    desc: 'Other',
    alert: false,
  },
  'SR-510': {
    desc: 'Duplicate of Converted Lead',
    alert: false,
  },
  'SR-511': {
    desc: 'Out of Area',
    alert: false,
  },
  'SR-512': {
    desc: 'Not a High School Graduate',
    alert: false,
  },
  'SR-513': {
    desc: 'Invalid Name',
    alert: false,
  },
  'SR-514': {
    desc: 'Invalid Address',
    alert: false,
  },
  'SR-515': {
    desc: 'Invalid Email',
    alert: false,
  },
  'SR-516': {
    desc: 'Invalid State',
    alert: false,
  },
  'SR-517': {
    desc: 'Invalid Zip/Postal Code',
    alert: false,
  },
  'SR-518': {
    desc: 'Invalid Level of Education',
    alert: false,
  },
  'SR-519': {
    desc: 'Unroutable',
    alert: true,
  },
  'SR-520': {
    desc: 'Duplicate - Same Lead Provider',
    alert: false,
  },
  'SR-521': {
    desc: 'Duplicate - Different Lead Provider',
    alert: false,
  },
  'SR-522': {
    desc: 'Duplicate - Identical POST',
    alert: false,
  },
  'SR-523': {
    desc: 'Invalid Graduation Date',
    alert: false,
  },
  'SR-524': {
    desc: 'Invalid External References',
    alert: false,
  },
  'SR-525': {
    desc: 'Invalid Country',
    alert: false,
  },
  'SR-526': {
    desc: 'Missing Required Field',
    alert: true,
  },
  'SR-529': {
    desc: 'Disqualified by 3rd Party',
    alert: false,
  },
  'SR-530': {
    desc: 'Over Cap',
    alert: true,
  },
  'SR-531': {
    desc: 'Test Data Sent in Error',
    alert: true,
  },
  'SR-532': {
    desc: 'Failed Verification',
    alert: false,
  },
  'SR-533': {
    desc: 'Enrolled Elsewhere',
    alert: false,
  },
  'SR-534': {
    desc: 'No Longer Interested',
    alert: false,
  },
  'SR-535': {
    desc: 'Requested Degree/Program Not Offered',
    alert: false,
  },
  'SR-536': {
    desc: 'Over Daily Cap',
    alert: true,
  },
  'SR-537': {
    desc: 'Sub-Affiliate Over Cap',
    alert: true,
  },
  'SR-538': {
    desc: 'Lead Buyer Deactivated',
    alert: true,
  },
  'SR-540': {
    desc: 'Do Not Call (Rejected)',
    alert: false,
  },
  'SR-541': {
    desc: "Zip/Postal Code Doesn't Match State/Province",
    alert: false,
  },
  'SR-542': {
    desc: 'Campus Not Active',
    alert: true,
  },
  'SR-543': {
    desc: 'Program Not Active',
    alert: true,
  },
  'SR-544': {
    desc: 'Deceased',
    alert: false,
  },
  'SR-545': {
    desc: 'No Visa',
    alert: false,
  },
  'SR-546': {
    desc: 'Call Center Disposition',
    alert: false,
  },
  'SR-547': {
    desc: 'Seeking Employment',
    alert: false,
  },
  'SR-548': {
    desc: 'Underage',
    alert: false,
  },
  'SR-550': {
    desc: 'Disqualified by LeadiD Audit Results',
    alert: false,
  },
  'SR-551': {
    desc: 'Compliance',
    alert: false,
  },
  'SR-552': {
    desc: 'Disqualified by TCPA',
    alert: false,
  },
  'SR-553': {
    desc: 'Rejected by Buyer Restrictions',
    alert: false,
  },
  'SR-554': {
    desc: 'Disqualified by iDatafy',
    alert: false,
  },
  'SR-900': {
    desc: 'The lead was not processed and returned an HTTP 500 error',
    alert: true,
  },
}
