import React from 'react'

import styled from 'styled-components'

import chevronPatternDown from '../../assets/svgs/chevron-pattern-down.svg'

export type ChevronPatternProps = {
  className?: string
}

const ChevronPattern = ({ className }: ChevronPatternProps) => (
  <div className={className} />
)

export default styled(ChevronPattern)`
  background-color: #fff;
  mask-image: url(${chevronPatternDown});
  mask-repeat: repeat;
  mask-size: 144px 72px;
  opacity: 0.075;
  position: absolute;
  inset: 0;
`
