// Node Module Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Style Imports
import { colors } from '../../styles/utilities';

class Program extends Component {

  state = {
    parsedDesc: [],
  }

  componentDidMount() {
    const { description } = this.props.program;
    const parsedDesc = description
      .substring(3, description.length - 5)
      .replace("&#8217;", "'")
      .replace('&amp;', '&')
      .replace('&#8211;', '-')
      .replace('<a href="', 'REPLACE')
      .replace('">', 'REPLACE')
      .replace("</a>", 'REPLACE')
      .split('REPLACE');
    this.setState({ parsedDesc });
  }

  render() {
    const { className, program } = this.props;
    const { parsedDesc } = this.state;
    const imgStyle = {
      backgroundColor: program.color,
    }

    return (
      <div className={`${className} Program`}>
        <div className="program-img-name">
          <div className="program-img" style={imgStyle}>
            <img src={program.icon} alt={program.name} title={program.name} />
          </div>
          <h4>{program.name}</h4>
        </div>
        <div className="program-description">
          <p>{parsedDesc[0]}<a href={program.url}>{parsedDesc[2]}</a>{parsedDesc[3]}</p>
        </div>
      </div>
    )
  }
}

export default styled(Program)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0 3px 8px rgba(0,0,0,.1);
  margin-bottom: 1.5rem;
  .program-img-name {
    display: flex;
    align-items: center;
    .program-img {
      margin-right: 10px;
      img {
        padding: 10px;
        max-height: 100px;
        max-width: 100px;
      }
    }
    h4 {
      color: ${colors.umaOrange};
      text-align: center;
      font-size: 1.4rem;
    }
  }
  .program-description {
    p {
      font-size: 1.1rem;
      a {
        color: ${colors.umaDarkBlue};
        font-size: 1.1rem;
        text-decoration: none;
      }
    }
  }
`;

Program.propTypes = {
  className: PropTypes.string.isRequired,
  program: PropTypes.object.isRequired
}