import React from 'react';
import HeroScalable from './HeroScalable';
import HeroBackgroundImage from './HeroBackgroundImage';
import HeroScalableConstrained from './HeroScalableConstrained';

const HeroGlobal = ({
  bgColorLeft,
  bgColorRight,
  displayLogo,
  hero,
  imgBig,
  imgSmall,
  pageClass,
  template
}) => {

  let isBgImage = false,
      isScalable = false, 
      isScalableConstrained = false;

  if (!pageClass) {
    if ( template === 'chat-now' || template === 'stitch-in' ) {
      isScalableConstrained = true;
    }
    else {
      isBgImage = true;
    }
  }

  else {
    if ( pageClass.match(/hero--scalable--constrained/) ) {
      isScalableConstrained = true;
    }
    else if ( pageClass.match(/page-template--pmx/) || pageClass.match(/hero--scalable/) ) {
      isScalable = true;
    }
    else if ( pageClass.match(/hero--bg-img/) ) {
      isBgImage = true;
    }
    else {
      isBgImage = true;
    }
  }

  // Allows for the bgColorLeft and bgColorRight props to be set via the CSS Classes field in WordPress
  // This is specifically for templates (like Brand) that are not configured to take scalable constrained hero images but someitmes need to use them. An example of this is the HIM lead gen page.
  // The values passed in the CSS Classes field must start with bgColorLeft- and bgColorRight-, each followed by a hex code.
  let pageClassBackgroundLeft, pageClassBackgroundRight;
  if (pageClass.match(/bgColorLeft/)) {
    pageClassBackgroundLeft = pageClass.indexOf('bgColorRight') !== -1 ? pageClass.slice(pageClass.indexOf('bgColorLeft') + 12, pageClass.indexOf('bgColorLeft') + 20) : '';
  }
  if (pageClass.match(/bgColorRight/)) {
    pageClassBackgroundRight = pageClass.indexOf('bgColorRight') !== -1 ? pageClass.slice(pageClass.indexOf('bgColorRight') + 13, pageClass.indexOf('bgColorRight') + 21) : '';
  }

  return (
    <>
      {
        isScalableConstrained &&
        <HeroScalableConstrained
          bgColorLeft={bgColorLeft || pageClassBackgroundLeft}
          bgColorRight={bgColorRight || pageClassBackgroundRight}
          displayLogo={displayLogo}
          imgBig={imgBig}
          imgSmall={imgSmall}
          template={template}
        />
      }
      { 
        isScalable && 
        <HeroScalable
          displayLogo={displayLogo}
          imgBig={imgBig}
          imgSmall={imgSmall}
          template={template}
        /> 
      }
      {
        isBgImage &&
        <HeroBackgroundImage
          displayLogo={displayLogo}
          hero={hero}
          imgBig={imgBig}
          imgSmall={imgSmall}
          template={template}
          pageClass={pageClass}
        />
      }
    </>
  )
}

export default HeroGlobal;