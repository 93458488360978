import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

/**
  eventFields Interface
  {
    // string - required - The object that was interacted with (e.g.video)
    category: "Special Button",
    
    // string - required - Type of interaction (e.g. 'play')
    action: "Click",
    
    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
    label: "Gatsby Plugin Example Campaign",
    
    // number - optional - Numeric value associated with the event. (e.g. A product ID)
    value: 43
  }
*/

const useGa = () => {
  const submit = (eventFields) => {
    trackCustomEvent(eventFields)
  };

  return submit;
};

export default useGa;
