import React, { useContext, useEffect, useState } from 'react'

import { FootnoteContext } from '../../context/FootnoteContext'

type FootnoteProps = {
  content: string
}

const Footnote = ({ content }: FootnoteProps) => {
  const [id, setID] = useState(0)
  const { addFootnote, getFootnoteByContent } = useContext(FootnoteContext)
  useEffect(() => {
    addFootnote(content)
  }, [addFootnote, content])
  useEffect(() => {
    setID(getFootnoteByContent(content)?.id)
  }, [content, getFootnoteByContent, setID])
  return <sup>{id}</sup>
}

export default Footnote
