/* eslint-disable sort-keys */

export const colorsNamed = {
  blueDarkPastel: '#132027',
  umaCoolGray: '#C8C9C7',
  umaDarkBlue: '#006BA6',
  umaDarkGray: '#505759',
  umaGreen: '#78BE20',
  umaGreenNew: '#15eac4',
  umaGreenHover: '#92DD34',
  umaLightBlue: '#0095CB',
  umaLightBlueHover: '#00BBFB',
  umaBlue400: '#0d3553',
  umaOrange: '#F68D2E',
  umaPink: '#E24585',
  umaPurple: '#68478D',
  umaPurpleNew: '#8444D8',
  umaRed: '#BA0C2F',
  umaTeal: '#00A3AD',
  umaTestFuchsia: '#BE21B5',
  umaUltimateLightGray: '#F0F0F0',
  umaYellow: '#FFC845',
}

function hslaBuilder(hsl) {
  return (opacity = 1) => `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${opacity})`
}

export const colorsDev = {
  blue: {
    a: hslaBuilder({ h: 201.3, s: 80, l: 22 }),
    b: hslaBuilder({ h: 201.3, s: 100, l: 32.5 }), // UMA Dark Blue
    c: hslaBuilder({ h: 196, s: 100, l: 40 }), // UMA Light Blue
    d: hslaBuilder({ h: 201.3, s: 90, l: 60 }),
    e: hslaBuilder({ h: 201.3, s: 80, l: 85 }),
  },
  gray: {
    a: hslaBuilder({ h: 0, s: 0, l: 0 }),
    b: hslaBuilder({ h: 0, s: 0, l: 25 }),
    c: hslaBuilder({ h: 0, s: 0, l: 50 }),
    d: hslaBuilder({ h: 0, s: 0, l: 75 }),
    e: hslaBuilder({ h: 0, s: 0, l: 90 }),
  },
  // 78be21
  green: {
    a: hslaBuilder({ h: 87.5, s: 60, l: 18 }),
    b: hslaBuilder({ h: 87.5, s: 67.6, l: 42.4 }), // UMA Green
    c: hslaBuilder({ h: 87.5, s: 60, l: 55 }),
    d: hslaBuilder({ h: 87.5, s: 80, l: 72 }),
    e: hslaBuilder({ h: 87.5, s: 80, l: 85 }),
  },
  orange: {
    a: hslaBuilder({ h: 23, s: 75, l: 18 }),
    b: hslaBuilder({ h: 23, s: 80, l: 32 }),
    c: hslaBuilder({ h: 29, s: 85, l: 45 }),
    d: hslaBuilder({ h: 29, s: 92, l: 57 }), // UMA Orange
    e: hslaBuilder({ h: 29, s: 90, l: 85 }),
  },
  pink: {
    a: hslaBuilder({ h: 303, s: 40, l: 18 }),
    b: hslaBuilder({ h: 303, s: 40, l: 28 }),
    c: hslaBuilder({ h: 303, s: 70, l: 44 }), // UMA Pink
    d: hslaBuilder({ h: 303, s: 85, l: 65 }),
    e: hslaBuilder({ h: 303, s: 90, l: 85 }),
  },
  purple: {
    a: hslaBuilder({ h: 268, s: 40, l: 18 }),
    b: hslaBuilder({ h: 268, s: 40, l: 28 }),
    c: hslaBuilder({ h: 268, s: 33, l: 42 }), // UMA Purple
    d: hslaBuilder({ h: 268, s: 80, l: 60 }),
    e: hslaBuilder({ h: 268, s: 90, l: 85 }),
  },
  red: {
    a: hslaBuilder({ h: 346, s: 40, l: 18 }),
    b: hslaBuilder({ h: 346, s: 50, l: 28 }),
    c: hslaBuilder({ h: 348, s: 88, l: 39 }), // UMA Red
    d: hslaBuilder({ h: 348, s: 90, l: 58 }),
    e: hslaBuilder({ h: 348, s: 90, l: 85 }),
  },
  teal: {
    a: hslaBuilder({ h: 183, s: 90, l: 22 }),
    b: hslaBuilder({ h: 183, s: 100, l: 34 }), // UMA Teal
    c: hslaBuilder({ h: 183, s: 80, l: 46 }),
    d: hslaBuilder({ h: 183, s: 80, l: 65 }),
    e: hslaBuilder({ h: 183, s: 80, l: 85 }),
  },
  yellow: {
    a: hslaBuilder({ h: 37, s: 40, l: 22 }),
    b: hslaBuilder({ h: 37, s: 40, l: 40 }),
    c: hslaBuilder({ h: 43, s: 95, l: 63 }), // UMA Yellow
    d: hslaBuilder({ h: 41, s: 95, l: 78 }),
    e: hslaBuilder({ h: 43, s: 90, l: 85 }),
  },
}

export const colorsSocial = {
  social: {
    facebook: '#3B5998',
    gitHub: {
      hoverGreenBase: '#269F42',
      hoverGreenLight: '#2FCB53',
    },
    instagram: '#F5005D',
    linkedIn: '#0A66C2',
    pinterest: '#E60023',
    twitter: '#1DA1F2',
    youTube: '#F50804',
  },
}

export const colors = {
  ...colorsNamed,
  dev: colorsDev,
  social: colorsSocial,
}
