import { AnchorHTMLAttributes } from 'react'

export type FooterLink = {
  attributes?: AnchorHTMLAttributes<HTMLAnchorElement>
  external: boolean
  href: string
  showExternalIcon: boolean
  text: string
}

export type FooterLinks = {
  leftSide: FooterLink[]
  rightSide: FooterLink[]
}

export const footerLinks: FooterLinks = {
  leftSide: [
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/future-students/programs/',
      showExternalIcon: false,
      text: 'Programs',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/about/accessibility-policy/',
      showExternalIcon: false,
      text: 'Accessibility',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/about/accreditation/',
      showExternalIcon: false,
      text: 'Accreditation',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/alumni/',
      showExternalIcon: false,
      text: 'Alumni Services',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/cares-act/',
      showExternalIcon: false,
      text: 'CARES Disclosure',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/students/commencement/',
      showExternalIcon: false,
      text: 'Commencement',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/contact-us/',
      showExternalIcon: false,
      text: 'Contact Us',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/covid-19/',
      showExternalIcon: false,
      text: 'COVID-19 Information',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/about/directory/',
      showExternalIcon: false,
      text: 'Directory',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'http://www.umafanshop.com/',
      showExternalIcon: true,
      text: 'Fan Shop',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/about/faq/',
      showExternalIcon: false,
      text: 'FAQ',
    },
  ],
  rightSide: [
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.guidanceresources.com/groWeb/login/login.xhtml',
      showExternalIcon: true,
      text: 'Guidance Resource Center',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/student-information/',
      showExternalIcon: false,
      text: 'Policies & Disclosures',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://go.ultimatemedical.edu/refer/',
      showExternalIcon: true,
      text: 'Refer a Friend',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/students/request-transcripts/',
      showExternalIcon: false,
      text: 'Request Transcripts',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/student-information/',
      showExternalIcon: false,
      text: 'School Catalog',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/about/social-communities/',
      showExternalIcon: false,
      text: 'Social Communities',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://go.ultimatemedical.edu/uma-cares/',
      showExternalIcon: true,
      text: 'UMA Cares',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/students/wellness-center/',
      showExternalIcon: false,
      text: 'Wellness Center',
    },
    {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      external: true,
      href: 'https://www.ultimatemedical.edu/workhere/',
      showExternalIcon: false,
      text: 'Work at UMA',
    },
  ],
}
