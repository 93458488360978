import React, { useCallback } from 'react'

import { Link } from 'gatsby'
import { FaExternalLinkAlt } from 'react-icons/fa'
import styled from 'styled-components'

import { above, colors } from '../../../styles/utilities'
import { footerLinks, FooterLink } from '../../data/footerLinks'
import SocialLinks from '../../lib/components/icons/SocialLinks'
import { Address, addresses, UMAAddresses } from '../../lib/data/addresses'
import { formatPhoneNumber } from '../../lib/util/format'
import { makeid } from '../../lib/util/strings'

// Get the Year
const today = new Date()
const year = today.getFullYear()

type HollyFooterProps = {
  className?: string
}

const HollyFooter = ({ className }: HollyFooterProps) => {
  const renderLinks = useCallback(
    (links: FooterLink[]) =>
      links.map((item) => {
        const rand = makeid()
        return item.external === true ? (
          <a
            className="footer-link"
            href={item.href}
            key={rand}
            {...item.attributes}
          >
            <span>
              {item.text}{' '}
              {item.external && item.showExternalIcon && (
                <FaExternalLinkAlt className="icon-sm" />
              )}
            </span>
          </a>
        ) : (
          <Link
            className="footer-link"
            to={item.href}
            key={rand}
            {...item.attributes}
          >
            {item.text}
          </Link>
        )
      }),
    [],
  )

  const renderLocations = useCallback((umaLocations: UMAAddresses) => {
    const locationKeys = Object.keys(umaLocations)
    return locationKeys.map((locationName) => {
      const currentLocation = umaLocations[locationName] as Address
      if (!currentLocation.includeInFooter) return null
      return (
        <div className="location" key={currentLocation.name}>
          <div>
            <strong className="d-block mb-2">{currentLocation.name}</strong>
          </div>
          <a
            className="address-link"
            href={currentLocation.googleMapsLink}
            rel="noopnener noreferrer"
            target="_blank"
          >
            <div>{currentLocation.addressLineOne}</div>
            <div>{currentLocation.addressLineTwo}</div>
          </a>
          <a
            href={`tel:${
              formatPhoneNumber(currentLocation.phoneNumber).stripped
            }`}
            aria-label="Call Ultimate Medical Academy"
          >
            {formatPhoneNumber(currentLocation.phoneNumber).label}
          </a>
        </div>
      )
    })
  }, [])

  return (
    <footer className={className}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-4 resources">
            <div className="container-fluid">
              <h4>Resources</h4>
              <div className="row footer-links">
                <div className="col-6">{renderLinks(footerLinks.leftSide)}</div>
                <div className="col-6">
                  {renderLinks(footerLinks.rightSide)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-8 follow-and-locations">
            <h4>Follow Us</h4>
            <SocialLinks />
            <div className="locations">
              <h4>Locations</h4>
              <div className="locations-list">{renderLocations(addresses)}</div>
              <div className="copyright">
                © {year} Ultimate Medical Academy, a nonprofit healthcare
                educational institution |{' '}
                <a
                  color={colors.umaDarkGray}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.ultimatemedical.edu/about/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

HollyFooter.defaultProps = {
  className: '',
}

export default styled(HollyFooter)`
  padding: 1.25rem 0 10rem 0;
  background-color: ${colors.umaUltimateLightGray};
  color: ${colors.umaDarkGray};
  ${above.lg`
    padding-bottom: 1.25rem;
  `}
  h4 {
    font-size: 1.25rem;
  }
  .icon-sm {
    width: 0.865rem;
    margin-top: -7px;
  }
  a {
    color: ${colors.umaDarkGray};
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    &:focus,
    &:hover {
      color: #1a1a1a;
    }
  }
  .resources {
    ${above.md`
      padding-left: 0;
      border-bottom: none;
    `};
    .footer-links {
      border-bottom: 0.0625rem solid #000;
      padding-bottom: 1.25rem;
      ${above.md`
        border-right: .0625rem solid #000;
        border-bottom: none;
        padding-bottom: 0;
      `}
      ${above.xl`
        padding-bottom: 0;
      `}
      .footer-link {
        display: block;
        margin-bottom: 0.5rem;
        line-height: 1.2;
        ${above.md`
          font-size: 1rem;
        `}
        ${above.xl`
          font-size: 1.2rem;
        `}
      }
    }
  }
  .follow-and-locations {
    ${above.md`
      padding-left: 2.5rem;
    `}
    .locations {
      border-top: 0.0625rem solid #000;
      margin-top: 1.875rem;
      .locations-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .location {
        flex: 1 1 12.5rem;
        display: inline-block;
        margin-bottom: 1.25rem;
        padding-right: 0.625rem;
      }
      .copyright {
        margin-top: 1.25rem;
      }
    }
  }
`
