export class WpPage {

  constructor(settings) {
    this.path = settings.path;
    this.template = settings.template;
    this.created_at = settings.created_at;
    this.updated_at = settings.updated_at;
    this.display = settings.display || true;
    this.filters = [];
    this.filterGroups = {};

    this.getFilters = this.getFilters.bind(this);
    this.hasFilters = this.hasFilters.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.passesFilters = this.passesFilters.bind(this);
  }


  getFilters() {
    let filters = [...this.filters];
    return {
      all: () => filters,
      inGroup: (group) => {
        return filters.filter(filter => filter.group === group);
      },
      notInGroup: (group) => {
        return filters.filter(filter => filter.group !== group);
      }
    }
  }

  hasFilters() {
    let filters = [...this.filters];
    return {
      any: () => filters.length > 0,
      except: filter => filters.filter(f => f.name !== filter.name).length > 0,
      inGroup: group => {
        return {
          any: () => filters.filter(filter => filter.group === group).length > 0,
          otherThan: filter => filters.filter(f => f.group === filter.group && f.name !== filter.name).length > 0,
        }
      },
      notInGroup: (group) => {
        return filters.filter(filter => filter.group !== group).length > 0;
      }
    }
  }

  addFilter(filter) {
    if(!this.filterGroups.hasOwnProperty(filter.group)) {
      this.filterGroups[filter.group] = [];
    }
    this.filterGroups[filter.group].push(filter);
    this.filters.push(filter);
  }

  removeFilter(filter) {
    this.filters = this.filters.filter(f => f.name !== filter.name);
    this.filterGroups[filter.group] = this.filterGroups[filter.group].filter(f => {
      return f.name !== filter.name;
    });
    if(!this.filterGroups[filter.group].length) {
      delete this.filterGroups[filter.group];
    }
  }
  
  passesFilters() {
    let results = {},
        success = true;

    // the page has to be displayed if there aren't any filters
    if(!this.hasFilters().any()) {
      return true;
    }

    // Loop through each filter group
    // if the page passes any of the filters within a group,
    // then it passes the filter group test
    Object.keys(this.filterGroups).forEach(filterGroupName => {
      results[filterGroupName] = { passesFilter: false };
      this.filterGroups[filterGroupName].forEach(filter => {
        if(filter.test(this)) {
          results[filterGroupName] = { passesFilter: true };
        }
      });
    });

    // Loop through the results of each filter group test
    // All the results need to be true in order to display the page
    Object.keys(results).forEach(filterGroupName => {
      if(!results[filterGroupName].passesFilter) {
        success = false;
      }
    });
    return success;
  }

}