import { footnoteState } from '../footnotes/footnotes';
import findInObject from '../../../utilities/findInObject';

/**
 * footnoteParser
 * 
 * This is a content filter function. It receives content,
 * obj, and originalMatch from the shortcodeParser.
 * 
 * The purpose of this function is to replace footnote shortcodes
 * with a number and to store the footnote text and/or link to later
 * render out in the footer.
 * 
 * Footnotes require two properties, shortcode and content, and have an 
 * optional third, link.
 * 
 * {{ shortcode: "footnote", "content": "footnote content", "link": "https://footnotes.com" }}
 * 
 * @param {string} content - The full chunk of content
 * @param {object} obj - The shortcode as a JS object
 * @param {string} originalMatch - The original match, useful for replacing the shortcode
 * 
 * @returns {string} content
 */
const footnoteParser = (content, obj, originalMatch) => {

  const objType = findInObject('shortcode', obj);

  if(objType === 'footnote') {
    const footnoteLink = findInObject('link', obj);
    const footnoteContent = findInObject('content', obj);
    const footnote = {text: footnoteContent, link: footnoteLink};
    const footnoteId = footnoteState.add(footnote);
    content = content.replace(originalMatch, `<sup class="footnote__sup">${ footnoteId }</sup>`);
  }

  return content;

};

export default footnoteParser;