import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'react-html-parser'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { above, colorsDev as colors, space } from '../../styles/utilities'
import { umaSelector } from '../redux/selectors'
import { footnoteState } from '../util/footnotes/footnotes'
import stripPhoneNumber from '../util/stripPhoneNumber'

const Footer = ({ className }) => {
  const year = new Date().getFullYear()
  const phoneNumber = useSelector(umaSelector.phone)

  const footnotes = footnoteState.getFootnotes()

  const query = useStaticQuery(graphql`
    {
      abhes: file(absolutePath: { regex: "/v2/images/logos/ABHES-logo/" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const abhesLogo = getImage(query.abhes.childImageSharp.gatsbyImageData)

  return (
    <footer className={className}>
      <div className="content-wrap">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-8 d-lg-flex align-items-center justify-content-center">
              <div className="image text-center">
                <GatsbyImage image={abhesLogo} alt="ABHES Logo" />
              </div>
              <div>
                <p>
                  Institutionally Accredited by the Accrediting Bureau of Health
                  Education Schools (ABHES)
                </p>
                <p>
                  © {year} UMA Education, Inc. d/b/a Ultimate Medical Academy |{' '}
                  <a
                    href="https://www.ultimatemedical.edu/about/citations/"
                    className="td-n-hu"
                  >
                    Marketing Statistics
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://www.ultimatemedical.edu/privacy-policy/"
                    className="td-n-hu"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p>
                  Ultimate Medical Academy does not recruit or enroll residents
                  of the European Union.
                </p>
              </div>
            </div>

            <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0">
              <a
                href={`tel:${stripPhoneNumber(phoneNumber)}`}
                className="phone"
              >
                {phoneNumber}
              </a>
            </div>
          </div>
        </div>
      </div>

      {footnotes.length > 0 && (
        <StyledFootnotes>
          <div className="container">
            <ol className="footnotes--list">
              {footnotes.map((footnote, idx) => (
                <li key={idx}>{parse(footnote.text)}</li>
              ))}
            </ol>
          </div>
        </StyledFootnotes>
      )}
    </footer>
  )
}

const StyledFootnotes = styled.div`
  background-color: #fff;
  color: ${colors.umaDarkGray};
  padding: 15px 0;
  line-height: 1rem;

  ol.footnotes--list {
    font-size: 0.65rem;
    margin: 0;
    padding: 0 15px;
    li {
      line-height: 1rem;
    }
  }
`

export default styled(Footer)`
  font-size: 0.8rem;
  line-height: normal;

  a {
    color: ${colors.yellow.c()};
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:focus,
    &:hover {
      color: ${colors.yellow.d()};
    }
  }

  a.phone {
    font-size: 1rem;
  }

  .content-wrap {
    background: ${colors.gray.b()};
    color: ${colors.gray.e()};
    padding: ${space.f} 0;

    p {
      margin: ${space.c} 0;
      text-align: center;

      ${above.lg`
      text-align: left;
    `}
    }
  }

  .image {
    margin-right: ${space.e};
  }
`
