// Node Module Imports
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LineQuote from '@Components/LineQuote'

// Utility Imports
import { space } from '@Style/utilities'
import sp from '@Util/shortcodes/parser'

const Quote = ({ background, color, className }) => {
  return (
    <section className={className}>
      <div className="container">
        <p className="quote">
          <strong>92%</strong> of surveyed students enrolled in UMA’s online
          programs would likely select UMA again if they had the opportunity to
          start over.
        </p>
        <LineQuote
          character="&#8220;"
          color={color || '#fff'}
          divMargin="0 1rem -1rem 1rem"
          fontSize={10}
          lineWidth={32}
          transY={2}
        />
        <p className="disclaimer">
          Source: UMA 2022-2023 Online Student Satisfaction Survey of 9,891
          responses. Survey was conducted and fielded by Hanover Research.
          Includes UMA Online students who were active at the time of the survey
          between Jul 1, 2022 - Jun 30, 2023.
        </p>
      </div>
    </section>
  )
}

Quote.propTypes = {
  background: PropTypes.string,
}

export const query = graphql`
  fragment ModuleQuote on MarkdownRemark {
    frontmatter {
      modules {
        quote {
          background
          color
        }
      }
    }
  }
`

export default styled(Quote)`
  background: ${(props) =>
    (props.background && sp(props.background)) || '#000'};
  padding: ${space.i} 0;
  text-align: center;

  p {
    color: ${(props) => (props.color && sp(props.color)) || '#fff'};
  }

  .container {
    position: relative;
  }

  .quote {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-top: 0;

    strong {
      font-size: 2.5rem;
    }
  }

  .disclaimer {
    margin-bottom: 0;
    line-height: 1.25;
  }
`
