import { FormTheme } from '.'
import { colors, space } from '../../../styles/utilities'

const labelTheme: FormTheme = {
  css: () => `
    label,
    input,
    select {
      border-radius: 1px;
      display: block;
      width: 100%;
    }

    .input-wrapper {
      background-color: #fff;
      position: relative;
      margin-bottom: 1rem;
    }

    .label-and-error {
      font-size: 0.75rem;
      padding: 0.4rem;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .label-text {
      font-weight: 700;
    }

    .req {
      color: ${colors.umaRed};
    }

    .error {
      color: ${colors.umaRed};
    }

    .form-error-message {
      background-color: #ff99ad;
      color: #281519;
      font-size: 0.8rem;
      padding: ${space.d};
    }

    input,
    select {
      border: none;
      padding: 1.75rem 0.4rem 0.75rem 0.4rem;
    }

    select {
      -webkit-appearance: initial;
    }

    input[type='submit'], button[type='submit'] {
      background-color: ${colors.umaGreen};
      color: #fff;
      cursor: pointer;
      font-weight: 700;
      text-transform: uppercase;
      padding: 1rem 0;
      border: none;
      width: 100%;
    }

    .disclaimer {
      color: #fff;
      font-size: 0.8rem;
    }

    input[type='submit'][disabled], button[type='submit'][disabled] {
      background-color: ${colors.umaDarkGray};
      cursor: progress;
    }

    fieldset.input-wrapper {
      background-color: transparent;
      border: 0;
      color: #fff;
      font-size: 0.9rem;
      font-weight: 700;
      padding: 0;
      margin-left: 0;
      margin-right: 0;

      label {
        align-items: center;
        display: inline-flex;
        width: auto;
        margin-right: 0.75rem;
      }

      legend {
        margin-left: 0;
        padding-left: 0;
        width: 100%;
      }

      .req {
        color: #fff;
      }

      .label-and-error {
        font-size: 0.9rem;
        padding-left: 0;
        padding-right: 0;
        position: relative;

        .error { 
          background-color: #fff;
          border-radius: 0.5rem;
          font-size: 0.75rem;
          font-weight: normal;
          padding: 0.25rem;
        }
      }

      input {
        margin-right: 0.25rem;
        width: auto;
      }
    }
`,
}

export default labelTheme
