// Node Module Imports
import { normalize } from 'polished'
import { createGlobalStyle } from 'styled-components'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

// Style Imports
import { colors } from './utilities'

const GlobalStyle = createGlobalStyle`
${normalize()};

body {
  color: ${colors.umaDarkGray};
  font-family: "Univers LT W01 45 Light", Arial;
  font-size: 1rem;
  font-weight: 300;
}

body.body-chevron {
  background-image: url("/images/trans-chev.png");
}

body.modal-open {
  height: 100vh;
  overflow: hidden;
}

body p, ul, ol, li {
  line-height: 1.4rem;
}

h1, h2, h3, h4, h5, h6 {
  color: ${colors.umaDarkGray};
  font-family: "Univers LT W01 65 Bold", Arial;
}

.wp-content h1, .wp-content .h1, .wp-content h2, .wp-content .h2, .wp-content h3, .wp-content .h3, .wp-content h4, .wp-content .h4, .wp-content h5, .wp-content .h5, .wp-content h6, .wp-content .h6 {
  color: ${colors.umaDarkBlue};
}

h1, .h1 { font-size: 2.2rem; }
h2, .h2 { font-size: 1.5rem; }
h3, .h3 { font-size: 1.29rem; }
h4, .h4 { font-size: 1.23rem; }
h5, .h5 { font-size: .91rem; }
h6, .h6 { font-size: .83rem; }

b, strong {
  font-weight: bold;
}

sup {
  font-size: 50%;
  top: -0.7em;
}

.nowrap { white-space: nowrap; }
.pointer { cursor: pointer; }

.td-n { text-decoration: none; }
a.td-n-hu {
  display: inline-block;
  line-height: normal;
  text-decoration: none;
  &:after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: black;
    transition: all .3s ease-in-out;
  }
  &:hover:after {
    width: 100%;
  }
}

.fff { color: #fff; }
.black { color: #000; }
.color-blue-d { color: ${colors.umaDarkBlue}; }
.color-blue-dp { color: ${colors.blueDarkPastel}; }
.color-blue-l { color: ${colors.umaLightBlue}; }
.color-gray-d { color: ${colors.umaDarkGray}; }
.color-gray-c { color: ${colors.umaCoolGray}; }
.color-gray-u { color: ${colors.umaUltimateLightGray}; }
.color-green { color: ${colors.umaGreen}; }
.color-orange { color: ${colors.umaOrange}; }
.color-pink { color: ${colors.umaPink}; }
.color-purple { color: ${colors.umaPurple}; }
.color-red { color: ${colors.umaRed}; }
.color-teal { color: ${colors.umaTeal}; }
.color-yellow { color: ${colors.umaYellow}; }

.bg-blue-d { background-color: ${colors.umaDarkBlue}; }
.bg-blue-dp { background-color: ${colors.blueDarkPastel}; }
.bg-blue-l { background-color: ${colors.umaLightBlue}; }
.bg-gray-d { background-color: ${colors.umaDarkGray}; }
.bg-gray-c { background-color: ${colors.umaCoolGray}; }
.bg-gray-u { background-color: ${colors.umaUltimateLightGray}; }
.bg-green { background-color: ${colors.umaGreen}; }
.bg-orange { background-color: ${colors.umaOrange}; }
.bg-pink { background-color: ${colors.umaPink}; }
.bg-purple { background-color: ${colors.umaPurple}; }
.bg-red { background-color: ${colors.umaRed}; }
.bg-teal { background-color: ${colors.umaTeal}; }
.bg-yellow { background-color: ${colors.umaYellow}; }
.bg-white { background-color: #fff; }

.ital {
  font-style: italic;
}

.all-caps {
  text-transform: uppercase;
}

.disclaimer {
  font-size: 0.75rem;
  line-height: 1.35;
}

.wp-content {
  p, li, strong {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    text-indent: -2.5rem;
    margin-left: 2rem;
    &:before {
      color: #F68D2E;
      content: "►";
      margin: .5rem .5rem 0 1rem;
    }
  }  
}

.screen {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.btn--strip {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.text-center {
  text-align: center;
}

/* IE 11 Fixes */
_:-ms-fullscreen, :root .hero__content.container div.phone-number { color: #fff; }

img[src*="https://jelly.mdhv.io"] {
  display: none;
}

.md-content {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    text-indent: -2.5rem;
    margin-left: 2rem;
    &:before {
      color: ${colors.umaOrange};
      content: "\u25BA";
      margin: .5rem .5rem 0 1rem;
    }
  }
}

.green-ul {
  li {
    list-style: none;
    text-indent: -2.5rem;
    :before {
      color: #78be21;
      content: "\u25BA";
      margin: .5rem .5rem 0 1rem;
    }
  }
}

.li--chevron {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    text-indent: -2.5em;
    margin-left: 2em;
    &:before {
      content: ">";
      margin: .5rem .5rem 0 1rem;
    }
  }
}

.position-relative { position: relative; }
.position-absolute { position: absolute; }

.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.list-style-none {
  list-style: none;
}

.disclaimer {
  line-height: 1;
}
`

export default GlobalStyle
