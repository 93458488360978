import React, { Component } from 'react';
import styled from 'styled-components';

class Filter extends Component {
  

  /**
   * constructor
   * 
   * @param {obj} props props
   */
  constructor(props) {
    super(props);
    
    this.filter = {
      active: false,
      name: this.props.name,
      group: this.props.group,
      column: this.props.filter.column,
      columnValue: this.props.filter.columnValue,
      test: page => page[this.filter.column] === this.filter.columnValue
    };

    this.state = {
      active: false
    };

    this.handleChange = this.handleChange.bind(this);
  }


  /**
   * handleChange
   * 
   * This function runs when the checkbox is clicked. If checked,
   * we apply the filter on the parent's pagelist. If not, then 
   * we un-apply the filter on the parent's pagelist.
   * 
   * @param {React Simulated Event} e the change event
   */
  handleChange(e) {
    let pageList = this.props.getParentState().pageList;
    pageList.forEach(page => {
      if(e.target.checked) {
        page.addFilter(this.filter);
      }
      else {
        page.removeFilter(this.filter);
      }
    });
    this.props.setParentState({pageList});
  }


  render() {
    return (
      <div className={`${this.props.className}`}>
        <label>
          <input
            type="checkbox"
            name={this.props.name}
            value={this.props.value}
            onChange={this.handleChange}
          />
          <span>{this.props.label}</span>
        </label>
      </div>
    )
  }
}

export default styled(Filter)`

label {
  cursor: pointer;
  display: block;
  padding: 0.25rem 0;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    font-weight: bold;
  }
}

label span {
  margin: 0 0.5rem;
}

input {
  cursor: pointer;
}

`;