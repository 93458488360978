// Node Module Imports
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const underlinedAGa = ({children, className, color, gaLabel, href, htmlRel, htmlTarget}) => (
  <a
    className={className}
    color={color}
    ga-label={gaLabel}
    href={href}
    rel={htmlRel}
    target={htmlTarget}
  >
    {children}
  </a>
)

export const UnderlinedAGa = styled(underlinedAGa)`
  display: inline-block;
  line-height: normal;
  text-decoration: none;
  color: ${props => props.color};
  &:after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${props => props.color};
    transition: all .3s ease-in-out;
  }
  &:hover:after {
    width: 100%;
  }
`;

underlinedAGa.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  gaLabel: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  htmlRel: PropTypes.string,
  htmltarget: PropTypes.string
};

UnderlinedAGa.defaultProps = {
  className: '',
  color: '#000',
  htmlRel: '',
  htmlTarget: '_self'
};