// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import parse from 'react-html-parser';

// Style Imports
import { colorIsWhite, colors } from '../../styles/utilities';
import { NarrowContainer, LineQuote } from '../../styles/elements';

// Utility Imports
import { shortcodeParser } from '../../utilities';

const quote = ({bgColor, className, fields}) => {
  const fq = fields.uma_brick_fact_quote;

  return (
    <section className={className}>
      <NarrowContainer>
        <p className="factoid fff">
        {parse(shortcodeParser(fq.fact))}
          {fq.add_footnote && <sup>2</sup>}
        </p>
        <LineQuote character="&#8220;"
                   color="#fff"
                   divMargin="0 1rem -1rem 1rem"
                   fontSize={10}
                   lineWidth={32}
                   transY={2}
        />
        <p className="quote">{fq.quote}</p>
        <span className="ital">{parse(fq.quote_author)}</span>
      </NarrowContainer>
    </section>
  );
};

export default styled(quote)`
  background-color: ${props => props.bgColor};
  ${props => {
    if (colorIsWhite(props.bgColor)) {
      return `color:${colors.umaDarkGray};`;
    }
    return `color: #fff;`;
  }}
  text-align: center;
  font-size: 1.3rem;
  .container {
    padding: 2rem 1rem;
    .factoid {
      margin-bottom: 1.5rem;
    }
    .quote {
      padding: 0 1rem;
    }
  }
`;

quote.propTypes = {
  bgColor: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired
}