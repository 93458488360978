import React from 'react'

import cx from 'classnames'
import { graphql } from 'gatsby'
import htmr from 'htmr'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { above, colors, space } from '../../styles/utilities'
import AcornFormWrapper from '../components/AcornFormWrapper'
import sp from '../util/shortcodes/parser'

const ModuleContentAndForm = ({ className, content, form }) => {
  const renderContent = (display) => (
    <>
      <div className={cx('sm-form-wrapper', display)}>
        <div className={cx('container')}>
          <AcornFormWrapper className={cx('form')} />
        </div>
      </div>
      <div className={cx('sm-content-wrapper', display)}>
        <div className={cx('container md-content')}>
          {htmr(sp(content.md.html))}
        </div>
      </div>
    </>
  )

  const renderContentLarge = (display) => (
    <div className={cx('container', display)}>
      <div className={cx('row')}>
        <div className={cx('content col-lg-7 order-2 order-lg-1 md-content')}>
          {htmr(sp(content.md.html))}
        </div>
        <div className={cx('col-lg-5 order-1 order-lg-2')}>
          <AcornFormWrapper className={cx('form')} />
        </div>
      </div>
    </div>
  )

  return (
    <section className={className}>
      {renderContent('d-lg-none')}
      {renderContentLarge('d-none d-lg-block')}
    </section>
  )
}

ModuleContentAndForm.propTypes = {
  content: PropTypes.shape({
    md: PropTypes.object.isRequired,
  }),
  form: PropTypes.shape({
    background: PropTypes.string.isRequired,
  }),
}

export const query = graphql`
  fragment ModuleContentAndForm on MarkdownRemark {
    frontmatter {
      modules {
        contentAndForm {
          content {
            md {
              html
            }
          }
        }
      }
    }
  }
`

export default styled(ModuleContentAndForm)`
  ${above.lg`
    margin: 2rem 0;
  `}

  .sm-form-wrapper {
    background: ${(props) => sp(props.form.background)};
  }

  .form {
    margin: 0 auto;
    max-width: 445px;
    ${above.lg`
      background: ${(props) => sp(props.form.background)};
      border-radius: 2px;
      padding: ${space.g};
    `}
    button {
      background: ${colors.dev.green.b()};
      transition: all 0.2s ease-in-out;
      &:hover,
      &:focus {
        background: ${colors.dev.green.c()};
      }
    }
  }
`
