const onInputFocus = (stickyMobileFooter) => {
  if (stickyMobileFooter) {
    // eslint-disable-next-line no-param-reassign
    stickyMobileFooter.style.display = 'none'
  }
}

const onInputBlur = (stickyMobileFooter) => {
  if (stickyMobileFooter) {
    // eslint-disable-next-line no-param-reassign
    stickyMobileFooter.style.display = 'flex'
  }
}

const watchInputs = (element = null) => {
  const inputs = element
    ? element.querySelectorAll('input')
    : document.querySelectorAll('input')
  const stickyMobileFooter = document.querySelector('.fixed-footer')
  if (stickyMobileFooter && inputs.length > 0) {
    inputs.forEach((input) => {
      input.addEventListener(
        'focus',
        onInputFocus.bind(undefined, stickyMobileFooter),
      )
      input.addEventListener(
        'blur',
        onInputBlur.bind(undefined, stickyMobileFooter),
      )
    })
  }
}

export default watchInputs
