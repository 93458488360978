import findInObject from '../../../utilities/findInObject'

/**
 * colorize shortcode
 * 
 * This is a content filter function. It receives content,
 * obj, and originalMatch from the shortcodeParser.
 * 
 * {{ shortcode: "colorize", color: "${colors.blue.a}" content="Hello" }}s
 * 
 * @param {string} content - The full chunk of content
 * @param {object} obj - The shortcode as a JS object
 * @param {string} originalMatch - The original match, useful for replacing the shortcode
 * 
 * @returns {string} content
 */
const color = (content, obj, originalMatch) => {
  const objType = findInObject('shortcode', obj)
  if(objType === 'colorize') {
    const color = findInObject('color', obj)
    const text = findInObject('content', obj)
    content = content.replace(originalMatch, `<span style="color:${color}">${text}</span>`)
  }
  return content
};

export default color;