export class ClassList {
  
  constructor(string) {
    this.classString = string;
    this.classList = this.classString.split(' ').filter(i => i.trim().length > 0);
  }

  contains = function(search) {
    if (typeof this.classList === 'object') {
      return this.classList.includes(search);
    }
    return false;
  }

}