class FootnoteManager {

  constructor() {
    this.footnotes = [];
    this.locked = false;
    this.count = this.count.bind(this);
    this.add = this.add.bind(this);
    this.getFootnotes = this.getFootnotes.bind(this);
    this.lock = this.lock.bind(this);
  }

  count() {
    return this.footnotes.length;
  }

  add(footnoteContext) {

    let id;

    this.footnotes.forEach(footnote => {
      if(footnote.text === footnoteContext.text) {
        id = footnote.id;
      }
    });

    if(!this.locked && !id) {
      id = `${this.count() + 1}`;
      this.footnotes.push({id: id, text: footnoteContext.text, link: footnoteContext.link})
    }

    return id;
  }

  getFootnotes() {
    return [...this.footnotes];
  }

  lock() {
    this.locked = true;
  }

  reset() {
    this.footnotes = [];
    this.locked = false;
  }

};

const footnoteState = new FootnoteManager();
export { footnoteState };