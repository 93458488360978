/* eslint-disable import/prefer-default-export */

import {
  UMAProgramCode,
  ClearwaterDegreeCode,
  ClearwaterDiplomaCode,
  OnlineDegreeCode,
  OnlineDiplomaCode,
} from './program'

export type ProgramLinkMap = Record<UMAProgramCode, { default: string }>

export const programLinkMap: ProgramLinkMap = {
  // online degree
  [OnlineDegreeCode.HCA]: {
    default: '/online/healthcare-accounting',
  },
  [OnlineDegreeCode.HCM]: {
    default: '/online/healthcare-management',
  },
  [OnlineDegreeCode.HHS]: {
    default: '/online/health-and-human-services',
  },
  [OnlineDegreeCode.HIM]: {
    default: '/online/health-information-management',
  },
  [OnlineDegreeCode.HIT]: {
    default: '/',
  },
  [OnlineDegreeCode.HS_MAA]: {
    default: '/online/medical-administrative-assistant',
  },
  [OnlineDegreeCode.HS_MOBS]: {
    default: '/online/medical-office-billing-specialist',
  },
  [OnlineDegreeCode.HS_PHT]: {
    default: '/online/pharmacy-technician',
  },
  [OnlineDegreeCode.MBCOA]: {
    default: '/online/medical-billing-coding',
  },

  // online diploma
  [OnlineDiplomaCode.MAA]: {
    default: '/online/medical-administrative-assistant',
  },
  [OnlineDiplomaCode.MBC]: {
    default: '/online/medical-billing-coding',
  },
  [OnlineDiplomaCode.MOBS]: {
    default: '/online/medical-office-billing-specialist',
  },

  // clearwater degree
  [ClearwaterDegreeCode.HS_DA]: {
    default: '/clearwater/dental-assistant',
  },
  [ClearwaterDegreeCode.HS_MA]: {
    default: '/clearwater/medical-assistant',
  },

  // clearwater diploma
  [ClearwaterDiplomaCode.DA]: {
    default: '/clearwater/dental-assistant',
  },
  [ClearwaterDiplomaCode.MA]: {
    default: '/clearwater/medical-assistant',
  },
  [ClearwaterDiplomaCode.NA]: {
    default: '/clearwater/nursing-assistant',
  },
  [ClearwaterDiplomaCode.PCT]: {
    default: '/clearwater/patient-care-technician',
  },
  [ClearwaterDiplomaCode.PH]: {
    default: '/clearwater/phlebotomy-technician',
  },
}
