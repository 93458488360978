import React, { useState } from 'react'

import { graphql, Link, navigate } from 'gatsby'
import htmr from 'htmr'
import styled from 'styled-components'

import ImageToutsCareMantra from '../components/cross-template-statics/ImageToutsCareMantra'
import NumberTouts from '../components/cross-template-statics/NumberTouts'
import GlobalFooter from '../components/GlobalFooter'
import HeaderWithMenu from '../components/HeadersAndHeroes/HeaderWithMenu'
import DRTVHeroScalable from '../components/heroes/DRTVHeroScalable'
import PS92020HeroScalable from '../components/heroes/PS92020HeroScalable'
import LogoSquare from '../components/LogoSquare'
import Menu from '../components/reusables/Menu'
import VideoLazyload from '../components/reusables/VideoLazyload'
import YouTubeEmbed from '../components/reusables/YouTubeEmbed'
import SEO from '../components/SEO'
import useGa from '../hooks/useGa'
import Layout from '../layouts/DefaultLayout'
import { above, colors } from '../styles/utilities'
import sp from '../utilities/shortcodeParser'
import StudentForm from '../v3/lib/components/forms/student/StudentForm'
import { SparkroomFieldName } from '../v3/lib/util/sparkroom'
import {
  ClearwaterDiplomaCode,
  newProgramManager,
  OnlineDegreeCode,
} from '../v3/lib/util/uma'
import { required } from '../v3/lib/util/validator'

const programManager = newProgramManager()

const CareMantra = ({ className, data }) => {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

  const phoneNumber = data?.markdown?.frontmatter?.phone_number

  const formEvent = useGa()

  const sortPrograms = (programEdges) =>
    programEdges.sort((a, b) => {
      const aNorm = a.node.meta.program_full_name.toLowerCase()
      const bNorm = b.node.meta.program_full_name.toLowerCase()
      if (aNorm < bNorm) {
        return -1
      } else if (aNorm > bNorm) {
        return 1
      }
      return 0
    })

  let sortedOnlinePrograms = sortPrograms(data.onlineDegreePrograms.edges)
  sortedOnlinePrograms = sortedOnlinePrograms.filter(
    (p) => p.node.meta.program_code !== 'UNSPEC',
  )
  sortedOnlinePrograms = sortedOnlinePrograms.map((p) => {
    if (p.node.meta.program_code !== 'HHS') {
      p.node.slug = p.node.slug.replace('and-', '')
    }
    return p
  })

  const sortedClearwaterPrograms = sortPrograms(
    data.clearwaterDiplomaPrograms.edges,
  )

  const closeModal = (e) => {
    if (displayMobileMenu && e.target === e.currentTarget) {
      setDisplayMobileMenu(false)
    }
  }

  const selectedCampus = 'Both'

  const programLinkPathMapperClearwater = [
    'dental-assistant-2',
    'medical-assistant-2',
    'nursing-assistant-2',
    'patient-care-technician-2',
    'phlebotomy-2',
    'dental-assistant-2',
    'medical-assistant-2',
  ]

  const includePrograms = [
    ClearwaterDiplomaCode.DA,
    ClearwaterDiplomaCode.MA,
    ClearwaterDiplomaCode.PCT,
    ClearwaterDiplomaCode.NA,
    ClearwaterDiplomaCode.PH,
    OnlineDegreeCode.HHS,
    OnlineDegreeCode.HIM,
    OnlineDegreeCode.HCA,
    OnlineDegreeCode.HCM,
    OnlineDegreeCode.HS_MAA,
    OnlineDegreeCode.MBCOA,
    OnlineDegreeCode.HS_MOBS,
    OnlineDegreeCode.HS_PHT,
  ]

  return (
    <Layout>
      <SEO
        title="Answer Your Calling to Care"
        description="Life can be more rewarding when we care for others. With care, small ripples can become waves that have the potential to strengthen communities and inspire other acts of care."
      />

      <div className={`${className} ${displayMobileMenu && 'menu-open'}`}>
        <div className="header-wrapper">
          <div className="container">
            <HeaderWithMenu
              phoneNumber={phoneNumber}
              displayMobileMenu={displayMobileMenu}
              setDisplayMobileMenu={setDisplayMobileMenu}
              selectedCampus={selectedCampus}
            />
          </div>
        </div>

        <div className="form-wrapper py-4 d-lg-none mobile-form">
          <div className="container">
            <div className="above-form">
              <h2 className="mt-0 text-white mb-1 form-font text-center">
                Learn how we care for students.
              </h2>
            </div>
            <StudentForm
              beforeSubmitCallback={() => {
                formEvent({
                  action: 'submit',
                  category: 'rfi-form',
                  label: 'under-hero',
                })
              }}
              className="form-container"
              action={() => {
                navigate(`/confirmation/?phone=${phoneNumber}&campaign=we-care`)
              }}
              fields={[
                SparkroomFieldName.FirstName,
                SparkroomFieldName.LastName,
                SparkroomFieldName.Email,
                SparkroomFieldName.Phone,
                {
                  hidden: false,
                  id: SparkroomFieldName.ProgramOnline,
                  options: includePrograms.map((code) => ({
                    label: programManager.only(code).fullName,
                    value: code,
                  })),
                  type: 'select',
                  validators: [required()],
                  value: '',
                },
              ]}
            />
          </div>
        </div>

        <PS92020HeroScalable
          bgImgSm={data.markdown.frontmatter.hero.img_sm.path.publicURL}
          bgImgLg={data.markdown.frontmatter.hero.img_lg.path.publicURL}
          phoneNumber={phoneNumber}
          className="d-lg-none"
        />

        <DRTVHeroScalable
          bgPosition="50% center"
          bgImgSm={data.markdown.frontmatter.hero.img_sm.path.publicURL}
          bgImgLg={data.markdown.frontmatter.hero.img_lg.path.publicURL}
          hideLogoAndPhone
          phoneNumber={phoneNumber}
          className="d-none d-lg-block"
        />

        <div className="page-content">
          <div className="container py-4 py-xl-5">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 py-5 pt-lg-0">
                {htmr(sp(data.markdown.frontmatter.content.aboveVideo))}
                <VideoLazyload
                  posterAlt="We care."
                  posterSrc={
                    data.markdown.frontmatter.content.videoPoster.path.publicURL
                  }
                  includePlayIcon
                >
                  <YouTubeEmbed
                    className="video-iframe"
                    width={999}
                    videoId={data.markdown.frontmatter.content.video}
                    title="Care Pays Back"
                    style={{
                      maxWidth: '100%',
                    }}
                  />
                </VideoLazyload>
              </div>
              <div className="col-lg-5 offset-lg-1 p-0 order-1 order-lg-2 d-none d-lg-block">
                <div className="form-wrap--lg p-4">
                  <div className="above-form">
                    <h2 className="mt-0 text-white mb-1 form-font text-center">
                      Learn how we care for students.
                    </h2>
                  </div>
                  <StudentForm
                    beforeSubmitCallback={() => {
                      formEvent({
                        action: 'submit',
                        category: 'rfi-form',
                        label: 'under-hero',
                      })
                    }}
                    className="form-container"
                    action={() => {
                      navigate(
                        `/confirmation/?phone=${phoneNumber}&campaign=we-care`,
                      )
                    }}
                    fields={[
                      SparkroomFieldName.FirstName,
                      SparkroomFieldName.LastName,
                      SparkroomFieldName.Email,
                      SparkroomFieldName.Phone,
                      {
                        hidden: false,
                        id: SparkroomFieldName.ProgramOnline,
                        markRequired: true,
                        options: includePrograms.map((code) => ({
                          label: programManager.only(code).fullName,
                          value: code,
                        })),
                        type: 'select',
                        validators: [required()],
                        value: '',
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="program-stack wp-content">
          <div className="clearwater">
            <div className="container">
              <div className="row d-flex">
                <div className="col-12 col-md-6 bg-img-wrap order-2 order-md-1">
                  <div
                    className="bg-img"
                    style={{
                      backgroundImage: `
                      url(${data.markdown.frontmatter.clearwaterPrograms.img.path.publicURL})
                    `,
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 p-0 text order-1 order-md-2">
                  <h3 className="heading m-0 p-3">CLEARWATER</h3>
                  <p className="px-3 m-0 bold">
                    Experience blended learning<sup>2</sup> at our Clearwater
                    Campus and get a mix of hands-on training with online
                    instruction.
                  </p>
                  <ul className="p-3">
                    {sortedClearwaterPrograms.map(({ node }, idx) => (
                      <li key={node.meta.program_full_name}>
                        <Link
                          className="Link"
                          to={`/clearwater/${programLinkPathMapperClearwater[idx]}`}
                        >
                          {node.meta.program_full_name}
                        </Link>
                      </li>
                    ))}
                    {data.clearwaterDegreePrograms.edges.map(
                      ({ node }, idx) => (
                        <li key={node.meta.program_full_name}>
                          <Link
                            className="Link"
                            to={`/clearwater/${programLinkPathMapperClearwater[idx]}`}
                          >
                            {node.meta.program_full_name}
                          </Link>
                        </li>
                      ),
                    )}
                  </ul>
                  <hr />
                  <h3 className="heading m-0 p-3">ONLINE</h3>
                  <p className="px-3 m-0 bold">
                    Earn a degree online from the comfort of your home with a
                    flexible schedule.<sup>3</sup>
                  </p>
                  <ul className="p-3">
                    {sortedOnlinePrograms.map(({ node }) => (
                      <li key={node.meta.program_full_name}>
                        <Link className="Link" to={`/online/${node.slug}-2`}>
                          {node.meta.program_full_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NumberTouts />

        <ImageToutsCareMantra
          content={() => (
            <p>
              With transformative student support and care, see how earning your
              education from home could fit into your busy life. Don’t worry –
              you won’t have to do this alone. You’ll have access to:
            </p>
          )}
        />

        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <LogoSquare />
              </div>
              <div className="col-12 col-md-8">
                <p>
                  If you’re committed to doing more, to seeking a new path, to
                  making a difference in your life, your family’s life, and the
                  lives of others, UMA is ready to help get you started. Begin
                  your journey today.
                </p>
              </div>
            </div>
          </div>
        </div>

        <>
          <div
            aria-label="Navigation Toggle"
            role="button"
            tabIndex="0"
            className={`mobile-menu-wrap ${displayMobileMenu && 'active'}`}
            onClick={closeModal}
            onKeyDown={(e) =>
              e.keyCode === 13 ? setDisplayMobileMenu(false) : null
            }
          />
          <div className={`mobile-menu ${displayMobileMenu && 'active'}`}>
            <Menu
              selectedCampus={selectedCampus}
              setDisplayMobileMenu={setDisplayMobileMenu}
            />
          </div>
        </>
      </div>

      <div className="container py-4">
        <p className="m-0">
          <small>
            <sup>1</sup> Bureau of Labor Statistics. Occupational Outlook
            Handbook. Healthcare Occupations.{' '}
            <a
              href="https://bls.gov/ooh/healthcare/home.htm"
              rel="noopener noreferrer"
              target="_blank"
            >
              bls.gov/ooh/healthcare/home.htm
            </a>
          </small>
          <br />
          <small>
            <sup>2</sup> Nursing Assistant diploma program remains fully
            on-campus.
          </small>
          <br />
          <small>
            <sup>3</sup> Set schedules required for externship/practicum courses
            in select programs and coursework deadlines are set by instructors.
          </small>
        </p>
      </div>

      <GlobalFooter phone={phoneNumber} sticky />
      {/* <StickyMobileFooter phone={phoneNumber} chatBtnId="LP_DIV_1432733350793" /> */}
    </Layout>
  )
}

export default styled(CareMantra)`
  p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1,
  h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .header-wrapper {
    background-color: ${colors.umaLightBlue};
    padding: 0.75rem 0;
  }

  [class^='form-wrap'] {
    h2.form-font {
      font-size: 2rem;
    }
    p.form-font {
      font-size: 1rem;
      ${above.lg`
        font-size: 1.25rem;
      `}
    }
  }

  form {
    input[type='submit'] {
      background-color: ${colors.umaGreenNew};
      color: ${colors.umaBlue400};
    }
  }

  .form-wrapper {
    background-color: ${colors.umaDarkBlue};
    > .container {
      padding: 0 7px;
    }
    .form-container {
      margin: 0 auto;
      padding: 0;
      max-width: 500px;
      h2,
      p {
        text-align: center;
      }
    }

    form {
      flex-wrap: wrap;
      justify-content: space-between;

      ${above.lg`
        display: flex;
      `}
    }

    form .input-wrap,
    form .button-wrap {
      flex: 0 0 48.5%;
      margin: 10px 6.75px;
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      select {
        font-size: 1rem;
      }
    }
    form .button-wrap {
      display: flex;
      margin: 1.5rem 6.75px;
    }
  }

  .hero-content {
    .row {
      padding-top: 8rem;

      ${above.lg`
        padding-top: 2rem;
        padding-bottom: 2rem;      
      `}
    }

    p {
      color: #fff;
      font-size: 3rem;
      line-height: 1;
      text-transform: uppercase;

      ${above.lg`
        font-size: 4rem;
      `}
    }

    .arrow {
      background-color: ${colors.umaOrange};
      box-shadow: 8px 8px 6px hsla(0, 0%, 0%, 0.4);
      border-radius: 1px;
      display: inline-block;
      margin-top: 1.5rem;
      position: relative;
      max-width: 88%;

      svg {
        border-radius: 1px;
        fill: ${colors.umaOrange};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        width: auto;
      }
    }

    .arrow p {
      font-size: 1.5rem;

      ${above.md`
        font-size: 2rem;
      `}
    }
  }

  .page-content {
    h1 {
      margin-top: 0;
    }

    li {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    strong {
      line-height: 1.25;
      font-weight: bold;
    }
  }

  .program-stack {
    color: white;
    margin-top: 3rem;

    .heading {
      color: white;
    }

    .Link {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .online,
    .clearwater {
      position: relative;

      .bg-img-wrap,
      .text {
        ${above.md`
          margin-top: -3.45rem;
        `}
      }

      .bg-img-wrap {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        overflow: hidden;
      }

      .text {
        background-color: ${colors.umaDarkBlue};
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        overflow: hidden;
      }
    }

    .online::before,
    .clearwater::before {
      content: '';
      display: block;
      background-color: ${colors.umaGreenNew};
      position: absolute;
      top: 3.45rem;
      right: 0;
      bottom: 3.45rem;
      left: 0;

      ${above.md`
        top: 0; bottom: -3.45rem;
        padding-bottom: 3.45rem;
      `}
    }

    .online {
      margin-bottom: 4rem;
      margin-top: 15rem;
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }

    .clearwater {
      ${above.md`
        margin-bottom: 4rem;
      `}
      .heading {
        background-color: ${colors.umaDarkBlue};
      }
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .phone {
    border-radius: 2px;
    color: ${colors.umaDarkBlue};
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    font-weight: normal;
    text-decoration: none;

    svg {
      transform: scaleX(-1);
      font-size: 1.5rem;

      ${above.sm`
        margin-right: 0.5rem;
        font-size: 1rem;
      `}
    }
  }

  .form-wrap--lg {
    position: relative;
    ${above.lg`
      box-shadow: -8px -8px 6px hsla(0, 0%, 0%, 0.4);
      margin-top: -10rem;
    `}

    &::before {
      background-color: ${colors.umaDarkBlue};
      border-radius: 2px;
      content: '';
      display: block;
      opacity: 0.9;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    .form--lg {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .mobile-menu-wrap {
    background: ${colors.umaLightBlue};
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: -100%;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    &.active {
      display: block;
      left: 0;
      opacity: 0.9;
    }
  }

  .mobile-menu {
    background-color: ${colors.umaDarkGray};
    color: #fff;
    font-size: 0.9rem;
    max-width: 350px;
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: all 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;

    width: 80%;
    z-index: 9;

    &.active {
      left: 0;

      a {
        padding: 0.2rem;
      }
      .close-wrap {
        text-align: right;
      }
    }

    a {
      display: block;
      color: #fff;
      line-height: 1.1rem;
      padding: 10rem 0;
      text-decoration: none;
      transition: color 0.2s ease-in-out, padding 0.4s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        color: ${colors.umaGreenNew};
      }
    }

    ul {
      list-style: none;
      margin: 0.5rem 0 2rem;
      padding: 0 0 0 1rem;
    }

    li {
      line-height: 1.2rem;
      margin: 0.75rem 0 0.75rem 0.5rem;
      text-indent: -1.1rem;

      svg {
        margin-bottom: -2px;
        margin-right: 0.2rem;
      }
    }

    .menu-heading {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }

  .mobile-form {
    padding: 0.5rem;
    .input-wrapper {
      margin-bottom: 0.5rem;
    }
  }
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    .video-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const query = graphql`
  query($id: String!) {
    markdown: mdx(id: { eq: $id }) {
      frontmatter {
        phone_number
        tracking {
          leadsource
          leadvendor
          vendor
        }
        hero {
          img_sm {
            path {
              publicURL
            }
          }
          img_lg {
            path {
              publicURL
            }
          }
        }
        clearwaterPrograms {
          img {
            path {
              publicURL
            }
          }
        }
        onlinePrograms {
          img {
            path {
              publicURL
            }
          }
        }
        content {
          aboveVideo
          video
          videoPoster {
            path {
              publicURL
            }
          }
        }
      }
      body
    }

    clearwaterDiplomaPrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "diploma" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    clearwaterDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "47" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_code }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
        }
      }
    }

    onlineDegreePrograms: allWordpressWpPrograms(
      filter: {
        meta: { program_campus: { eq: "45" }, program_type: { eq: "degree" } }
      }
      sort: { fields: meta___program_full_name }
    ) {
      edges {
        node {
          meta {
            program_code
            program_full_name
          }
          slug
        }
      }
    }
  }
`
