// Node Module Imports
import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import ImageBox from './ImageBox';
import MasonryContainer from './MasonryContainer';
import Programs from './Programs';
import ProgramToggle from './ProgramToggle';
import Quote from './Quote';
import Tout from './Tout';
import VideoLazyload from './VideoLazyload';
import Wysiwyg from './WYSIWYG';

export default function bricks({
  brickSections, queriedBricks, programs, selectedCampus,
  className, template
}) {
  
  // called at the end of the renderBrickSections function
  const renderIndividualBricks = (brickSection, brickLayout) => {

    // loop over each individual brick in the masonry component (brick section)
    return brickSection.bricks.map((individualBrick, idx) => {

      // take a copy of the queried bricks, so as to operate on the copy and not modify the original array
      const queriedBricksCopy = [...queriedBricks];

      // in the queried bricks copy, find the queried brick that corresponds (by id) to the current iteration of an individual brick within a masonry component (brick section)
      const correspondingQueriedBrick = queriedBricksCopy.splice(queriedBricks.indexOf(queriedBricks.find(queriedBrick => queriedBrick.node.wordpress_id === individualBrick.id)), 1);

      // add a new property to the current iteration of an individual brick (it's an object) called queriedBrick, and set it equal to the current iteration of an individual brick's corresponding queried brick
      individualBrick.queriedBrick = correspondingQueriedBrick[0] !== undefined ? correspondingQueriedBrick[0].node : null;

      // destructure acf to shorten the value of props passed to children components
      const { acf } = individualBrick.queriedBrick;
      
      // determine which components to render, based on the type of brick is currently being mapped
      if (acf.uma_brick.type.value === 'fact_quote') {
        return <Quote
                fields={acf}
                key={idx}
                bgColor={individualBrick.backgroundColor.value}
              />;
      } 
      else if (acf.uma_brick.type.value === 'programs') {
        return <Programs
                fields={acf}
                key={idx}
                progList={programs}
                selectedCampus={selectedCampus}
              />;
      } 
      else if (acf.uma_brick.type.value === 'tout') {
        return <Tout
                className={`idx-${idx} component--tout`}
                fields={acf}
                key={idx}
                bgColor={individualBrick.backgroundColor.value}
                layout={brickLayout}
                associatedPrograms={programs}
                template={template}
              />;
      } 
      else if (acf.uma_brick.type.value === 'video') {
        return <VideoLazyload
                className={`idx-${idx} component--video`}
                key={idx}
                posterAlt={acf.uma_brick_video.poster.alt_text}
                posterGatsbyImgType="fluid"
                posterSrc={acf.uma_brick_video.poster.localFile.childImageSharp.fluid}
                videoEmbedString={acf.uma_brick_video.video_embed_code}
              />;
      } 
      else if (acf.uma_brick.type.value === 'wysiwyg') {
        return <Wysiwyg
                className={`idx-${idx} component--wysiwyg`}
                fields={acf}
                key={idx}
                bgColor={individualBrick.backgroundColor.value}
                layout={brickLayout}
              />;
      } 
      else if (acf.uma_brick.type.value === 'program_list') {
        return <ProgramToggle 
                  className={`idx-${idx} component--program-toggle`}
                  key={idx} 
                  bgColor={individualBrick.backgroundColor.value}
                  layout={brickLayout}
                  progList={programs}
                  selectedCampus={selectedCampus}
                />;
      } 
      else if (acf.uma_brick.type.value === 'image_box') {
        return <ImageBox
                className={`idx-${idx}`}
                fields={acf}
                key={idx}
                bgColor={individualBrick.backgroundColor.value}
                layout={brickLayout}
              />;
      } 
      // else if (acf.uma_brick.type.value === 'program_list') {
      //   return <Video className={`idx-${idx} ${brickLayout}`} fields={acf} key={idx} bgColor={individualBrick.backgroundColor.value} layout={brickLayout} />;
      // }
      return null;
    })
  };

  // this function is called in the component's render method, runs on component mount 
  const renderBrickSections = (brickSections) => {
    // loop over each masonry component (brick section) in the masonry (all brick sections)
    return brickSections.map((brickSection, idx) => {
      // get the value of the brick layout (horizontal, checkerboard, etc.) and background color to pass it as a prop to the outermost div and the child tout component
      const brickLayout = brickSection.layout_style.value;
      const bgColor = brickSection.bg_color !== null ? brickSection.bg_color.value : null;
      // call a function to loop over each individual brick in the current iteration of a masonry component (brick section), and wrap all bricks in the current masonry component (brick section) in the markup below
      return (
        <MasonryContainer 
          key={idx}
          className={`${brickLayout}`}
          bgColor={bgColor}
          /*style={{background: `${bgColor}59`}}*/
          template={template}
        >
          {renderIndividualBricks(brickSection, brickLayout)}
        </MasonryContainer>
      )
    })
  }

  return (
    <div className={className}>
      {/* this runs on mounting of the component */}
      { renderBrickSections(brickSections) }
    </div>
  );
};

bricks.propTypes = {
  brickSections: PropTypes.array.isRequired,
  programs: PropTypes.array.isRequired,
  queriedBricks: PropTypes.array.isRequired,
  selectedCampus: PropTypes.string.isRequired,
  template: PropTypes.string
}

bricks.defaultProps = {
  template: "default"
}