import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

const LogoRelevancy = ({className}) => (
  <div className={`${className}`}>
    <StaticQuery
      query={graphql`
      {
        file(sourceInstanceName: {eq: "images"}, name: {eq: "rectangle-logo-with-r"}) {
          childImageSharp {
            fluid(maxWidth: 250, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      `}
      render={data => {
        if ( data.file.childImageSharp !== null ) {
          return (
            <Img
              loading="eager"
              className="img--logo"
              fluid={data.file.childImageSharp.fluid}
              style={{
                display: 'inline-block',
                padding: '0',
                width: '250px'
              }}
              placeholderStyle={{
                display: 'none'
              }}
            />
          )
        }
        return null;
      }}
    />
  </div>
);

export default styled(LogoRelevancy)`     
  font-size: 0;
  line-height: 0;
`