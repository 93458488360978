module.exports = (function() {
  if(process.env.CONTEXT === 'branch-deploy') {
    return process.env.DEPLOY_PRIME_URL;
  }
  else if(process.env.CONTEXT === 'deploy-preview') {
    return process.env.DEPLOY_URL;
  }
  else if(process.env.CONTEXT === 'production') {
    return process.env.URL;
  }
  else {
    return 'http://localhost:8000';
  }
})();