/* eslint-disable sort-keys */
import { css } from 'styled-components'

const sizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export const above = Object.keys(sizes).reduce<
  Record<keyof typeof sizes, Function>
>(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `

    return acc
  },
  { sm: () => null, md: () => null, lg: () => null, xl: () => null },
)

export const below = Object.keys(sizes).reduce<
  Record<keyof typeof sizes, Function>
>(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label] - 1}px) {
        ${css(...args)}
      }
    `

    return acc
  },
  { sm: () => null, md: () => null, lg: () => null, xl: () => null },
)
