import React, { createContext, useCallback, useMemo, useState } from 'react'

type Modal = () => React.ReactNode

interface ModalContextInterface {
  activeModal: Modal
  closeModal(): void
  isModalActive: boolean
  openModal(modal: Modal): void
  toggleModal(modal: Modal): void
}

export const ModalContext = createContext<ModalContextInterface>({
  activeModal: null,
  closeModal: () => null,
  isModalActive: false,
  openModal: () => null,
  toggleModal: () => null,
})

type ModalProviderProps = {
  children: React.ReactNode
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [activeModal, setActiveModal] = useState<Modal>(null)

  const openModal = useCallback((modal: Modal) => {
    setActiveModal(modal)
  }, [])

  const closeModal = useCallback(() => {
    setActiveModal(null)
  }, [])

  const toggleModal = useCallback((modal: Modal) => {
    setActiveModal((prevState) => {
      if (prevState === null) return modal
      return null
    })
  }, [])

  const isModalActive = useMemo(() => activeModal !== null, [activeModal])

  return (
    <ModalContext.Provider
      value={{ activeModal, closeModal, isModalActive, openModal, toggleModal }}
    >
      {children}
    </ModalContext.Provider>
  )
}
