import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Pages`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`/pages`}</inlineCode>{` directory is where all learn's pages should live. This was a bit of a free-for-all until we came up with our `}<inlineCode parentName="p">{`/v2`}</inlineCode>{` naming convention. Our goal is to migrate all existing pages to the `}<inlineCode parentName="p">{`/v2`}</inlineCode>{` directory and put all new pages in the `}<inlineCode parentName="p">{`/v2`}</inlineCode>{` directory as well. We will also move away from creating pages from markdown.`}</p>
    <p>{`The naming convention that we came up with looks like this:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/v2/<campus>/<channel>/<campaign-name>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`/v2/<campus>/programs/<program-name>`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      