import { combineReducers } from 'redux';
import { 
  initialFormConfig, initialGlobalState, initialAcornFormConfig 
} from '../initial-state';
import { assign, unionBy, find } from 'lodash';

import { hiddenFormParamsReducer, umaReducer } from '../../v2/redux/reducers'

// Reducers
const activeServiceReducer = (previousState = [], action) => {
  if(action.type === 'INIT_SERVICE') {
    return [...previousState, action.payload.service];
  }
  return previousState;
}

// const leadIdReducer = (previousState = null, action) => {
//   if(action.type === 'UPDATE_LEADID') {
//     return action.payload.leadid;
//   }
//   return previousState;
// };

const formParamsReducer = (previousState = initialFormConfig, action) => {

  if(action.type === 'ADD_OR_UPDATE_FORM_PARAM') {
    return unionBy(action.payload.param, previousState, 'key');
  }

  else if(action.type === 'ADD_OR_UPDATE_FORM_PARAMS') {
    return unionBy(action.payload.params, previousState, 'key');
  }

  return previousState;
};

const globalsReducer = (previousState = initialGlobalState, action) => {
  if(action.type === "ADD_OR_UPDATE_GLOBAL") {
    return assign({}, previousState, action.payload);
  }
  return previousState;
}

const acornFormParamReducer = (previousState=initialAcornFormConfig, action) => {
  if(action.type === "UPDATE_ACORN_PARAMS") {
    return unionBy(action.payload, previousState, 'key');
  }
  if(action.type === "UPDATE_ACORN_PARAM_VALUE") {
    const newState = [...previousState];
    const obj = find(newState, o => { 
      return o.key === action.payload.key 
    });
    if(obj) { 
      obj.value = action.payload.value; 
      return newState;
    }
  }
  return previousState;
}

const reducers = combineReducers({
  _r_services: activeServiceReducer,
  _r_formParams: formParamsReducer,
  _r_globals: globalsReducer,
  acornFormParams: acornFormParamReducer,
  v2_hiddenFormParams: hiddenFormParamsReducer,
  v2_uma: umaReducer
});

export { reducers };