// Node Module Imports
import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

// Utility Component Imports
import GlobalStyle from '../styles/Global'

// Utility Imports
import { TrackGa } from '../utilities'
import watchInputs from '../utilities/watchInputs'
import GlobalFooter from './GlobalFooter'
import StickyMobileFooter from './reusables/StickyMobileFooter'

const LayoutChickfila = ({
  children,
  phone,
  seo,
  showDisclaimer,
  sticky,
  stickyFuchsia,
  template,
}) => {
  useEffect(() => {
    TrackGa.init({
      DEBUG: false,
      parentToObserve: document.querySelector('body'),
      clickables: [
        {
          selector:
            'img[src="https://www.ultimatemedical.edu/wp-content/uploads/2016/04/liveEngage-circle-e1482121031291.png"]',
          events: 'click',
          reselect: true,
          ga: [
            'send',
            'event',
            'chat',
            'click',
            'click to chat - desktop - Learn',
          ],
        },
      ],
    })

    watchInputs()
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {`${
            seo && seo.page_title ? `${seo.page_title} | ` : ''
          }Ultimate Medical Academy`}
        </title>
        {seo && seo.meta_description && (
          <meta name="description" content={seo.meta_description} />
        )}
      </Helmet>
      {children}
      {showDisclaimer && (
        <GlobalFooter
          sticky={sticky && sticky.display_sticky_footer}
          phone={phone}
          template={template}
        />
      )}
      {/* {sticky && sticky.display_sticky_footer && (
        <StickyMobileFooter
          chatBtnId={
            sticky.chat_button_id_select.label !== 'Custom'
              ? sticky.chat_button_id_select.value
              : sticky.chatBtnId
          }
          phone={phone}
          stickyFuchsia={stickyFuchsia}
        />
      )} */}
      <GlobalStyle />
      <div className="container disclaimer">
        <p
          className="py-3 my-0"
          style={{ fontSize: '0.65rem', lineHeight: '1.25' }}
        >
          Where national data regarding earnings or employability within a
          particular field are cited, individual readers should take note that
          national averages may not accurately reflect the earnings of workers
          in their particular part of the country, and may include earners at
          all stages of their career, not just entry-level wages for recent
          graduates.
        </p>
        <p className="my-0" style={{ fontSize: '0.65rem', lineHeight: '1.25' }}>
          1. Set schedules required for on-site externship/practicum courses in
          select programs and in all programs, coursework deadlines are set by
          instructors.
        </p>
        <p className="mt-3" style={{ fontSize: '0.65rem', lineHeight: '1.25' }}>
          Note: Tuition coverage provided by Chick-fil-A may not cover the
          entirety of your tuition at Ultimate Medical Academy. However,
          financial aid is available for those who qualify.
        </p>
      </div>
    </>
  )
}

LayoutChickfila.propTypes = {
  showDisclaimer: PropTypes.bool,
  stickyFuchsia: PropTypes.bool,
}

LayoutChickfila.defaultProps = {
  showDisclaimer: true,
  stickyFuchsia: false,
}

export default LayoutChickfila
