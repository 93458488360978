import React, { createContext, useCallback, useEffect, useState } from 'react'

export type Footnote = {
  content: string
  id: number
}

interface FootnoteContextInterface {
  addFootnote(s: string): void
  clearFootnotes(): void
  footnotes: Footnote[]
  getFootnoteByContent: (c: string) => Footnote
  removeFootnoteByContent(c: string): void
}

export const FootnoteContext = createContext<FootnoteContextInterface>({
  addFootnote: () => null,
  clearFootnotes: () => null,
  footnotes: [],
  getFootnoteByContent: () => undefined,
  removeFootnoteByContent: () => null,
})

type FootnoteProviderProps = {
  children: React.ReactNode
}

export const FootnoteProvider = ({ children }: FootnoteProviderProps) => {
  const [footnotes, setFootnotes] = useState<Footnote[]>([])

  const addFootnote = useCallback(
    (s: string) => {
      setFootnotes((prevState) => {
        const footnote = { content: s, id: prevState.length + 1 }
        return [...prevState, footnote]
      })
    },
    [setFootnotes],
  )

  const clearFootnotes = useCallback(() => {
    setFootnotes([])
  }, [setFootnotes])

  const getFootnoteByContent = useCallback(
    (c: string) => footnotes.filter((f) => f.content === c)?.[0],
    [footnotes],
  )

  const removeFootnoteByContent = useCallback(
    (c: string) => {
      setFootnotes((prevState) => prevState.filter((f) => f.content === c))
    },
    [setFootnotes],
  )

  useEffect(() => {
    return () => setFootnotes([])
  }, [])

  return (
    <FootnoteContext.Provider
      value={{
        addFootnote,
        clearFootnotes,
        footnotes,
        getFootnoteByContent,
        removeFootnoteByContent,
      }}
    >
      {children}
    </FootnoteContext.Provider>
  )
}
