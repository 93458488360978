import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { above, colors } from '../../../styles/utilities/index'

import CaringForYourCareer from '../../../images/we-care-call/caring-for-your-career.jpg'
import ChoosingYourProgram from '../../../images/we-care-call/choosing-your-program.jpg'
import StudentLifeBalance from '../../../images/we-care-call/your-student-life-balance.jpg'

const HowCarePaysBack = ({ className }) => (
  <div className={className} id="how-care-pays-back">
    <div className="container">
      <h2>How Care Pays Back: A Three-Part Webinar Series</h2>
      <p>
        Join UMA for an in-depth look at the support we offer to more than
        10,000 students in every aspect of their educational journey toward
        graduation and pursuing a career in healthcare.
      </p>
      <p>
        <a
          href="https://umawebinars.vfairs.com/"
          rel="noopener noreferrer"
          target="blank"
        >
          Sign up
        </a>{' '}
        and learn about four important student care teams in just three
        webinars:
      </p>
      <div className="video-links d-flex">
        <a
          className="video-link"
          href="https://umawebinars.vfairs.com/"
          rel="noopener noreferrer"
          target="blank"
        >
          <img src={ChoosingYourProgram} alt="" />
        </a>
        <a
          className="video-link"
          href="https://umawebinars.vfairs.com/"
          rel="noopener noreferrer"
          target="blank"
        >
          <img src={StudentLifeBalance} alt="" />
        </a>
        <a
          className="video-link"
          href="https://umawebinars.vfairs.com/"
          rel="noopener noreferrer"
          target="blank"
        >
          <img src={CaringForYourCareer} alt="" />
        </a>
      </div>
    </div>
  </div>
)

export default styled(HowCarePaysBack)`
  padding: 2rem 0;
  ${above.md`
    padding: 5rem 0 2rem;
  `}
  ${above.lg`
    padding: 2rem 0;
  `}

  .video-links {
    justify-content: space-between;
    flex-wrap: wrap;
    ${above.xl`
    justify-content: space-evenly;
    `}
    .video-link {
      flex: 1 1 80%;
      text-align: center;
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 2rem;
      }
      ${above.md`
        margin-bottom: 0;
        flex: 0 0 30%;
      `}
      ${above.xl`
        margin-bottom: 0;
        flex: 0 0 25%;
      `}
    }
  }
`
HowCarePaysBack.propTypes = {
  className: PropTypes.string,
}

HowCarePaysBack.defaultProps = {
  className: '',
}
