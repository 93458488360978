import React, { FormEvent, useEffect, useMemo, useState } from 'react'

import cx from 'classnames'
import htmr from 'htmr'

import { formDisclaimer } from '../../../../data/disclaimers'
import { FormTheme } from '../../../../themes/forms'
import useForm, { FieldOptionsWithID } from '../../../hooks/useForm'
import useLeadID from '../../../hooks/useLeadID'
import useSparkroom from '../../../hooks/useSparkroom'
import useTeams from '../../../hooks/useTeams'
import { getGAClientID } from '../../../util/cookie'
import { SparkroomFieldName } from '../../../util/sparkroom'
import { newStudentRequest } from '../../../util/student'
import FieldRouter from '../generic/FieldRouter'
import StyledForm from '../generic/StyledForm'

export type StudentFormProps = {
  action: (leadID: string) => void
  afterValidate?: (e: FormEvent) => void
  button?:
    | string
    | (({ isSubmitting }: { isSubmitting: boolean }) => React.ReactElement)
  className?: string
  debug?: boolean
  fields: (string | FieldOptionsWithID)[]
  themes?: FormTheme[]
}

function StudentForm({
  action,
  afterValidate,
  button,
  className,
  debug,
  fields,
  themes,
}: StudentFormProps) {
  // local state
  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [triedSubmit, setTriedSubmit] = useState(false)

  // utils
  const { leadID } = useLeadID()
  const { sendToSparkroom } = useSparkroom()
  const { sendToTeams } = useTeams()

  // memos
  const memoedDefaults = useMemo(() => newStudentRequest(), [])
  const memoedOptions = useMemo(() => fields, [fields])

  // get the field state and form validator
  const {
    blurAllFields,
    fieldJSON,
    fieldState,
    isValid,
    updateField,
  } = useForm({
    fieldDefaults: memoedDefaults,
    fieldOptions: memoedOptions,
  })

  // update fields that need the window
  useEffect(() => {
    // populate clientID
    const clientID = getGAClientID()
    if (clientID) updateField(SparkroomFieldName.ClientID, { value: clientID })
    // populate leadID
    updateField(SparkroomFieldName.LeadIDToken, { value: leadID })
    // populate PathLabel
    updateField(SparkroomFieldName.PathLabel, { value: window.location.href })
  }, [leadID, updateField])

  // set form error message if tried submit with errors
  useEffect(() => {
    if (triedSubmit && !isValid()) {
      setErrorMessage('Please fix form errors before submitting.')
    } else if (triedSubmit && isValid) setErrorMessage('')
  }, [isValid, setErrorMessage, triedSubmit])

  // onSubmit callback
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    // attempted submit
    setTriedSubmit(true)

    // form is submitting
    setIsSubmitting(true)

    if (!isValid()) {
      setIsSubmitting(false)
      blurAllFields()
      return null
    }

    if (afterValidate) afterValidate(e)

    if (debug) {
      // eslint-disable-next-line no-console
      console.log({ json: fieldJSON() })
      setIsSubmitting(false)
      return null
    }

    const { notification, sparkroomError } = await sendToSparkroom(
      fieldJSON({ omitEmpty: true }),
    )
    const { teamsError } = await sendToTeams(notification)

    if (sparkroomError || teamsError) {
      // TODO: Don't actually hardcode this phone number
      setErrorMessage(
        'Oh no! We\'re sorry, but something went wrong with your submission. Please try again or call <a style="color:hsl(347.4, 31.1%, 12%);" href="tel:8882052510">(888) 205-2510</a>.',
      )
      setIsSubmitting(false)
      return null
    }

    action(leadID)
    return null
  }

  return (
    <StyledForm className={cx(className)} themes={themes}>
      <form onSubmit={onSubmit}>
        {errorMessage && (
          <p className="form-error-message">{htmr(errorMessage)}</p>
        )}

        <FieldRouter fields={fieldState} />

        {button && typeof button === 'function'
          ? button({ isSubmitting })
          : null}
        {button && typeof button === 'string' ? (
          <input type="submit" value={button} disabled={isSubmitting} />
        ) : null}

        <footer>
          <p className="disclaimer">{htmr(formDisclaimer)}</p>
        </footer>
      </form>
    </StyledForm>
  )
}

StudentForm.defaultProps = {
  beforeSubmitCallback: undefined,
  button: 'Request Info',
  className: '',
  themes: undefined,
}

export default StudentForm
