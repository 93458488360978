import React from 'react'

import styled from 'styled-components'

import darkChevron from '../../assets/images/backgrounds/dark-chevron.png'
import Chevron from '../uma-svgs/Chevron'

type ChevronTwoToneProps = {
  children: React.ReactNode
  className?: string
}

const ChevronTwoTone = ({ children, className }: ChevronTwoToneProps) => (
  <div className={`${className} py-10 py-lg-12 position-relative`}>
    <div className="color-screen" />
    <div className="img-screen" />
    <div className="container chevron-container text-center">
      <Chevron className="mb-8" />
    </div>
    {children}
  </div>
)

ChevronTwoTone.defaultProps = {
  className: '',
}

export default styled(ChevronTwoTone)`
  .color-screen,
  .img-screen {
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -1;
  }

  .color-screen {
    background-color: #333;
  }

  .img-screen {
    background-image: url(${darkChevron});
    background-repeat: repeat;
    background-size: contain;
    opacity: 0.5;
  }
`
