// Node Module Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Style Imports
import { colors } from '../../styles/utilities';

class Question extends Component {

  // Invoked when the user makes an answer selection; passes up to the handleSelection func on the quiz component
  handleClick = (answerID, programCodes) => {
    programCodes = programCodes.split(',');
    this.props.handleSelection(answerID, programCodes);
  }

  renderAnswerChoices = () => {
    const { children } = this.props;
    
    return children
      .map(child => (
        <button className={`answer answer-${child.id}`} key={child.image} onClick={() => this.handleClick(child.id, child.programs)}>
          <img src={child.image} alt={child.answer} title={child.answer} />
          <p>{child.answer}</p>
        </button>
      ))
  }

  render() {
    const { className } = this.props;

    return (
      <div className={`${className} Question`}>
        <h2>{this.props.question}</h2>
        <div className="answer-choice-wrapper">
          { this.renderAnswerChoices() }
        </div>
      </div>
    )
  }
}

export default styled(Question)`
  margin: auto;
  img {
    width: 100%;
  }
  .answer-choice-wrapper {
    .answer {
      background-color: #fafafa;
      border: none;
      padding: 20px;
      margin-bottom: 1em;
      box-shadow: 0 3px 8px rgba(0,0,0,.1);
      background: #fafafa;
      p {
        text-align: left;
        font-size: 1.1rem;
        font-weight: 700;
        color: ${colors.umaDarkBlue};
        padding-left: 10px;
        margin-bottom: 0;
      }
    }
  }
`;

Question.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  handleSelection: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired
}