import { FormTheme } from '.'

const lightBackgroundTheme: FormTheme = {
  css: () => `
    h2 { color: #000; }
    fieldset.input-wrapper { color: #000; }
    .disclaimer { color: #000; }
  `,
}

export default lightBackgroundTheme
