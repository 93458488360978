import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { above } from '../../styles/utilities'

const PS92020Hero = ({ bgImgSm, bgImgLg, bgPosition, children, className }) => {
  return (
    <div className={`section--hero ${className}`}>
      <div
        className="d-sm-none hero-img"
        style={{
          backgroundImage: `url(${bgImgSm})`,
        }}
      />
      <div
        className="d-none d-sm-block hero-img"
        style={{
          backgroundImage: `url(${bgImgLg})`,
        }}
      />

      {children}
    </div>
  )
}

PS92020Hero.propTypes = {
  bgImgSm: PropTypes.string.isRequired,
  bgImgLg: PropTypes.string.isRequired,
  bgPosition: PropTypes.string,
}

PS92020Hero.defaultProps = {
  bgPosition: '30% center',
}

export default styled(PS92020Hero)`
  padding: 2rem 0;
  position: relative;
  min-height: 462px;

  .hero-img {
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;

    ${above.sm`
      background-position: ${(props) => props.bgPosition};
    `}
  }
`
