// Node Module Imports
import React from 'react';
import styled from 'styled-components';

// Style Imports
import { NarrowContainer } from '../../../../styles/elements';
import { colors } from '../../../../styles/utilities';

const FooterWhiteCenetered = ({className, renderFooterText}) => (
  <section className={className}>
    <NarrowContainer className="footer">
      {renderFooterText()}
    </NarrowContainer>
  </section>
);

export default styled(FooterWhiteCenetered)`
  background-color: ${colors.umaUltimateLightGray};
  text-align: center;
  padding: 1.5rem 0;
  p {
    line-height: 1.4rem;
    font-size: .8rem;
    margin: 0;
  }
  a {
    color: ${colors.umaDarkGray};
    text-decoration: none;
  }
`;