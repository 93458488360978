export const returnUnderscores = length => {
  let str = '';
  for (let i = 0; i < length; i++) {
    str += '_'
  }
  return str;
};

export const applyPhoneMask = field => {
  if(!field) return;
  field.addEventListener('input', e => {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = '(' + x[1] + returnUnderscores(3-x[1].length) + ') ' + x[2] + returnUnderscores(3-x[2].length) + '-' + x[3] + returnUnderscores(4-x[3].length);
    let pos = !x[2].length ? x[1].length + 1 : x[1].length + 3 + x[2].length + x[3].length;
    pos += x[3].length ? 1 : 0;
    e.target.setSelectionRange(pos, pos)
  });
};

export const determineNeededFormParams = (pageClass, selectedCampus, tracking) => {
  return [
    {
      key: 'CID',
      override: /^cid$/gi,
      value: (() => {
        if(selectedCampus === 'Clearwater') {
          return 'SR_U_10530';
        }
        else if(pageClass.contains('show-state')) {
          return 'SR_CID-000051208180';
        }
        else if(pageClass.contains('show-address')) {
          return 'SR_CID-000031793933';
        }
        return 'SR_U_10365';
      })(),
    },
    {
      key: 'PostalCodeOrZip',
      value: (() => {
        if(pageClass.contains('show-address') || pageClass.contains('show-zip')) {
          return '';
        }
        return '00000';
      })()
    },
    {
      key: 'LeadSource',
      override: /^(leadsource|ld_src)$/gi,
      value: tracking.lead_source ? encodeURIComponent(tracking.lead_source) : 'INTS-PPC'
    },
    {
      key: 'LeadVendor',
      override: /^leadvendor$/gi,
      value: (() => {
        const dflt = 'PSM';
        if(typeof tracking.lead_vendor !== 'undefined') {
          return tracking.lead_vendor ? encodeURIComponent(tracking.lead_vendor) : dflt;
        }
        else if(typeof tracking.vendor !== 'undefined') {
          return tracking.vendor ? encodeURIComponent(tracking.vendor) : dflt;
        }
        return dflt;
      })(),
    },
    {
      key: 'Vendor',
      override: /^vendor$/gi,
      value: tracking.vendor ? encodeURIComponent(tracking.vendor) : 'PSM',
    },
  ];
}