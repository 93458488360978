// Node Module Imports
import React, { useEffect } from 'react';
import styled from 'styled-components';
import QSP from '@ultimatemedical/uma-utils/dist/query-string/parser';

// Component Imports
import VideoFC from './VideoFC';
import VideoTout from './VideoTout';

// Style Imports
import { NarrowContainer } from '../../styles/elements';

// Utility Imports
import getPagePath from '../../utilities/getPagePath';

const VideoWithTouts = ({className, loc, renderAboveVideo, videoInfo, videoTouts}) => {

  let name = new QSP(loc.search).get('fname');

  useEffect(() => {
    if (name) {
      const replace = document.querySelector('.replace');
      replace.innerHTML = `Hi ${name}, `;
    };
  });

  function renderTouts(toutObj) {
    if (!toutObj) {
      return;
    } else {
      return Object.keys(toutObj).map(tout => {
      const current = toutObj[tout].childMdx;
      const frontmatter = current.frontmatter;
      const linkPath = getPagePath({
        absolutePath: current.fileAbsolutePath,
        folderToServeAsRootForSlug: '/src/markdown/pages/',
        exclude: [
          /\.mdx?/,
        ]
      });
      return (
        <VideoTout
          key={linkPath}
          imgAlt={frontmatter.video.posterSmAlt}
          imgSrc={frontmatter.video.posterSm.childImageSharp.fluid}
          linkPath={linkPath}
          bodyText={current.body}
        />
      )});
    };
  };

  return (
    <section className={className}>
      <NarrowContainer>
        {renderAboveVideo()}
        <VideoFC
          embedCode={videoInfo.embedCode}
          posterAlt={videoInfo.posterAlt}
          posterSrc={videoInfo.poster.childImageSharp.fixed}
        />
        <section className="video-touts-wrapper">
          {renderTouts(videoTouts)}
        </section>
      </NarrowContainer>
    </section>
  )
}

export default styled(VideoWithTouts)`
  padding: 2rem 0;
  h1 {
    font-size: 1.5rem;
    margin: 0;
    .replace {
      text-transform: capitalize;
    }
  }
  .video-touts-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;