// This template is designed to look like the the root page on the Info2 site, https://info2.ultimatemedical.edu

// Node Module Imports
import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'
import axios from 'axios'
import parse from 'react-html-parser'

// Component Imports
import Form from '../../components/forms/AcormFormWrapper'
import GlobalFooter from '../../components/GlobalFooter'
import HeaderWithMenu from '../../components/HeadersAndHeroes/HeaderWithMenu'
import Layout from '../../layouts/DefaultLayout'
import Menu from '../../components/reusables/Menu'
import SEO from '../../components/SEO'
import StickyMobileFooter from '../../components/reusables/StickyMobileFooter'
import RememberTraceParams from '../../components/effects/RememberTraceParams'

// Asset Imports
import OldLadyImage from '../../images/info2-design/info2-circle-lady.png'
import HeroImg from '../../images/info2-design/info2-header-background.jpg'

// Style Imports
import { above, colors } from '../../styles/utilities'

// Hooks Imports
import useGa from '../../hooks/useGa'

// Redux Imports
import { store } from '../../redux'
import { AcornFormParams } from '../../redux/actions'

// Utility Imports
import findInObject from '../../utilities/findInObject'
import sp from '../../utilities/shortcodeParser'

const TemplateBrandOldRoot = ({ className, data }) => {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

  const selectedCampus = 'Online'

  const settings = {
      allowNull: true,
    },
    leadsource = findInObject(
      'tracking.leadsource',
      data.markdown.frontmatter,
      settings,
    ),
    leadvendor = findInObject(
      'tracking.leadvendor',
      data.markdown.frontmatter,
      settings,
    ),
    vendor = findInObject(
      'tracking.vendor',
      data.markdown.frontmatter,
      settings,
    ),
    phoneNumber = findInObject('phone_number', data.markdown.frontmatter, {
      strictMode: true,
    })

  if (leadsource) {
    store.dispatch(AcornFormParams.updateParamValue('LeadSource', leadsource))
  }
  if (leadvendor) {
    store.dispatch(AcornFormParams.updateParamValue('LeadVendor', leadvendor))
  }
  if (vendor) {
    store.dispatch(AcornFormParams.updateParamValue('Vendor', vendor))
  }

  const hideMenu = findInObject('hideMenu', data.markdown.frontmatter, settings)
  const hideStickyFooter = findInObject(
    'hideStickyFooter',
    data.markdown.frontmatter,
    settings,
  )
  const useFBAPI = findInObject('useFBAPI', data.markdown.frontmatter, settings)
  let headerLogoIsLink = findInObject(
    'headerLogoIsLink',
    data.markdown.frontmatter,
    settings,
  )
  headerLogoIsLink = headerLogoIsLink === false ? false : true

  const excludePrograms = data?.markdown?.frontmatter?.v1?.form?.excludePrograms?.map(
    (p) => p.code,
  )

  const formEvent = useGa()

  const closeModal = (e) => {
    if (displayMobileMenu && e.target === e.currentTarget) {
      setDisplayMobileMenu(false)
    }
  }

  const allTouts = data.touts.edges
  const imgOnlinePrograms = allTouts.find(
    (item) => item.node.wordpress_id === 922,
  ).node.acf.uma_brick_tout.icon.localFile.childImageSharp
  const imgJobSearch = allTouts.find((item) => item.node.wordpress_id === 923)
    .node.acf.uma_brick_tout.icon.localFile.childImageSharp
  const imgFinancialAid = allTouts.find(
    (item) => item.node.wordpress_id === 924,
  ).node.acf.uma_brick_tout.icon.localFile.childImageSharp
  const imgOnlineLearning = allTouts.find(
    (item) => item.node.wordpress_id === 925,
  ).node.acf.uma_brick_tout.icon.localFile.childImageSharp

  const [toutsExpanded, setToutsExpanded] = useState({
    onlinePrograms: false,
    jobSearch: false,
    financialAid: false,
    onlineLearning: false,
  })

  const handleToutClick = (e) => {
    const toutThatWasClicked = e.currentTarget.dataset.toutname
    const stateCopy = Object.assign({}, toutsExpanded)
    stateCopy[toutThatWasClicked] = !toutsExpanded[toutThatWasClicked]
    setToutsExpanded({ ...stateCopy })
  }

  return (
    <Layout>
      <RememberTraceParams whichForm="acorn" />

      <SEO
        title="Healthcare Career Training"
        description="The nation needs heroes in healthcare. The journey begins at Ultimate Medical Academy."
      />

      <div className={`${className} ${displayMobileMenu && `menu-open`}`}>
        <div className="header-wrapper">
          <div className="container">
            <HeaderWithMenu
              hideMenu={hideMenu}
              phoneNumber={phoneNumber}
              displayMobileMenu={displayMobileMenu}
              logoIsLink={headerLogoIsLink}
              setDisplayMobileMenu={setDisplayMobileMenu}
              selectedCampus={selectedCampus}
            />
          </div>
        </div>
        <div className="info2-header d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col-6 offset-4">
                <h1 className="fff healthcare-career-training">
                  HEALTHCARE CAREER TRAINING
                </h1>
              </div>
              <div className="col-2 hide-overflow">
                <img
                  className="circle-image"
                  src={OldLadyImage}
                  alt="UMA student"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-wrapper pb-2 d-lg-none">
          <div className="container-fluid form-container--mobile">
            <Form
              beforeSubmitCallback={async (formFields) => {
                if (useFBAPI) {
                  try {
                    await axios.post(
                      '/.netlify/functions/send-to-facebook',
                      formFields,
                    )
                  } catch (error) {
                    console.log(error)
                  }
                }
                formEvent({
                  category: 'rfi-form',
                  action: 'submit',
                  label: 'under-hero',
                })
              }}
              // fbc=false will turn off the facebook confirmation page pixel
              redirect={`/confirmation/?phone=${phoneNumber}${
                useFBAPI ? '&fbc=false' : ''
              }`}
              // redirect={false}
              aboveForm={() => (
                <>
                  <h2 className="mt-0 text-white mb-1 form-font">
                    Healthcare Career Training
                  </h2>
                  <p className="text-white mt-0 form-font bold">
                    Learn How We Can Help You Succeed
                  </p>
                </>
              )}
              includeProgramSelect={{
                Campus: '45',
                ProgramType: 'degree',
              }}
              excludePrograms={excludePrograms}
              className="form-container"
              removeFormParamsAndSpecifyDefaultValues={{
                Campus: '45',
                State: 'UNSPEC',
              }}
            />
          </div>
        </div>

        <div className="page-content pb-4">
          <div className="container py-lg-3">
            <div className="row">
              <div className="col-lg-4 p-0 order-1 order-lg-1 d-none d-lg-flex">
                <div className="form-wrap--lg px-4 pb-4">
                  <Form
                    beforeSubmitCallback={async (formFields) => {
                      if (useFBAPI) {
                        try {
                          await axios.post(
                            '/.netlify/functions/send-to-facebook',
                            formFields,
                          )
                        } catch (error) {
                          console.log(error)
                        }
                      }
                      formEvent({
                        category: 'rfi-form',
                        action: 'submit',
                        label: 'under-hero',
                      })
                    }}
                    // fbc=false will turn off the facebook confirmation page pixel
                    redirect={`/confirmation/?phone=${phoneNumber}${
                      useFBAPI ? '&fbc=false' : ''
                    }`}
                    // redirect={false}
                    aboveForm={() => (
                      <>
                        <h2 className="mt-0 text-white mb-1 text-center form-font bold">
                          Learn How
                        </h2>
                        <p className="text-white mt-0 text-center form-font bold">
                          We Can Help You Succeed
                        </p>
                      </>
                    )}
                    includeProgramSelect={{
                      Campus: '45',
                      ProgramType: 'degree',
                    }}
                    excludePrograms={excludePrograms}
                    className="form--lg"
                    removeFormParamsAndSpecifyDefaultValues={{
                      Campus: '45',
                      State: 'UNSPEC',
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-8 order-2 order-lg-2 pt-5 pt-lg-0">
                <div className="row">
                  <div className="d-sm-none col-12 text-center">
                    <img
                      className="circle-image small-circle"
                      src={OldLadyImage}
                      alt="UMA student"
                    />
                  </div>
                  <div className="d-none d-sm-block col-sm-3 d-lg-none px-0">
                    <img
                      className="circle-image"
                      src={OldLadyImage}
                      alt="UMA student"
                    />
                  </div>
                  <div className="col-12 col-sm-9 col-lg-9">
                    <h1 className="h2 color-blue-l">
                      Ultimate Medical Academy – Online
                      {parse(
                        sp(
                          "{{ function: 'fn', text: 'Coursework is online except for on-site externship/practicum in select programs. Deadlines are set by course instructors. Not all course room functions are accessible on mobile devices.' }}",
                        ),
                      )}
                    </h1>
                    <p>
                      As a UMA student, you get personalized attention from the
                      very beginning, throughout your career training, and after
                      you graduate. Here are just a few Ultimate Student
                      Services that are included in tuition:
                    </p>

                    <ul>
                      <li>One-on-one Academic Support</li>
                      <li>
                        Certification Preparation
                        {parse(
                          sp(
                            "{{ function: 'fn', text: 'Applicable to select online programs.' }}",
                          ),
                        )}
                      </li>
                      <li>24/7 Technical Support</li>
                      <li>Job Interview Coaching</li>
                      <li>Job Search Assistance</li>
                      <li>Laptops for those who qualify</li>
                    </ul>

                    <p>
                      Ultimate Medical Academy has a relationship with thousands
                      of employers across the U.S. to help our graduating
                      students find job opportunities.
                    </p>
                  </div>
                </div>

                <div className="touts">
                  <button
                    className="tout"
                    data-toutname="onlinePrograms"
                    onClick={handleToutClick}
                  >
                    <Img
                      fixed={imgOnlinePrograms.fixed}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <h4 className="fff my-0">
                      UMA Online
                      {parse(
                        sp(
                          "{{ shortcode: 'footnote', content: '${disclaimers.onlineProgram}' }}",
                        ),
                      )}{' '}
                      Programs
                    </h4>
                  </button>
                  <button
                    className="tout"
                    data-toutname="jobSearch"
                    onClick={handleToutClick}
                  >
                    <Img
                      fixed={imgJobSearch.fixed}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <h4 className="fff my-0">Job Search Assistance</h4>
                  </button>
                  <button
                    className="tout"
                    data-toutname="financialAid"
                    onClick={handleToutClick}
                  >
                    <Img
                      fixed={imgFinancialAid.fixed}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <h4 className="fff my-0">Financial Aid</h4>
                  </button>
                  <button
                    className="tout"
                    data-toutname="onlineLearning"
                    onClick={handleToutClick}
                  >
                    <Img
                      fixed={imgOnlineLearning.fixed}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <h4 className="fff my-0">Engaging Online Learning</h4>
                  </button>

                  {toutsExpanded.onlinePrograms && (
                    <div className="tout-expanded online-programs">
                      <div className="expanded-inner">
                        <div className="row">
                          <div className="col-4 col-sm-3 p-0">
                            <Img
                              fixed={imgOnlinePrograms.fixed}
                              imgStyle={{ objectFit: 'contain' }}
                            />
                          </div>
                          <div className="col-8 col-sm-9 pl-2 pr-0 px-sm-0">
                            <h4 className="fff">
                              UMA Online
                              {parse(
                                sp(
                                  "{{ shortcode: 'footnote', content: '${disclaimers.onlineProgram}' }}",
                                ),
                              )}{' '}
                              Programs
                            </h4>
                            <div className="row">
                              <div className="col-12 col-sm-6">
                                <ul className="m-0 pl-4 pl-sm-3">
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/healthcare-accounting-2"
                                    >
                                      Healthcare Accounting
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/health-and-human-services-2"
                                    >
                                      Health and Human Services
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/health-information-management-2"
                                    >
                                      Health Information Management
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/medical-administrative-assistant-2"
                                    >
                                      Health Sciences &mdash; Medical
                                      Administrative Assistant
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/medical-office-billing-specialist-2"
                                    >
                                      Health Sciences &mdash; Medical Office and
                                      Billing Specialist
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-12 col-sm-6">
                                <ul className="m-0 pl-4 pl-sm-3">
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/healthcare-management-2"
                                    >
                                      Healthcare Management
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/medical-billing-coding-2"
                                    >
                                      Medical Billing & Coding (Associate
                                      Degree)
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/medical-administrative-assistant-2"
                                    >
                                      Medical Administrative Assistant
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/medical-billing-coding-2"
                                    >
                                      Medical Billing & Coding (Diploma)
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="fff td-n"
                                      to="/online/medical-office-billing-specialist-2"
                                    >
                                      Medical Office and Billing Specialist
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="x-button"
                          data-toutname="onlinePrograms"
                          onClick={handleToutClick}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  )}

                  {toutsExpanded.jobSearch && (
                    <div className="tout-expanded job-search">
                      <div className="expanded-inner">
                        <div className="row">
                          <div className="col-3 p-0">
                            <Img
                              fixed={imgJobSearch.fixed}
                              imgStyle={{ objectFit: 'contain' }}
                            />
                          </div>
                          <div className="col-9 pl-2 pr-0 px-sm-0">
                            <h4 className="fff">Job Search Assistance</h4>
                            <p>
                              More than 225 UMA career support members help
                              graduates pursue job opportunities and schedule
                              interviews with potential employers.
                            </p>
                          </div>
                        </div>
                        <button
                          className="x-button"
                          data-toutname="jobSearch"
                          onClick={handleToutClick}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  )}

                  {toutsExpanded.financialAid && (
                    <div className="tout-expanded financial-aid">
                      <div className="expanded-inner">
                        <div className="row">
                          <div className="col-3 p-0">
                            <Img
                              fixed={imgFinancialAid.fixed}
                              imgStyle={{ objectFit: 'contain' }}
                            />
                          </div>
                          <div className="col-9 pl-2 pr-0 px-sm-0">
                            <h4 className="fff">Financial Aid</h4>
                            <p>
                              Financial aid is available for those who qualify.
                              Let us help you explore your options.
                            </p>
                          </div>
                        </div>
                        <button
                          className="x-button"
                          data-toutname="financialAid"
                          onClick={handleToutClick}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  )}

                  {toutsExpanded.onlineLearning && (
                    <div className="tout-expanded online-learning">
                      <div className="expanded-inner">
                        <div className="row">
                          <div className="col-3 p-0">
                            <Img
                              fixed={imgOnlineLearning.fixed}
                              imgStyle={{ objectFit: 'contain' }}
                            />
                          </div>
                          <div className="col-9 pl-2 pr-0 px-sm-00">
                            <h4 className="fff">Engaging Online Learning</h4>
                            <ul className="pl-3">
                              <li>
                                Get individual feedback from your instructors
                              </li>
                              <li>Watch educational videos</li>
                              <li>
                                Join the conversation on discussion boards
                              </li>
                              <li>Participate in live study group sessions</li>
                            </ul>
                          </div>
                        </div>
                        <button
                          className="x-button"
                          data-toutname="onlineLearning"
                          onClick={handleToutClick}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          <>
            <div
              aria-label="Navigation Toggle"
              role="button"
              tabIndex="0"
              className={`mobile-menu-wrap ${displayMobileMenu && `active`}`}
              onClick={closeModal}
              onKeyDown={(e) =>
                e.keyCode === 13 ? setDisplayMobileMenu(false) : null
              }
            ></div>
            <div className={`mobile-menu ${displayMobileMenu && `active`}`}>
              <Menu
                setDisplayMobileMenu={setDisplayMobileMenu}
                excludePrograms={excludePrograms}
                selectedCampus={selectedCampus}
              />
            </div>
          </>
        }

        <div className="quote-section py-4 d-none d-lg-block">
          <div className="container">
            <div className="sub-container">
              <h2 className="fff text-center fact">
                4 out of 5 surveyed employers say they are likely to hire a UMA
                Online grad again.
                {parse(
                  sp(
                    "{{ function: 'fn', text: 'Source: Survey was conducted by Hanover Research. Employers that hired UMA Online students between Jul 1, 2022 - September 30, 2023. Survey responses received from 257 employers. Excludes corporate alliance partners.' }}",
                  ),
                )}
              </h2>
              <p className="quote fff text-center mb-2">
                "UMA has consistently introduced solid candidates to our
                organization and truly cares for their students and graduates.
                They provide qualified applicants."
              </p>
              <p className="quote-by fff text-center mt-2">
                <em>Employer – Danielle Oliva, Network Synergy</em>
              </p>
            </div>
          </div>
        </div>

        <GlobalFooter
          phone={phoneNumber}
          sticky={hideStickyFooter === true ? false : true}
        />
        {/* {
      hideStickyFooter === true
        ? null
        : <StickyMobileFooter phone={phoneNumber} chatBtnId="LP_DIV_1432733350793" />
    } */}
      </div>
    </Layout>
  )
}

export default styled(TemplateBrandOldRoot)`

  color: #505759;
  font-family: Arial;
  font-size: 0.96rem;
  font-weight: 200;
  -moz-osx-font-smoothing: grayscale;

  .quote-section {
    background-color: #68478D;
    .sub-container {
      max-width: 74%;
      margin: auto;
    }
    .fact {
      font-size: 2rem;
      margin-bottom: 2rem;
      line-height: 1.5;
    }
    .quote {
      position: relative;
      font-size: 1rem;
      font-weight: 700;
      &::before {
        content: open-quote;
        font-size: 5em;
        position: absolute;
        top: -.65em;
        left: -3rem;
        font-family: Georgia, serif;
      }
    }
    .quote-by {
      font-size: 1rem;
    }
  }

  .touts {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.15rem;
    ${above.md`
      flex-wrap: nowrap;
    `}
    color: #fff;
    .tout {
      cursor: pointer;
      border: none;
      text-align: left;
      outline: none;
      padding: .5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 50%;
      min-height: 12rem;
      ${above.md`
        flex: 0 0 23%;
        min-height: 8rem;
      `}
      &:first-child {
        background-color: ${colors.umaLightBlue};
        img {
          width: 75px !important;
          height: 65px !important;
        }
      }
      &:nth-child(2) {
        background-color: ${colors.umaPink};
        img {
          width: 75px !important;
          height: 65px !important;
        }
      }
      &:nth-child(3) {
        background-color: ${colors.umaGreen};
        img {
          width: 75px !important;
          height: 75px !important;
        }
      }
      &:last-child {
        background-color: ${colors.umaPurple};
        img {
          width: 75px !important;
          height: 70px !important;
        }
      }
    }
    .tout-expanded {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      img {
        width: 75% !important;
        height: 75% !important;
        ${above.sm`
          width: 100% !important;
          height: 100% !important;
        `}
      }
      h4 {
        margin: 1.5rem 0 .5rem 0;
        ${above.sm`
          margin: 0 0 1rem 0;
        `}
      }
      &.online-programs {
        background-color: ${colors.umaLightBlue};
        overflow: hidden;
        li {
          font-size: .85rem;
          line-height: 1.15rem;
        }
      }
      &.job-search {
        background-color: ${colors.umaPink};
      }
      &.financial-aid {
        background-color: ${colors.umaGreen};
      }
      &.online-learning {
        background-color: ${colors.umaPurple};
      }
      .expanded-inner {
        position: relative;
        .x-button {
          position: absolute;
          top: 0;
          right: 0;
          background: transparent;
          color: #fff;
          border: none;
          cursor: pointer;
        }
      }
    }
  }

  p {
    line-height: 1.5;
  }

  .text-white {
    color: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  h1::not(.fff), h2 {
    color: ${colors.umaDarkBlue};
  }

  img {
    max-width: 100%;
  }

  .bg-img-wrap {
    min-height: 462px;
  }

  .bg-img {
    background-position: center top;
    background-size: cover;
    position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
  }

  .header-wrapper {
    background-color: ${colors.umaLightBlue};
    padding: .75rem 0;
  }

  [class^="form-wrap"] {
    background-color: ${colors.umaLightBlue};
    h2.form-font {
      font-size: 2rem;
    }
    p.form-font {
      font-size: 1rem;
      ${above.lg`
        font-size: 1.25rem;
      `}
    }
  }

  .form-wrapper {
    background-color: ${colors.umaLightBlue};
    > .container {
      padding: 0 7px;
    }
    .form-container {
      margin: 0 auto;
      padding: 0;
      max-width: 100%;
      h2, p {
        text-align: center;
      }
    }

    form {
      flex-wrap: wrap;
      justify-content: space-between;

      ${above.sm`
        display: flex;
      `}
    }

    form .input-wrap {
      flex: 0 0 48%;
      margin: 10px 0;
      ${above.sm`
        margin: 5px 0;
      `}
      &:nth-child(5) {
        flex: 0 0 100%;
      }
      input[type="text"], input[type="tel"], input[type="email"], select {
        font-size: 1rem;
      }
    }
    form .button-wrap {
      display: flex;
      flex: 0 0 100%;
      margin: 1.5rem 0;
    }
  }

  .info2-header {
    height: 14rem;
    background-image: url(${HeroImg});
    .healthcare-career-training {
      margin-top: 4.5rem;
    }
    .circle-image {
      transform: translateY(5rem);

      ${above.md`
        width: 190px;
        max-width: 190px;
        position: absolute;
        transform: translate(-2rem, 5rem);
      `}

      ${above.lg`
        width: 190px;
        max-width: 190px;
        transform: translateY(8rem);
      `}
    }
  }

  .page-content {

    h1 {
      margin-top: 0;
    }

    strong {
      color: ${colors.umaTeal};
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: bold;
    }

    .circle-image.small-circle {
      transform: scale(.6);
      margin-top: -3rem;
      margin-bottom: -2rem;
    }
  }

  .footer {
    background-color: ${colors.umaDarkBlue};
    color: #fff;
  }

  .phone {
    border-radius: 2px;
    color: ${colors.umaDarkBlue};
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    font-weight: normal;
    text-decoration: none;

    svg {
      transform: scaleX(-1);
      font-size: 1.5rem;

      ${above.sm`
        margin-right: 0.5rem;
        font-size: 1rem;
      `}
    }
  }

  .form-wrap--lg {
    position: relative;
    ${above.lg`
      margin-top: -12.8rem;
      padding-top: 0;
    `}

      &::before {
        background-color: ${colors.umaLightBlue};
        border-radius: 2px;
        content: '';
        display: block;
        opacity: 0.9;
        position: absolute;
          top: 0; right: 0; bottom: 0; left: 0;
        width: 100%;
        z-index: -1;
      }

    .form--lg {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .mobile-menu-wrap {
    background: ${colors.umaLightBlue};
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
      top: 0; bottom: 0; right: 0; left: -100%;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    &.active {
      display: block;
      left: 0;
      opacity: 0.9;
    }
  }

  .mobile-menu {
    background-color: ${colors.umaDarkGray};
    color: #fff;
    font-size: 0.9rem;
    max-width: 350px;
    padding: 1rem;
    position: fixed; 
      top: 0; bottom: 0; left: -100%;
    transition: all 0.2s ease-in-out;
    -webkit-font-smoothing: antialiased;

    width: 80%;
    z-index: 9;

    &.active {
      left: 0;

      a {
        padding: 0.2rem;
      }
      .close-wrap {
        text-align: right;
      }
    }

    a {
      display: block;
      color: #fff;
      line-height: 1.1rem;
      padding: 10rem 0;
      text-decoration: none; 
      transition: color 0.2s ease-in-out, padding 0.4s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        color: ${colors.umaGreenHover};
      }

    }

    ul {
      list-style: none;
      margin: 0.5rem 0 2rem;
      padding: 0 0 0 1rem;
    }

    li {
      line-height: 1.2rem;
      margin: 0.75rem 0 0.75rem 0.5rem;
      text-indent: -1.1rem;

      svg {
        margin-bottom: -2px;
        margin-right: 0.2rem;
      }
    }

    .menu-heading {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }

  .form-container--mobile {
    padding-left: 12px;
    padding-right: 12px;
  }

  .form-wrap {
    padding: 1rem 0;

    .input-wrap {
      margin: 0.75rem 0;
    }

    .button-wrap {
      margin: 1.5rem 0;
    }

  form .button-wrap {
    margin: 1rem 0;
  }

`

export const query = graphql`
  query($id: String!) {
    markdown: mdx(id: { eq: $id }) {
      frontmatter {
        useFBAPI
        hideMenu
        hideStickyFooter
        phone_number
        headerLogoIsLink
        tracking {
          leadsource
          leadvendor
          vendor
        }
        v1 {
          form {
            excludePrograms {
              code
            }
          }
        }
      }
      body
    }
    touts: allWordpressUmaBricks(
      filter: { wordpress_id: { in: [925, 924, 923, 922] } }
    ) {
      edges {
        node {
          wordpress_id
          acf {
            uma_brick_tout {
              icon {
                localFile {
                  childImageSharp {
                    fixed(quality: 100, width: 125) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
