import React from 'react'

import cx from 'classnames'

import logoSrc from '../../../assets/images/logos/rectangle-logo.png'
import { defaultTheme, logo } from './style.module.css'

type Props = {
  className?: string
  ctas?: (() => React.ReactElement) | null
  themes?: string | string[]
}

const HeaderThinStrip = ({ className, ctas, themes }: Props) => (
  <div className={cx(themes, className)}>
    <div className="container d-flex justify-content-center justify-content-md-between position-relative">
      <div>
        <img
          className={cx('logo position-relative py-2 py-md-0', logo)}
          src={logoSrc}
          alt="Ultimate Medical Academy"
          loading="eager"
        />
      </div>
      <div className="d-none d-md-block d-flex align-items-center">
        {ctas && ctas()}
      </div>
    </div>
  </div>
)

HeaderThinStrip.defaultProps = {
  className: '',
  ctas: null,
  themes: [defaultTheme],
}

export default HeaderThinStrip
