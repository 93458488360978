import { colorsDev as colors } from '../../../styles/utilities'
import findInObject from '../../../utilities/findInObject'

/**
 * color shortcode
 *
 * This is a content filter function. It receives content,
 * obj, and originalMatch from the shortcodeParser.
 *
 * {{ shortcode: "color", get: "${colors.blue.a}" [,opacity:1] }}
 *
 * @param {string} content - The full chunk of content
 * @param {object} obj - The shortcode as a JS object
 * @param {string} originalMatch - The original match, useful for replacing the shortcode
 *
 * @returns {string} content
 */
const color = (content, obj, originalMatch) => {
  const objType = findInObject('shortcode', obj)
  if (objType === 'color') {
    const color = findInObject('color', obj)
    const shade = findInObject('shade', obj)
    const opacity = findInObject('opacity', obj) || 1
    const hsla = colors[color][shade](opacity)
    content = content.replace(originalMatch, hsla)
  }
  return content
}

export default color
