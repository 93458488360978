// Node Module Imports
import htmr from 'htmr';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Style Imports
import { colors, hexToHSL } from '../../styles/utilities';

// Utility Imports
import sp from '../../utilities/shortcodeParser'

class ProgramToggle extends Component {
  
  state = {
    showDegreePrograms: this.props.selectedCampus !== 'Clearwater'
  }

  generateLi = (program, idx) => {
    let link = program.node.acf.uma_program_info.links.find(linkData => linkData.link_key === 'learn');
    return link
      ? (
        <li key={idx}>
          <a href={link.page_link.url}>{program.node.acf.uma_program_info.full_name}</a>
        </li>
      )
      : null
  }

  getOnlinePrograms = () => {
    const {progList} = this.props;
    return progList.filter(program => {
      return typeof program.node.acf.uma_program_info.campus !== 'undefined'
      && program.node.acf.uma_program_info.campus.value === 'online';
    });
  }

  getClearwaterPrograms = () => {
    const {progList} = this.props;
    return progList.filter(program => {
      return typeof program.node.acf.uma_program_info.campus !== 'undefined'
      && program.node.acf.uma_program_info.campus.value === 'clearwater';
    });
  }

  renderListOfDegreePrograms = () => {
    let onlinePrograms = this.getOnlinePrograms(),
        clearwaterPrograms = this.getClearwaterPrograms();
    if(onlinePrograms.length && clearwaterPrograms.length) {
      return (
        <>
          <h3>Online{htmr(sp("{{ shortcode: 'footnote', content: '${disclaimers.onlineProgram}' }}"))} Programs</h3>
          <ul>
            {
              onlinePrograms.map((program, idx) => {
                return program.node.acf.uma_program_info.type === 'degree' 
                  ? this.generateLi(program, idx)
                  : null
              })
            }
          </ul>
          <h3>Clearwater Programs</h3>
          <ul>
            {
              clearwaterPrograms.map((program, idx) => {
                return program.node.acf.uma_program_info.type === 'degree' 
                  ? this.generateLi(program, idx)
                  : null
              })
            }
          </ul>
        </>
      )
    }
    else if (onlinePrograms.length) {
      return (
        <ul>
          {
            onlinePrograms.map((program, idx) => {
              return program.node.acf.uma_program_info.type === 'degree' 
                ? this.generateLi(program, idx)
                : null
            })
          }
        </ul>
      )
    }
    else if (clearwaterPrograms.length) {
      return (
        <ul>
          {
            clearwaterPrograms.map((program, idx) => {
              return program.node.acf.uma_program_info.type === 'degree' 
                ? this.generateLi(program, idx)
                : null
            })
          }
        </ul>
      )
    }
    else {
      return null;
    }
  }

  renderListOfDiplomaPrograms = () => {
    let onlinePrograms = this.getOnlinePrograms(),
        clearwaterPrograms = this.getClearwaterPrograms();
    if(onlinePrograms.length && clearwaterPrograms.length) {
      return (
        <>
          <h3>Online{htmr(sp("{{ shortcode: 'footnote', content: '${disclaimers.onlineProgram}' }}"))} Programs</h3>
          <ul>
            {
              onlinePrograms.map((program, idx) => {
                return program.node.acf.uma_program_info.type === 'diploma' 
                  ? this.generateLi(program, idx)
                  : null
              })
            }
          </ul>
          <h3>Clearwater Programs</h3>
          <ul>
            {
              clearwaterPrograms.map((program, idx) => {
                return program.node.acf.uma_program_info.type === 'diploma' 
                  ? this.generateLi(program, idx)
                  : null
              })
            }
          </ul>
        </>
      )
    }
    else if (onlinePrograms.length) {
      return (
        <ul>
          {
            onlinePrograms.map((program, idx) => {
              return program.node.acf.uma_program_info.type === 'diploma' 
                ? this.generateLi(program, idx)
                : null
            })
          }
        </ul>
      )
    }
    else if (clearwaterPrograms.length) {
      return (
        <ul>
          {
            clearwaterPrograms.map((program, idx) => {
              return program.node.acf.uma_program_info.type === 'diploma' 
                ? this.generateLi(program, idx)
                : null
            })
          }
        </ul>
      )
    }
    else {
      return null;
    }
  }

  toggle = programType => {
    const { showDegreePrograms } = this.state;
    if (programType === 'degree' && !showDegreePrograms) {
      this.setState({
        showDegreePrograms: true
      });
    } else if (programType === 'diploma' && showDegreePrograms) {
      this.setState({
        showDegreePrograms: false
      });
    } else {
      return;
    }
  }

  setProgramListHeights = () => {
    let tallest = 0,
        programLists = [...document.querySelectorAll('.programs-list div')];
        
    programLists.forEach(pl => {
      pl.style.height = 'auto';
      tallest = pl.offsetHeight > tallest ? pl.offsetHeight : tallest;
    });

    programLists.forEach(pl => pl.style.height = `${tallest}px`);
  }

  componentDidMount = () => {
    this.setProgramListHeights();
    //window.onresize = this.setProgramListHeights;
  }

  renderTitle = () => {
    if(this.props.selectedCampus === "Online") {
      return <>UMA Online{htmr(sp("{{ shortcode: 'footnote', content: '${disclaimers.onlineProgram}' }}"))} Programs</>
    }
    else return `UMA ${ this.props.selectedCampus } PROGRAMS`
  }

  render() {
    const { className }          = this.props,
          { showDegreePrograms } = this.state;

    return (
      <div className={className}>
        <div className="programs-header">
          <div className="programs-header-inner">
            <h2 className="h2 all-caps">{this.renderTitle()}</h2>
          </div>
        </div>
        <div className="programs-toggle">
          <div className="programs-toggle-inner">
            <button className={`degrees tab ${showDegreePrograms ? '' : 'inactive'}`} onClick={() => this.toggle('degree')}>Associate Degrees{this.props.selectedCampus === "Clearwater" && <span>*</span>}</button>
            <button className={`diplomas tab ${showDegreePrograms ? 'inactive' : ''}`}  onClick={() => this.toggle('diploma')}>Diplomas</button>
          </div>
        </div>
        <div className="programs-list" >
          <div className={showDegreePrograms ? 'active' : ''}>
            { this.renderListOfDegreePrograms() }
            {this.props.selectedCampus === "Clearwater" && <p class="mt-4 asterisk-text">*Enrollment in an associate degree program is contingent upon completing its diploma program first.</p> }
          </div>
          <div className={showDegreePrograms ? '' : 'active'}>
            { this.renderListOfDiplomaPrograms() }
          </div>
        </div>
      </div>
    )
  }
}

export default styled(ProgramToggle)`
  width: 100%;
  background-color: ${props => `hsl(${hexToHSL(props.bgColor)})`};
  * {
    color: #fff;
  }

  sup.footnote__sup {
    font-size: 0.65em !important;
    padding-left: 0 !important;
  }

  .programs-header {
    background-color: #fff;
    .programs-header-inner {
      background-color: ${props => `hsla(${hexToHSL(props.bgColor)},0.75)`};
      padding: 15px 0;
      h1, h2, h3, h4, h5, h6 {
        color: #fff;
        display: block;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  .programs-toggle {
    background-color: #fff;
    .programs-toggle-inner {
      font-size: 1.5rem;
      cursor: pointer;
      display: flex;
      .tab {
        background-color: ${props => `hsl(${hexToHSL(props.bgColor)})`};
        border: 0;
        display: inline-block;
        flex: 1;
        padding: .5rem 1.5rem;

        &.inactive {
          background-color: ${props => `hsla(${hexToHSL(props.bgColor)},0.5)`};
        }
      }
    }
  }
  .programs-list {
    background-color: ${props => `hsl(${hexToHSL(props.bgColor)})`};
    padding: 1.5rem 1rem;
    position: relative;
    div {
      margin: 0;
      overflow: auto;
      padding: 0;
      position: absolute;
      visibility: hidden;

      &.active {
        position: relative;
        visibility: visible;
      }
    }
    h3:first-of-type {
      margin-top: 0;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      margin-left: 2rem;
      text-indent: -2.5rem;
      :before {
        color: ${colors.umaOrange};
        content: "\u25BA";
        margin: .5rem .5rem 0 1rem;
      }
    }
    a {
      text-decoration: none;
    }
    .asterisk-text {
      font-size: .8em;
      line-height: 1.2;
    }
  }`

ProgramToggle.propTypes = {
  bgColor: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  progList: PropTypes.array.isRequired,
  selectedCampus: PropTypes.string.isRequired
}