import { store } from '../redux'
import { addOrUpdateGlobal } from '../redux/actions'

class ProgramSelectSetupService {
  constructor(programSelectField, page) {
    if (typeof document === 'undefined') {
      console.log(
        `Please be sure run ${this.constructor.name} only after the component mounts.`,
      )
      return
    }
    this.programSelectField = programSelectField
    this.notSureOption = document.createElement('option')
    this.notSureOption.id = 'UNSPEC'
    this.notSureOption.value = 'UNSPEC'
    this.notSureOption.innerHTML = 'Not Sure'
    this.page = page
  }

  boot() {
    if (
      this.page.isClearwaterProgram &&
      !this.page.classes.contains('cpc') &&
      !this.page.classes.contains('cpl')
    ) {
      this.addOptionForNotSure()
    }
    if (
      this.page.classes.contains('cpc') ||
      this.page.classes.contains('cpl')
    ) {
      this.removeOptionForNotSure()
      this.removeSpecificProgramOptions(['HIT', 'HS-HTS'])
    }
    if (this.page.classes.contains('hide-prog-of-int')) {
      this.hideProgramOfInterestAndSelectNotSure()
    }
    if (!this.page.classes.contains('hide-prog-of-int')) {
      this.setProgramAndCampus()
    }
    this.removeValueFromDefaultSelect()
  }

  addOptionForNotSure() {
    this.programSelectField.appendChild(this.notSureOption)
  }

  /**
   * removeOptionForNotSure
   * Remove the "Not Sure" option from the form.
   *
   * @param {void}
   * @return {void}
   */
  removeOptionForNotSure() {
    this.programSelectField.removeChild(this.notSureOption)
    this.removeSpecificProgramOptions(['HIT', 'HS-HTS'])
  }

  hideProgramOfInterestAndSelectNotSure() {
    const progOfIntDiv = document.getElementById('Program_OnlineDiv')
    const notSureOption = progOfIntDiv.querySelector('#UNSPEC')
    if (progOfIntDiv) progOfIntDiv.style.display = 'none'
    if (notSureOption) notSureOption.setAttribute('selected', 'selected')
  }

  // This only runs on the personality quiz and program-specific pages
  setProgramAndCampus() {
    const suffixOA = ['MBC'],
      prefixHS = ['HTS', 'MAA', 'MOBS']
    let programOption = null

    // TODO: Remove the first condition from this ternary
    // when the Healthcare Accounting program goes live;
    // At that point in time we should add a new program
    // in WordPress for HCA instead of adding a specific
    // condition to account for it in the code
    const optionToSelect =
      this.page.classes.classList.indexOf('healthcare-accounting') >= 0
        ? 'HCA'
        : prefixHS.indexOf(this.page.program) >= 0
        ? `HS-${this.page.program}`
        : suffixOA.indexOf(this.page.program) >= 0
        ? `${this.page.program}`
        : this.page.program

    // TODO: Remove the third condition from the statement below when HCA goes live
    if (
      this.page.isQuiz ||
      this.page.isProgramPage ||
      optionToSelect === 'HCA'
    ) {
      let parentDiv = document.getElementById('Program_OnlineDiv')
      parentDiv.style.display = 'none'
      programOption = this.programSelectField.querySelector(
        `[name="${optionToSelect}"]`,
      )

      if (programOption) {
        programOption.setAttribute('selected', 'selected')
        store.dispatch(
          addOrUpdateGlobal('program_of_interest', programOption.value),
        )
      } else {
        let newOption = document.createElement('option')
        newOption.id = optionToSelect
        newOption.value = optionToSelect
        newOption.innerHTML = optionToSelect
        newOption.selected = 'selected'
        this.programSelectField.appendChild(newOption)
        store.dispatch(
          addOrUpdateGlobal('program_of_interest', newOption.value),
        )
      }

      if (this.page.isClearwaterProgram) {
        let campusFields = document.querySelectorAll('[name="Campus"]')
        campusFields.forEach((field) => {
          field.setAttribute('value', 47)
        })
      }
    }
  }

  /**
   * removeSpecificProgramOptions
   *
   * Remove some program options from the form by passing their
   * values to this function.
   *
   * @param {array} programCodes
   * @return {void}
   */
  removeSpecificProgramOptions(programCodes) {
    programCodes.forEach((programCode) => {
      let pc = programCode.toUpperCase(),
        programOption = this.programSelectField.querySelector(
          `option[value="${pc}"]`,
        )
      if (programOption) {
        this.programSelectField.removeChild(programOption)
      }
    })
  }

  /**
   * removeValueFromDefaultSelect
   *
   * Remove the value from the default selected option.
   * e.g. Change the value of the "Select One..." option
   * from "N_A" to "" so it won't pass validation if another
   * option is not selected
   *
   */
  removeValueFromDefaultSelect() {
    let defaultSelected = this.programSelectField.querySelectorAll(
      'option[value="N_A"]',
    )
    defaultSelected = [...defaultSelected]
    if (defaultSelected.length) {
      defaultSelected.forEach((ds) => {
        ds.setAttribute('value', '')
      })
    }
  }
}

export { ProgramSelectSetupService }
