const quotes = {
  employer: {
    satisfaction: {
      disclaimer:
        'Source: Survey was conducted by Hanover Research. Employers that hired UMA Online students between Jul 1, 2022 - September 30, 2023. Survey responses received from 257 employers. Excludes corporate alliance partners.',
      quote:
        '4 out of 5 surveyed employers say they are likely to hire a UMA Online grad again.',
    },
  },
}

export default quotes
