import React from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { above, colors } from '../../../styles/utilities'
import ChevronPattern from '../uma-svgs/ChevronPattern'

export type HeroChevronProps = {
  children: React.ReactElement
  className?: string
  imageSource: string
}

const HeroChevron = ({
  children,
  className,
  imageSource,
}: HeroChevronProps) => (
  <div className={cx(className, 'd-flex', 'position-relative', 'hero-chevron')}>
    <div className="bg-img-wrap">
      <img src={imageSource} alt="" loading="eager" />
    </div>

    <div className="overlay">
      <ChevronPattern className="chevron-pattern" />
    </div>

    <div className="container d-flex">
      <div className="hero-content-wrap position-relative d-flex align-items-center justify-content-center">
        <div className="hero-content-container container p-0">{children}</div>
      </div>
    </div>
  </div>
)

HeroChevron.defaultProps = {
  className: '',
}

export default styled(HeroChevron)`
  --rotation: 45deg;

  overflow: hidden;
  min-height: 400px;

  .bg-img-wrap {
    position: absolute;
    inset: 0;

    img {
      float: right;
    }
  }

  .overlay {
    outline: 2rem solid ${colors.dev.green.b(0.75)};
    background-color: ${colors.umaDarkBlue};
    position: absolute;
    inset: 0;
    top: -8px;
    transform: rotate(var(--rotation));
    transform-origin: right top;
    height: 400%;
    overflow: hidden;
    width: 81%;

    ${above.md`
      width: 60%;
    `}

    .chevron-pattern {
      transform: rotate(calc(-1 * var(--rotation)));
      transform-origin: top right;
      svg path {
        fill: #fff;
      }
    }
  }

  .hero-content-wrap {
    flex: 0 0 50%;
  }

  .hero-content-container * {
    color: #fff;
  }
`
