/* eslint-disable import/prefer-default-export */

/**
 * Creates a random string of letters and numbers of `n` length. The default
 * length is 7 characters. This is a lighter-weight solution than uuid but
 * obviously not as robust. Good for simple places throughout the app where you
 * need random strings like in providing a key to react components.
 */
export function makeid(length: number = 7) {
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
