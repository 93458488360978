/* eslint-disable brace-style, import/prefer-default-export */

import { AxiosError } from 'axios'

import { newTeamsPayload, TeamsPayload } from './payload'
import { Fact } from './types'

/**
 * Converts an axios error into an array of teams facts.
 * The way errors are handled is based on the axios documentation.
 * See https://github.com/axios/axios#handling-errors
 */
function errorToFacts(error: AxiosError): Fact[] {
  let facts: { name: string; value: string }[]

  // The request was made and the server responded with a status code that falls
  // out of the range of 2xx
  if (error.response) {
    facts = [
      {
        name: 'Error Message',
        value: JSON.stringify(error.message),
      },
      {
        name: 'Error Response Data',
        value: JSON.stringify(error.response.data),
      },
      {
        name: 'Error Response Status',
        value: JSON.stringify(error.response.status),
      },
      {
        name: 'Error Response Headers',
        value: JSON.stringify(error.response.headers),
      },
      {
        name: 'Full Error',
        value: JSON.stringify(error),
      },
    ]
  }

  // The request was made but no response was received `error.request` is an
  // instance of XMLHttpRequest in the browser and an instance of
  // http.ClientRequest in node.js
  else if (error.request) {
    facts = [
      {
        name: 'Error Request',
        value: JSON.stringify(error.request),
      },
      {
        name: 'Error Message',
        value: JSON.stringify(error.message),
      },
      {
        name: 'Full Error',
        value: JSON.stringify(error),
      },
    ]
  }

  // Something happened in setting up the request that triggered an Error
  else {
    facts = [
      {
        name: 'Error Message',
        value: JSON.stringify(error.message),
      },
      {
        name: 'Full Error',
        value: JSON.stringify(error),
      },
    ]
  }

  facts = [
    ...facts,
    {
      name: 'Error Config',
      value: JSON.stringify(error.config),
    },
    {
      name: 'Error JSON',
      value: JSON.stringify(error),
    },
  ]

  return facts
}

/**
 * Converts an axios error into a teams notification.
 */
export function errorToTeamsNotification(error: AxiosError): TeamsPayload {
  const facts = errorToFacts(error)
  const notification = newTeamsPayload({ facts, success: false })
  return notification
}
