// Node Module Imports
import styled from 'styled-components';
import { Link } from 'gatsby';

export const UnderlinedLink = styled(Link)`
  display: inline-block;
  line-height: normal;
  text-decoration: none;
  color: ${props => props.color};
  &:after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${props => props.color};
    transition: all .3s ease-in-out;
  }
  &:hover:after {
    width: 100%;
  }
`;